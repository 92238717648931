import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccountChangePassword from 'src/components/Account/AccountChangePassword';
import AccountContactUs from 'src/components/Account/AccountContactUs';
import AccountMemberships from 'src/components/Account/AccountMemberships';
import AccountPersonalInformation from 'src/components/Account/AccountPersonalInformation';
import AccountUserPrivacy from 'src/components/Account/AccountUserPrivacy';
import AccountVolunteer from 'src/components/Account/AccountVolunteer';
import { openModal } from 'src/redux/actions/modal';
import { Drawer } from '@mui/material';
import { isMobile } from 'react-device-detect';

const AccountContent = () => {
  const user = useSelector(state => state.userStore?.user);
  const [currentIndex, setCurrentIndex] = useState();
  const [currentComponent, setCurrentComponent] = useState();
  const [drawerOpen, setDrawerOpen] = useState();
  const dispatch = useDispatch();

  const tabs = [{
    label: 'Personal Information',
    component: AccountPersonalInformation
  }, {
    label: 'Contact Me',
    component: AccountContactUs
  }, {
    label: 'User Privacy',
    component: AccountUserPrivacy
  }, {
    label: 'Memberships & Affiliations',
    component: AccountMemberships
  }, {
    label: 'Volunteer To Judge',
    component: AccountVolunteer
  }, {
    label: 'Change Password',
    component: AccountChangePassword
  }];

  const handleHeadshot = () => {
    dispatch(openModal({ key: 'updateUserHeadshot' }));
  }

  const handleClose = () => {
    setDrawerOpen(false);
  }

  const handleTabChange = (tab, index, showModal) => {
    const Component = tab.component;
    setCurrentIndex(index);
    setCurrentComponent(() => <Component handleClose={handleClose} />);
    if (showModal && isMobile) {
      setDrawerOpen(true);
    }
  }

  useEffect(() => {
    handleTabChange(tabs[0], 0, false);
  }, []);

  return (
    <section className="my-account">
      <aside className="my-account-tabs">
        <h2 className="my-account-tabs-title">My Account</h2>
        <img
          className="my-account-headshot"
          onClick={handleHeadshot}
          src={user?.headshot}
        />
        <h3 className="my-account-name">{user?.fullName}</h3>
        {
          tabs.map((tab, index) => 
            <div
              className={`my-account-tabs-item${currentIndex === index ? ' active': ''}`}
              key={tab.label}
              onClick={() => handleTabChange(tab, index, true)}
            >
              <div className="my-account-tabs-item-label">{tab.label}</div>
            </div>
          )
        }
      </aside>
      {
        !isMobile && <div className="my-account-content desktop">{currentComponent}</div>
      }
      { isMobile && 
        <Drawer
          anchor="right"
          className="my-account-content mobile"
          ModalProps={{
            keepMounted: true,
          }}
          open={drawerOpen}
          sx={{
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
            },
          }}
          variant="temporary"
        >{currentComponent}</Drawer>
        }
    </section>
  )
}

export default AccountContent;
