import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, MenuItem, Select, TextField } from '@mui/material';
import FileUploader from 'src/components/Elements/FileUploader';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import DragDrop from 'src/components/DragDrop';
import { defaultFilterOption } from 'src/constants';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const ResourceList = ({
  actions,
  activeItem,
  draggable,
  fetching,
  filters,
  handleDrag,
  itemDisabled,
  list,
  notSelectable,
  onClickFn,
  onFilterChange,
  pageSize,
  renderHeaders,
  renderItem,
  renderKey,
  searchProp,
  title,
  updating
}) => {
  const modalRequest = useSelector(state => state.modalStore?.modal);
  const dialogRequest = useSelector(state => state.modalStore?.dialog);
  const [selectedItem, setSelectedItem] = useState();
  const [searchText, setSearchText] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState('');
  const [filterOptions, setFilterOptions] = useState();
  const [filterOptionArray, setFilterOptionArray] = useState();
  const [selectedSecondaryFilterOption, setSelectedSecondaryFilterOption] = useState('');
  const [secondaryFilterOptions, setSecondaryFilterOptions] = useState();
  const [secondaryFilterOptionArray, setSecondaryFilterOptionArray] = useState();
  const [filteredList, setFilteredList] = useState(list);
  const [page, setPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(pageSize || 0);

  useEffect(() => {
    if (filters) {
      const filterOptions = filters.noAll ? { ...filters.options } : { all: {
        fn: () => true,
        label: 'All'
      }, ...filters.options };
      setSelectedFilterOption(filters.default || defaultFilterOption);
      setFilterOptions(filterOptions);
      setFilterOptionArray(Object.keys(filterOptions).map(key => {
        return {
          label: filterOptions[key].label,
          value: key
        };
      }));

      if (filters.secondaryOptions) {
        const secondaryFilterOptions = filters.noAll ? { ...filters.secondaryOptions } : { all: {
          fn: () => true,
          label: 'All'
        }, ...filters.secondaryOptions };
        setSelectedSecondaryFilterOption(filters.secondaryDefault || defaultFilterOption);
        setSecondaryFilterOptions(secondaryFilterOptions);
        setSecondaryFilterOptionArray(Object.keys(secondaryFilterOptions).map(key => {
          return {
            label: secondaryFilterOptions[key].label,
            value: key
          };
        }));
      }
    }
  }, [filters]);

  useEffect(() => {
    if (modalRequest?.activeClose || dialogRequest?.activeClose) {
      setSelectedItem();
    }
  }, [modalRequest?.activeClose, dialogRequest?.activeClose]);

  useEffect(() => {
    setFilteredList(filterList({ selectedFilterOption, selectedSecondaryFilterOption, searchText }));
    if ((list || []).indexOf(selectedItem) === -1) {
      setSelectedItem();
    }
    setPage(1);
  }, [filters, list, selectedFilterOption, selectedSecondaryFilterOption]);

  const filterList = ({ selectedFilterOption, selectedSecondaryFilterOption, searchText }) => {
    if (!filters) {
      return list;
    }
    
    const lowerSearchText = searchText.toLowerCase();

    const filteredList = (list || [])?.filter(item => {
      const searchObject = searchProp ? (item[searchProp] || {}) : null;

        return (!searchText ||
          searchText.trim() === '' ||
          (filters?.searchBy || []).some(key => 
            (searchObject ? (searchObject[key] || '').toLowerCase().includes(lowerSearchText) : false) ||
            (item[key] || '').toLowerCase().includes(lowerSearchText)
          )
        ) && (
          !selectedFilterOption || 
          selectedFilterOption === defaultFilterOption ||
          (typeof filterOptions[selectedFilterOption]?.fn === 'function' ? filterOptions[selectedFilterOption]?.fn(item) : true)
        ) && (
          !selectedSecondaryFilterOption ||
          selectedSecondaryFilterOption === defaultFilterOption ||
          (typeof secondaryFilterOptions[selectedSecondaryFilterOption]?.fn === 'function' ? secondaryFilterOptions[selectedSecondaryFilterOption]?.fn(item) : true)
        )
      }
    );

    const selectedItemInFilteredList = filteredList?.some(item => item === selectedItem);
    if (!selectedItemInFilteredList) {
      setSelectedItem();
    }

    return filteredList;
  }

  const handlePaginationChange = (event, page) => {
    setPage(page);
    setStartIndex((page - 1) * (pageSize || 0));
    setEndIndex(((page - 1) * (pageSize || 0)) + (pageSize || 0));
  }

  const handleFilterInputChange = (event) => {
    setSearchText(event.target.value);
    setFilteredList(filterList({ selectedFilterOption, selectedSecondaryFilterOption, searchText: event.target.value }));
  }

  const handleFilterSelectChange = (event) => {
    setSelectedFilterOption(event.target.value);
    const filteredList = filterList({ selectedFilterOption: event.target.value, selectedSecondaryFilterOption, searchText })
    setFilteredList(filteredList);
    if (typeof onFilterChange === 'function') {
      onFilterChange({ list: filteredList, selectedFilterOption: event.target.value, selectedSecondaryFilterOption });
    }
  }

  const handleSecondaryFilterSelectChange = (event) => {
    setSelectedSecondaryFilterOption(event.target.value);
    const filteredList = filterList({ selectedFilterOption, selectedSecondaryFilterOption: event.target.value, searchText });
    setFilteredList(filteredList);
    if (typeof onFilterChange === 'function') {
      onFilterChange({ list: filteredList, selectedFilterOption, selectedSecondaryFilterOption: event.target.value });
    }
  }

  const renderListItem = ({ item }) => {
    const active = typeof activeItem === 'function' ? activeItem(item) : false;
    const disabled = typeof itemDisabled === 'function' ? itemDisabled(item) : false;
    return (
      <div
        className={`item${item === selectedItem ? ' selected' : ''}${disabled ? ' disabled' : ''}${active ? ' active' : ''}`}
        key={item[renderKey]}
        onClick={() => {
          if (disabled) {
            return;
          }
          
          setSelectedItem(item);
          if (typeof onClickFn === 'function') {
            onClickFn(item);
          }
        }}
      >
        {!notSelectable && (
          <input
            checked={item === selectedItem}
            onChange={() => setSelectedItem(item)}
            name="selectedItem"
            type="radio"
            value={item[renderKey]}
          />
        )}
        {
          typeof renderItem === 'function' && (
            renderItem(item)
          ) 
        }
      </div>
    )
  }

  return (
    <section className="resource-list box">
      <div className="box-title">
        <h3>
          <span>{title}
          {
            (fetching || updating) && (
              <LoadingCircle variant="dark" />
            )
          }
          </span>
        </h3>
        {
          (!fetching && actions?.length > 0) && 
            <div className="actions">
              {
                actions.map(action => {
                  return (
                    <div key={action.label || action.fileUpload.label}>
                    {
                      !action.fileUpload && (
                        <Button
                          className="action"
                          color={action.color || 'primary'}
                          disabled={(action.requiresSelection && !selectedItem) || (typeof action.disabled === 'function' && action.disabled(selectedItem))}
                          onClick={() => {action.fn(selectedItem)}}
                          variant={action.variant || 'outlined'}
                        >{typeof action.label === 'function' ? action.label(selectedItem) : action.label}</Button>
                      )
                    }
                    {
                      action.fileUpload && (
                        <FileUploader
                          accept={action.fileUpload.accept || ''}
                          className="action"
                          color={action.color || 'primary'}
                          disabled={(action.requiresSelection && !selectedItem) || (typeof action.disabled === 'function' && action.disabled(selectedItem))}
                          id="file-upload"
                          label={action.fileUpload.label || ''}
                          multiple={action.fileUpload.multiple || false}
                          onChange={action.fileUpload.onChange}
                          requestPrefix={action.fileUpload.requestPrefix || ''}
                        />
                      )
                    }
                    </div>
                  )
                })
              }
            </div>
        }
      </div>
      {
        !fetching && (
          <div className="box-content">
                        {
              filters && (
                <div className="filters">
                  {
                    filters.searchBy && (
                      <TextField
                        autoComplete="off"
                        name="searchText"
                        onChange={handleFilterInputChange}
                        placeholder="Search..."
                        type="text"
                        value={searchText}
                      />
                    )
                  }
                  {
                    filters?.options && (
                      <span>
                        <label>Filter by: </label>
                        <Select 
                          onChange={handleFilterSelectChange}
                          value={selectedFilterOption}
                        >{
                          filterOptionArray?.map(option => {
                            return (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                              >{option.label}</MenuItem>
                            )
                          })
                        }</Select>
                      </span>
                    )
                  }
{
                    filters?.secondaryOptions && (
                      <span>
                        <label>Filter by: </label>
                        <Select 
                          onChange={handleSecondaryFilterSelectChange}
                          value={selectedSecondaryFilterOption}
                        >{
                          secondaryFilterOptionArray?.map(option => {
                            return (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                              >{option.label}</MenuItem>
                            )
                          })
                        }</Select>
                      </span>
                    )
                  }
                </div>
              )
            }
            <div className="list">
              {
                (typeof renderHeaders === 'function' && !!filteredList?.length) && (
                  <div className="headers">
                    { renderHeaders() }
                  </div>
                )
              }
              {
                draggable && (
                  <DragDrop
                    droppableId={`${renderKey}-list`}
                    handleDrag={handleDrag}
                    list={filteredList}
                    renderKey={renderKey}
                    renderListItem={renderListItem}
                    setList={setFilteredList}
                  />
                )
              }
              {
                !draggable && (
                  (pageSize && (filteredList || []).length > pageSize) ? <>
                    { (filteredList || []).slice(startIndex, endIndex).map(item => renderListItem({ item })) }
                    <Stack
                      alignItems="center"
                      sx={{ margin: '12px 0' }}
                    >
                      <Pagination
                        count={Math.ceil((filteredList || []).length / pageSize)}
                        color="primary"
                        onChange={handlePaginationChange}
                        page={page}
                      />
                    </Stack>
                  </> : (filteredList || []).map(item => renderListItem({ item }))
                )
              }
              {
                (!filteredList?.length) && (
                  <div className="item disabled">
                    No Records Found
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    </section>
  )
}

export default ResourceList;
