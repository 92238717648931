export const ARCHIVE_CONTEST_REQUEST = 'ARCHIVE_CONTEST_REQUEST';
export const ARCHIVE_CONTEST_SUCCESS = 'ARCHIVE_CONTEST_SUCCESS';
export const ARCHIVE_CONTEST_FAILED = 'ARCHIVE_CONTEST_FAILED';

export const ASSIGN_CONTEST_AWARDS_REQUEST = 'ASSIGN_CONTEST_AWARDS_REQUEST';
export const ASSIGN_CONTEST_AWARDS_SUCCESS = 'ASSIGN_CONTEST_AWARDS_SUCCESS';
export const ASSIGN_CONTEST_AWARDS_FAILED = 'ASSIGN_CONTEST_AWARDS_FAILED';

export const CLEAR_CONTEST_VOLUNTEERS = 'CLEAR_CONTEST_VOLUNTEERS';
export const CLEAR_CONTEST_VOLUNTEERS_SUCCESS = 'CLEAR_CONTEST_VOLUNTEERS_SUCCESS';

export const CLEAR_SCORING_PROGRESS = 'CLEAR_SCORING_PROGRESS';

export const CLEAR_SCORING_PROGRESS_ENTRIES = 'CLEAR_SCORING_PROGRESS_ENTRIES';
export const CLEAR_SCORING_PROGRESS_ENTRIES_SUCCESS = 'CLEAR_SCORING_PROGRESS_ENTRIES_SUCCESS';

export const CLEAR_SCORING_PROGRESS_JUDGES = 'CLEAR_SCORING_PROGRESS_JUDGES';
export const CLEAR_SCORING_PROGRESS_JUDGES_SUCCESS = 'CLEAR_SCORING_PROGRESS_JUDGES_SUCCESS';

export const CONVERT_CONTEST_JUDGE_PROSPECT_REQUEST = 'CONVERT_CONTEST_JUDGE_PROSPECT_REQUEST';
export const CONVERT_CONTEST_JUDGE_PROSPECT_SUCCESS = 'CONVERT_CONTEST_JUDGE_PROSPECT_SUCCESS';
export const CONVERT_CONTEST_JUDGE_PROSPECT_FAILED = 'CONVERT_CONTEST_JUDGE_PROSPECT_FAILED';

export const CREATE_CONTEST_REQUEST = 'CREATE_CONTEST_REQUEST';
export const CREATE_CONTEST_SUCCESS = 'CREATE_CONTEST_SUCCESS';
export const CREATE_CONTEST_FAILED = 'CREATE_CONTEST_FAILED';

export const CREATE_CONTEST_ARCHIVE_REQUEST = 'CREATE_CONTEST_ARCHIVE_REQUEST';
export const CREATE_CONTEST_ARCHIVE_SUCCESS = 'CREATE_CONTEST_ARCHIVE_SUCCESS';
export const CREATE_CONTEST_ARCHIVE_FAILED = 'CREATE_CONTEST_ARCHIVE_FAILED';

export const DOWNLOAD_CONTEST_ENTRY_FORM_REQUEST = 'DOWNLOAD_CONTEST_ENTRY_FORM_REQUEST';
export const DOWNLOAD_CONTEST_ENTRY_FORM_SUCCESS = 'DOWNLOAD_CONTEST_ENTRY_FORM_SUCCESS';
export const DOWNLOAD_CONTEST_ENTRY_FORM_FAILED = 'DOWNLOAD_CONTEST_ENTRY_FORM_FAILED';

export const DOWNLOAD_CONTEST_REGISTRATIONS_REQUEST = 'DOWNLOAD_CONTEST_REGISTRATIONS_REQUEST';
export const DOWNLOAD_CONTEST_REGISTRATIONS_SUCCESS = 'DOWNLOAD_CONTEST_REGISTRATIONS_SUCCESS';
export const DOWNLOAD_CONTEST_REGISTRATIONS_FAILED = 'DOWNLOAD_CONTEST_REGISTRATIONS_FAILED';

export const DOWNLOAD_CONTEST_SCORES_REQUEST = 'DOWNLOAD_CONTEST_SCORES_REQUEST';
export const DOWNLOAD_CONTEST_SCORES_SUCCESS = 'DOWNLOAD_CONTEST_SCORES_SUCCESS';
export const DOWNLOAD_CONTEST_SCORES_FAILED = 'DOWNLOAD_CONTEST_SCORES_FAILED';

export const DOWNLOAD_CONTEST_WINNERS_REQUEST = 'DOWNLOAD_CONTEST_WINNERS_REQUEST';
export const DOWNLOAD_CONTEST_WINNERS_SUCCESS = 'DOWNLOAD_CONTEST_WINNERS_SUCCESS';
export const DOWNLOAD_CONTEST_WINNERS_FAILED = 'DOWNLOAD_CONTEST_WINNERS_FAILED';

export const DUPLICATE_APPLICATION_FORM_REQUEST = 'DUPLICATE_APPLICATION_FORM_REQUEST';
export const DUPLICATE_APPLICATION_FORM_SUCCESS = 'DUPLICATE_APPLICATION_FORM_SUCCESS';
export const DUPLICATE_APPLICATION_FORM_FAILED = 'DUPLICATE_APPLICATION_FORM_FAILED';

export const DUPLICATE_CONTEST_REQUEST = 'DUPLICATE_CONTEST_REQUEST';
export const DUPLICATE_CONTEST_SUCCESS = 'DUPLICATE_CONTEST_SUCCESS';
export const DUPLICATE_CONTEST_FAILED = 'DUPLICATE_CONTEST_FAILED';

export const DUPLICATE_ENTRY_FORM_REQUEST = 'DUPLICATE_ENTRY_FORM_REQUEST';
export const DUPLICATE_ENTRY_FORM_SUCCESS = 'DUPLICATE_ENTRY_FORM_SUCCESS';
export const DUPLICATE_ENTRY_FORM_FAILED = 'DUPLICATE_ENTRY_FORM_FAILED';

export const DUPLICATE_SCORESHEET_REQUEST = 'DUPLICATE_SCORESHEET_REQUEST';
export const DUPLICATE_SCORESHEET_SUCCESS = 'DUPLICATE_SCORESHEET_SUCCESS';
export const DUPLICATE_SCORESHEET_FAILED = 'DUPLICATE_SCORESHEET_FAILED';

export const GET_CONTEST_REQUEST = 'GET_CONTEST_REQUEST';
export const GET_CONTEST_SUCCESS = 'GET_CONTEST_SUCCESS';
export const GET_CONTEST_FAILED = 'GET_CONTEST_FAILED';

export const GET_CONTEST_ARCHIVES_REQUEST = 'GET_CONTEST_ARCHIVES_REQUEST';
export const GET_CONTEST_ARCHIVES_SUCCESS = 'GET_CONTEST_ARCHIVES_SUCCESS';
export const GET_CONTEST_ARCHIVES_FAILED = 'GET_CONTEST_ARCHIVES_FAILED';

export const GET_CONTEST_LIST_REQUEST = 'GET_CONTEST_LIST_REQUEST';
export const GET_CONTEST_LIST_SUCCESS = 'GET_CONTEST_LIST_SUCCESS';
export const GET_CONTEST_LIST_FAILED = 'GET_CONTEST_LIST_FAILED';

export const GET_CONTEST_PUBLIC_VOTES_REQUEST = 'GET_CONTEST_PUBLIC_VOTES_REQUEST';
export const GET_CONTEST_PUBLIC_VOTES_SUCCESS = 'GET_CONTEST_PUBLIC_VOTES_SUCCESS';
export const GET_CONTEST_PUBLIC_VOTES_FAILED = 'GET_CONTEST_PUBLIC_VOTES_FAILED';

export const GET_CONTEST_WINNERS_REQUEST = 'GET_CONTEST_WINNERS_REQUEST';
export const GET_CONTEST_WINNERS_SUCCESS = 'GET_CONTEST_WINNERS_SUCCESS';
export const GET_CONTEST_WINNERS_FAILED = 'GET_CONTEST_WINNERS_FAILED';

export const GET_SCORING_PROGRESS_BY_ENTRY_REQUEST = 'GET_SCORING_PROGRESS_BY_ENTRY_REQUEST';
export const GET_SCORING_PROGRESS_BY_ENTRY_SUCCESS = 'GET_SCORING_PROGRESS_BY_ENTRY_SUCCESS';
export const GET_SCORING_PROGRESS_BY_ENTRY_FAILED = 'GET_SCORING_PROGRESS_BY_ENTRY_FAILED';

export const GET_SCORING_PROGRESS_BY_JUDGE_REQUEST = 'GET_SCORING_PROGRESS_BY_JUDGE_REQUEST';
export const GET_SCORING_PROGRESS_BY_JUDGE_SUCCESS = 'GET_SCORING_PROGRESS_BY_JUDGE_SUCCESS';
export const GET_SCORING_PROGRESS_BY_JUDGE_FAILED = 'GET_SCORING_PROGRESS_BY_JUDGE_FAILED';

export const GET_SCORING_PROGRESS_FOR_ENTRY_REQUEST = 'GET_SCORING_PROGRESS_FOR_ENTRY_REQUEST';
export const GET_SCORING_PROGRESS_FOR_ENTRY_SUCCESS = 'GET_SCORING_PROGRESS_FOR_ENTRY_SUCCESS';
export const GET_SCORING_PROGRESS_FOR_ENTRY_FAILED = 'GET_SCORING_PROGRESS_FOR_ENTRY_FAILED';

export const GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE_REQUEST = 'GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE_REQUEST';
export const GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE_SUCCESS = 'GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE_SUCCESS';
export const GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE_FAILED = 'GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE_FAILED';

export const GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY_REQUEST = 'GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY_REQUEST';
export const GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY_SUCCESS = 'GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY_SUCCESS';
export const GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY_FAILED = 'GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY_FAILED';

export const REMOVE_CONTEST_APPLICATION_FOLDER_REQUEST = 'REMOVE_CONTEST_APPLICATION_FOLDER_REQUEST';
export const REMOVE_CONTEST_APPLICATION_FOLDER_SUCCESS = 'REMOVE_CONTEST_APPLICATION_FOLDER_SUCCESS';
export const REMOVE_CONTEST_APPLICATION_FOLDER_FAILED = 'REMOVE_CONTEST_APPLICATION_FOLDER_FAILED';

export const REMOVE_CONTEST_APPLICATION_FORM_REQUEST = 'REMOVE_CONTEST_APPLICATION_FORM_REQUEST';
export const REMOVE_CONTEST_APPLICATION_FORM_SUCCESS = 'REMOVE_CONTEST_APPLICATION_FORM_SUCCESS';
export const REMOVE_CONTEST_APPLICATION_FORM_FAILED = 'REMOVE_CONTEST_APPLICATION_FORM_FAILED';

export const REMOVE_CONTEST_ARCHIVE_REQUEST = 'REMOVE_CONTEST_ARCHIVE_REQUEST';
export const REMOVE_CONTEST_ARCHIVE_SUCCESS = 'REMOVE_CONTEST_ARCHIVE_SUCCESS';
export const REMOVE_CONTEST_ARCHIVE_FAILED = 'REMOVE_CONTEST_ARCHIVE_FAILED';

export const REMOVE_CONTEST_AWARD_REQUEST = 'REMOVE_CONTEST_AWARD_REQUEST';
export const REMOVE_CONTEST_AWARD_SUCCESS = 'REMOVE_CONTEST_AWARD_SUCCESS';
export const REMOVE_CONTEST_AWARD_FAILED = 'REMOVE_CONTEST_AWARD_FAILED';

export const REMOVE_CONTEST_BANNER_IMAGE_REQUEST = 'REMOVE_CONTEST_BANNER_IMAGE_REQUEST';
export const REMOVE_CONTEST_BANNER_IMAGE_SUCCESS = 'REMOVE_CONTEST_BANNER_IMAGE_SUCCESS';
export const REMOVE_CONTEST_BANNER_IMAGE_FAILED = 'REMOVE_CONTEST_BANNER_IMAGE_FAILED';

export const REMOVE_CONTEST_CATEGORY_REQUEST = 'REMOVE_CONTEST_CATEGORY_REQUEST';
export const REMOVE_CONTEST_CATEGORY_SUCCESS = 'REMOVE_CONTEST_CATEGORY_SUCCESS';
export const REMOVE_CONTEST_CATEGORY_FAILED = 'REMOVE_CONTEST_CATEGORY_FAILED';

export const REMOVE_CONTEST_COORDINATOR_REQUEST = 'REMOVE_CONTEST_COORDINATOR_REQUEST';
export const REMOVE_CONTEST_COORDINATOR_SUCCESS = 'REMOVE_CONTEST_COORDINATOR_SUCCESS';
export const REMOVE_CONTEST_COORDINATOR_FAILED = 'REMOVE_CONTEST_COORDINATOR_FAILED';

export const REMOVE_CONTEST_DOCUMENT_FOLDER_REQUEST = 'REMOVE_CONTEST_DOCUMENT_FOLDER_REQUEST';
export const REMOVE_CONTEST_DOCUMENT_FOLDER_SUCCESS = 'REMOVE_CONTEST_DOCUMENT_FOLDER_SUCCESS';
export const REMOVE_CONTEST_DOCUMENT_FOLDER_FAILED = 'REMOVE_CONTEST_DOCUMENT_FOLDER_FAILED';

export const REMOVE_CONTEST_DOWNLOADABLE_FILE_REQUEST = 'REMOVE_CONTEST_DOWNLOADABLE_FILE_REQUEST';
export const REMOVE_CONTEST_DOWNLOADABLE_FILE_SUCCESS = 'REMOVE_CONTEST_DOWNLOADABLE_FILE_SUCCESS';
export const REMOVE_CONTEST_DOWNLOADABLE_FILE_FAILED = 'REMOVE_CONTEST_DOWNLOADABLE_FILE_FAILED';

export const REMOVE_CONTEST_ENTRY_FORM_REQUEST = 'REMOVE_CONTEST_ENTRY_FORM_REQUEST';
export const REMOVE_CONTEST_ENTRY_FORM_SUCCESS = 'REMOVE_CONTEST_ENTRY_FORM_SUCCESS';
export const REMOVE_CONTEST_ENTRY_FORM_FAILED = 'REMOVE_CONTEST_ENTRY_FORM_FAILED';

export const REMOVE_CONTEST_JUDGE_REQUEST = 'REMOVE_CONTEST_JUDGE_REQUEST';
export const REMOVE_CONTEST_JUDGE_SUCCESS = 'REMOVE_CONTEST_JUDGE_SUCCESS';
export const REMOVE_CONTEST_JUDGE_FAILED = 'REMOVE_CONTEST_JUDGE_FAILED';

export const REMOVE_CONTEST_JUDGE_INVITATION_REQUEST = 'REMOVE_CONTEST_JUDGE_INVITATION_REQUEST';
export const REMOVE_CONTEST_JUDGE_INVITATION_SUCCESS = 'REMOVE_CONTEST_JUDGE_INVITATION_SUCCESS';
export const REMOVE_CONTEST_JUDGE_INVITATION_FAILED = 'REMOVE_CONTEST_JUDGE_INVITATION_FAILED';

export const REMOVE_CONTEST_JUDGE_PROSPECT_REQUEST = 'REMOVE_CONTEST_JUDGE_PROSPECT_REQUEST';
export const REMOVE_CONTEST_JUDGE_PROSPECT_SUCCESS = 'REMOVE_CONTEST_JUDGE_PROSPECT_SUCCESS';
export const REMOVE_CONTEST_JUDGE_PROSPECT_FAILED = 'REMOVE_CONTEST_JUDGE_PROSPECT_FAILED';

export const REMOVE_CONTEST_OPTIONAL_FEE_REQUEST = 'REMOVE_CONTEST_OPTIONAL_FEE_REQUEST';
export const REMOVE_CONTEST_OPTIONAL_FEE_SUCCESS = 'REMOVE_CONTEST_OPTIONAL_FEE_SUCCESS';
export const REMOVE_CONTEST_OPTIONAL_FEE_FAILED = 'REMOVE_CONTEST_OPTIONAL_FEE_FAILED';

export const REMOVE_CONTEST_PROMO_CODE_REQUEST = 'REMOVE_CONTEST_PROMO_CODE_REQUEST';
export const REMOVE_CONTEST_PROMO_CODE_SUCCESS = 'REMOVE_CONTEST_PROMO_CODE_SUCCESS';
export const REMOVE_CONTEST_PROMO_CODE_FAILED = 'REMOVE_CONTEST_PROMO_CODE_FAILED';

export const REMOVE_CONTEST_REGISTRATION_REQUEST = 'REMOVE_CONTEST_REGISTRATION_REQUEST';
export const REMOVE_CONTEST_REGISTRATION_SUCCESS = 'REMOVE_CONTEST_REGISTRATION_SUCCESS';
export const REMOVE_CONTEST_REGISTRATION_FAILED = 'REMOVE_CONTEST_REGISTRATION_FAILED';

export const REMOVE_CONTEST_SCHOLARSHIP_REQUEST = 'REMOVE_CONTEST_SCHOLARSHIP_REQUEST';
export const REMOVE_CONTEST_SCHOLARSHIP_SUCCESS = 'REMOVE_CONTEST_SCHOLARSHIP_SUCCESS';
export const REMOVE_CONTEST_SCHOLARSHIP_FAILED = 'REMOVE_CONTEST_SCHOLARSHIP_FAILED';

export const REMOVE_CONTEST_SCORESHEET_REQUEST = 'REMOVE_CONTEST_SCORESHEET_REQUEST';
export const REMOVE_CONTEST_SCORESHEET_SUCCESS = 'REMOVE_CONTEST_SCORESHEET_SUCCESS';
export const REMOVE_CONTEST_SCORESHEET_FAILED = 'REMOVE_CONTEST_SCORESHEET_FAILED';

export const REMOVE_CONTEST_SPONSOR_TIER_REQUEST = 'REMOVE_CONTEST_SPONSOR_TIER_REQUEST';
export const REMOVE_CONTEST_SPONSOR_TIER_SUCCESS = 'REMOVE_CONTEST_SPONSOR_TIER_SUCCESS';
export const REMOVE_CONTEST_SPONSOR_TIER_FAILED = 'REMOVE_CONTEST_SPONSOR_TIER_FAILED';

export const REMOVE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST = 'REMOVE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST';
export const REMOVE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS = 'REMOVE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS';
export const REMOVE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_FAILED = 'REMOVE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_FAILED';

export const REMOVE_CONTEST_TIMEFRAME_REQUEST = 'REMOVE_CONTEST_TIMEFRAME_REQUEST';
export const REMOVE_CONTEST_TIMEFRAME_SUCCESS = 'REMOVE_CONTEST_TIMEFRAME_SUCCESS';
export const REMOVE_CONTEST_TIMEFRAME_FAILED = 'REMOVE_CONTEST_TIMEFRAME_FAILED';

export const REORDER_CONTEST_APPLICATION_FOLDERS_REQUEST = 'REORDER_CONTEST_APPLICATION_FOLDERS_REQUEST';
export const REORDER_CONTEST_APPLICATION_FOLDERS_SUCCESS = 'REORDER_CONTEST_APPLICATION_FOLDERS_SUCCESS';
export const REORDER_CONTEST_APPLICATION_FOLDERS_FAILED = 'REORDER_CONTEST_APPLICATION_FOLDERS_FAILED';

export const REORDER_CONTEST_APPLICATION_FORMS_REQUEST = 'REORDER_CONTEST_APPLICATION_FORMS_REQUEST';
export const REORDER_CONTEST_APPLICATION_FORMS_SUCCESS = 'REORDER_CONTEST_APPLICATION_FORMS_SUCCESS';
export const REORDER_CONTEST_APPLICATION_FORMS_FAILED = 'REORDER_CONTEST_APPLICATION_FORMS_FAILED';

export const REORDER_CONTEST_AWARDS_REQUEST = 'REORDER_CONTEST_AWARDS_REQUEST';
export const REORDER_CONTEST_AWARDS_SUCCESS = 'REORDER_CONTEST_AWARDS_SUCCESS';
export const REORDER_CONTEST_AWARDS_FAILED = 'REORDER_CONTEST_AWARDS_FAILED';

export const REORDER_CONTEST_CATEGORIES_REQUEST = 'REORDER_CONTEST_CATEGORIES_REQUEST';
export const REORDER_CONTEST_CATEGORIES_SUCCESS = 'REORDER_CONTEST_CATEGORIES_SUCCESS';
export const REORDER_CONTEST_CATEGORIES_FAILED = 'REORDER_CONTEST_CATEGORIES_FAILED';

export const REORDER_CONTEST_COORDINATORS_REQUEST = 'REORDER_CONTEST_COORDINATORS_REQUEST';
export const REORDER_CONTEST_COORDINATORS_SUCCESS = 'REORDER_CONTEST_COORDINATORS_SUCCESS';
export const REORDER_CONTEST_COORDINATORS_FAILED = 'REORDER_CONTEST_COORDINATORS_FAILED';

export const REORDER_CONTEST_DOCUMENT_FOLDERS_REQUEST = 'REORDER_CONTEST_DOCUMENT_FOLDERS_REQUEST';
export const REORDER_CONTEST_DOCUMENT_FOLDERS_SUCCESS = 'REORDER_CONTEST_DOCUMENT_FOLDERS_SUCCESS';
export const REORDER_CONTEST_DOCUMENT_FOLDERS_FAILED = 'REORDER_CONTEST_DOCUMENT_FOLDERS_FAILED';

export const REORDER_CONTEST_ENTRY_FORMS_REQUEST = 'REORDER_CONTEST_ENTRY_FORMS_REQUEST';
export const REORDER_CONTEST_ENTRY_FORMS_SUCCESS = 'REORDER_CONTEST_ENTRY_FORMS_SUCCESS';
export const REORDER_CONTEST_ENTRY_FORMS_FAILED = 'REORDER_CONTEST_ENTRY_FORMS_FAILED';

export const REORDER_CONTEST_OPTIONAL_FEES_REQUEST = 'REORDER_CONTEST_OPTIONAL_FEES_REQUEST';
export const REORDER_CONTEST_OPTIONAL_FEES_SUCCESS = 'REORDER_CONTEST_OPTIONAL_FEES_SUCCESS';
export const REORDER_CONTEST_OPTIONAL_FEES_FAILED = 'REORDER_CONTEST_OPTIONAL_FEES_FAILED';

export const REORDER_CONTEST_SCHOLARSHIPS_REQUEST = 'REORDER_CONTEST_SCHOLARSHIPS_REQUEST';
export const REORDER_CONTEST_SCHOLARSHIPS_SUCCESS = 'REORDER_CONTEST_SCHOLARSHIPS_SUCCESS';
export const REORDER_CONTEST_SCHOLARSHIPS_FAILED = 'REORDER_CONTEST_SCHOLARSHIPS_FAILED';

export const REORDER_CONTEST_SCORESHEETS_REQUEST = 'REORDER_CONTEST_SCORESHEETS_REQUEST';
export const REORDER_CONTEST_SCORESHEETS_SUCCESS = 'REORDER_CONTEST_SCORESHEETS_SUCCESS';
export const REORDER_CONTEST_SCORESHEETS_FAILED = 'REORDER_CONTEST_SCORESHEETS_FAILED';

export const REORDER_CONTEST_SPONSOR_TIERS_REQUEST = 'REORDER_CONTEST_SPONSOR_TIERS_REQUEST';
export const REORDER_CONTEST_SPONSOR_TIERS_SUCCESS = 'REORDER_CONTEST_SPONSOR_TIERS_SUCCESS';
export const REORDER_CONTEST_SPONSOR_TIERS_FAILED = 'REORDER_CONTEST_SPONSOR_TIERS_FAILED';

export const REQUEST_CONTEST_JUDGE_VOLUNTEER_REQUEST = 'REQUEST_CONTEST_JUDGE_VOLUNTEER_REQUEST';
export const REQUEST_CONTEST_JUDGE_VOLUNTEER_SUCCESS = 'REQUEST_CONTEST_JUDGE_VOLUNTEER_SUCCESS';
export const REQUEST_CONTEST_JUDGE_VOLUNTEER_FAILED = 'REQUEST_CONTEST_JUDGE_VOLUNTEER_FAILED';

export const REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST_REQUEST = 'REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST_REQUEST';
export const REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST_SUCCESS = 'REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST_SUCCESS';
export const REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST_FAILED = 'REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST_FAILED';

export const RESET_CONTEST_URL_CHANGED = 'RESET_CONTEST_URL_CHANGED';
export const RESET_CONTEST_URL_CHANGED_SUCCESS = 'RESET_CONTEST_URL_CHANGED_SUCCESS';

export const SELECT_CONTEST_BANNER_IMAGE_REQUEST = 'SELECT_CONTEST_BANNER_IMAGE_REQUEST';
export const SELECT_CONTEST_BANNER_IMAGE_SUCCESS = 'SELECT_CONTEST_BANNER_IMAGE_SUCCESS';
export const SELECT_CONTEST_BANNER_IMAGE_FAILED = 'SELECT_CONTEST_BANNER_IMAGE_FAILED';

export const SEND_CONTEST_NOTIFICATIONS_REQUEST = 'SEND_CONTEST_NOTIFICATIONS_REQUEST';
export const SEND_CONTEST_NOTIFICATIONS_SUCCESS = 'SEND_CONTEST_NOTIFICATIONS_SUCCESS';
export const SEND_CONTEST_NOTIFICATIONS_FAILED = 'SEND_CONTEST_NOTIFICATIONS_FAILED';

export const UPDATE_CONTEST_APPLICATION_FOLDER_REQUEST = 'UPDATE_CONTEST_APPLICATION_FOLDER_REQUEST';
export const UPDATE_CONTEST_APPLICATION_FOLDER_SUCCESS = 'UPDATE_CONTEST_APPLICATION_FOLDER_SUCCESS';
export const UPDATE_CONTEST_APPLICATION_FOLDER_FAILED = 'UPDATE_CONTEST_APPLICATION_FOLDER_FAILED';

export const UPDATE_CONTEST_APPLICATION_FORM_REQUEST = 'UPDATE_CONTEST_APPLICATION_FORM_REQUEST';
export const UPDATE_CONTEST_APPLICATION_FORM_SUCCESS = 'UPDATE_CONTEST_APPLICATION_FORM_SUCCESS';
export const UPDATE_CONTEST_APPLICATION_FORM_FAILED = 'UPDATE_CONTEST_APPLICATION_FORM_FAILED';

export const UPDATE_CONTEST_APPLICATION_FORM_STRUCTURE_REQUEST = 'UPDATE_CONTEST_APPLICATION_FORM_STRUCTURE_REQUEST';
export const UPDATE_CONTEST_APPLICATION_FORM_STRUCTURE_SUCCESS = 'UPDATE_CONTEST_APPLICATION_FORM_STRUCTURE_SUCCESS';
export const UPDATE_CONTEST_APPLICATION_FORM_STRUCTURE_FAILED = 'UPDATE_CONTEST_APPLICATION_FORM_STRUCTURE_FAILED';

export const UPDATE_CONTEST_AWARD_REQUEST = 'UPDATE_CONTEST_AWARD_REQUEST';
export const UPDATE_CONTEST_AWARD_SUCCESS = 'UPDATE_CONTEST_AWARD_SUCCESS';
export const UPDATE_CONTEST_AWARD_FAILED = 'UPDATE_CONTEST_AWARD_FAILED';

export const UPDATE_CONTEST_CATEGORY_REQUEST = 'UPDATE_CONTEST_CATEGORY_REQUEST';
export const UPDATE_CONTEST_CATEGORY_SUCCESS = 'UPDATE_CONTEST_CATEGORY_SUCCESS';
export const UPDATE_CONTEST_CATEGORY_FAILED = 'UPDATE_CONTEST_CATEGORY_FAILED';

export const UPDATE_CONTEST_CONFIGURATION_REQUEST = 'UPDATE_CONTEST_CONFIGURATION_REQUEST';
export const UPDATE_CONTEST_CONFIGURATION_SUCCESS = 'UPDATE_CONTEST_CONFIGURATION_SUCCESS';
export const UPDATE_CONTEST_CONFIGURATION_FAILED = 'UPDATE_CONTEST_CONFIGURATION_FAILED';

export const UPDATE_CONTEST_CONFIRMATION_EMAIL_REQUEST = 'UPDATE_CONTEST_CONFIRMATION_EMAIL_REQUEST';
export const UPDATE_CONTEST_CONFIRMATION_EMAIL_SUCCESS = 'UPDATE_CONTEST_CONFIRMATION_EMAIL_SUCCESS';
export const UPDATE_CONTEST_CONFIRMATION_EMAIL_FAILED = 'UPDATE_CONTEST_CONFIRMATION_EMAIL_FAILED';

export const UPDATE_CONTEST_CONNECTED_EVENT_REQUEST = 'UPDATE_CONTEST_CONNECTED_EVENT_REQUEST';
export const UPDATE_CONTEST_CONNECTED_EVENT_SUCCESS = 'UPDATE_CONTEST_CONNECTED_EVENT_SUCCESS';
export const UPDATE_CONTEST_CONNECTED_EVENT_FAILED = 'UPDATE_CONTEST_CONNECTED_EVENT_FAILED';

export const UPDATE_CONTEST_COORDINATOR_REQUEST = 'UPDATE_CONTEST_COORDINATOR_REQUEST';
export const UPDATE_CONTEST_COORDINATOR_SUCCESS = 'UPDATE_CONTEST_COORDINATOR_SUCCESS';
export const UPDATE_CONTEST_COORDINATOR_FAILED = 'UPDATE_CONTEST_COORDINATOR_FAILED';

export const UPDATE_CONTEST_CUSTOM_PAGE_SETTINGS_REQUEST = 'UPDATE_CONTEST_CUSTOM_PAGE_SETTINGS_REQUEST';
export const UPDATE_CONTEST_CUSTOM_PAGE_SETTINGS_SUCCESS = 'UPDATE_CONTEST_CUSTOM_PAGE_SETTINGS_SUCCESS';
export const UPDATE_CONTEST_CUSTOM_PAGE_SETTINGS_FAILED = 'UPDATE_CONTEST_CUSTOM_PAGE_SETTINGS_FAILED';

export const UPDATE_CONTEST_CUSTOM_PAGE_TEXT_REQUEST = 'UPDATE_CONTEST_CUSTOM_PAGE_TEXT_REQUEST';
export const UPDATE_CONTEST_CUSTOM_PAGE_TEXT_SUCCESS = 'UPDATE_CONTEST_CUSTOM_PAGE_TEXT_SUCCESS';
export const UPDATE_CONTEST_CUSTOM_PAGE_TEXT_FAILED = 'UPDATE_CONTEST_CUSTOM_PAGE_TEXT_FAILED';

export const UPDATE_CONTEST_DATES_REQUEST = 'UPDATE_CONTEST_DATES_REQUEST';
export const UPDATE_CONTEST_DATES_SUCCESS = 'UPDATE_CONTEST_DATES_SUCCESS';
export const UPDATE_CONTEST_DATES_FAILED = 'UPDATE_CONTEST_DATES_FAILED';

export const UPDATE_CONTEST_DOCUMENT_FOLDER_REQUEST = 'UPDATE_CONTEST_DOCUMENT_FOLDER_REQUEST';
export const UPDATE_CONTEST_DOCUMENT_FOLDER_SUCCESS = 'UPDATE_CONTEST_DOCUMENT_FOLDER_SUCCESS';
export const UPDATE_CONTEST_DOCUMENT_FOLDER_FAILED = 'UPDATE_CONTEST_DOCUMENT_FOLDER_FAILED';

export const UPDATE_CONTEST_DOWNLOADABLE_FILE_REQUEST = 'UPDATE_CONTEST_DOWNLOADABLE_FILE_REQUEST';
export const UPDATE_CONTEST_DOWNLOADABLE_FILE_SUCCESS = 'UPDATE_CONTEST_DOWNLOADABLE_FILE_SUCCESS';
export const UPDATE_CONTEST_DOWNLOADABLE_FILE_FAILED = 'UPDATE_CONTEST_DOWNLOADABLE_FILE_FAILED';

export const UPDATE_CONTEST_ENTRY_FORM_REQUEST = 'UPDATE_CONTEST_ENTRY_FORM_REQUEST';
export const UPDATE_CONTEST_ENTRY_FORM_SUCCESS = 'UPDATE_CONTEST_ENTRY_FORM_SUCCESS';
export const UPDATE_CONTEST_ENTRY_FORM_FAILED = 'UPDATE_CONTEST_ENTRY_FORM_FAILED';

export const UPDATE_CONTEST_ENTRY_FORM_STRUCTURE_REQUEST = 'UPDATE_CONTEST_ENTRY_FORM_STRUCTURE_REQUEST';
export const UPDATE_CONTEST_ENTRY_FORM_STRUCTURE_SUCCESS = 'UPDATE_CONTEST_ENTRY_FORM_STRUCTURE_SUCCESS';
export const UPDATE_CONTEST_ENTRY_FORM_STRUCTURE_FAILED = 'UPDATE_CONTEST_ENTRY_FORM_STRUCTURE_FAILED';

export const UPDATE_CONTEST_GALLERY_REQUEST = 'UPDATE_CONTEST_GALLERY_REQUEST';
export const UPDATE_CONTEST_GALLERY_SUCCESS = 'UPDATE_CONTEST_GALLERY_SUCCESS';
export const UPDATE_CONTEST_GALLERY_FAILED = 'UPDATE_CONTEST_GALLERY_FAILED';

export const UPDATE_CONTEST_INSTRUCTIONS_REQUEST = 'UPDATE_CONTEST_INSTRUCTIONS_REQUEST';
export const UPDATE_CONTEST_INSTRUCTIONS_SUCCESS = 'UPDATE_CONTEST_INSTRUCTIONS_SUCCESS';
export const UPDATE_CONTEST_INSTRUCTIONS_FAILED = 'UPDATE_CONTEST_INSTRUCTIONS_FAILED';

export const UPDATE_CONTEST_JUDGE_REQUEST = 'UPDATE_CONTEST_JUDGE_REQUEST';
export const UPDATE_CONTEST_JUDGE_SUCCESS = 'UPDATE_CONTEST_JUDGE_SUCCESS';
export const UPDATE_CONTEST_JUDGE_FAILED = 'UPDATE_CONTEST_JUDGE_FAILED';

export const UPDATE_CONTEST_JUDGE_INVITATION_REQUEST = 'UPDATE_CONTEST_JUDGE_INVITATION_REQUEST';
export const UPDATE_CONTEST_JUDGE_INVITATION_SUCCESS = 'UPDATE_CONTEST_JUDGE_INVITATION_SUCCESS';
export const UPDATE_CONTEST_JUDGE_INVITATION_FAILED = 'UPDATE_CONTEST_JUDGE_INVITATION_FAILED';

export const UPDATE_CONTEST_JUDGE_PROSPECT_REQUEST = 'UPDATE_CONTEST_JUDGE_PROSPECT_REQUEST';
export const UPDATE_CONTEST_JUDGE_PROSPECT_SUCCESS = 'UPDATE_CONTEST_JUDGE_PROSPECT_SUCCESS';
export const UPDATE_CONTEST_JUDGE_PROSPECT_FAILED = 'UPDATE_CONTEST_JUDGE_PROSPECT_FAILED';

export const UPDATE_CONTEST_NAME_REQUEST = 'UPDATE_CONTEST_NAME_REQUEST';
export const UPDATE_CONTEST_NAME_SUCCESS = 'UPDATE_CONTEST_NAME_SUCCESS';
export const UPDATE_CONTEST_NAME_FAILED = 'UPDATE_CONTEST_NAME_FAILED';

export const UPDATE_CONTEST_OPTIONAL_FEE_REQUEST = 'UPDATE_CONTEST_OPTIONAL_FEE_REQUEST';
export const UPDATE_CONTEST_OPTIONAL_FEE_SUCCESS = 'UPDATE_CONTEST_OPTIONAL_FEE_SUCCESS';
export const UPDATE_CONTEST_OPTIONAL_FEE_FAILED = 'UPDATE_CONTEST_OPTIONAL_FEE_FAILED';

export const UPDATE_CONTEST_PARTNER_PAGE_REQUEST = 'UPDATE_CONTEST_PARTNER_PAGE_REQUEST';
export const UPDATE_CONTEST_PARTNER_PAGE_SUCCESS = 'UPDATE_CONTEST_PARTNER_PAGE_SUCCESS';
export const UPDATE_CONTEST_PARTNER_PAGE_FAILED = 'UPDATE_CONTEST_PARTNER_PAGE_FAILED';

export const UPDATE_CONTEST_PROMO_CODE_REQUEST = 'UPDATE_CONTEST_PROMO_CODE_REQUEST';
export const UPDATE_CONTEST_PROMO_CODE_SUCCESS = 'UPDATE_CONTEST_PROMO_CODE_SUCCESS';
export const UPDATE_CONTEST_PROMO_CODE_FAILED = 'UPDATE_CONTEST_PROMO_CODE_FAILED';

export const UPDATE_CONTEST_PUBLIC_VOTING_REQUEST = 'UPDATE_CONTEST_PUBLIC_VOTING_REQUEST';
export const UPDATE_CONTEST_PUBLIC_VOTING_SUCCESS = 'UPDATE_CONTEST_PUBLIC_VOTING_SUCCESS';
export const UPDATE_CONTEST_PUBLIC_VOTING_FAILED = 'UPDATE_CONTEST_PUBLIC_VOTING_FAILED';

export const UPDATE_CONTEST_REGISTRATION_REQUEST = 'UPDATE_CONTEST_REGISTRATION_REQUEST';
export const UPDATE_CONTEST_REGISTRATION_SUCCESS = 'UPDATE_CONTEST_REGISTRATION_SUCCESS';
export const UPDATE_CONTEST_REGISTRATION_FAILED = 'UPDATE_CONTEST_REGISTRATION_FAILED';

export const UPDATE_CONTEST_RULES_REQUEST = 'UPDATE_CONTEST_RULES_REQUEST';
export const UPDATE_CONTEST_RULES_SUCCESS = 'UPDATE_CONTEST_RULES_SUCCESS';
export const UPDATE_CONTEST_RULES_FAILED = 'UPDATE_CONTEST_RULES_FAILED';

export const UPDATE_CONTEST_SCHOLARSHIP_REQUEST = 'UPDATE_CONTEST_SCHOLARSHIP_REQUEST';
export const UPDATE_CONTEST_SCHOLARSHIP_SUCCESS = 'UPDATE_CONTEST_SCHOLARSHIP_SUCCESS';
export const UPDATE_CONTEST_SCHOLARSHIP_FAILED = 'UPDATE_CONTEST_SCHOLARSHIP_FAILED';

export const UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS_REQUEST = 'UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS_REQUEST';
export const UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS_SUCCESS = 'UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS_SUCCESS';
export const UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS_FAILED = 'UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS_FAILED';

export const UPDATE_CONTEST_SCORESHEET_REQUEST = 'UPDATE_CONTEST_SCORESHEET_REQUEST';
export const UPDATE_CONTEST_SCORESHEET_SUCCESS = 'UPDATE_CONTEST_SCORESHEET_SUCCESS';
export const UPDATE_CONTEST_SCORESHEET_FAILED = 'UPDATE_CONTEST_SCORESHEET_FAILED';

export const UPDATE_CONTEST_SCORESHEET_CRITERIA_REQUEST = 'UPDATE_CONTEST_SCORESHEET_CRITERIA_REQUEST';
export const UPDATE_CONTEST_SCORESHEET_CRITERIA_SUCCESS = 'UPDATE_CONTEST_SCORESHEET_CRITERIA_SUCCESS';
export const UPDATE_CONTEST_SCORESHEET_CRITERIA_FAILED = 'UPDATE_CONTEST_SCORESHEET_CRITERIA_FAILED';

export const UPDATE_CONTEST_SCORING_INSTRUCTIONS_REQUEST = 'UPDATE_CONTEST_SCORING_INSTRUCTIONS_REQUEST';
export const UPDATE_CONTEST_SCORING_INSTRUCTIONS_SUCCESS = 'UPDATE_CONTEST_SCORING_INSTRUCTIONS_SUCCESS';
export const UPDATE_CONTEST_SCORING_INSTRUCTIONS_FAILED = 'UPDATE_CONTEST_SCORING_INSTRUCTIONS_FAILED';

export const UPDATE_CONTEST_SEO_REQUEST = 'UPDATE_CONTEST_SEO_REQUEST';
export const UPDATE_CONTEST_SEO_SUCCESS = 'UPDATE_CONTEST_SEO_SUCCESS';
export const UPDATE_CONTEST_SEO_FAILED = 'UPDATE_CONTEST_SEO_FAILED';

export const UPDATE_CONTEST_SPONSOR_TIER_REQUEST = 'UPDATE_CONTEST_SPONSOR_TIER_REQUEST';
export const UPDATE_CONTEST_SPONSOR_TIER_SUCCESS = 'UPDATE_CONTEST_SPONSOR_TIER_SUCCESS';
export const UPDATE_CONTEST_SPONSOR_TIER_FAILED = 'UPDATE_CONTEST_SPONSOR_TIER_FAILED';

export const UPDATE_CONTEST_SPONSOR_OPTIONS_REQUEST = 'UPDATE_CONTEST_SPONSOR_OPTIONS_REQUEST';
export const UPDATE_CONTEST_SPONSOR_OPTIONS_SUCCESS = 'UPDATE_CONTEST_SPONSOR_OPTIONS_SUCCESS';
export const UPDATE_CONTEST_SPONSOR_OPTIONS_FAILED = 'UPDATE_CONTEST_SPONSOR_OPTIONS_FAILED';

export const UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST = 'UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST';
export const UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS = 'UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS';
export const UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_FAILED = 'UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_FAILED';

export const UPDATE_CONTEST_TIMEFRAME_REQUEST = 'UPDATE_CONTEST_TIMEFRAME_REQUEST';
export const UPDATE_CONTEST_TIMEFRAME_SUCCESS = 'UPDATE_CONTEST_TIMEFRAME_SUCCESS';
export const UPDATE_CONTEST_TIMEFRAME_FAILED = 'UPDATE_CONTEST_TIMEFRAME_FAILED';

export const UPLOAD_CONTEST_BANNER_IMAGE_REQUEST = 'UPLOAD_CONTEST_BANNER_IMAGE_REQUEST';
export const UPLOAD_CONTEST_BANNER_IMAGE_SUCCESS = 'UPLOAD_CONTEST_BANNER_IMAGE_SUCCESS';
export const UPLOAD_CONTEST_BANNER_IMAGE_FAILED = 'UPLOAD_CONTEST_BANNER_IMAGE_FAILED';

export const UPLOAD_CONTEST_DOWNLOADABLE_FILE_REQUEST = 'UPLOAD_CONTEST_DOWNLOADABLE_FILE_REQUEST';
export const UPLOAD_CONTEST_DOWNLOADABLE_FILE_SUCCESS = 'UPLOAD_CONTEST_DOWNLOADABLE_FILE_SUCCESS';
export const UPLOAD_CONTEST_DOWNLOADABLE_FILE_FAILED = 'UPLOAD_CONTEST_DOWNLOADABLE_FILE_FAILED';

export function archiveContestRequest (body) {
  return {
    body,
    type: ARCHIVE_CONTEST_REQUEST
  }
}

export function assignContestAwardsRequest (body) {
  return {
    body,
    type: ASSIGN_CONTEST_AWARDS_REQUEST
  }
}

export function clearContestVolunteersRequest () {
  return {
    type: CLEAR_CONTEST_VOLUNTEERS
  }
}

export function clearScoringProgressRequest () {
  return {
    type: CLEAR_SCORING_PROGRESS
  }
}

export function clearScoringProgressEntriesRequest () {
  return {
    type: CLEAR_SCORING_PROGRESS_ENTRIES
  }
}

export function clearScoringProgressJudgesRequest () {
  return {
    type: CLEAR_SCORING_PROGRESS_JUDGES
  }
}

export function convertContestJudgeProspectRequest (body) {
  return {
    body,
    type: CONVERT_CONTEST_JUDGE_PROSPECT_REQUEST
  }
}

export function createContestRequest (body) {
  return {
    body,
    type: CREATE_CONTEST_REQUEST
  }
}

export function createContestArchiveRequest (body) {
  return {
    body,
    type: CREATE_CONTEST_ARCHIVE_REQUEST
  }
}

export function downloadContestEntryFormRequest (body) {
  return {
    body,
    type: DOWNLOAD_CONTEST_ENTRY_FORM_REQUEST
  }
}

export function downloadContestRegistrationsRequest (body) {
  return {
    body,
    type: DOWNLOAD_CONTEST_REGISTRATIONS_REQUEST
  }
}

export function downloadContestScoresRequest (body) {
  return {
    body,
    type: DOWNLOAD_CONTEST_SCORES_REQUEST
  }
}

export function downloadContestWinnersRequest (body) {
  return {
    body,
    type: DOWNLOAD_CONTEST_WINNERS_REQUEST
  }
}

export function duplicateApplicationFormRequest (body) {
  return {
    body,
    type: DUPLICATE_APPLICATION_FORM_REQUEST
  }
}

export function duplicateContestRequest (body) {
  return {
    body,
    type: DUPLICATE_CONTEST_REQUEST
  }
}

export function duplicateEntryFormRequest (body) {
  return {
    body,
    type: DUPLICATE_ENTRY_FORM_REQUEST
  }
}

export function duplicateScoresheetRequest (body) {
  return {
    body,
    type: DUPLICATE_SCORESHEET_REQUEST
  }
}

export function getContestRequest (body) {
  return {
    body,
    type: GET_CONTEST_REQUEST
  }
}

export function getContestArchivesRequest (body) {
  return {
    body,
    type: GET_CONTEST_ARCHIVES_REQUEST
  }
}

export function getContestListRequest (body) {
  return {
    body,
    type: GET_CONTEST_LIST_REQUEST
  }
}

export function getContestPublicVotingRequest (body) {
  return {
    body,
    type: GET_CONTEST_PUBLIC_VOTES_REQUEST
  }
}

export function getContestWinnersRequest (body) {
  return {
    body,
    type: GET_CONTEST_WINNERS_REQUEST
  }
}

export function getScoringProgressByEntryRequest (body) {
  return {
    body,
    type: GET_SCORING_PROGRESS_BY_ENTRY_REQUEST
  }
}

export function getScoringProgressByJudgeRequest (body) {
  return {
    body,
    type: GET_SCORING_PROGRESS_BY_JUDGE_REQUEST
  }
}

export function getScoringProgressForEntryRequest (body) {
  return {
    body,
    type: GET_SCORING_PROGRESS_FOR_ENTRY_REQUEST
  }
}

export function getScoringProgressEntriesForJudgeRequest (body) {
  return {
    body,
    type: GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE_REQUEST
  }
}

export function getScoringProgressJudgesForEntryRequest (body) {
  return {
    body,
    type: GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY_REQUEST
  }
}

export function removeContestApplicationFolderRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_APPLICATION_FOLDER_REQUEST
  }
}

export function removeContestApplicationFormRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_APPLICATION_FORM_REQUEST
  }
}

export function removeContestArchiveRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_ARCHIVE_REQUEST
  }
}

export function removeContestAwardRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_AWARD_REQUEST
  }
}

export function removeContestBannerImageRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_BANNER_IMAGE_REQUEST
  }
}

export function removeContestCategoryRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_CATEGORY_REQUEST
  }
}

export function removeContestCoordinatorRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_COORDINATOR_REQUEST
  }
}

export function removeContestDocumentFolderRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_DOCUMENT_FOLDER_REQUEST
  }
}

export function removeContestDownloadableFileRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_DOWNLOADABLE_FILE_REQUEST
  }
}

export function removeContestEntryFormRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_ENTRY_FORM_REQUEST
  }
}

export function removeContestJudgeRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_JUDGE_REQUEST
  }
}

export function removeContestJudgeInvitationRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_JUDGE_INVITATION_REQUEST
  }
}

export function removeContestJudgeProspectRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_JUDGE_PROSPECT_REQUEST
  }
}

export function removeContestOptionalFeeRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_OPTIONAL_FEE_REQUEST
  }
}

export function removeContestPromoCodeRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_PROMO_CODE_REQUEST
  }
}

export function removeContestRegistrationRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_REGISTRATION_REQUEST
  }
}

export function removeContestScholarshipRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_SCHOLARSHIP_REQUEST
  }
}

export function removeContestScoresheetRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_SCORESHEET_REQUEST
  }
}

export function removeContestSponsorTierRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_SPONSOR_TIER_REQUEST
  }
}

export function removeContestSupportingProductTimeframeRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST
  }
}

export function removeContestTimeframeRequest (body) {
  return {
    body,
    type: REMOVE_CONTEST_TIMEFRAME_REQUEST
  }
}

export function reorderContestApplicationFoldersRequest (body) {
  return {
    body,
    type: REORDER_CONTEST_APPLICATION_FOLDERS_REQUEST
  }
}

export function reorderContestApplicationFormsRequest (body) {
  return {
    body,
    type: REORDER_CONTEST_APPLICATION_FORMS_REQUEST
  }
}

export function reorderContestAwardsRequest (body) {
  return {
    body,
    type: REORDER_CONTEST_AWARDS_REQUEST
  }
}

export function reorderContestCategoriesRequest (body) {
  return {
    body,
    type: REORDER_CONTEST_CATEGORIES_REQUEST
  }
}

export function reorderContestCoordinatorsRequest (body) {
  return {
    body,
    type: REORDER_CONTEST_COORDINATORS_REQUEST
  }
}

export function reorderContestDocumentFoldersRequest (body) {
  return {
    body,
    type: REORDER_CONTEST_DOCUMENT_FOLDERS_REQUEST
  }
}

export function reorderContestEntryFormsRequest (body) {
  return {
    body,
    type: REORDER_CONTEST_ENTRY_FORMS_REQUEST
  }
}

export function reorderContestFeesRequest (body) {
  return {
    body,
    type: REORDER_CONTEST_OPTIONAL_FEES_REQUEST
  }
}

export function reorderContestScholarshipsRequest (body) {
  return {
    body,
    type: REORDER_CONTEST_SCHOLARSHIPS_REQUEST
  }
}

export function reorderContestScoresheetsRequest (body) {
  return {
    body,
    type: REORDER_CONTEST_SCORESHEETS_REQUEST
  }
}

export function reorderContestSponsorTiersRequest (body) {
  return {
    body,
    type: REORDER_CONTEST_SPONSOR_TIERS_REQUEST
  }
}

export function requestContestJudgeVolunteerRequest (body) {
  return {
    body,
    type: REQUEST_CONTEST_JUDGE_VOLUNTEER_REQUEST
  }
}

export function requestContestJudgeVolunteerListRequest (body) {
  return {
    body,
    type: REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST_REQUEST
  }
}

export function resetContestURLChanged () {
  return {
    type: RESET_CONTEST_URL_CHANGED
  }
}

export function selectContestBannerImageRequest (body) {
  return {
    body,
    type: SELECT_CONTEST_BANNER_IMAGE_REQUEST
  }
}

export function sendContestNotifications (body) {
  return {
    body,
    type: SEND_CONTEST_NOTIFICATIONS_REQUEST
  }
}

export function updateContestApplicationFolderRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_APPLICATION_FOLDER_REQUEST
  }
}

export function updateContestApplicationFormRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_APPLICATION_FORM_REQUEST
  }
}

export function updateContestApplicationFormStructureRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_APPLICATION_FORM_STRUCTURE_REQUEST
  }
}

export function updateContestAwardRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_AWARD_REQUEST
  }
}

export function updateContestCategoryRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_CATEGORY_REQUEST
  }
}

export function updateContestConfigurationRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_CONFIGURATION_REQUEST
  }
}

export function updateContestConfirmationEmailRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_CONFIRMATION_EMAIL_REQUEST
  }
}

export function updateContestConnectedEventRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_CONNECTED_EVENT_REQUEST
  }
}

export function updateContestCoordinatorRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_COORDINATOR_REQUEST
  }
}

export function updateContestCustomPageSettingsRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_CUSTOM_PAGE_SETTINGS_REQUEST
  }
}

export function updateContestCustomPageTextRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_CUSTOM_PAGE_TEXT_REQUEST
  }
}


export function updateContestDatesRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_DATES_REQUEST
  }
}

export function updateContestDocumentFolderRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_DOCUMENT_FOLDER_REQUEST
  }
}

export function updateContestDownloadableFileRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_DOWNLOADABLE_FILE_REQUEST
  }
}

export function updateContestEntryFormRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_ENTRY_FORM_REQUEST
  }
}

export function updateContestEntryFormStructureRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_ENTRY_FORM_STRUCTURE_REQUEST
  }
}

export function updateContestGalleryRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_GALLERY_REQUEST
  }
}

export function updateContestInstructionsRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_INSTRUCTIONS_REQUEST
  }
}

export function updateContestJudgeRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_JUDGE_REQUEST
  }
}

export function updateContestJudgeInvitationRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_JUDGE_INVITATION_REQUEST
  }
}

export function updateContestJudgeProspectRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_JUDGE_PROSPECT_REQUEST
  }
}

export function updateContestNameRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_NAME_REQUEST
  }
}

export function updateContestOptionalFeeRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_OPTIONAL_FEE_REQUEST
  }
}

export function updateContestPartnerPageRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_PARTNER_PAGE_REQUEST
  }
}

export function updateContestPromoCodeRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_PROMO_CODE_REQUEST
  }
}

export function updateContestPublicVotingRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_PUBLIC_VOTING_REQUEST
  }
}

export function updateContestRegistrationRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_REGISTRATION_REQUEST
  }
}

export function updateContestRulesRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_RULES_REQUEST
  }
}

export function updateContestScholarshipRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_SCHOLARSHIP_REQUEST
  }
}

export function updateContestScholarshipRequirementsRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS_REQUEST
  }
}

export function updateContestScoresheetRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_SCORESHEET_REQUEST
  }
}

export function updateContestScoresheetCriteriaRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_SCORESHEET_CRITERIA_REQUEST
  }
}

export function updateContestScoringInstructionsRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_SCORING_INSTRUCTIONS_REQUEST
  }
}

export function updateContestSEORequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_SEO_REQUEST
  }
}

export function updateContestSponsorTierRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_SPONSOR_TIER_REQUEST
  }
}

export function updateContestSponsorOptionsRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_SPONSOR_OPTIONS_REQUEST
  }
}

export function updateContestSupportingProductTimeframeRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST
  }
}

export function updateContestTimeframeRequest (body) {
  return {
    body,
    type: UPDATE_CONTEST_TIMEFRAME_REQUEST
  }
}

export function uploadContestBannerImageRequest (body) {
  return {
    body,
    type: UPLOAD_CONTEST_BANNER_IMAGE_REQUEST
  }
}

export function uploadContestDownloadableFileRequest (body) {
  return {
    body,
    type: UPLOAD_CONTEST_DOWNLOADABLE_FILE_REQUEST
  }
}
