import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import UserProfile from 'src/components/UserProfile';
import { fieldTypes } from 'src/constants';
import { updateContestJudgeProspectRequest } from 'src/redux/actions/contests';

const JudgeProspectForm = ({ prospect, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    dispatch(updateContestJudgeProspectRequest({ clientURL: client.url, contestURL: contest.url, form: { ...prospect, ...event } }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Notes',
          name: 'notes',
          type: fieldTypes.textarea,
          value: prospect?.notes
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="judge-form">
      <UserProfile
        showEmail
        user={prospect?.user}
      ></UserProfile>
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_CONTEST_JUDGE_PROSPECT'}
      />
    </section>
  )
}

export default JudgeProspectForm;
