import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContestRequest,
  uploadContestDownloadableFileRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import ResourceList from 'src/components/ResourceList';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';

const ContestDownloadableFiles = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const [progress, setProgress] = useState();
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  const actions = [{
    fileUpload: {
      label: 'Upload',
      multiple: true,
      onChange: file => {
        dispatch(uploadContestDownloadableFileRequest({ clientURL: client.url, contestURL: contest.url, file, handleProgress: setProgress }));
      },
      requestPrefix: 'UPLOAD_CONTEST_DOWNLOADABLE_FILE'
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    variant: 'contained',
    fn: file => {
      dispatch(
        openModal({
          props: { client, contest, file },
          key: 'editContestDownloadableFileFolders'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: file => {
      dispatch(
        openDialog({
          props: { client, contest, file },
          key: 'removeContestDownloadableFile'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestDownloadableFiles', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestDownloadableFiles',
      options: { client, contest } 
    }));
  }, [client, contest]);
  
  return (
    <ResourceList
      actions={actions}
      fetching={contestFetching}
      list={contest?.files}
      renderItem={item => 
        <div className="downloadable-file">
          <a href={item.url}><span className="downloadable-file-name">{item.name}</span></a>
          <span className="downloadable-file-size">{item.size}</span>
        </div>
      }
      renderKey="name"
      progress={(!!progress && progress < 100) && progress}
      title="Downloadable Files"
    />
  )
}

export default ContestDownloadableFiles;