import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  downloadParadeRegistrationsRequest,
  getParadeRequest,
  updateParadeRegistrationRequest
} from 'src/redux/actions/parades';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';
import UserProfile from 'src/components/UserProfile';
import { currency } from 'src/utilities/strings';
import { Button } from '@mui/material';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import LoadingCircles from 'src/components/Elements/LoadingCircles';
import { purchaseTypes } from 'src/constants';

const ParadeRegistrationList = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const user = useSelector(state => state.userStore?.user);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const downloadingRegistrations = useSelector(state => state.loadingStore.DOWNLOAD_PARADE_REGISTRATIONS);
  const { paradeURL } = useParams();
  const [totalItems, setTotalItems] = useState();
  const [totalRevenue, setTotalRevenue] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  useEffect(() => {
    if (parade?.registrations) {
      setTotalRevenue(parade.registrations.reduce((acc, registration) => 
        acc + registration.items.reduce((acc, item) => acc + (item.quantity * item.price), 0), 0));
      setTotalItems(parade.registrations.reduce((acc, registration) => 
        acc + registration.items.reduce((acc, item) => acc + (item.itemID === 'promoID' ? 0 : item.quantity), 0), 0));
    }
  }, [parade?.registrations]);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeRegistrationList', client, parade }));
    dispatch(updateDrawerContent({
      key: 'paradeRegistrationList',
      options: { client, parade } 
    }));
  }, [client, parade]);

  const handleRegistrationsDownload = () => {
    dispatch(downloadParadeRegistrationsRequest({ clientURL: client?.url, paradeURL: parade?.url, paradeID: parade?.paradeID }));
  };

  const onSubmit = ({ form, transactionID }) => {
    dispatch(updateParadeRegistrationRequest({
      clientURL: client?.url,
      paradeURL: parade?.url,
      transactionID,
      form
    }));
  };

  const getAvailableTransactionOptions = () => {
    return {
      [purchaseTypes.event]: () => [...(parade.tickets || []).map(ticket => {
        return {
          label: ticket.name,
          value: ticket.ticketID
        };
      }), {
        label: 'Promotional Discount',
        value: 'promoID'
      }],
      [purchaseTypes.property]: () => {
        if (!!(parade.propertyGroups || []).length) {
          return [...(parade.propertyGroups || []).map(group => {
            return {
              label: group.name,
              value: group.propertyGroupID
            };
          }), {
            label: 'Promotional Discount',
            value: 'promoID'
          }];
        }
  
        return [{
          label: 'General Property Submission',
          value: 'null'
        }, {
          label: 'Promotional Discount',
          value: 'promoID'
        }];
      },
      [purchaseTypes.sponsor]: () => {
        if (!!(parade.sponsorTiers || []).length) {
          return [...(parade.sponsorTiers || []).map(tier => {
            return {
              label: tier.name,
              value: tier.sponsorTierID
            };
          }), {
            label: 'Promotional Discount',
            value: 'promoID'
          }];
        }
    
        return [{
          label: 'General Sponsorship',
          value: 'null'
        }, {
          label: 'Promotional Discount',
          value: 'promoID'
        }];
      }
    }
  };

  const adminActions = [{
    label: 'Edit',
    requiresSelection: true,
    fn: registration => {
      const { guestEmail, items, purchaseType, transactionID, user } = registration;
      const options = getAvailableTransactionOptions();
      dispatch(openModal({
        props: {
          guestEmail,
          items,
          onSubmit,
          options,
          purchaseType,
          transactionID,
          updatePrefix: 'UPDATE_PARADE_REGISTRATION',
          user
        },
        key: 'editParadeRegistration'
      }))
    }
  }];

  const userActions = [{
    label: 'View',
    requiresSelection: true,
    fn: registration => {
      const { items } = registration;
      dispatch(openModal({
        props: { items },
        key: 'viewParadeRegistration'
      }))
    }
  }];

  const actions = [{
    label: 'Receipt',
    requiresSelection: true,
    fn: registration => window.open(`https://${client.url}.cpjam.com/${parade?.url}/receipts/${registration.transactionID}`)
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: registration => {
      dispatch(
        openDialog({
          props: { client, parade, registration },
          key: 'removeParadeRegistration'
        })
      )
    }
  }];

  const filters = {
    searchBy: ['fullName', 'email', 'guestEmail']
  };

  return (
    <section className="registration-list grid">
      <section className="box">
        <div className="box-title">
          <h3>
            {
              !paradeFetching && (
                <span>Registration List Summary
                {
                  (downloadingRegistrations) && (
                    <LoadingCircle variant="dark" />
                  )
                }
                </span>
              )
            }
            {
              paradeFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
        </div>
        {
          !paradeFetching && (
          <div className="box-content options">
            <div className="total">
              <div className="total-revenue">Total Revenue: {currency(totalRevenue || 0)}</div>
              <div className="total-items">Total Items: {totalItems}</div>
            </div>
            <Button
              color="confirm"
              disabled={downloadingRegistrations}
              onClick={handleRegistrationsDownload}
              variant="contained"
            >
              {
                downloadingRegistrations && (<LoadingCircles />)
              }
              {
                !downloadingRegistrations && <>Download Registrations</>
              }
            </Button>
          </div>
          )
        }
      </section>
      <ResourceList
        actions={user?.admin ? [...adminActions, ...actions] : [...userActions, ...actions]}
        fetching={paradeFetching}
        filters={filters}
        list={parade?.registrations}
        pageSize={20}
        renderHeaders={() => 
          <div className="registration">
            <div className="registration-owner">Paid By</div>
            <div className="registration-date">Invoice Date</div>
            <div className="registration-items">Total Items</div>
            <div className="registration-price">Total Price</div>
          </div>
        }
        renderItem={invoice => 
          <div className="registration">
            <div className="registration-owner">{
              invoice.guestEmail ?
                <div className="registration-owner-guest">
                  <div className="registration-owner-guest-name">Guest Registation</div>
                  <div className="registration-owner-guest-email">{invoice.guestEmail}</div>
                </div> : 
                <UserProfile showEmail user={invoice.user}/>}</div>
            <div className="registration-date">{invoice.date}</div>
            <div className="registration-items"><span className="mobile-display">Total Items: </span>{invoice.items.reduce((acc, item) => acc + (item.itemID === 'promoID' ? 0 : item.quantity), 0)}</div>
            <div className="registration-price"><span className="mobile-display">Total Price: </span>{currency(invoice.items.reduce((acc, item) => acc + (item.quantity * item.price), 0))}</div>
          </div>
        }
        renderKey="transactionID"
        searchProp="user"
        title="Registration List"
      />
    </section>
  )
}

export default ParadeRegistrationList;
