import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AutoForm from 'src/components/AutoForm';
import ModalContainer from 'src/components/ModalContainer';
import { fieldTypes, inputTypes } from 'src/constants';
import { openModal } from 'src/redux/actions/modal';
import { loginRequest } from 'src/redux/actions/users';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.userStore?.user);

  const forgotPassword = () => {
    dispatch(
      openModal({
        key: 'forgotPassword'
      })
    );
  }

  const handleSubmit = (form) => {
    dispatch(loginRequest({ form }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Email Address',
          name: 'email',
          type: fieldTypes.input,
          inputType: inputTypes.email,
          autoComplete: 'email',
          value: '',
          required: true
        }]
      }, {
        elements: [{
          label: 'Password',
          name: 'password',
          type: fieldTypes.input,
          inputType: inputTypes.passwordAny,
          autoComplete: 'current-password',
          value: '',
          required: true
        }]
      }]
    },
    submitCTA: 'Sign In'
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [navigate, user]);

  return (
    <div className="login">
      <div className="login-form floating">
        <img className="logo" src="/assets/images/logo.svg" alt="Logo" />
        <h2>Administrative Portal</h2>
        <AutoForm
          configuration={configuration}
          handleSubmit={handleSubmit}
          requestPrefix={'LOGIN'}
          submitOnEnter
        />
        <p className="login-link"><span onClick={() => forgotPassword()}>Forgot your password?</span></p>
      </div>
      <ModalContainer />
    </div>
  )
}

export default Login;
