import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';

const MobileDrawerContainer = ({
  leftDrawerOpen,
  rightDrawerOpen,
  setLeftDrawerOpen,
  setRightDrawerOpen
}) => {
  return <div className="drawer-container-mobile">
    <FontAwesomeIcon
      className="icon"
      icon={faBars}
      onClick={() => {
        setLeftDrawerOpen(!leftDrawerOpen);
        setRightDrawerOpen(false);
      }}
    />
    <FontAwesomeIcon
      className="icon"
      icon={faQuestionCircle}
      onClick={() => {
        setRightDrawerOpen(!rightDrawerOpen);
        setLeftDrawerOpen(false);
      }}
    /> 
  </div>
}

export default MobileDrawerContainer;
