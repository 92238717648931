import { UPDATE_BREADCRUMB } from 'src/redux/actions/breadcrumbs';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BREADCRUMB:
      return { ...state, breadcrumbs: action.body };
    default:
      return state;
  }
}

export default reducer;
