import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateUserContactRequest } from 'src/redux/actions/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const AccountContactUs = ({ handleClose }) => {
  const user = useSelector(state => state.userStore?.user);
  const submitted = useSelector(state => state.completedStore.UPDATE_USER_CONTACT);
  const dispatch = useDispatch();

  useEffect(() => {
    if (submitted) {
      handleClose();
    }
  }, [submitted]);

  const handleSubmit = (event) => {
    dispatch(updateUserContactRequest({ form: event }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Email',
          name: 'email',
          type: fieldTypes.input,
          inputType: inputTypes.email,
          autoComplete: 'email',
          value: user?.email || '',
          required: true
        }, {
          label: 'Phone',
          name: 'phone',
          type: fieldTypes.input,
          inputType: inputTypes.phone,
          autoComplete: 'phone',
          value: user?.phone || ''
        }]
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="my-account-content-section">
      <div className="my-account-content-section-title">
        <span>Contact Me</span>
        <FontAwesomeIcon
          className="my-account-content-section-title-close"
          icon={faXmark}
          onClick={handleClose}
        />
      </div>
      <div className="my-account-content-section-sub-title">Email, phone and other settings</div>
      <AutoForm
        configuration={configuration}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_USER_CONTACT'}
      />
    </section>
  )
}

export default AccountContactUs;
