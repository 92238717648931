import React from 'react';
import { useDispatch } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import UserProfile from 'src/components/UserProfile';
import { fieldTypes } from 'src/constants';
import { updateAdministratorRequest, updateAdministratorInvitationRequest } from 'src/redux/actions/client';

const AdministratorForm = ({ administrator, client, handleClose }) => {
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    if (administrator?.sendInvite || administrator?.invitationKey) {
      dispatch(updateAdministratorInvitationRequest({ clientURL: client.url, form: { ...administrator, ...event } }));

      return;
    }

    dispatch(updateAdministratorRequest({ clientURL: client.url, form: { ...administrator, ...event } }));
  }

  const verifyAdministratorValue = (value) => {
    if (value === false) {
      return value;
    }

    return true;
  }

  const configuration = {
    formElements: {
      rows: [{
        heading: 'Awards Program Email Permissions'
      }, {
        elements: [{
          label: 'Receive Awards Program Support Emails',
          name: 'permitContestSupportEmail',
          type: fieldTypes.checkbox,
          value: verifyAdministratorValue(administrator?.permitContestSupportEmail)
        }, {
          label: 'Receive Awards Program Registration Emails',
          name: 'permitContestRegistrationEmail',
          type: fieldTypes.checkbox,
          value: verifyAdministratorValue(administrator?.permitContestRegistrationEmail)
        }]
      }, {
        elements: [{
          label: 'Receive Awards Program Volunteer Request Emails',
          name: 'permitContestVolunteerEmail',
          type: fieldTypes.checkbox,
          value: verifyAdministratorValue(administrator?.permitContestVolunteerEmail)
        }]
      }, {
        heading: 'Event Email Permissions'
      }, {
        elements: [{
          label: 'Receive Event Registration Emails',
          name: 'permitEventRegistrationEmail',
          type: fieldTypes.checkbox,
          value: verifyAdministratorValue(administrator?.permitEventRegistrationEmail)
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="administrator-form">
      <div className="administrator-form-invitee">
        {
          administrator?.userID && <UserProfile
            user={administrator}
            showEmail
          />
        }
        {
          !administrator?.userID &&
            <div className="administrator-form-invitee-email">Sending invitation to: <span>{administrator?.sendInvite || administrator?.email}</span></div>
        }
      </div>
        
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={['UPDATE_ADMINISTRATOR', 'UPDATE_ADMINISTRATOR_INVITATION']}
      />
    </section>
  )
}

export default AdministratorForm;
