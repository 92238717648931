import React from 'react';
import { useSelector } from 'react-redux';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import LoadingCircles from 'src/components/Elements/LoadingCircles';

const StripeCreditCardForm = ({ handleStripeToken, setTokenRequesting, tokenRequesting }) => {
  const paymentSubmitting = useSelector(state => state.loadingStore.PAY_INVOICE);
  const stripe = useStripe();
  const elements = useElements();

  const style = {
    base: {
      iconColor: '#666EE8',
      color: '#212529',
      lineHeight: '26px',
      fontWeight: 400,
      fontFamily: 'Arial',
      fontSize: '16px',
      '::placeholder': {
        color: '#CFD7E0'
      }
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    setTokenRequesting(true);

    const {error, token} = await stripe.createToken(elements.getElement(CardElement));

    handleStripeToken({ error, token });
  };

  return (
    <form onSubmit={onSubmit}>
      <CardElement options={{ hidePostalCode: true, style: style }} />
      <Button
        color="confirm"
        disabled={!stripe || !elements || paymentSubmitting || tokenRequesting}
        type="submit"
        variant="contained"
      >{
        (paymentSubmitting || tokenRequesting) && (
          <LoadingCircles />
        )
      }
      {
        (!paymentSubmitting && !tokenRequesting) && (
          <>
            Complete Purchase
          </>
        )
      }
      </Button>
    </form>
  );
};

export default StripeCreditCardForm;
