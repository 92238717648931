import React from 'react';
import AutoForm from 'src/components/AutoForm';
import {
  animationDirections,
  fieldTypes,
  homePageAlignments,
  homePageBackgroundTypes,
  homePageContentTypes,
  inputTypes
} from 'src/constants';

const HomepageColumnSettingsForm = ({ column, handleClose, images, onSubmit, row }) => {
  const handleSubmit = (event) => {
    onSubmit({ column, event, row });
    handleClose();
  }

  const alignments = [{
    label: 'Top',
    value: homePageAlignments.top
  }, {
    label: 'Center',
    value: homePageAlignments.center
  }, {
    label: 'Bottom',
    value: homePageAlignments.bottom
  }];

  const backgroundTypes = [{
    label: 'Color',
    value: homePageBackgroundTypes.color
  }, {
    label: 'Image',
    value: homePageBackgroundTypes.image
  }];

  const contentTypes = [{
    label: 'Text',
    value: homePageContentTypes.text
  }, {
    label: 'Image',
    value: homePageContentTypes.image
  }, {
    label: 'Countdown',
    value: homePageContentTypes.countdown
  }, {
    label: 'Property List',
    value: homePageContentTypes.propertyList
  }, {
    label: 'Sponsors',
    value: homePageContentTypes.sponsors
  }];

  const directions = [{
    label: 'No Animation',
    value: animationDirections.none
  }, {
    label: 'Left',
    value: animationDirections.left
  }, {
    label: 'Right',
    value: animationDirections.right
  }, {
    label: 'Bottom',
    value: animationDirections.bottom
  }]

  const configuration = row?.hero ? {
    formElements: {
      rows: [{
        heading: 'Background'
      }, {
        elements: [{
          label: 'Content Image',
          name: 'backgroundImage',
          type: fieldTypes.select,
          options: images,
          value: column.content.backgroundImage?.fileID || images[0]?.value
        }]
      }, {
        heading: 'Content'
      }, {
        elements: [{
          label: 'Title',
          name: 'title',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: column.content.title || ''
        }]
      }, {
        elements: [{
          label: 'Sub Title',
          name: 'subTitle',
          type: fieldTypes.textarea,
          value: column.content.subTitle || ''
        }]
      }, {
        elements: [{
          label: 'Box Content',
          name: 'boxContent',
          type: fieldTypes.textarea,
          value: column.content.boxContent || ''
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  } : {
    formElements: {
      rows: [{
        heading: 'Background'
      }, {
        elements: [{
          label: 'Background Type',
          name: 'backgroundType',
          type: fieldTypes.select,
          options: backgroundTypes,
          value: column.background.backgroundType || homePageBackgroundTypes.color
        }]
      }, {
        elements: [{
          label: 'Background Color',
          name: 'backgroundColor',
          hide: { key: 'backgroundType', values: [homePageBackgroundTypes.image, homePageBackgroundTypes.parallax] },
          placeholder: '#000000',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: column.background.backgroundColor || ''
        }, {
          label: 'Background Image',
          name: 'backgroundImage',
          hide: { key: 'backgroundType', value: homePageBackgroundTypes.color },
          type: fieldTypes.select,
          options: images,
          value: column.background.backgroundImage?.fileID || images[0]?.value
        }]
      }, {
        heading: 'Box Model'
      }, {
        elements: [{
          label: 'Padding (Space Inside Column)',
          name: 'padding',
          placeholder: '0px',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: column.boxModel.padding || ''
        }]
      }, {
        heading: 'Alignment'
      }, {
        elements: [{
          label: 'Align Item To:',
          name: 'alignment',
          type: fieldTypes.select,
          options: alignments,
          value: column.alignment || alignments[0].value
        }]
      }, {
        heading: 'Animation'
      }, {
        elements: [{
          label: 'Fade Item In From:',
          name: 'animate',
          type: fieldTypes.select,
          options: directions,
          value: column.animate || directions[0].value
        }]
      }, {
        heading: 'Content'
      }, {
        elements: [{
          label: 'Content Type',
          name: 'contentType',
          type: fieldTypes.select,
          options: contentTypes,
          value: column.content.contentType || homePageContentTypes.text
        }]
      }, {
        elements: [{
          label: 'Content Image',
          name: 'contentImage',
          hide: { key: 'contentType', values: [
            homePageContentTypes.countdown,
            homePageContentTypes.propertyList,
            homePageContentTypes.sponsors,
            homePageContentTypes.text
          ]},
          type: fieldTypes.select,
          options: images,
          value: column.content.contentImage?.fileID || images[0]?.value
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="homepage-column-settings-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={''}
      />
    </section>
  )
}

export default HomepageColumnSettingsForm;
