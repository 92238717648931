import React from 'react';
import { currency } from 'src/utilities/strings';

const ViewInvoice = ({ balance, items }) => {

  return (
    <section className="view-invoice">
      <div className="view-invoice-item heading">
        <div className="view-invoice-item-name">Name</div>
        <div className="view-invoice-item-price">Price</div>
        <div className="view-invoice-item-quantity">Qty</div>
        <div className="view-invoice-item-total">Total</div>
      </div>
      {
        (items || []).map(item => {
          return (
            <div className="view-invoice-item" key={item?.name}>
              <div className="view-invoice-item-name">{item?.name}</div>
              <div className="view-invoice-item-price">{currency(parseFloat(item?.amount))}</div>
              <div className="view-invoice-item-quantity">{(item?.quantity || 1)}</div>
              <div className="view-invoice-item-total">{currency((item?.quantity || 1) * parseFloat(item?.amount))}</div>
            </div>
          )
        })
      }
      <div className="view-invoice-item summary">
        <div className="view-invoice-item-name">Total</div>
        <div className="view-invoice-item-price"></div>
        <div className="view-invoice-item-quantity"></div>
        <div className="view-invoice-item-total">{currency(balance)}</div>
      </div>
    </section>
  )
}

export default ViewInvoice;
