import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import ImageCropper from 'src/components/Account/AccountHeadshot/ImageCropper';
import { openDialog } from 'src/redux/actions/dialog';
import { uploadHeadshotRequest } from 'src/redux/actions/users';

const AccountHeadshot = () => {
  const user = useSelector(state => state.userStore?.user);
  const [, setProgress] = useState();
  const dispatch = useDispatch();

  const handleSubmit = ({ file }) => {
    dispatch(uploadHeadshotRequest({ file, handleProgress: setProgress }));
  }

  const removeHeadshot = () => {
    dispatch(openDialog({
      key: 'removeHeadshot'
    }));
  }

  return (
    <section className="my-account-headshot-editor">
      {
        !user.image &&
          <ImageCropper
            handleSubmit={handleSubmit}
            requestPrefix={'UPLOAD_HEADSHOT'}
          />
      }
      {
        user.image && <>
          <img className="my-account-headshot-editor-image" src={user.headshot} />
          <Button
            color="danger"
            onClick={removeHeadshot}
            variant="contained"
          >Remove Image</Button>
        </>
      }
    </section>
  )
}

export default AccountHeadshot;
