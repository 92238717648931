const createRandomString = () => {
  const availableCharacters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return [...Array(5)].reduce(
    (a) =>
      a + availableCharacters[~~(Math.random() * availableCharacters.length)],
    ''
  );
}

export const phone = (value) => {
  return value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export const createResourceID = () => {
  return `${createRandomString()}-${createRandomString()}`;
}

export const currency = (value) => {
  if (!value) {
    return '$0.00';
  }
  
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
}