import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { getInvitationRequest, signupRequest } from 'src/redux/actions/users';

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.userStore?.user);
  const invitation = useSelector(state => state.userStore?.invitation);
  const { invitationKey } = useParams();

  if (!invitationKey) {
    navigate('/');
  }

  const handleSubmit = (event) => {
    dispatch(signupRequest({ form: {
      form: event,
      invitation
    } }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'First Name',
          name: 'firstName',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          autoComplete: 'given-name',
          value: '',
          required: true
        }, {
          label: 'Last Name',
          name: 'lastName',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          autoComplete: 'family-name',
          value: '',
          required: true
        }]
      }, {
        elements: [{
          label: 'Email Address',
          name: 'email',
          type: fieldTypes.input,
          inputType: inputTypes.email,
          autoComplete: 'email',
          value: invitation?.email,
          required: true
        }, {
          label: 'Phone Number',
          name: 'phone',
          type: fieldTypes.input,
          inputType: inputTypes.phone,
          autoComplete: 'phone',
          value: ''
        }]
      }, {
        elements: [{
          label: 'Password',
          name: 'password',
          mustMatchPassword: 'confirm-password',
          type: fieldTypes.input,
          inputType: inputTypes.password,
          value: '',
          required: true
        }, {
          label: 'Confirm Password',
          name: 'confirm-password',
          passwordToMatch: 'password',
          type: fieldTypes.input,
          inputType: inputTypes.passwordAny,
          value: '',
          required: true
        }]
      }]
    },
    submitCTA: 'Sign Up',
    cancelCTA: 'Cancel'
  };

  useEffect(() => {
    dispatch(getInvitationRequest({ invitationKey }));
  }, [dispatch, invitationKey])

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [navigate, user]);

  return (
    <div className="signup">
      <div className="signup-form floating">
        <img className="logo" src="/assets/images/logo.svg" alt="Logo" />
        <h2>Administrative Portal Sign Up</h2>
        <AutoForm
          configuration={configuration}
          handleSubmit={handleSubmit}
          requestPrefix={'SIGNUP'}
        />
        {
          invitation?.description && <p className="signup-description">{invitation.description}</p>
        }
      </div>
    </div>
  )
}

export default SignUp;
