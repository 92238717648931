import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContestRequest,
  updateContestConnectedEventRequest
} from 'src/redux/actions/contests';
import {
  getEventListRequest
} from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import {
  fieldTypes,
  inputTypes,
  none
} from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const ContestConnectedEvent = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const events = useSelector(state => state.eventStore?.events);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const [configuration, setConfiguration] = useState(null);
  const [hasSelectedEvent, setHasSelectedEvent] = useState(contest?.connectedEvent !== none);
  const [title, setTitle] = useState();
  const [event, setEvent] = useState();
  const [CTA, setCTA] = useState();
  const [description, setDescription] = useState();
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  const handleSubmit = (event) => {
    dispatch(updateContestConnectedEventRequest({ clientURL: client.url, contestURL, form: event }));
  }

  const onChange = ({ element, value }) => {
    if (element?.name === 'connectedEvent') {
      setHasSelectedEvent(value !== none);
      setEvent(value);
    }
    if (element?.name === 'eventSectionTitle') {
      setTitle(value);
    }
    if (element?.name === 'eventCTA') {
      setCTA(value);
    }
    if (element?.name === 'eventDescription') {
      setDescription(value);
    }
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
    if (!events) {
      dispatch(getEventListRequest({ clientURL: client.url }));
    }
  }, []);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestConnectedEvent', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestConnectedEvent',
      options: { client, contest } 
    }));

    if (!contestFetching && !!events?.length)
      setConfiguration({
        formElements: {
          rows: [{
            elements: [{
              label: 'Connected Event',
              name: 'connectedEvent',
              type: fieldTypes.select,
              options: [{ label: 'None', value: none },
                ...(events || [])
                  .filter(event => !event.archived)
                  .map(event => { return { label: event.name, value: event.eventID }})
              ],
              value: event || contest?.connectedEvent || none
            }]
          }, {
            elements: [{
              label: 'Section Title',
              name: 'eventSectionTitle',
              type: fieldTypes.input,
              inputType: inputTypes.text,
              value: title || contest?.eventSectionTitle || '',
              hide: { key: 'connectedEvent', value: none },
              required: !!hasSelectedEvent
            }, {
              label: 'Event Call To Action',
              name: 'eventCTA',
              type: fieldTypes.input,
              inputType: inputTypes.text,
              value: CTA || contest?.eventCTA || '',
              hide: { key: 'connectedEvent', value: none },
              required: !!hasSelectedEvent
            }]
          }, {
            elements: [{
              label: 'Event Description',
              name: 'eventDescription',
              type: fieldTypes.textarea,
              value: description || contest?.eventDescription || '',
              hide: { key: 'connectedEvent', value: none },
              required: !!hasSelectedEvent
            }]
          }]
        },
        submitCTA: 'Save'
      });
  }, [contestFetching, events, hasSelectedEvent]);

  return (
    <section className="contest-name box">
      <div className="box-title">
        <h3>
            {
              !contestFetching && (
                <span>Connected Event</span>
              )
            }
            {
              contestFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        (!contestFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            onChange={onChange}
            requestPrefix={'UPDATE_CONTEST_CONNECTED_EVENT'}
          />
        </div>
        )
      }
    </section>
  )
}

export default ContestConnectedEvent;
