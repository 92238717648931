import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import {
  fieldTypes,
  inputTypes
} from 'src/constants';
import { clearForgotPasswordChanged, forgotPasswordRequest } from 'src/redux/actions/users';

const ForgotPassword = () => {
  const forgotPasswordChanged = useSelector(state => state.userStore?.forgotPasswordChanged);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearForgotPasswordChanged());
  }, []);

  const handleSubmit = (event) => {
    dispatch(forgotPasswordRequest({ form: { ...event, clientURL: 'admin' } }));
  }
  
  const configuration = {
    formElements: {
      rows: [{
        forward: 'Provide your email address, and if it exists in our system we will email you a link to reset your account.'
      }, {
        elements: [{
          label: 'Email',
          name: 'email',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: '',
          required: true
        }]
      }]
    },
    submitCTA: 'Request',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="forgot-password">
      {
        forgotPasswordChanged && <p>Thanks, keep an eye on your email.</p>
      }
      {
        !forgotPasswordChanged && <AutoForm
          configuration={configuration}
          handleSubmit={handleSubmit}
          requestPrefix={'FORGOT_PASSWORD'}
        />
      }
    </section>
  )
}

export default ForgotPassword;
