import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { Button } from '@mui/material';
import { fieldTypes, gatewayOptions } from 'src/constants';
import { openDialog } from 'src/redux/actions/dialog';
import {
  authorizeSquareRequest,
  authorizeStripeRequest,
  updateClientGatewayRequest
} from 'src/redux/actions/client';
import LoadingCircles from 'src/components/Elements/LoadingCircles';
import createLoadingSelector from 'src/redux/loading';

const ClientGateway = ({ gatewaysFetching }) => {
  const client = useSelector(state => state.clientStore?.client);
  const dispatch = useDispatch();

  const gateways = [{
    label: 'Invoice Manually',
    value: 'demo'
  }, {
    label: 'Stripe',
    value: 'stripe'
  }, {
    label: 'Square',
    value: 'square'
  }];

  const handleSubmit = (event) => {
    dispatch(updateClientGatewayRequest({ clientURL: client.url, form: event }));
  };

  const authorizeActions = {
    [gatewayOptions.stripe.value]: () => {
      dispatch(authorizeStripeRequest({ clientURL: client.url }));
    },
    [gatewayOptions.square.value]: () => {
      dispatch(authorizeSquareRequest({ clientURL: client.url }));
    }
  };

  const revokeActions = {
    [gatewayOptions.stripe.value]: () => {
      dispatch(openDialog({
        props: { client },
        key: 'revokeStripe'
      }));
    },
    [gatewayOptions.square.value]: () => {
      dispatch(openDialog({
        props: { client },
        key: 'revokeSquare'
      }));
    }
  };

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Select Payment Gateway',
          name: 'gateway',
          type: fieldTypes.select,
          options: gateways,
          value: client.gateway
        }]
      }]
    },
    submitCTA: 'Save'
  };
  
  const renderGatewayConnection = () => {
    if (!client[client?.gateway]) {
      return (
        <>
          <p>
            {`CPJ requires read/write access to your ${gatewayOptions[client?.gateway]?.label} account in order to process payments on your behalf. `}
            {`To grant access, click the button below to be taken to ${gatewayOptions[client?.gateway]?.label} for authorization.`}
          </p>
          <Button
            color="confirm"
            disabled={gatewaysFetching}
            onClick={() => authorizeActions[client.gateway]()}
            variant="contained"
          >{ gatewaysFetching && (
              <LoadingCircles />
            )
          }
          {
            !gatewaysFetching && (
              <>Authorize</>
            )
          }</Button>
        </>
      );
    }
    if (client[client?.gateway]) {
      return (
        <>
          <p>
            {`CPJ is connected with your ${gatewayOptions[client?.gateway]?.label} account. We are now prepared to process payments on your behalf.`}
          </p>
          <Button
            color="danger"
            disabled={gatewaysFetching}
            onClick={() => revokeActions[client.gateway]()}
            variant="contained"
          >{ gatewaysFetching && (
            <LoadingCircles />
          )
        }
        {
          !gatewaysFetching && (
            <>Revoke Access</>
          )
        }</Button>
        </>
      )
    }
  };

  return (
    <section className="client-gateway">
      <p>Configure how your organization is paid for your sales through our platform.</p>
      <div className="client-gateway-select">
        <AutoForm
          configuration={configuration}
          handleSubmit={handleSubmit}
          requestPrefix={'UPDATE_CLIENT_GATEWAY'}
        />
      </div>
      <div className="client-gateway-details">
        {
          client.gateway === gatewayOptions.demo.value && (
            <p>
              {`The manual invoice process will fully complete the registration process without accepting payment at the time of checkout. `}
              {`All items in the cart will be activated such as awards program entries or event tickets, and your organization will be responsible for invoicing the purchaser directly.`}
            </p>
          )
        }
        {
          client.gateway !== gatewayOptions.demo.value && (
            <>
              <p>
                {
                  <a href={gatewayOptions[client.gateway]?.url} target="_blank" rel="noreferrer">{gatewayOptions[client.gateway]?.url}</a>
                }
              </p>
              <p>
                {`No credit card data is ever submitted directly through our servers. Instead, payments taken through our platform are done so by users providing credit card data into a secure gateway's website via iframe. `}
                {`The iframe will appear at the end of the checkout flow, and distribution of the funds will be handled according to your selected gateway's policies and procedures. `}
              </p>
              {
                renderGatewayConnection()
              }
            </>
          )
        }
      </div>
    </section>
  )
}

const loadingSelector = createLoadingSelector([
  'AUTHORIZE_SQUARE',
  'AUTHORIZE_STRIPE',
  'REVOKE_SQUARE',
  'REVOKE_STRIPE',
]);
const mapStateToProps = (state) => ({ gatewaysFetching: loadingSelector(state) });
export default connect(mapStateToProps)(ClientGateway);
