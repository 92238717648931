import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes } from 'src/constants';
import { updateUserPrivacyRequest } from 'src/redux/actions/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const AccountUserPrivacy = ({ handleClose }) => {
  const dispatch = useDispatch();
  const submitted = useSelector(state => state.completedStore.UPDATE_USER_PRIVACY);
  const user = useSelector(state => state.userStore?.user);

  useEffect(() => {
    if (submitted) {
      handleClose();
    }
  }, [submitted]);

  const handleSubmit = (event) => {
    dispatch(updateUserPrivacyRequest({ form: event }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Opt out of all CPJ communications',
          name: 'optOutAll',
          type: fieldTypes.checkbox,
          value: user?.optOutAll || false
        }]
      }, {
        forward: 'Some communications related to important events you are associated with cannot be opted out of.'
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="my-account-content-section privacy">
      <div className="my-account-content-section-title">
        <span>User Privacy Settings</span>
        <FontAwesomeIcon
          className="my-account-content-section-title-close"
          icon={faXmark}
          onClick={handleClose}
        />
      </div>
      <div className="my-account-content-section-sub-title">Enable privacy settings for your data</div>
      <AutoForm
        configuration={configuration}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_USER_PRIVACY'}
      />
    </section>
  )
}

export default AccountUserPrivacy;
