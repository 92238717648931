import * as actions from 'src/redux/actions/contests';
import { toast } from 'react-toastify';
import sortBy from 'src/utilities/sort';
import { cloneDeep } from 'lodash';
import { supportingProductTypes } from 'src/constants';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.ARCHIVE_CONTEST_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.contest.contestID) {
        const updatedContests = [...state?.contests];
        const index = updatedContests.findIndex(contest => contest.contestID === action.context.contest.contestID);
        if (index !== -1) {
          updatedContests[index] = {...updatedContests[index], ...action.context.contest};
          toast.success('Awards program archive status updated.');
        }
        return { ...state, contests: updatedContests };
      }

      return state;

    case actions.ASSIGN_CONTEST_AWARDS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.entryID && action.context?.categoryID && action.context.awards) {
        const updatedWinnersList = cloneDeep(state.contest?.winnersCategoryList);
        const categoryIndex = updatedWinnersList.findIndex(category => category.categoryID === action.context?.categoryID);
        const entryIndex = updatedWinnersList[categoryIndex].entries.findIndex(entry => entry.entryID === action.context?.entryID);
        updatedWinnersList[categoryIndex].entries[entryIndex].awards = action.context.awards;

        toast.success('Awards assigned for entry.');

        return { ...state, contest: { ...state.contest, winnersCategoryList: updatedWinnersList } };
      }

      if (action.context?.entryID && action.context?.scholarshipID && action.context.awards) {
        const updatedWinnersList = cloneDeep(state.contest?.winnersScholarshipList);
        const scholarshipIndex = updatedWinnersList.findIndex(scholarship => scholarship.scholarshipID === action.context?.scholarshipID);
        const entryIndex = updatedWinnersList[scholarshipIndex].applications.findIndex(entry => entry.entryID === action.context?.entryID);
        updatedWinnersList[scholarshipIndex].applications[entryIndex].awards = action.context.awards;

        toast.success('Awards assigned for scholarship application.');

        return { ...state, contest: { ...state.contest, winnersScholarshipList: updatedWinnersList } };
      }

      return state;

    case actions.CLEAR_CONTEST_VOLUNTEERS_SUCCESS:
      return { ...state, contest: { ...state.contest, volunteers: [] } };

    case actions.CLEAR_SCORING_PROGRESS:
      return { ...state, contest: { ...state.contest, scoringProgress: null }};

    case actions.CLEAR_SCORING_PROGRESS_ENTRIES_SUCCESS:
      return { ...state, contest: { ...state.contest, scoringProgressEntries: null } };
  
    case actions.CLEAR_SCORING_PROGRESS_JUDGES_SUCCESS:
      return { ...state, contest: { ...state.contest, scoringProgressJudges: null } };

    case actions.CONVERT_CONTEST_JUDGE_PROSPECT_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.judge && action.context?.prospectID) {
        const updatedJudges = [...state.contest.judges, action.context.judge];
        const updatedProspects = state.contest.judgeProspects.filter(prospect => {
          return prospect.prospectID !== action.context.prospectID;
        });
        toast.success('Awards program judge created.');

        return { ...state, contest: { ...state.contest, judges: sortBy({ key: 'user.fullName', list: updatedJudges }), judgeProspects: updatedProspects } };
      }
      return state;

    case actions.CREATE_CONTEST_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('Awards program created.');

      return { ...state, contests: [action.context.contest, ...state.contests] };

    case actions.CREATE_CONTEST_ARCHIVE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return { ...state, contest: { ...state.contest, archives: [] }};
      }

      if (action.context?.archive) {
        toast.success('File archive is being created. You will be notified via email when ready.');
      }

      return state;

    case actions.DUPLICATE_CONTEST_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('Awards program duplicated.');

      return { ...state, contests: [action.context.contest, ...state.contests] };

    case actions.DUPLICATE_APPLICATION_FORM_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context.applicationForm) {
        toast.success('Awards program application form duplicated.');
        return { ...state, contest: { ...state.contest, applicationForms: [...(state.contest.applicationForms || []), action.context.applicationForm] } };
      }

      return state;

    case actions.DUPLICATE_ENTRY_FORM_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context.entryForm) {
        toast.success('Awards program entry form duplicated.');
        return { ...state, contest: { ...state.contest, entryForms: [...(state.contest.entryForms || []), action.context.entryForm] } };
      }

      return state;

    case actions.DUPLICATE_SCORESHEET_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context.scoresheet) {
        toast.success('Awards program scoresheet duplicated.');
        return { ...state, contest: { ...state.contest, scoresheets: [...(state.contest.scoresheets || []), action.context.scoresheet] } };
      }

      return state;

    case actions.GET_CONTEST_REQUEST:
      return { ...state, contest: null };

    case actions.GET_CONTEST_SUCCESS:
      if (action.context?.error) {
        return state;
      }

      return action.context?.contest ? { ...state, contest: action.context.contest, event: null, parade: null } : state;

    case actions.GET_CONTEST_ARCHIVES_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return { ...state, contest: { ...state.contest, archives: [], archiveCategoryList: [], archiveScholarshipList: [] }};
      }

      if (action.context?.archives) {
        return { ...state, contest: { ...state.contest, archives: action.context.archives, archiveCategoryList: action.context.categories, archiveScholarshipList: action.context.scholarships }};
      }

      return state;

    case actions.GET_CONTEST_LIST_REQUEST:
      return { ...state, contests: [] };

    case actions.GET_CONTEST_LIST_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return { ...state, contests: [] };
      }

      return action.context?.contests ? { ...state, contests: action.context.contests } : state;

    case actions.GET_CONTEST_PUBLIC_VOTES_SUCCESS:
      if (action.context.publicVotes) {
        const map = new Map();
        for (let i = 0; i < (action.context.publicVotes.votes || []).length; i++) {
          const categories = action.context.publicVotes.votes[i].ballot.categories;
          for (let j = 0; j < (categories || []).length; j++) {
            if (!map.has(categories[j].categoryID)) {
              map.set(categories[j].categoryID, new Map());
            }
            for (let k = 0; k < (categories[j].entries || []).length; k++) {
              if (!map.get(categories[j].categoryID).has(categories[j].entries[k])) {
                map.get(categories[j].categoryID).set(categories[j].entries[k], 0);
              }
              map.get(categories[j].categoryID).set(categories[j].entries[k], map.get(categories[j].categoryID).get(categories[j].entries[k]) + 1);
            }
          }
        }
        const publicVotes = {
          total: action.context.publicVotes?.votes?.length,
          categories: (action.context.publicVotes?.categories || []).map(category => {
            category.entries = category.entries.map(entry => {
              entry.votes = map?.get(category.categoryID)?.get(entry.entryID) || 0;
              return entry;
            }).sort((a, b) => {
              if (a.votes < b.votes) return 1;
              if (a.votes > b.votes) return -1;
              return 0;
            });
            return category;
          })
        };

        return { ...state, contest: { ...state.contest, publicVotes }};
      }

      return state;

    case actions.GET_CONTEST_WINNERS_REQUEST:
      return { ...state, contest: { ...state.contest, winnersCategoryList: [], winnersScholarshipList : [] }};

    case actions.GET_CONTEST_WINNERS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return { ...state, contest: { ...state.contest, winnersCategoryList: action.context.winnersCategoryList, winnersScholarshipList: action.context.winnersScholarshipList }};

    case actions.GET_SCORING_PROGRESS_BY_ENTRY_REQUEST:
      return { ...state, contest: { ...state.contest, scoringProgressEntries: null, scoringProgressJudges: null }};

    case actions.GET_SCORING_PROGRESS_BY_ENTRY_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return { ...state, contest: { ...state.contest, scoringProgressEntries: action.context.entries }};

    case actions.GET_SCORING_PROGRESS_BY_JUDGE_REQUEST:
      return { ...state, contest: { ...state.contest, scoringProgressEntries: null, scoringProgressJudges: null }};

    case actions.GET_SCORING_PROGRESS_BY_JUDGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return { ...state, contest: { ...state.contest, scoringProgressJudges: action.context.judges }};

    case actions.GET_SCORING_PROGRESS_FOR_ENTRY_REQUEST:
      return { ...state, contest: { ...state.contest, scoringProgress: null }};

    case actions.GET_SCORING_PROGRESS_FOR_ENTRY_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return { ...state, contest: { ...state.contest, scoringProgress: action.context.scoresheet }};

    case actions.GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE_REQUEST:
      return { ...state, contest: { ...state.contest, scoringProgressEntries: null }};

    case actions.GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return { ...state, contest: { ...state.contest, scoringProgressEntries: action.context.entries }};

    case actions.GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY_REQUEST:
      return { ...state, contest: { ...state.contest, scoringProgressJudges: null }};

    case actions.GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return { ...state, contest: { ...state.contest, scoringProgressJudges: action.context.judges }};

    case actions.REMOVE_CONTEST_BANNER_IMAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.bannerID) {
        toast.success('Banner image removed.');
        const updatedBannerImages = state.contest.bannerImages.filter(bannerImage => bannerImage.bannerID !== action.context.bannerID);

        return { ...state, contest: { ...state.contest, bannerImages: updatedBannerImages } };
      }

      return state;

    case actions.REMOVE_CONTEST_APPLICATION_FOLDER_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.applicationFolderID) {
        toast.success('Awards program application folder removed.');
        const updatedApplicationFolders = state.contest.applicationFolders.filter(applicationFolder => {
          return applicationFolder.applicationFolderID !== action.context.applicationFolderID;
        });
        return { ...state, contest: { ...state.contest, applicationFolders: updatedApplicationFolders } };
      }

      return state;

    case actions.REMOVE_CONTEST_APPLICATION_FORM_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.applicationFormID) {
        toast.success('Awards program application form removed.');
        const updatedApplicationForms = state.contest.applicationForms.filter(applicationForm => {
          return applicationForm.applicationFormID !== action.context.applicationFormID;
        });
        return { ...state, contest: { ...state.contest, applicationForms: updatedApplicationForms } };
      }

      return state;

    case actions.REMOVE_CONTEST_ARCHIVE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return { ...state, contest: { ...state.contest, archives: [] }};
      }

      if (action.context?.archiveID) {
        toast.success('File archive removed.');
        const updatedArchives = state.contest.archives.filter(archive => {
          return archive.archiveID !== action.context.archiveID;
        });
        return { ...state, contest: { ...state.contest, archives: updatedArchives } };
      }

      return state;

    case actions.REMOVE_CONTEST_AWARD_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.awardID) {
        toast.success('Awards program award removed.');
        const updatedAwards = state.contest.awards.filter(award => {
          return award.awardID !== action.context.awardID;
        });
        return { ...state, contest: { ...state.contest, awards: updatedAwards } };
      }

      return state;

    case actions.REMOVE_CONTEST_CATEGORY_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.categoryID) {
        toast.success('Awards program category removed.');
        const updatedCategories = state.contest.categories.filter(category => {
          return category.categoryID !== action.context.categoryID;
        });
        return { ...state, contest: { ...state.contest, categories: updatedCategories } };
      }

      return state;

    case actions.REMOVE_CONTEST_COORDINATOR_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.coordinator) {
        toast.success('Awards program coordinator removed.');
        const updatedCoordinators = state.contest.coordinators.filter(coordinator => {
          return coordinator.coordinatorID !== action.context.coordinator.coordinatorID;
        });
        return { ...state, contest: { ...state.contest, coordinators: updatedCoordinators } };
      }

      return state;

    case actions.REMOVE_CONTEST_DOCUMENT_FOLDER_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.documentFolderID) {
        toast.success('Awards program document folder removed.');
        const updatedDocumentFolders = state.contest.documentFolders.filter(documentFolder => {
          return documentFolder.documentFolderID !== action.context.documentFolderID;
        });
        return { ...state, contest: { ...state.contest, documentFolders: updatedDocumentFolders } };
      }

      return state;

    case actions.REMOVE_CONTEST_DOWNLOADABLE_FILE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.file) {
        toast.success('Downloadable file removed.');
        const updatedFiles = state.contest.files.filter(file => {
          return file.fileID !== action.context.file.fileID;
        });
        return { ...state, contest: { ...state.contest, files: updatedFiles } };
      }

      return state;

    case actions.REMOVE_CONTEST_ENTRY_FORM_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.entryFormID) {
        toast.success('Awards program entry form removed.');
        const updatedEntryForms = state.contest.entryForms.filter(entryForm => {
          return entryForm.entryFormID !== action.context.entryFormID;
        });
        return { ...state, contest: { ...state.contest, entryForms: updatedEntryForms } };
      }

      return state;

    case actions.REMOVE_CONTEST_JUDGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.assignmentID) {
        toast.success('Awards program judge removed.');
        const updatedJudges = state.contest.judges.filter(judge => {
          return judge.assignmentID !== action.context.assignmentID;
        });
        return { ...state, contest: { ...state.contest, judges: updatedJudges } };
      }

      return state;

    case actions.REMOVE_CONTEST_JUDGE_INVITATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.invitationKey) {
        toast.success('Awards program judge invitation removed.');
        const updatedInvitations = state.contest.judgeInvitations.filter(invitation => {
          return invitation.invitationKey !== action.context.invitationKey;
        });
        return { ...state, contest: { ...state.contest, judgeInvitations: updatedInvitations } };
      }

      return state;

    case actions.REMOVE_CONTEST_JUDGE_PROSPECT_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.prospectID) {
        toast.success('Awards program judge prospect dismissed.');
        const updatedProspects = state.contest.judgeProspects.filter(prospect => {
          return prospect.prospectID !== action.context.prospectID;
        });
        return { ...state, contest: { ...state.contest, judgeProspects: updatedProspects } };
      }

      return state;

    case actions.REMOVE_CONTEST_OPTIONAL_FEE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.feeID) {
        toast.success('Awards program optional fee removed.');
        const updatedFees = state.contest.fees.filter(fee => {
          return fee.feeID !== action.context.feeID;
        });
        return { ...state, contest: { ...state.contest, fees: updatedFees } };
      }

      return state;

    case actions.REMOVE_CONTEST_PROMO_CODE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.promoCodeID) {
        toast.success('Awards program promo code removed.');
        const updatedPromoCodes = state.contest.promoCodes.filter(promoCode => {
          return promoCode.promoCodeID !== action.context.promoCodeID;
        });
        return { ...state, contest: { ...state.contest, promoCodes: updatedPromoCodes } };
      }

      return state;

    case actions.REMOVE_CONTEST_REGISTRATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
    
      if (action.context?.transactionID) {
        toast.success('Awards program registration removed.');
        const updatedRegistrations = state.contest.registrations.filter(registration => {
          return registration.transactionID !== action.context.transactionID;
        });
        return { ...state, contest: { ...state.contest, registrations: updatedRegistrations } };
      }
    
      return state;

    case actions.REMOVE_CONTEST_SCHOLARSHIP_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.scholarshipID) {
        toast.success('Awards program scholarship removed.');
        const updatedScholarships = state.contest.scholarships.filter(scholarship => {
          return scholarship.scholarshipID !== action.context.scholarshipID;
        });
        return { ...state, contest: { ...state.contest, scholarships: updatedScholarships } };
      }

      return state;
    
    case actions.REMOVE_CONTEST_SCORESHEET_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
    
      if (action.context?.scoresheetID) {
        toast.success('Awards program scoresheet removed.');
        const updatedScoresheets = state.contest.scoresheets.filter(scoresheet => {
          return scoresheet.scoresheetID !== action.context.scoresheetID;
        });
        return { ...state, contest: { ...state.contest, scoresheets: updatedScoresheets } };
      }
    
      return state;

    case actions.REMOVE_CONTEST_SPONSOR_TIER_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.sponsorTierID) {
        toast.success('Awards program sponsor tier removed.');
        const updatedSponsorTiers = state.contest.sponsorTiers.filter(sponsorTier => {
          return sponsorTier.sponsorTierID !== action.context.sponsorTierID;
        });
        return { ...state, contest: { ...state.contest, sponsorTiers: updatedSponsorTiers } };
      }

      return state;

    case actions.REMOVE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.timeframeID && action.context.type === supportingProductTypes.sponsor) {
        toast.success('Awards program sponsor timeframe removed.');
        const updatedTimeframes = state.contest.sponsorshipTimeframes.filter(timeframe => {
          return timeframe.timeframeID !== action.context.timeframeID;
        });
        return { ...state, contest: { ...state.contest, sponsorshipTimeframes: updatedTimeframes } };
      }

      return state;
    
    case actions.REMOVE_CONTEST_TIMEFRAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.timeframeID) {
        toast.success('Awards program timeframe removed.');
        const updatedTimeframes = state.contest.timeframes.filter(timeframe => {
          return timeframe.timeframeID !== action.context.timeframeID;
        });
        return { ...state, contest: { ...state.contest, timeframes: updatedTimeframes } };
      }

      return state;

    case actions.REORDER_CONTEST_APPLICATION_FOLDERS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.applicationFolders) {
        toast.success('Awards program application folder order updated.');
        return { ...state, contest: { ...state.contest, applicationFolders: action.context?.applicationFolders } };
      }

      return state;
    
    case actions.REORDER_CONTEST_APPLICATION_FORMS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.applicationForms) {
        toast.success('Awards program application form order updated.');
        return { ...state, contest: { ...state.contest, applicationForms: action.context?.applicationForms } };
      }

      return state;

    case actions.REORDER_CONTEST_AWARDS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.awards) {
        toast.success('Awards program award order updated.');
        return { ...state, contest: { ...state.contest, awards: action.context?.awards } };
      }

      return state;

    case actions.REORDER_CONTEST_CATEGORIES_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.categories) {
        toast.success('Awards program category order updated.');
        return { ...state, contest: { ...state.contest, categories: action.context?.categories } };
      }

      return state;

    case actions.REORDER_CONTEST_COORDINATORS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.coordinators) {
        toast.success('Awards program coordinator order updated.');
        return { ...state, contest: { ...state.contest, coordinators: action.context?.coordinators } };
      }

      return state;

    case actions.REORDER_CONTEST_DOCUMENT_FOLDERS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.documentFolders) {
        toast.success('Awards program document folder order updated.');
        return { ...state, contest: { ...state.contest, documentFolders: action.context?.documentFolders } };
      }

      return state;

    case actions.REORDER_CONTEST_ENTRY_FORMS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.entryForms) {
        toast.success('Awards program entry form order updated.');
        return { ...state, contest: { ...state.contest, entryForms: action.context?.entryForms } };
      }

      return state;

    case actions.REORDER_CONTEST_OPTIONAL_FEES_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.fees) {
        toast.success('Awards program optional fee order updated.');
        return { ...state, contest: { ...state.contest, fees: action.context?.fees } };
      }

      return state;

    case actions.REORDER_CONTEST_SCHOLARSHIPS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.scholarships) {
        toast.success('Awards program scholarship order updated.');
        return { ...state, contest: { ...state.contest, scholarships: action.context?.scholarships } };
      }

      return state;

    case actions.REORDER_CONTEST_SCORESHEETS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
    
      if (action.context?.scoresheets) {
        toast.success('Awards program scoresheet order updated.');
        return { ...state, contest: { ...state.contest, scoresheets: action.context?.scoresheets } };
      }
    
      return state;

    case actions.REORDER_CONTEST_SPONSOR_TIERS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.sponsorTiers) {
        toast.success('Awards program sponsor tier order updated.');
        return { ...state, contest: { ...state.contest, sponsorTiers: action.context?.sponsorTiers } };
      }

      return state;
    
    case actions.REQUEST_CONTEST_JUDGE_VOLUNTEER_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.prospect) {
        toast.success('Judging prospect has been added to your list.');
        return { ...state, contest: { ...state.contest, judgeProspects: [...(state.contest.judgeProspects || []), action.context?.prospect] } };
      }
    
      return state;

    case actions.REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.users) {
        return { ...state, contest: { ...state.contest, volunteers: action.context?.users } };
      }
    
      return state;

    case actions.RESET_CONTEST_URL_CHANGED:
      const updatedContest = { ...state.contest, urlChanged: false }
      return { ...state, contest: updatedContest };

    case actions.SELECT_CONTEST_BANNER_IMAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.bannerImage) {
        toast.success('Banner image selected.');
        return { ...state, contest: { ...state.contest, bannerImage: action.context.bannerImage } };
      }
      return state;

    case actions.SEND_CONTEST_NOTIFICATIONS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('Notifications have been sent.');

      return state;

    case actions.UPDATE_CONTEST_APPLICATION_FOLDER_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.applicationFolder) {
        const index = state.contest.applicationFolders.findIndex(applicationFolder => applicationFolder.applicationFolderID === action.context.applicationFolder.applicationFolderID);
        let updatedApplicationFolders;
        if (index !== -1) {
          updatedApplicationFolders = [...state.contest.applicationFolders];
          updatedApplicationFolders[index] = {...updatedApplicationFolders[index], ...action.context.applicationFolder};
          toast.success('Awards program application folder updated.');
        } else {
          updatedApplicationFolders = [...state.contest.applicationFolders, action.context.applicationFolder];
          toast.success('Awards program application folder created.');
        }

        return { ...state, contest: { ...state.contest, applicationFolders: updatedApplicationFolders } };
      }

      return state;

    case actions.UPDATE_CONTEST_APPLICATION_FORM_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.applicationForm) {
        const index = state.contest.applicationForms.findIndex(applicationForm => applicationForm.applicationFormID === action.context.applicationForm.applicationFormID);
        let updatedApplicationForms;
        if (index !== -1) {
          updatedApplicationForms = [...state.contest.applicationForms];
          updatedApplicationForms[index] = {...updatedApplicationForms[index], ...action.context.applicationForm};
          toast.success('Awards program application form updated.');
        } else {
          updatedApplicationForms = [...state.contest.applicationForms, action.context.applicationForm];
          toast.success('Awards program application form created.');
        }

        return { ...state, contest: { ...state.contest, applicationForms: updatedApplicationForms } };
      }

      return state;

    case actions.UPDATE_CONTEST_APPLICATION_FORM_STRUCTURE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.applicationForm) {
        const index = state.contest.applicationForms.findIndex(applicationForm => applicationForm.applicationFormID === action.context.applicationForm.applicationFormID);
        let updatedApplicationForms;
        if (index !== -1) {
          updatedApplicationForms = [...state.contest.applicationForms];
          updatedApplicationForms[index] = {...updatedApplicationForms[index], ...action.context.applicationForm};
          toast.success('Awards program application form updated.');
        }

        return { ...state, contest: { ...state.contest, applicationForms: updatedApplicationForms } };
      }

      return state;

    case actions.UPDATE_CONTEST_AWARD_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.award) {
        const index = state.contest.awards.findIndex(award => award.awardID === action.context.award.awardID);
        let updatedAwards;
        if (index !== -1) {
          updatedAwards = [...state.contest.awards];
          updatedAwards[index] = {...updatedAwards[index], ...action.context.award};
          toast.success('Awards program award updated.');
        } else {
          updatedAwards = [...state.contest.awards, action.context.award];
          toast.success('Awards program award created.');
        }

        return { ...state, contest: { ...state.contest, awards: updatedAwards } };
      }

      return state;

    case actions.UPDATE_CONTEST_CATEGORY_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.category) {
        const index = state.contest.categories.findIndex(category => category.categoryID === action.context.category.categoryID);
        let updatedCategories;
        if (index !== -1) {
          updatedCategories = [...state.contest.categories];
          updatedCategories[index] = {...updatedCategories[index], ...action.context.category};
          toast.success('Awards program category updated.');
        } else {
          updatedCategories = [...state.contest.categories, action.context.category];
          toast.success('Awards program category created.');
        }

        return { ...state, contest: { ...state.contest, categories: updatedCategories } };
      }

      return state;

    case actions.UPDATE_CONTEST_CONFIGURATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.contest) {
        const updatedContest = Object.assign({ ...state.contest }, { ...action.context?.contest });
        toast.success('Changes saved.');
        return { ...state, contest: updatedContest };
      }

      return state;

    case actions.UPDATE_CONTEST_CONFIRMATION_EMAIL_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.contest.confirmationEmailText) {
        toast.success('Confirmation email updated.');
        return { ...state, contest: { ...state.contest, confirmationEmailText: action.context.contest.confirmationEmailText } };
      }
      
      return state;

    case actions.UPDATE_CONTEST_CONNECTED_EVENT_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.contest) {
        const updatedContest = Object.assign({ ...state.contest }, { ...action.context?.contest });
        toast.success('Changes saved.');
        return { ...state, contest: updatedContest };
      }

      return state;

    case actions.UPDATE_CONTEST_COORDINATOR_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.coordinator) {
        const newState = cloneDeep(state);

        const index = newState.contest.coordinators.findIndex(coordinator => coordinator.coordinatorID === action.context.coordinator.coordinatorID);
        if (index !== -1) {
          newState.contest.coordinators[index] = {...newState.contest.coordinators[index], ...action.context.coordinator};
          toast.success('Awards program coordinator updated.');
        } else {
          newState.contest.coordinators = [...newState.contest.coordinators, action.context.coordinator];
          toast.success('Awards program coordinator assigned.');
        }
        const updatedCoordinators = sortBy({ key: 'fullName', list: newState.contest.coordinators });
        return { ...newState, contest: { ...newState.contest, coordinators: updatedCoordinators } };
      }
      return state;

    case actions.UPDATE_CONTEST_CUSTOM_PAGE_SETTINGS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.customPage) {
        toast.success('Custom page settings updated.');
        return { ...state, contest: { ...state.contest, customPage: { ...(state.contest.customPage || {}), ...action.context.customPage } } };
      }
      
      return state;

    case actions.UPDATE_CONTEST_CUSTOM_PAGE_TEXT_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.customPage) {
        toast.success('Custom page settings updated.');
        return { ...state, contest: { ...state.contest, customPage: { ...(state.contest.customPage || {}), ...action.context.customPage } } };
      }
      
      return state;

    case actions.UPDATE_CONTEST_DATES_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.contest) {
        toast.success('Awards program dates updated.');
        return {
          ...state,
          contest: {
            ...state.contest, ...action.context.contest
          }
        }
      }
      
      return state;

    case actions.UPDATE_CONTEST_DOCUMENT_FOLDER_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.documentFolder) {
        const index = state.contest.documentFolders.findIndex(documentFolder => documentFolder.documentFolderID === action.context.documentFolder.documentFolderID);
        let updatedDocumentFolders;
        if (index !== -1) {
          updatedDocumentFolders = [...state.contest.documentFolders];
          updatedDocumentFolders[index] = {...updatedDocumentFolders[index], ...action.context.documentFolder};
          toast.success('Awards program document folder updated.');
        } else {
          updatedDocumentFolders = [...state.contest.documentFolders, action.context.documentFolder];
          toast.success('Awards program document folder created.');
        }

        return { ...state, contest: { ...state.contest, documentFolders: updatedDocumentFolders } };
      }

      return state;

    case actions.UPDATE_CONTEST_DOWNLOADABLE_FILE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.file) {
        const index = state.contest.files.findIndex(file => file.fileID === action.context.file.fileID);
        let updatedFiles;
        if (index !== -1) {
          updatedFiles = [...state.contest.files];
          updatedFiles[index] = {...updatedFiles[index], ...action.context.file};
          toast.success('Awards program downloadable file updated.');
        } else {
          updatedFiles = [...state.contest.files, action.context.file];
          toast.success('Awards program downloadable file created.');
        }

        return { ...state, contest: { ...state.contest, files: updatedFiles } };
      }

      return state;

    case actions.UPDATE_CONTEST_ENTRY_FORM_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.entryForm) {
        const index = state.contest.entryForms.findIndex(entryForm => entryForm.entryFormID === action.context.entryForm.entryFormID);
        let updatedEntryForms;
        if (index !== -1) {
          updatedEntryForms = [...state.contest.entryForms];
          updatedEntryForms[index] = {...updatedEntryForms[index], ...action.context.entryForm};
          toast.success('Awards program entry form updated.');
        } else {
          updatedEntryForms = [...state.contest.entryForms, action.context.entryForm];
          toast.success('Awards program entry form created.');
        }

        return { ...state, contest: { ...state.contest, entryForms: updatedEntryForms } };
      }

      return state;

    case actions.UPDATE_CONTEST_ENTRY_FORM_STRUCTURE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.entryForm) {
        const index = state.contest.entryForms.findIndex(entryForm => entryForm.entryFormID === action.context.entryForm.entryFormID);
        let updatedEntryForms;
        if (index !== -1) {
          updatedEntryForms = [...state.contest.entryForms];
          updatedEntryForms[index] = {...updatedEntryForms[index], ...action.context.entryForm};
          toast.success('Awards program entry form updated.');
        }

        return { ...state, contest: { ...state.contest, entryForms: updatedEntryForms } };
      }

      return state;

    case actions.UPDATE_CONTEST_GALLERY_SUCCESS: 
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('Awards program gallery settings changed.')
      return { ...state, contest: { ...state.contest, galleryActive: action.context.contest.galleryActive } };

    case actions.UPDATE_CONTEST_INSTRUCTIONS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.contest.instructions) {
        toast.success('Optional entry instructions updated.');
        return { ...state, contest: { ...state.contest, instructions: action.context.contest.instructions } };
      }
      
      return state;

    case actions.UPDATE_CONTEST_JUDGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.judge) {
        const index = state.contest.judges.findIndex(judge => judge.assignmentID === action.context.judge.assignmentID);
        let updatedJudges;
        if (index !== -1) {
          updatedJudges = [...state.contest.judges];
          updatedJudges[index] = {...updatedJudges[index], ...action.context.judge};
          toast.success('Awards program judge updated.');
        } else {
          updatedJudges = [...state.contest.judges, action.context.judge];
          toast.success('Awards program judge created.');
        }

        return { ...state, contest: { ...state.contest, judges: sortBy({ key: 'user.fullName', list: updatedJudges }) } };
      }

      return state;

    case actions.UPDATE_CONTEST_JUDGE_INVITATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.invitation) {
        const index = state.contest.judgeInvitations.findIndex(invitation => invitation.invitationKey === action.context.invitation.invitationKey);
        let updatedInvitations;
        if (index !== -1) {
          updatedInvitations = [...state.contest.judgeInvitations];
          updatedInvitations[index] = {...updatedInvitations[index], ...action.context.invitation};
          toast.success('Awards program judge invitation updated.');
        } else {
          updatedInvitations = [...state.contest.judgeInvitations, action.context.invitation];
          toast.success('Awards program judge invitation created.');
        }

        return { ...state, contest: { ...state.contest, judgeInvitations: sortBy({ key: 'email', list: updatedInvitations }) } };
      }

      return state;

    case actions.UPDATE_CONTEST_JUDGE_PROSPECT_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.prospect) {
        const index = state.contest.judgeProspects.findIndex(prospect => prospect.prospectID === action.context.prospect.prospectID);
        let updatedProspects;
        if (index !== -1) {
          updatedProspects = [...state.contest.judgeProspects];
          updatedProspects[index] = {...updatedProspects[index], ...action.context.prospect};
          toast.success('Awards program judge prospect updated.');
        } else {
          updatedProspects = [...(state.contest.judgeProspects || []), action.context.prospect];
          toast.success('Awards program judge prospect created.');
        }

        return { ...state, contest: { ...state.contest, judgeProspects: sortBy({ key: 'email', list: updatedProspects }) } };
      }

      return state;

    case actions.UPDATE_CONTEST_NAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.contest) {
        const updatedContest = Object.assign({ ...state.contest }, { ...action.context?.contest });

        if (action.context?.contest?.url !== state.contest.url) {
          updatedContest.urlChanged = action.context?.contest?.url;
        }

        toast.success('Changes saved.')
        return { ...state, contest: updatedContest };
      }

      return state;

    case actions.UPDATE_CONTEST_OPTIONAL_FEE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.fee) {
        const index = state.contest.fees.findIndex(fee => fee.feeID === action.context.fee.feeID);
        let updatedFees;
        if (index !== -1) {
          updatedFees = [...state.contest.fees];
          updatedFees[index] = {...updatedFees[index], ...action.context.fee};
          toast.success('Awards program optional fee updated.');
        } else {
          updatedFees = [...state.contest.fees, action.context.fee];
          toast.success('Awards program optional fee created.');
        }

        return { ...state, contest: { ...state.contest, fees: updatedFees } };
      }

      return state;

    case actions.UPDATE_CONTEST_PARTNER_PAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.contest.partnerPageText) {
        toast.success('Partner page updated.');
        return { ...state, contest: { ...state.contest, partnerPageText: action.context.contest.partnerPageText } };
      }
      
      return state;

    case actions.UPDATE_CONTEST_PROMO_CODE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.promoCode) {
        const index = state.contest.promoCodes.findIndex(promoCode => promoCode.promoCodeID === action.context.promoCode.promoCodeID);
        let updatedPromoCodes;
        if (index !== -1) {
          updatedPromoCodes = [...state.contest.promoCodes];
          updatedPromoCodes[index] = {...updatedPromoCodes[index], ...action.context.promoCode};
          toast.success('Awards program promo code updated.');
        } else {
          updatedPromoCodes = [...state.contest.promoCodes, action.context.promoCode];
          toast.success('Awards program promo code created.');
        }

        return { ...state, contest: { ...state.contest, promoCodes: updatedPromoCodes } };
      }

      return state;

    case actions.UPDATE_CONTEST_PUBLIC_VOTING_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.contest) {
        toast.success('Awards program public vote settings updated.');
        return {
          ...state,
          contest: {
            ...state.contest, ...action.context.contest
          }
        }
      }
      
      return state;

    case actions.UPDATE_CONTEST_REGISTRATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
    
      if (action.context?.transaction) {
        const index = state.contest.registrations.findIndex(registration => registration.transactionID === action.context.transaction.transactionID);
        const updatedRegistrations = cloneDeep(state.contest.registrations);
        updatedRegistrations[index].items = action.context.transaction.items;
        toast.success('Awards program registration updated.');
        return { ...state, contest: { ...state.contest, registrations: updatedRegistrations } };
      }
    
      return state;

    case actions.UPDATE_CONTEST_RULES_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.contest.pageText) {
        toast.success('Rules & Regulations updated.');
        return { ...state, contest: { ...state.contest, pageText: action.context.contest.pageText } };
      }
      
      return state;

    case actions.UPDATE_CONTEST_SCHOLARSHIP_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.scholarship) {
        const index = state.contest.scholarships.findIndex(scholarship => scholarship.scholarshipID === action.context.scholarship.scholarshipID);
        let updatedScholarships;
        if (index !== -1) {
          updatedScholarships = [...state.contest.scholarships];
          updatedScholarships[index] = {...updatedScholarships[index], ...action.context.scholarship};
          toast.success('Awards program scholarship updated.');
        } else {
          updatedScholarships = [...state.contest.scholarships, action.context.scholarship];
          toast.success('Awards program scholarship created.');
        }

        return { ...state, contest: { ...state.contest, scholarships: updatedScholarships } };
      }

      return state;

    case actions.UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.scholarship) {
        const index = state.contest.scholarships.findIndex(scholarship => scholarship.scholarshipID === action.context.scholarship.scholarshipID);
        const updatedScholarships = [...state.contest.scholarships];
        updatedScholarships[index] = {...updatedScholarships[index], ...action.context.scholarship};
        toast.success('Awards program scholarship requirements updated.');

        return { ...state, contest: { ...state.contest, scholarships: updatedScholarships } };
      }

      return state;

    case actions.UPDATE_CONTEST_SCORESHEET_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
    
      if (action.context?.scoresheet) {
        const index = state.contest.scoresheets.findIndex(scoresheet => scoresheet.scoresheetID === action.context.scoresheet.scoresheetID);
        let updatedScoresheets;
        if (index !== -1) {
          updatedScoresheets = [...state.contest.scoresheets];
          updatedScoresheets[index] = {...updatedScoresheets[index], ...action.context.scoresheet};
          toast.success('Awards program scoresheet updated.');
        } else {
          updatedScoresheets = [...state.contest.scoresheets, action.context.scoresheet];
          toast.success('Awards program scoresheet created.');
        }
    
        return { ...state, contest: { ...state.contest, scoresheets: updatedScoresheets } };
      }
    
      return state;
    
    case actions.UPDATE_CONTEST_SCORESHEET_CRITERIA_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
    
      if (action.context?.scoresheet) {
        const index = state.contest.scoresheets.findIndex(scoresheet => scoresheet.scoresheetID === action.context.scoresheet.scoresheetID);
        let updatedScoresheets;
        if (index !== -1) {
          updatedScoresheets = [...state.contest.scoresheets];
          updatedScoresheets[index] = {...updatedScoresheets[index], ...action.context.scoresheet};
          toast.success('Awards program scoresheet updated.');
        }
    
        return { ...state, contest: { ...state.contest, scoresheets: updatedScoresheets } };
      }
    
      return state;
    
    case actions.UPDATE_CONTEST_SCORING_INSTRUCTIONS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.contest.scoringInstructions) {
        toast.success('Optional scoring instructions updated.');
        return { ...state, contest: { ...state.contest, scoringInstructions: action.context.contest.scoringInstructions } };
      }
      
      return state;

    case actions.UPDATE_CONTEST_SEO_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.contest) {
        const updatedContest = Object.assign({ ...state.contest }, { ...action.context?.contest });
        toast.success('Changes saved.');
        return { ...state, contest: updatedContest };
      }

      return state;

    case actions.UPDATE_CONTEST_SPONSOR_TIER_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.sponsorTier) {
        const index = state.contest.sponsorTiers.findIndex(sponsorTier => sponsorTier.sponsorTierID === action.context.sponsorTier.sponsorTierID);
        let updatedSponsorTiers;
        if (index !== -1) {
          updatedSponsorTiers = [...state.contest.sponsorTiers];
          updatedSponsorTiers[index] = {...updatedSponsorTiers[index], ...action.context.sponsorTier};
          toast.success('Awards program sponsor tier updated.');
        } else {
          updatedSponsorTiers = [...state.contest.sponsorTiers, action.context.sponsorTier];
          toast.success('Awards program sponsor tier created.');
        }

        return { ...state, contest: { ...state.contest, sponsorTiers: updatedSponsorTiers } };
      }

      return state;

    case actions.UPDATE_CONTEST_SPONSOR_OPTIONS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.contest) {
        toast.success('Changes saved.');
        return { ...state, contest: { ...state.contest, ...action.context?.contest } };
      }

      return state;

    case actions.UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context.timeframe?.type === supportingProductTypes.sponsor) {
        const index = state.contest.sponsorshipTimeframes.findIndex(timeframe => timeframe.timeframeID === action.context.timeframe.timeframeID);
        let updatedTimeframes;
        if (index !== -1) {
          updatedTimeframes = [...state.contest.sponsorshipTimeframes];
          updatedTimeframes[index] = {...updatedTimeframes[index], ...action.context.timeframe};
          toast.success('Awards program sponsor timeframe updated.');
        } else {
          updatedTimeframes = [...state.contest.sponsorshipTimeframes, action.context.timeframe];
          toast.success('Awards program sponsor timeframe created.');
        }

        return { ...state, contest: { ...state.contest, sponsorshipTimeframes: updatedTimeframes } };
      }

      return state;

    case actions.UPDATE_CONTEST_TIMEFRAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.timeframe) {
        const index = state.contest.timeframes.findIndex(timeframe => timeframe.timeframeID === action.context.timeframe.timeframeID);
        let updatedTimeframes;
        if (index !== -1) {
          updatedTimeframes = [...state.contest.timeframes];
          updatedTimeframes[index] = {...updatedTimeframes[index], ...action.context.timeframe};
          toast.success('Awards program timeframe updated.');
        } else {
          updatedTimeframes = [...state.contest.timeframes, action.context.timeframe];
          toast.success('Awards program timeframe created.');
        }

        return { ...state, contest: { ...state.contest, timeframes: updatedTimeframes } };
      }

      return state;

    case actions.UPLOAD_CONTEST_BANNER_IMAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.bannerImage) {
        toast.success('Banner image uploaded.');
        const defaultBanners = state.contest.bannerImages.filter(bannerImage => bannerImage.default);
        const customBanners = state.contest.bannerImages.filter(bannerImage => !bannerImage.default);
        const updatedCustomBannerImages = sortBy({ key: 'name', list: [...customBanners, action.context.bannerImage] });
        return { ...state, contest: { ...state.contest, bannerImages: [...updatedCustomBannerImages, ...defaultBanners] } };
      }
      return state;  

    case actions.UPLOAD_CONTEST_DOWNLOADABLE_FILE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.file) {
        toast.success('File uploaded.');
        const updatedFiles = sortBy({ key: 'name', list: [...state.contest.files, action.context.file] });
        return { ...state, contest: { ...state.contest, files: updatedFiles } };
      }
      return state;

    default:
      return state;
  }
}

export default reducer;
