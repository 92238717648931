import React, { useRef, useState } from 'react';
import CurrencyField from 'src/components/AutoForm/CurrencyField';
import { TextField } from '@mui/material';

const AmountField = ({ className, label, name, onChange, value }) => {
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState();
  const ref = useRef();

  const handleTouched = () => {
    setTouched(true);
  };

  const handleChange = (value) => {
    setError(isNaN(value) ? `Amount is required` : undefined);
    onChange(value);
  };

  return <div className={className}>
    <label>{label}</label>
    <TextField
      className="currency"
      error={!!(touched && error)}
      helperText={touched && error ? error : null}
      id={`invoice-amount-${name}`}
      InputProps={{
        inputComponent: CurrencyField,
        inputRef: ref
      }}
      name={name}
      onChange={e => {handleChange(e.target.value)}}
      onBlur={() => {}}
      onFocus={handleTouched}
      required={true}
      type="text"
      value={value}
    />
  </div>
}

export default AmountField;
