import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getParadeRequest, uploadParadeHomePageImageRequest } from 'src/redux/actions/parades';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import ResourceList from 'src/components/ResourceList';
import { openDialog } from 'src/redux/actions/dialog';
import { fileRestrictions } from 'src/constants';


const ParadeHomePageImages = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);

  const [progress, setProgress] = useState();
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeHomePageImages', client, parade }));
    dispatch(updateDrawerContent({
      key: 'paradeHomePageImages',
      options: { client, parade } 
    }));
  }, [client, parade]);

  const actions = [{
    fileUpload: {
      accept: fileRestrictions.images.accept,
      label: 'Upload',
      multiple: true,
      onChange: file => {
        dispatch(uploadParadeHomePageImageRequest({ clientURL: client.url, paradeURL: parade.url, file, handleProgress: setProgress }));
      },
      requestPrefix: 'UPLOAD_PARADE_HOME_PAGE_IMAGE'
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: file => {
      dispatch(
        openDialog({
          props: { client, parade, file },
          key: 'removeParadeHomePageImage'
        })
      )
    }
  }];
  
  return (
    <ResourceList
      actions={actions}
      list={parade?.homePageImages}
      renderItem={item => 
        <div className="selectable-image">
          <img src={item.url} alt={item.name}/>
          <div className="selectable-image-details">
            <span className="selectable-image-details-name">{item.name}</span>
            <span className="selectable-image-details-size">{item.size}</span>
          </div>
        </div>
      }
      renderKey="fileID"
      progress={(!!progress && progress < 100) && progress}
      title="Parade Images"
    />
  )
}

export default ParadeHomePageImages;