import React, { useEffect, useState } from 'react';
import { formFieldTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';

const ViewSurveyVote = ({ survey, submission }) => {
  const [configuration, setConfiguration] = useState();
  useEffect(() => {
    setConfiguration(createConfiguration());
  }, []);

  const createQuestionData = ({ type, ...props }) => {
    switch(type) {
      case formFieldTypes.checkbox: return {
        type: formFieldTypes.checkbox,
        value: !!submission.ballot[props.questionID]
      };
      case formFieldTypes.multiSelect: return {
        options: props.options.map(option => { return {
          disabled: true,
          label: option.data,
          name: option.optionID,
          value: !!submission.ballot[option.optionID]
        }}),
        type: formFieldTypes.multiSelect
      };
      case formFieldTypes.radio: return {
        options: props.options.map(option => { return {
          label: option.data,
          name: option.optionID,
          value: option.data
        }}),
        type: formFieldTypes.radio,
        value: submission.ballot[props.questionID]
      };
      case formFieldTypes.textarea: return {
        type: formFieldTypes.textarea,
        value: submission.ballot[props.questionID]
      };
      case formFieldTypes.image: return {
        image: props.image.url,
        type: formFieldTypes.image
      };
      default: return {
        inputType: props.formElements?.inputType,
        type: formFieldTypes.input,
        value: submission.ballot[props.questionID]
      };
    }
  }

  const createConfiguration = () => {
    return {
      formElements: {
        rows: (survey?.questions || []).map(question => {
          if (question.type === formFieldTypes.heading) {
            return { heading: question.formElements?.data };
          }
          if (question.type === formFieldTypes.paragraph) {
            return { forward: question.formElements?.data };
          }
          return {
            elements: [{
              disabled: true,
              label: question.formElements?.data,
              name: question.questionID,
              required: question.formElements?.required,
              ...createQuestionData(question)
            }]
          }
        })
      }
    };
  }

  return (
    <section className="view-survey-submission">
      <AutoForm
        configuration={configuration}
        handleSubmit={() => {}}
        requestPrefix={''}
      />
    </section>
  )
}

export default ViewSurveyVote;
