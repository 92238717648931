import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes, oneToHundred } from 'src/constants';
import { updateEventTicketRequest } from 'src/redux/actions/events';

const TicketForm = ({ ticket, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const dispatch = useDispatch();

  const handleSubmit = (evt) => {
    evt.sequence = evt.sequence || event.tickets?.length || 0;
    dispatch(updateEventTicketRequest({ clientURL: client.url, eventURL: event.url, form: {...(ticket || {}), ...evt} }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Ticket Label',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: ticket?.name || '',
          required: true
        }]
      }, {
        elements: [{
          label: 'Maximum Available For Sale',
          name: 'maxAvailable',
          type: fieldTypes.select,
          options: [{ label: 'Unlimited', value: 0 }, ...oneToHundred],
          value: ticket?.maxAvailable || 0
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="ticket-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_EVENT_TICKET'}
      />
    </section>
  )
}

export default TicketForm;
