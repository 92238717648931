import React from 'react';
import { Box, LinearProgress } from '@mui/material';

const ProgressBar = ({ progress }) => {
  return (
    <Box sx={{
      alignItems: 'center',
      display: 'flex',
      mt: 1,
      pl: 5,
      width: '100%'
    }}>
      <Box sx={{
        mr: 1,
        width: '100%'
      }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        {`${progress}%`}
      </Box>
    </Box>
  )
}

export default ProgressBar;
