import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContestArchivesRequest,
  getContestRequest,
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';
import { toast } from 'react-toastify';

const ContestFileArchives = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const archivesFetching = useSelector(state => state.loadingStore.GET_CONTEST_ARCHIVES)
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(openModal({
        props: { client, contest },
        key: 'createContestArchive'
      }))
    }
  }, {
    label: 'View',
    requiresSelection: true,
    fn: archive => {
      dispatch(openModal({
        props: { client, contest, archive },
        key: 'viewContestArchive'
      }))
    }
  }, {
    color: 'confirm',
    label: 'Copy Link',
    requiresSelection: true,
    variant: 'contained',
    fn: archive => {
      navigator.clipboard.writeText(`https://${client.url}.cpjam.com/${contestURL}/archives/${archive.archiveID}`);
      toast.success('Link copied to clipboard.');
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: archive => {
      dispatch(
        openDialog({
          props: { client, contest, archive },
          key: 'removeContestArchive'
        })
      )
    }
  }];

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestArchives', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestArchives',
      options: { client, contest } 
    }));
  }, [client, contest]);

  useEffect(() => {
    if (contest?.contestID) {
      dispatch(getContestArchivesRequest({ clientURL: client?.url, contestURL }));
    }
  }, [contest?.contestID]);

  return (
    <ResourceList
      actions={actions}
      fetching={contestFetching || archivesFetching}
      list={contest?.archives}
      renderItem={archive => 
        <div className="archive">
          <div className="archive-details">
            <div className="archive-details-name">
              <span className="strong">{archive.name}</span> &mdash; <span>{archive.size}</span>
            </div>
            <div className="archive-details-link">
              {`https://${client.url}.cpjam.com/${contestURL}/archives/${archive.archiveID}`}
            </div>
          </div>
          <div className="archive-exp italic strong">expires: {archive.expDate}</div>
        </div>
      }
      renderKey="archiveID"
      title="File Archive List"
    />
  )
}

export default ContestFileArchives;
