import { productTypes } from "src/constants";

const drawerContentOptions = {
  client: ({ client }) => {
    const links = {
      title: 'Client Portal Manager',
      list: [{
        label: 'Client Settings',
        items: [{
          label: 'General Settings',
          page: 'clientSettings',
          url: `/${client?.url}/client`
        }, {
          label: 'Administrative Rights',
          page: 'clientAdministrative',
          url: `/${client?.url}/administrative`
        }, {
          label: 'Invoicing',
          page: 'clientInvoicing',
          url: `/${client?.url}/invoices`
        }, {
          label: 'Advanced Configuration',
          page: 'clientOptions',
          url: `/${client?.url}/options`
        }]
      }, {
        label: 'Service Management',
        items: [{
          label: 'Awards Programs',
          page: 'clientContests',
          url: `/${client?.url}/contests`
        }, {
          label: 'Event Manager',
          page: 'clientEvents',
          url: `/${client?.url}/events`
        }, {
          label: 'Surveys',
          page: 'clientSurveys',
          url: `/${client?.url}/surveys`
        }]
      }]
    };
    if (!client.enableParades) {
      return links;
    }

    links.list[1].items.splice(2, 0, {
      label: 'Parade Websites',
      page: 'clientParades',
      url: `/${client?.url}/parades`
    });

    return links;
  },
  contest: ({ client, contest }) => {
    const list = [{
      label: 'General Settings',
      items: [{
        label: 'Name & URL',
        page: 'contestName',
        url: `/${client?.url}/contests/${contest?.url}/name`
      }, {
        label: 'Banner Image',
        page: 'contestBannerImage',
        url: `/${client?.url}/contests/${contest?.url}/banner`
      }, {
        label: 'Coordinators',
        page: 'contestCoordinators',
        url: `/${client?.url}/contests/${contest?.url}/coordinators`
      }, {
        label: 'Downloadable Files',
        page: 'contestDownloadableFiles',
        url: `/${client?.url}/contests/${contest?.url}/files`
      }, {
        label: 'Advanced Configuration',
        page: 'contestConfiguration',
        url: `/${client?.url}/contests/${contest?.url}/configuration`
      }, {
        label: 'Search Engine Optimization',
        page: 'contestSEO',
        url: `/${client?.url}/contests/${contest?.url}/seo`
      }, {
        label: 'Custom Page',
        page: 'contestCustomPage',
        url: `/${client?.url}/contests/${contest?.url}/custom-page`
      }, {
        label: 'Connected Event',
        page: 'contestConnectedEvent',
        restrictedTo: productTypes.contest,
        url: `/${client?.url}/contests/${contest?.url}/event`
      }]
    }, {
      label: 'Utilities',
      items: [{
        label: 'Notifications',
        page: 'contestNotifications',
        url: `/${client?.url}/contests/${contest?.url}/notifications`
      }, {
        label: 'Awards Program Previews',
        page: 'contestPreview',
        url: `/${client?.url}/contests/${contest?.url}/preview`
      }]
    }, {
      label: 'Phase One: Rules & Organization',
      items: [{
        label: 'Categories',
        page: 'contestCategories',
        restrictedTo: productTypes.contest,
        url: `/${client?.url}/contests/${contest?.url}/categories`
      }, {
        label: 'Scholarships',
        page: 'contestScholarships',
        restrictedTo: productTypes.scholarship,
        url: `/${client?.url}/contests/${contest?.url}/scholarships`
      }, {
        label: 'Optional Fees',
        page: 'contestOptionalFees',
        restrictedTo: productTypes.contest,
        url: `/${client?.url}/contests/${contest?.url}/fees`
      }, {
        label: 'Dates',
        page: 'contestDates',
        url: `/${client?.url}/contests/${contest?.url}/dates`
      }, {
        label: 'Pricing & Availability',
        page: 'contestTimeframes',
        restrictedTo: productTypes.contest,
        url: `/${client?.url}/contests/${contest?.url}/timeframes`
      }, {
        label: 'Promotional Codes',
        page: 'contestPromoCodes',
        restrictedTo: productTypes.contest,
        url: `/${client?.url}/contests/${contest?.url}/promo-codes`
      }, {
        label: 'Document Folders',
        page: 'contestDocumentFolders',
        url: `/${client?.url}/contests/${contest?.url}/document-folders`
      }, {
        label: 'Entry Forms',
        page: 'contestEntryForms',
        url: `/${client?.url}/contests/${contest?.url}/entry-forms`
      }, {
        label: 'Optional Instructions',
        page: 'contestOptionalInstructions',
        url: `/${client?.url}/contests/${contest?.url}/instructions`
      }, {
        label: 'Rules & Regulations',
        page: 'contestRules',
        url: `/${client?.url}/contests/${contest?.url}/rules`
      }, {
        label: 'Confirmation Email',
        page: 'contestConfirmationEmail',
        restrictedTo: productTypes.contest,
        url: `/${client?.url}/contests/${contest?.url}/confirmation-email`
      }]
    }, {
      label: 'Phase Two: Scoring',
      items: [{
        label: 'Scoresheets',
        page: 'contestScoresheets',
        url: `/${client?.url}/contests/${contest?.url}/scoresheets`
      }, {
        label: 'Assign Judges',
        page: 'contestJudges',
        url: `/${client?.url}/contests/${contest?.url}/judges`
      }, {
        label: 'Scoring Progress',
        page: 'contestScoringProgress',
        url: `/${client?.url}/contests/${contest?.url}/scoring-progress`
      }, {
        label: 'Public Voting',
        page: 'contestPublicVoting',
        restrictedTo: productTypes.contest,
        url: `/${client?.url}/contests/${contest?.url}/public-voting`
      }]
    }, {
      label: 'Phase Three: Evaluating Results',
      items: [{
        label: 'Assignable Awards',
        page: 'contestAwards',
        url: `/${client?.url}/contests/${contest?.url}/awards`
      }, {
        label: 'Confirm Winners',
        page: 'contestConfirmWinners',
        url: `/${client?.url}/contests/${contest?.url}/confirm-winners`
      }, {
        label: 'Download Reports',
        page: 'contestFinalReports',
        url: `/${client?.url}/contests/${contest?.url}/reports`
      }, {
        label: 'Download Entry Files',
        page: 'contestArchives',
        url: `/${client?.url}/contests/${contest?.url}/archives`
      }, {
        label: 'Registration List',
        page: 'contestRegistrationList',
        restrictedTo: productTypes.contest,
        url: `/${client?.url}/contests/${contest?.url}/registration-list`
      }, {
        label: 'Winners Gallery',
        page: 'contestGallery',
        restrictedTo: productTypes.contest,
        url: `/${client?.url}/contests/${contest?.url}/gallery`
      }]
    }, {
      label: 'Become A Partner',
      items: [{
        label: 'Partner Page Content',
        page: 'contestPartnerPage',
        url: `/${client?.url}/contests/${contest?.url}/partner-page`
      }, {
        label: 'Sponsor Tiers & Options',
        page: 'contestSponsorOptions',
        url: `/${client?.url}/contests/${contest?.url}/sponsor-options`
      }, {
        label: 'Sponsorship Pricing & Availability',
        page: 'contestSponsorTimeframes',
        url: `/${client?.url}/contests/${contest?.url}/sponsor-timeframes`
      }]
    }];

    for (let i = 0; i < list.length; i++) {
      list[i].items = list[i].items.filter(item => !item.restrictedTo || item.restrictedTo === contest?.type);
    }

    if (!contest) {
      return null;
    }

    return {
      title: contest?.type === productTypes.contest ? 'Awards Program Manager' : 'Scholarship/Grant Manager',
      list
    };
  },
  event: ({ client, event }) => {
    return {
      title: 'Event Manager',
      list: [{
        label: 'General Settings',
        items: [{
          label: 'Name & URL',
          page: 'eventName',
          url: `/${client?.url}/events/${event?.url}/name`
        }, {
          label: 'Location & Date/Time',
          page: 'eventLocation',
          url: `/${client?.url}/events/${event?.url}/location`
        }, {
          label: 'Banner Image',
          page: 'eventBannerImage',
          url: `/${client?.url}/events/${event?.url}/banner`
        }, {
          label: 'Slideshow Images',
          page: 'eventSlideshowImages',
          url: `/${client?.url}/events/${event?.url}/slideshow`
        }, {
          label: 'Coordinators',
          page: 'eventCoordinators',
          url: `/${client?.url}/events/${event?.url}/coordinators`
        }, {
          label: 'Downloadable Files',
          page: 'eventDownloadableFiles',
          url: `/${client?.url}/events/${event?.url}/files`
        }, {
          label: 'Advanced Configuration',
          page: 'eventConfiguration',
          url: `/${client?.url}/events/${event?.url}/configuration`
        }, {
          label: 'Search Engine Optimization',
          page: 'eventSEO',
          url: `/${client?.url}/events/${event?.url}/seo`
        }, {
          label: 'Connected Awards Program',
          page: 'eventConnectedContest',
          url: `/${client?.url}/events/${event?.url}/awards-program`
        }]
      }, {
        label: 'Utilities',
        items: [{
          label: 'Notifications',
          page: 'eventNotifications',
          url: `/${client?.url}/events/${event?.url}/notifications`
        }, {
          label: 'Event Previews',
          page: 'eventPreview',
          url: `/${client?.url}/events/${event?.url}/preview`
        }]
      }, {
        label: 'Payments & Discounts',
        items: [{
          label: 'Tickets',
          page: 'eventTickets',
          url: `/${client?.url}/events/${event?.url}/tickets`
        }, {
          label: 'Optional Fees',
          page: 'eventOptionalFees',
          url: `/${client?.url}/events/${event?.url}/fees`
        }, {
          label: 'Pricing & Availability',
          page: 'eventTimeframes',
          url: `/${client?.url}/events/${event?.url}/timeframes`
        }, {
          label: 'Promotional Codes',
          page: 'eventPromoCodes',
          url: `/${client?.url}/events/${event?.url}/promo-codes`
        }]
      }, {
        label: 'Verbiage & Questions',
        items: [{
          label: 'Optional Registration Questions',
          page: 'eventRegistrationQuestions',
          url: `/${client?.url}/events/${event?.url}/registration-questions`
        }, {
          label: 'Details',
          page: 'eventDetails',
          url: `/${client?.url}/events/${event?.url}/details`
        }, {
          label: 'Confirmation Email',
          page: 'eventConfirmationEmail',
          url: `/${client?.url}/events/${event?.url}/confirmation-email`
        }]
      }, {
        label: 'Registration',
        items: [{
          label: 'Registration List',
          page: 'eventRegistrationList',
          url: `/${client?.url}/events/${event?.url}/registration-list`
        }, {
          label: 'Attendance',
          page: 'eventAttendance',
          url: `/${client?.url}/events/${event?.url}/attendance`
        }]
      }, {
        label: 'Become A Partner',
        items: [{
          label: 'Partner Page Content',
          page: 'eventPartnerPage',
          url: `/${client?.url}/events/${event?.url}/partner-page`
        }, {
          label: 'Sponsor Tiers & Options',
          page: 'eventSponsorOptions',
          url: `/${client?.url}/events/${event?.url}/sponsor-options`
        }, {
          label: 'Sponsorship Pricing & Availability',
          page: 'eventSponsorTimeframes',
          url: `/${client?.url}/events/${event?.url}/sponsor-timeframes`
        }]
      }]
    };
  },
  network: () => {
    return {
      title: 'Network Administration',
      list: [{
        label: 'Client Services',
        items: [{
          label: 'Manage Clients',
          page: 'networkClientList',
          url: '/network/clients',
        }, {
          label: 'Current Resources',
          page: 'networkCurrentResources',
          url: '/network/current-resources',
        }, {
          label: 'Invoices',
          page: 'networkInvoices',
          url: '/network/invoices',
        }, {
          label: 'Email Administrators',
          page: 'networkEmailAdministrators',
          url: '/network/email-administrators',
        }, {
          label: 'System Notifications',
          page: 'networkSystemNotifications',
          url: '/network/system-notifications',
        }, {
          label: 'Error Logs',
          page: 'networkErrorLogs',
          url: '/network/error-logs',
        }]
      }, {
        label: 'User Services',
        items: [{
          label: 'View User',
          page: 'networkViewUser',
          url: '/network/view-user',
        }, {
          label: 'User Migration',
          page: 'networkMigrateUser',
          url: '/network/migrate-user',
        }, {
          label: 'Email Change',
          page: 'networkChangeEmail',
          url: '/network/change-email',
        }, {
          label: 'Password Change',
          page: 'networkChangePassword',
          url: '/network/change-password',
        }, {
          label: 'Password Change Requests',
          page: 'networkPasswordRequests',
          url: '/network/password-requests',
        }]
      }]
    }
  },
  parade: ({ client, parade }) => {
    return {
      title: 'Parade Website Manager',
      list: [{
        label: 'General Settings',
        items: [{
          label: 'Name & URL',
          page: 'paradeName',
          url: `/${client?.url}/parades/${parade?.url}/name`
        }, {
          label: 'Dates / Times',
          page: 'paradeDates',
          url: `/${client?.url}/parades/${parade?.url}/dates`
        }, {
          label: 'Downloadable Files',
          page: 'paradeDownloadableFiles',
          url: `/${client?.url}/parades/${parade?.url}/files`
        }, {
          label: 'Ticket Takers',
          page: 'paradeTicketTakers',
          url: `/${client?.url}/parades/${parade?.url}/ticket-takers`
        }, {
          label: 'Search Engine Optimization',
          page: 'paradeSEO',
          url: `/${client?.url}/parades/${parade?.url}/seo`
        }, {
          label: 'Public Voting Options',
          page: 'paradePublicVoting',
          url: `/${client?.url}/parades/${parade?.url}/public-voting`
        }]
      }, {
        label: 'Payments & Discounts',
        items: [{
          label: 'Tickets',
          page: 'paradeTickets',
          url: `/${client?.url}/parades/${parade?.url}/tickets`
        }, {
          label: 'Optional Fees',
          page: 'paradeOptionalFees',
          url: `/${client?.url}/parades/${parade?.url}/fees`
        }, {
          label: 'Pricing & Availability',
          page: 'paradeTimeframes',
          url: `/${client?.url}/parades/${parade?.url}/timeframes`
        }, {
          label: 'Promotional Codes',
          page: 'paradePromoCodes',
          url: `/${client?.url}/parades/${parade?.url}/promo-codes`
        }]
      }, {
        label: 'Home Page Content',
        items: [{
          label: 'Home Page Images',
          page: 'paradeHomePageImages',
          url: `/${client?.url}/parades/${parade?.url}/home-page-images`
        }, {
          label: 'Home Page Generator',
          page: 'paradeHomePage',
          url: `/${client?.url}/parades/${parade?.url}/home-page`
        }]
      }, {
        label: 'Registration',
        items: [{
          label: 'Confirmation Email',
          page: 'paradeConfirmationEmail',
          url: `/${client?.url}/parades/${parade?.url}/confirmation-email`
        }, {
          label: 'Registration List',
          page: 'paradeRegistrationList',
          url: `/${client?.url}/parades/${parade?.url}/registration-list`
        }, {
          label: 'Attendance & Analytics',
          page: 'paradeAttendance',
          url: `/${client?.url}/parades/${parade?.url}/attendance`
        }]
      }, {
        label: 'Become A Partner',
        items: [{
          label: 'Partner Page Content',
          page: 'paradePartnerPage',
          url: `/${client?.url}/parades/${parade?.url}/partner-page`
        }, {
          label: 'Sponsor Tiers & Options',
          page: 'paradeSponsorOptions',
          url: `/${client?.url}/parades/${parade?.url}/sponsor-options`
        }, {
          label: 'Sponsorship Pricing & Availability',
          page: 'paradeSponsorTimeframes',
          url: `/${client?.url}/parades/${parade?.url}/sponsor-timeframes`
        }, {
          label: 'Optional Property Groups',
          page: 'paradePropertyGroups',
          url: `/${client?.url}/parades/${parade?.url}/property-groups`
        }, {
          label: 'Property Pricing & Availability',
          page: 'paradePropertyTimeframes',
          url: `/${client?.url}/parades/${parade?.url}/property-timeframes`
        }]
      }]
    }
  },
  survey: ({ client, survey }) => {
    return {
      title: 'Survey Manager',
      list: [{
        label: 'General Settings',
        items: [{
          label: 'Name & URL',
          page: 'surveyName',
          url: `/${client?.url}/surveys/${survey?.url}/name`
        }, {
          label: 'Submission Timeframe',
          page: 'surveyTimeframe',
          url: `/${client?.url}/surveys/${survey?.url}/timeframe`
        }, {
          label: 'Survey Questions',
          page: 'surveyQuestions',
          url: `/${client?.url}/surveys/${survey?.url}/questions`
        }]
      }, {
        label: 'Evaluating Results',
        items: [{
          label: 'Survey Submissions',
          page: 'surveyVoterList',
          url: `/${client?.url}/surveys/${survey?.url}/submissions`
        }, {
          label: 'Survey Summary',
          page: 'surveyResults',
          url: `/${client?.url}/surveys/${survey?.url}/summary`
        }]
      }]
    }
  }
};

const resources = ['contests', 'events', 'surveys', 'parades'];

const getLeftDrawerContent = ({ key, options }) => {
  if (resources.includes(key)) {
    return drawerContentOptions.client(options);
  }

  const lower = key.toLowerCase();

  for (const option in drawerContentOptions) {
    if (lower.startsWith(option)) {
      return drawerContentOptions[option](options);
    }
  }

  return {};
}

export default getLeftDrawerContent;
