import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContestRequest } from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';
import { currency } from 'src/utilities/strings';

const ContestPromoCodes = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, contest },
          key: 'createContestPromoCode'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: promoCode => {
      dispatch(
        openModal({
          props: { client, contest, promoCode },
          key: 'editContestPromoCode'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: promoCode => {
      dispatch(
        openDialog({
          props: { client, contest, promoCode },
          key: 'removeContestPromoCode'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestPromoCodes', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestPromoCodes',
      options: { client, contest } 
    }));
  }, [client, contest]);
  
  return (
    <ResourceList
      actions={actions}
      fetching={contestFetching}
      list={contest?.promoCodes}
      renderHeaders={() => 
        <div className="promo-code">
          <div className="promo-code-key">Promotional Code Key</div>
          <div className="promo-code-amount">Amount</div>
        </div>
      }
      renderItem={promoCode => 
        <div className="promo-code">
          <div className="promo-code-key">{promoCode.promoKey}</div>
          <div className="promo-code-amount">{currency(promoCode.amount)}</div>
        </div>
      }
      renderKey="promoCodeID"
      title="Promo Code List"
    />
  )
}

export default ContestPromoCodes;