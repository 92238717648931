import React from 'react';
import { useDispatch } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateClientRequest } from 'src/redux/actions/client';

const ClientForm = ({ client, handleClose }) => {
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    dispatch(updateClientRequest({ form: {...(client || {}), ...event} }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Client Name',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: client?.name || '',
          required: true
        }, {
          label: 'Client URL',
          name: 'url',
          type: fieldTypes.input,
          inputType: inputTypes.url,
          value: client?.url || '',
          required: true
        }]
      }, {
        elements: [{
          label: 'Searchable',
          name: 'searchable',
          type: fieldTypes.checkbox,
          value: client?.searchable || false,
        }, {
          label: 'Permits Memberships',
          name: 'hasMemberships',
          type: fieldTypes.checkbox,
          value: client?.hasMemberships || false,
        }]
      }, {
        elements: [{
          label: 'Hide Organization Name',
          name: 'hideOrganization',
          type: fieldTypes.checkbox,
          value: client?.hideOrganization || false,
        }, {
          label: 'Enable Parade Websites',
          name: 'enableParades',
          type: fieldTypes.checkbox,
          value: client?.enableParades || false,
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="award-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_CLIENT'}
      />
    </section>
  )
}

export default ClientForm;
