import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AdminRoute = ({ children }) => {
  const user = useSelector(state => state.userStore?.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user === false) {
      navigate('/login');
    }

    if (!!user && !user.admin) {
      navigate('/');
    }
  }, [user])

  return children;
}

export default AdminRoute;
