import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadSurveyImageRequest } from 'src/redux/actions/surveys';
import ResourceList from 'src/components/ResourceList';
import { openDialog } from 'src/redux/actions/dialog';
import { fileRestrictions } from 'src/constants';

const SurveyImages = ({ handleClose, handleSelect, question, questionID }) => {
  const client = useSelector(state => state.clientStore?.client);
  const survey = useSelector(state => state.surveyStore?.survey);
  const [progress, setProgress] = useState();
  const dispatch = useDispatch();

  const actions = [{
    fileUpload: {
      accept: fileRestrictions.images.accept,
      label: 'Upload',
      multiple: true,
      onChange: file => {
        dispatch(uploadSurveyImageRequest({ clientURL: client.url, surveyURL: survey.url, file, handleProgress: setProgress }));
      },
      requestPrefix: 'UPLOAD_SURVEY_IMAGE'
    }
  }, {
    color: 'confirm',
    label: 'Select',
    requiresSelection: true,
    variant: 'contained',
    fn: file => {
      handleSelect({ file, question, questionID });
      handleClose();
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: file => {
      dispatch(
        openDialog({
          props: { client, survey, file },
          key: 'removeSurveyImage'
        })
      )
    }
  }];
  
  return (
    <ResourceList
      actions={actions}
      list={survey?.images}
      renderItem={item => 
        <div className="survey-image">
          <img src={item.url} alt={item.name}/>
          <div className="survey-image-details">
            <span className="survey-image-details-name">{item.name}</span>
            <span className="survey-image-details-size">{item.size}</span>
          </div>
        </div>
      }
      renderKey="fileID"
      progress={(!!progress && progress < 100) && progress}
      title="Survey Images"
    />
  )
}

export default SurveyImages;