export const formFieldTypes = {
  checkbox: 'checkbox',
  paragraph: 'paragraph',
  select: 'select',
  input: 'input',
  textarea: 'textarea',
  heading: 'heading'
}

export const entryFormInputTypes = {
  date: 'date',
  email: 'email',
  phone: 'phone',
  text: 'text',
  website: 'website'
}

export const formFieldOptions = [{
  label: 'Short Answer',
  value: formFieldTypes.input
}, {
  label: 'Textarea',
  value: formFieldTypes.textarea
}, {
  label: 'Checkbox',
  value: formFieldTypes.checkbox
}, {
  label: 'Drop Down',
  value: formFieldTypes.select
}, {
  label: 'Title',
  value: formFieldTypes.heading
}, {
  label: 'Paragraph',
  value: formFieldTypes.paragraph
}];

export const entryFormInputOptions = [{
  label: 'Plain Text',
  value: entryFormInputTypes.text
}, {
  label: 'Email Address',
  value: entryFormInputTypes.email
}, {
  label: 'Phone Number',
  value: entryFormInputTypes.phone
}, {
  label: 'Date',
  value: entryFormInputTypes.date
}, {
  label: 'Website',
  value: entryFormInputTypes.website
}];

export const applicationFormInputTypes = {
  date: 'date',
  email: 'email',
  phone: 'phone',
  text: 'text',
  website: 'website'
}

export const applicationFormInputOptions = [{
  label: 'Plain Text',
  value: applicationFormInputTypes.text
}, {
  label: 'Email Address',
  value: applicationFormInputTypes.email
}, {
  label: 'Phone Number',
  value: applicationFormInputTypes.phone
}, {
  label: 'Date',
  value: applicationFormInputTypes.date
}, {
  label: 'Website',
  value: applicationFormInputTypes.website
}];
