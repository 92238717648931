import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getParadeRequest,
  updateParadeHomePageContentRequest
} from 'src/redux/actions/parades';
import DragDrop from 'src/components/DragDrop';
import {
  Button,
  Tooltip
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGear,
  faPencil,
  faTrashCanXmark
} from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import { createResourceID } from 'src/utilities/strings';
import {
  animationDirections,
  homePageAlignments,
  homePageBackgroundTypes,
  homePageContentTypes,
  homePageOverlayTypes
} from 'src/constants';
import { cloneDeep } from 'lodash';
import sortBy from 'src/utilities/sort';
import useWindowDimensions from 'src/components/WindowDimensions';
import parse from 'html-react-parser';
import LoadingCircles from 'src/components/Elements/LoadingCircles';

const ParadeHomePageGenerator = () => {
  const [formState, setFormState] = useState({});
  const [rows, setRows] = useState([]);

  const { width } = useWindowDimensions();

  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const isUpdating = useSelector(state => state.loadingStore.UPDATE_PARADE_HOME_PAGE_CONTENT);
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeHomePage', client, parade }));
    dispatch(updateDrawerContent({
      key: 'paradeHomePage',
      options: { client, parade } 
    }));
  }, [client, parade]);

  useEffect(() => {
    const rows = (parade?.homePageContent?.rows?.length && parade?.homePageContent?.rows) || [createHeroRow()];
    const newState = createInitialState({ rows });
    setFormState(newState);
    setRows(rows);
  }, [parade]);

  const createInitialState = ({ rows }) => {
    return rows.reduce((acc, row, index) => {
      const { rowID, ...props } = row;
      return {...acc, [row.rowID]: {
        ...props,
        columns: row.columns.reduce((acc, column, index) => {
          const { columnID, ...props } = column;
          return { ...acc, [column.columnID]: {
            ...props,
            sequence: index
          } };
        }, {}),
        sequence: index
      }}
    }, {});
  }

  const createHeroColumn = () => {
    return {
      background: {
        backgroundImage: null,
        backgroundType: homePageBackgroundTypes.image
      },
      boxModel: {
        padding: 0
      },
      columnID: createResourceID(),
      content: {
        boxContent: '',
        subTitle: '',
        title: ''
      }
    };
  };

  const createDefaultColumn = () => {
    return {
      alignment: homePageAlignments.top,
      animate: animationDirections.none,
      background: {
        backgroundImage: null,
        backgroundType: homePageBackgroundTypes.color,
        color: ''
      },
      boxModel: {
        padding: 0
      },
      columnID: createResourceID(),
      content: {
        contentImage: null,
        contentType: homePageContentTypes.text,
        text: ''
      },
      title: ''
    };
  };

  const createDefaultRow = () => {
    return {
      background: {
        backgroundImage: null,
        backgroundType: homePageBackgroundTypes.color,
        color: ''
      },
      boxModel: {
        gap: 0,
        margin: 0,
        padding: 0
      },
      columns: [createDefaultColumn()],
      rowID: createResourceID()
    };
  };

  const createHeroRow = () => {
    return {
      background: {
        backgroundImage: null,
        backgroundType: homePageBackgroundTypes.color,
        color: ''
      },
      boxModel: {
        gap: 0,
        margin: 0,
        padding: 0
      },
      columns: [createHeroColumn()],
      hero: true,
      rowID: createResourceID()
    };
  };

  const createRow = () => {
    const newRow = createDefaultRow();
    setRows([...rows, newRow]);

    const { rowID, ...props } = newRow;
    const { columnID, ...columnProps } = props.columns[0];

    const newState = {...formState, [rowID]: {
      ...props,
      columns: {
        [columnID]: {
          ...columnProps,
          sequence: 0
        }
      },
      sequence: rows?.length || 0
    }}
    
    setFormState(newState);
  };

  const onRowSettingsUpdate = ({ event, row }) => {
    const { backgroundColor, backgroundImage, backgroundType, gap, margin, overlay, padding } = event;
    const payload = {
      background: {
        backgroundColor,
        backgroundImage: getImage(backgroundImage),
        backgroundType,
        overlay
      },
      boxModel: {
        gap,
        margin,
        padding
      }
    };

    const rowIndex = rows.findIndex(item => row.rowID === item.rowID);
    const newRows = cloneDeep(rows);
    newRows[rowIndex].background = { ...newRows[rowIndex].background, ...payload.background };
    newRows[rowIndex].boxModel = { ...newRows[rowIndex].boxModel, ...payload.boxModel };
    setRows(newRows);

    formState[row.rowID].background = newRows[rowIndex].background;
    formState[row.rowID].boxModel = newRows[rowIndex].boxModel;
    setFormState(cloneDeep(formState));
  }

  const changeRowSettings = ({ row }) => {
    dispatch(openModal({
      key: 'paradeHomepageRowSettings',
      props: {
        images: (parade?.homePageImages || []).map(image => {
          return {
            label: image.name,
            value: image.fileID
          };
        }),
        onSubmit: onRowSettingsUpdate,
        row
      }
    }));
  };

  const createColumn = ({ row }) => {
    const newColumn = createDefaultColumn();
    const index = rows.indexOf(row);
    const newRows = cloneDeep(rows);

    newRows[index].columns.push(newColumn);
    setRows(newRows);

    const { columnID, ...props } = newColumn;
    const newState = cloneDeep(formState);

    newState[row.rowID].columns[columnID] = {
      ...props,
      sequence: row.columns.length
    };

    setFormState(newState);
  };

  const handleSubmit = () => {
    const content = prepareFormValues({ formState });

    dispatch(updateParadeHomePageContentRequest({
      clientURL: client.url,
      paradeURL,
      form: {
        homePageContent: content
      }
    }));
  }

  const onDrag = ({ list }) => {
    setRows([rows[0], ...list]);
    const newState = cloneDeep(formState);
    
    ([rows[0], ...list]).forEach((row, index) => {
      newState[row.rowID].sequence = index;
    });

    setFormState(newState);
  }

  const onColumnDrag = ({ item, columns }) => {
    const index = rows.findIndex(row => row.rowID === item.rowID);
    const newRows = cloneDeep(rows);
    newRows[index].columns = [...columns];

    setRows(newRows);

    const newState = cloneDeep(formState);
    (columns || []).forEach((column, index) => {
      newState[item.rowID].columns[column.columnID].sequence = index;
    });

    setFormState(newState);
  }

  const onColumnSettingsUpdate = ({ column, event, row }) => {
    let payload;

    if (row.hero) {
      const {
        backgroundImage,
        boxContent,
        subTitle,
        title
      } = event;

      payload = {
        background: {
          backgroundImage: getImage(backgroundImage),
          backgroundType: homePageBackgroundTypes.image
        },
        content: {
          boxContent,
          subTitle,
          title
        }
      };
    } else {
      const {
        alignment,
        animate,
        backgroundColor,
        backgroundImage,
        backgroundType,
        contentImage,
        contentType,
        padding
      } = event;

      payload = {
        alignment,
        animate,
        background: {
          backgroundColor,
          backgroundImage: getImage(backgroundImage),
          backgroundType
        },
        boxModel: {
          padding
        },
        content: {
          contentImage: getImage(contentImage),
          contentType
        }
      };
    }
    
    const rowIndex = rows.findIndex(item => row.rowID === item.rowID);
    const newRows = cloneDeep(rows);
    const columnIndex = rows[rowIndex].columns.findIndex(item => column.columnID === item.columnID);
    newRows[rowIndex].columns[columnIndex].alignment = payload.alignment;
    newRows[rowIndex].columns[columnIndex].animate = payload.animate;
    newRows[rowIndex].columns[columnIndex].dragAlignment = getAlignmentClass(payload);
    newRows[rowIndex].columns[columnIndex].background = { ...newRows[rowIndex].columns[columnIndex].background, ...payload.background };
    newRows[rowIndex].columns[columnIndex].content = { ...newRows[rowIndex].columns[columnIndex].content, ...payload.content };
    if (!row.hero) {
      newRows[rowIndex].columns[columnIndex].boxModel = { ...newRows[rowIndex].columns[columnIndex].boxModel, ...payload.boxModel };
    }
    setRows(newRows);

    formState[row.rowID].columns[column.columnID] = newRows[rowIndex].columns[columnIndex];
    setFormState(cloneDeep(formState));
  }

  const onColumnTextUpdate = ({ column, event, row }) => {
    const rowIndex = rows.findIndex(item => row.rowID === item.rowID);
    const newRows = cloneDeep(rows);
    const columnIndex = rows[rowIndex].columns.findIndex(item => column.columnID === item.columnID);
    newRows[rowIndex].columns[columnIndex].content.text = event;
    setRows(newRows);

    formState[row.rowID].columns[column.columnID].content.text = event;
    setFormState(cloneDeep(formState));
  }

  const changeColumnSettings = ({ column, row }) => {
    dispatch(openModal({
      key: 'paradeHomepageColumnSettings',
      props: {
        column,
        images: (parade?.homePageImages || []).map(image => {
          return {
            label: image.name,
            value: image.fileID
          };
        }),
        onSubmit: onColumnSettingsUpdate,
        row
      }
    }));
  }

  const getImage = (image) => {
    return (parade?.homePageImages || []).map(image => {
      const { fileID, url } = image;

      return {
        fileID, url
      };
    }).find(img => img.fileID === image);
  };

  const getBackgroundClass = ({ background }) => {
    switch (background.backgroundType) {
      case homePageBackgroundTypes.image: return ' background-image';
      case homePageBackgroundTypes.parallax: return ' background-parallax';
      default: return '';
    }
  };

  const getAlignmentClass = ({ alignment }) => {
    switch(alignment) {
      case homePageAlignments.bottom: return 'bottom';
      case homePageAlignments.center: return 'center';
      case homePageAlignments.top: return 'top';
      default: return '';
    }
  }

  const getBackgroundStyle = ({ background, boxModel }) => {
    const backgroundImage = (background.backgroundType === homePageBackgroundTypes.image || background.backgroundType === homePageBackgroundTypes.parallax) ?
      `url(${background.backgroundImage?.url })` : null;

    return {
      backgroundColor: background.backgroundColor || 'transparent',
      backgroundImage,
      marginBottom: boxModel?.margin,
      marginTop: boxModel?.margin,
      padding: boxModel?.padding
    };
  }

  const prepareFormValues = ({ formState }) => {
    const rows = Array.from(Object.keys(formState)).map(rowKey => {
      const { columns, ...props } = formState[rowKey];

      return {
        columns: sortBy({ key: 'sequence', list: Array.from(Object.keys((columns)).map(columnKey => {
          return { ...formState[rowKey].columns[columnKey], columnID: columnKey };
        })) }),
        rowID: rowKey,
        ...props
      }
    });
    return {
      rows: sortBy({ key: 'sequence', list: rows })
    };
  }

  const editColumnText = ({ column, row }) => {
    dispatch(openModal({
      key: 'paradeHomepageColumnText',
      props: {
        column,
        onSubmit: onColumnTextUpdate,
        row
      }
    }));
  }

  const removeColumn = ({ column, row }) => {
    dispatch(
      openDialog({
        key: 'removeParadeHomePageColumn',
        props: {
          fn: () => {
            const newRows = cloneDeep(rows);
            const rowIndex = newRows.findIndex(item => item.rowID === row.rowID);
            const columnIndex = newRows[rowIndex].columns.findIndex(col => col.columnID === column.columnID);
            newRows[rowIndex].columns.splice(columnIndex, 1);
            setRows(newRows);

            const newState = cloneDeep(formState);

            delete newState[row.rowID].columns[column.columnID];

            setFormState(newState);
          } 
        }
      })
    );
  }

  const removeRow = ({ row }) => {
    dispatch(
      openDialog({
        key: 'removeParadeHomePageRow',
        props: {
          fn: () => {
            const newRows = cloneDeep(rows);
            const index = rows.findIndex(item => row.rowID === item.rowID);
            newRows.splice(index, 1)
            setRows(newRows);

            const newState = cloneDeep(formState);
            delete newState[row.rowID];

            setFormState(newState);
          }
        }
      })
    );
  };

  const renderCountdown = () => {
    return <div className="parade-homepage-countdown">
      <div className="parade-homepage-countdown-title">
        <p>Countdown</p>
      </div>
      <div className="parade-homepage-countdown-items">
        <span className="parade-homepage-countdown-item">
          <span className="parade-homepage-countdown-item-value">00</span>
          <span className="parade-homepage-countdown-item-label">Days</span>
        </span>
        <span className="parade-homepage-countdown-item">
          <span className="parade-homepage-countdown-item-value">:</span>
          <span className="parade-homepage-countdown-item-label">&nbsp;</span>
        </span>
        <span className="parade-homepage-countdown-item">
          <span className="parade-homepage-countdown-item-value">00</span>
          <span className="parade-homepage-countdown-item-label">Hours</span>
        </span>
        <span className="parade-homepage-countdown-item">
          <span className="parade-homepage-countdown-item-value">:</span>
          <span className="parade-homepage-countdown-item-label">&nbsp;</span>
        </span>
        <span className="parade-homepage-countdown-item">
          <span className="parade-homepage-countdown-item-value">00</span>
          <span className="parade-homepage-countdown-item-label">Minutes</span>
        </span>
        <span className="parade-homepage-countdown-item">
          <span className="parade-homepage-countdown-item-value">:</span>
          <span className="parade-homepage-countdown-item-label">&nbsp;</span>
        </span>
        <span className="parade-homepage-countdown-item">
          <span className="parade-homepage-countdown-item-value">00</span>
          <span className="parade-homepage-countdown-item-label">Seconds</span>
        </span>
      </div>
    </div>
  }

  const renderPropertyList = () => {
    return <section className="parade-homepage-properties">
      <div className="parade-homepage-properties-list">
        <div className="parade-homepage-property">
          <div className="parade-homepage-property-image-container">
            <img
              className="parade-homepage-property-image"
              src="https://d1nt1hxve4jn1b.cloudfront.net/default-images/parade-banners/banner2.jpg"
            />
          </div>
          <div className="parade-homepage-property-flex">
            <div className="parade-homepage-property-details">
              <div className="parade-homepage-property-details-builder">Builder Name</div>
              <div className="parade-homepage-property-details-address">123 Main</div>
            </div>
          </div>
        </div>
        <div className="parade-homepage-property">
          <div className="parade-homepage-property-image-container">
            <img
              className="parade-homepage-property-image"
              src="https://d1nt1hxve4jn1b.cloudfront.net/default-images/parade-banners/banner2.jpg"
            />
          </div>
          <div className="parade-homepage-property-flex">
            <div className="parade-homepage-property-details">
              <div className="parade-homepage-property-details-builder">Builder Name</div>
              <div className="parade-homepage-property-details-address">123 Main</div>
            </div>
          </div>
        </div>
        <div className="parade-homepage-property">
          <div className="parade-homepage-property-image-container">
            <img
              className="parade-homepage-property-image"
              src="https://d1nt1hxve4jn1b.cloudfront.net/default-images/parade-banners/banner2.jpg"
            />
          </div>
          <div className="parade-homepage-property-flex">
            <div className="parade-homepage-property-details">
              <div className="parade-homepage-property-details-builder">Builder Name</div>
              <div className="parade-homepage-property-details-address">123 Main</div>
            </div>
          </div>
        </div>
      </div>
      <Button
        color="confirm"
        variant="contained"
      >VIEW ALL PROPERTIES</Button>
    </section>;
  }

  const renderSponsors = () => {
    return <div className="parade-homepage-sponsor-tiers">
      <div className="parade-homepage-sponsor-tier">
        <div className="parade-homepage-sponsor-tier-name">Large Sponsors</div>
        <div className="parade-homepage-sponsor-list no-border">
          <div className="parade-homepage-sponsor-list-item large">
            <div className="parade-homepage-sponsor-list-item-logo">
              <img style={{width: '200px'}} src="https://d1nt1hxve4jn1b.cloudfront.net/default-images/parade-banners/fake-logo1.svg" />
            </div>
            <div className="parade-homepage-sponsor-list-item-details">
              <div className="parade-homepage-sponsor-list-item-details-name">Sponsor Name</div>
              <div className="parade-homepage-sponsor-list-item-details-about">About this sponsor.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="parade-homepage-sponsor-tier">
        <div className="parade-homepage-sponsor-tier-name">Medium Sponsors</div>
        <div className="parade-homepage-sponsor-list">
          <div className="parade-homepage-sponsor-list-item medium">
            <div className="parade-homepage-sponsor-list-item-logo">
              <img src="https://d1nt1hxve4jn1b.cloudfront.net/default-images/parade-banners/fake-logo2.svg" />
            </div>
          </div>
          <div className="parade-homepage-sponsor-list-item medium">
            <div className="parade-homepage-sponsor-list-item-logo">
              <img src="https://d1nt1hxve4jn1b.cloudfront.net/default-images/parade-banners/fake-logo2.svg" />
            </div>
          </div>
          <div className="parade-homepage-sponsor-list-item medium">
            <div className="parade-homepage-sponsor-list-item-logo">
              <img src="https://d1nt1hxve4jn1b.cloudfront.net/default-images/parade-banners/fake-logo2.svg" />
            </div>
          </div>
        </div>
      </div>
      <div className="parade-homepage-sponsor-tier">
        <div className="parade-homepage-sponsor-tier-name">Small Sponsors</div>
        <div className="parade-homepage-sponsor-list">
          <div className="parade-homepage-sponsor-list-item small">
            <div className="parade-homepage-sponsor-list-item-logo">
              <img src="https://d1nt1hxve4jn1b.cloudfront.net/default-images/parade-banners/fake-logo2.svg" />
            </div>
          </div>
          <div className="parade-homepage-sponsor-list-item small">
            <div className="parade-homepage-sponsor-list-item-logo">
              <img src="https://d1nt1hxve4jn1b.cloudfront.net/default-images/parade-banners/fake-logo2.svg" />
            </div>
          </div>
          <div className="parade-homepage-sponsor-list-item small">
            <div className="parade-homepage-sponsor-list-item-logo">
              <img src="https://d1nt1hxve4jn1b.cloudfront.net/default-images/parade-banners/fake-logo2.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  const renderColumn = ({ item: column, row }) => {
    return (
      <div
        className={`parade-homepage-column${getBackgroundClass(column)}`}
        style={getBackgroundStyle(column)}
      >
        <div className="parade-homepage-column-config">
          {
            column?.content?.contentType === homePageContentTypes.text && <Tooltip title="Edit Text Content">
              <Button
                onClick={() => editColumnText({ column, row })}
                variant="contained"
              ><FontAwesomeIcon icon={faPencil} /></Button>
            </Tooltip>
          }
          <Tooltip title="Column Settings">
            <Button
              onClick={() => changeColumnSettings({ column, row })}
              variant="contained"
            ><FontAwesomeIcon icon={faGear} /></Button>
          </Tooltip>
          {
            row.columns.length > 1 && 
            <Tooltip title="Remove Column">
              <Button
                color="error"
                onClick={() => removeColumn({ column, row })}
                variant="contained"
              ><FontAwesomeIcon icon={faTrashCanXmark} /></Button>
            </Tooltip>
          }
        </div>
        <div className={`parade-homepage-column-content${column?.content?.contentType === homePageContentTypes.text ? ' tiny-mce-render' : ''}`}>
        {
          row?.hero ? 
            <div
              className="parade-homepage-column-content-hero"
            >
              <div className="parade-homepage-overlay-dark"></div>
              <div className="parade-homepage-column-content-hero-content">
                <div className="parade-homepage-column-content-hero-title">{column?.content?.title}</div>
                {
                  column?.content?.subTitle && 
                  <div className="parade-homepage-column-content-hero-subtitle">{column?.content?.subTitle}</div>
                }
                {
                  column?.content?.boxContent && 
                  <div className="parade-homepage-column-content-hero-box-content">{column?.content?.boxContent}</div>
                }
              </div>
            </div> : <>
            { column?.content?.contentType === homePageContentTypes.text && parse(column?.content.text || '') }
            { column?.content?.contentType === homePageContentTypes.image && <img
                src={column?.content.contentImage?.url}
            /> }
            { column?.content?.contentType === homePageContentTypes.countdown && renderCountdown() }
            { column?.content?.contentType === homePageContentTypes.propertyList && renderPropertyList() }
            { column?.content?.contentType === homePageContentTypes.sponsors && renderSponsors() }
          </>
        }
        </div>
      </div>
    )
  }

  const renderRow = ({ item }) => {
    return (
      <div
        className={`parade-homepage-row${item.hero ? ' hero' : ''}${getBackgroundClass(item)}`}
        key={item.rowID}
        style={getBackgroundStyle(item)}
      >
        <div className="parade-homepage-row-config">
          {
            (!item?.hero && item.columns?.length < 3) &&
              <Tooltip title="Create Content Column">
                <Button
                  onClick={() => createColumn({ row: item })}
                  size="small"
                  variant="contained"
                ><FontAwesomeIcon icon={faPlus} /></Button>
              </Tooltip>
          }
          {
            !item.hero && <>
              <Tooltip title="Row Settings">
                <Button
                  onClick={() => changeRowSettings({ row: item })}
                  variant="contained"
                ><FontAwesomeIcon icon={faGear} /></Button>
              </Tooltip>
              <Tooltip title="Delete Row">
                <Button
                  color="error"
                  onClick={() => removeRow({ row: item })}
                  variant="contained"
                ><FontAwesomeIcon icon={faTrashCanXmark} /></Button>
              </Tooltip>
            </>
          }
        </div>
        {
          item.background?.overlay === homePageOverlayTypes.dark && <div className="parade-homepage-overlay-dark"></div>
        }
        {
          item.background?.overlay === homePageOverlayTypes.light && <div className="parade-homepage-overlay-light"></div>
        }
        <div className="parade-homepage-row-columns">
          {
            item.hero ? renderColumn({ item: item.columns[0], row: item }) :
            <DragDrop
              droppableId={`${item.rowID}-columns`}
              gap={item?.boxModel?.gap}
              handleDrag={event => {onColumnDrag({ item, columns: event.list })}}
              horizontal={width > 767}
              list={item?.columns || []}
              renderKey="columnID"
              renderListArgs={{ row: item }}
              renderListItem={renderColumn}
              setList={() => {}}
            />
          }
        </div>
      </div>
    )
  }

  return <section className="parade-homepage-generator">
    <div className="parade-homepage-generator-content">
      {
        !!(rows || []).length > 0 && renderRow({ item: rows[0] })
      }
      {
        (rows || []).length > 1 && <DragDrop
          droppableId="parade-homepage-rows"
          handleDrag={onDrag}
          list={rows.slice(1, rows.length) || []}
          renderKey="rowID"
          renderListItem={renderRow}
          setList={() => {}}
        />
      }
    </div>
    <div className="parade-homepage-generator-buttons">
      <Button
        color="confirm"
        disabled={isUpdating}
        onClick={() => handleSubmit()}
        variant="contained"
      >{ isUpdating ? <LoadingCircles /> : 'Save' }</Button>
      <Button
        disabled={isUpdating}
        onClick={() => createRow()}
        variant="contained"
      >Create Content Row</Button>
    </div>
  </section>
}

export default ParadeHomePageGenerator;
