import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  entryFormInputOptions,
  entryFormInputTypes,
  formFieldTypes,
  formFieldOptions
} from './types';
import StructureForm from 'src/components/TemplateForms/Common/StructureForm';
import DragDrop from 'src/components/DragDrop';
import FormStructureInputField from 'src/components/TemplateForms/Common/StructureForm/FormStructureInputField';
import FormStructureSelectField from 'src/components/TemplateForms/Common/StructureForm/FormStructureSelectField';
import FormStructureSliderField from 'src/components/TemplateForms/Common/StructureForm/FormStructureSliderField';
import { Button } from '@mui/material';
import { createResourceID } from 'src/utilities/strings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCanXmark } from '@fortawesome/pro-light-svg-icons';
import { openDialog } from 'src/redux/actions/dialog';
import { cloneDeep } from 'lodash';
import sortBy from 'src/utilities/sort';

const FormGenerator = ({
  form,
  handleClose,
  isRequesting,
  onSubmit
}) => {
  const dispatch = useDispatch();
  const [formValid, setFormValid] = useState(true);
  const [formState, setFormState] = useState({});
  const [list, setList] = useState(form);

  useEffect(() => {
    const list = (form?.length && form) || [createDefaultQuestion()];
    const newState = createInitialState({ list });
    setFormState(newState);
    setList(list);
    errorCheck(newState);
  }, []);

  const createInitialState = ({ list }) => {
    return list.reduce((acc, question, index) => {
      return {...acc, [question.questionID]: {
        currentDropdownOptions: question.type === formFieldTypes.select ? 
          (question.currentDropdownOptions || []).reduce((acc, option, index) => {
            return { ...acc, [option.dropdownOptionID]: { value: option.data, sequence: index } }
          }, {}) : undefined
        ,
        inputType: question.formElements.inputType,
        required: question.formElements.required,
        restriction: question.formElements.restriction,
        restrictionCount: question.formElements.restrictionCount,
        sequence: index,
        value: (question.formElements.data === null || question.formElements.data === undefined) ? '' : question.formElements.data,
        type: question.type
      }}
    }, {});
  }

  const createOption = ({ item }) => {
    const dropdownOptionID = createResourceID();

    const index = list.findIndex(question => question.questionID === item.questionID);
    const updatedItems = cloneDeep(list);
    updatedItems[index].currentDropdownOptions = [...(item.currentDropdownOptions || []), {
      data: '',
      dropdownOptionID
    }];

    setList(updatedItems);

    const newState = cloneDeep(formState);
    newState[item.questionID].currentDropdownOptions = { ...newState[item.questionID].currentDropdownOptions, [dropdownOptionID]: { value: '' } };
    setFormState(newState);
    errorCheck(newState);
  }

  const createButton = () => {
    return (
      <Button
        onClick={createQuestion}
        variant="contained"
      >Create Form Element</Button>
    )
  }

  const createDefaultQuestion = () => {
    return {
      formElements: {
        data: '',
        inputType: entryFormInputTypes.text,
        required: false,
      },
      questionID: createResourceID(),
      type: formFieldTypes.input,
      value: ''
    };
  }

  const createQuestion = () => {
    const newQuestion = createDefaultQuestion();
    setList([...list, newQuestion]);

    const newState = {...formState, [newQuestion.questionID]: {
      inputType: entryFormInputTypes.text,
      required: false,
      sequence: list?.length || 0,
      type: formFieldTypes.input,
      value: ''
    }}
    
    setFormState(newState);
    errorCheck(newState);
  }

  const errorCheck = (newState) => {
    let inValid = false;
    for (const question in newState) {
      if (!newState[question].value || (newState[question].restriction && !newState[question].restrictionCount)) {
        inValid = true;
        break;
      }

      inValid = Object.values(newState[question].currentDropdownOptions || {}).some(option => {
        return !option.value;
      });

      if (inValid) {
        break;
      }
    }
    setFormValid(!inValid);
  }

  const handleBlur = () => {
    errorCheck(formState);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ formState: prepareFormValues({ formState }) });
  }

  const onElementChange = ({ item, prop, value }) => {
    if (prop === 'type') {
      let newState,
        newList,
        index;
      if (value === formFieldTypes.select) {
        const dropdownOptionID = createResourceID();
        newState = cloneDeep(formState);
        newState[item.questionID][prop] = value;
        newState[item.questionID].currentDropdownOptions = { [dropdownOptionID]: { value: '' } };

        newList = cloneDeep(list);
        index = newList.findIndex(question => question.questionID === item.questionID);
        newList[index].currentDropdownOptions = [{
          dropdownOptionID,
          data: ''
        }];
      } else {
        newState = cloneDeep(formState);
        newState[item.questionID][prop] = value;

        if (value === formFieldTypes.input) {
          newState[item.questionID].inputType = entryFormInputTypes.text;
        }

        delete newState[item.questionID].currentDropdownOptions;

        newList = cloneDeep(list);
        index = newList.findIndex(question => question.questionID === item.questionID);
        delete newList[index].currentDropdownOptions;
      }

      setFormState(newState);
      setList(newList);
      errorCheck(newState);

      return;
    }

    const newState = cloneDeep(formState);
    newState[item.questionID][prop] = value;

    setFormState(newState);
    errorCheck(newState);
  }

  const onOptionChange = ({ option, question, value }) => {
    const newState = cloneDeep(formState);
    newState[question.questionID].currentDropdownOptions[option.dropdownOptionID].value = value;

    setFormState(newState);
    errorCheck(newState);
  }

  const onDrag = ({ questionList }) => {
    setList(questionList);
    const newState = cloneDeep(formState);
    
    (questionList || []).forEach((item, index) => {
      newState[item.questionID].sequence = index;
    });

    setFormState(newState);
  }

  const onOptionDrag = ({ item, optionList }) => {
    const index = list.findIndex(question => question.questionID === item.questionID);
    const newList = cloneDeep(list);
    newList[index].currentDropdownOptions = [...optionList];

    setList(newList);

    const newState = cloneDeep(formState);
    (optionList || []).forEach((option, index) => {
      newState[item.questionID].currentDropdownOptions[option.dropdownOptionID].sequence = index;
    });

    setFormState(newState);
  }

  const prepareFormValues = ({ formState }) => {
    const questions = Array.from(Object.keys(formState)).map(key => {
      const currentDropdownOptions = Array.from(Object.keys((formState[key].currentDropdownOptions || {}))).map(option => {
        return {
          data: formState[key].currentDropdownOptions[option].value,
          dropdownOptionID: option,
          sequence: formState[key].currentDropdownOptions[option].sequence
        }
      });
      return {
        currentDropdownOptions: sortBy({ key: 'sequence', list: currentDropdownOptions }),
        formElements: {
          data: formState[key].value,
          inputType: formState[key].inputType,
          required: formState[key].required,
          restriction: formState[key].restriction,
          restrictionCount: formState[key].restrictionCount,
        },
        questionID: key,
        sequence: formState[key].sequence,
        type: formState[key].type
      }
    });
    return sortBy({ key: 'sequence', list: questions });
  }

  const removeOption = ({ option, question }) => {
    dispatch(
      openDialog({
        key: 'removeFormOption',
        props: {
          fn: () => {
            const newList = cloneDeep(list);
            const questionIndex = newList.findIndex(item => item.questionID === question.questionID);
            const optionIndex = newList[questionIndex].currentDropdownOptions.findIndex(opt => opt.dropdownOptionID === option.dropdownOptionID);
            newList[questionIndex].currentDropdownOptions.splice(optionIndex, 1);
            setList(newList);

            const newState = cloneDeep(formState);

            delete newState[question.questionID].currentDropdownOptions[option.dropdownOptionID];

            setFormState(newState);
            errorCheck(newState);
          } 
        }
      })
    );
  }

  const removeQuestion = ({ item }) => {
    dispatch(
      openDialog({
        key: 'removeFormQuestion',
        props: {
          fn: () => {
            const newList = cloneDeep(list);
            const index = list.findIndex(question => question.questionID === item.questionID);
            newList.splice(index, 1)
            setList(newList);

            const newState = cloneDeep(formState);
            delete newState[item.questionID];

            setFormState(newState);
            errorCheck(newState);
          }
        }
      })
    );
  }

  const renderOptionItem = ({ item: option, question }) => {
    return (
      <div className="form-question-options-option">
        <FormStructureInputField
          error={!formState[question.questionID]?.currentDropdownOptions[option.dropdownOptionID].value && 'Option value required'}
          handleBlur={handleBlur}
          handleChange={value => onOptionChange({ option, question, value })}
          placeholder="Option"
          value={formState[question.questionID]?.currentDropdownOptions[option.dropdownOptionID].value || ''}
        />
        <Button
          className="trash"
          onClick={() => removeOption({ option, question })}
        ><FontAwesomeIcon icon={faTrashCanXmark} /></Button>
      </div>
    )
  }

  const renderListItem = ({ item }) => {
    return (
      <div
        className="form-question"
        key={item.questionID}
      >
        <FormStructureInputField
          displayParagraph={formState[item.questionID]?.type === formFieldTypes.paragraph}
          error={!formState[item.questionID]?.value && 'Question value required'}
          handleBlur={handleBlur}
          handleChange={value => onElementChange({ item, prop: 'value', value })}
          placeholder={
            formState[item.questionID]?.type === formFieldTypes.heading ? 'Title' :
            formState[item.questionID]?.type === formFieldTypes.paragraph ? 'Paragraph' : 'Type Your Question'
          }
          value={formState[item.questionID]?.value || ''}
        />
        <div className="form-question-config">
          <div className="form-question-config-content">
            <FormStructureSelectField
              handleBlur={handleBlur}
              handleChange={value => onElementChange({ item, prop: 'type', value })}
              options={formFieldOptions}
              value={formState[item.questionID]?.type}
            />
            {
              formState[item.questionID]?.type === formFieldTypes.input && (
                <FormStructureSelectField
                  handleBlur={handleBlur}
                  handleChange={value => onElementChange({ item, prop: 'inputType', value })}
                  options={entryFormInputOptions}
                  value={formState[item.questionID]?.inputType}
                />
              )
            }
            {
              formState[item.questionID]?.type === formFieldTypes.textarea && (
                <>
                  <FormStructureSliderField
                    checked={formState[item.questionID].restriction}
                    label="Restrict Word Length"
                    onChange={e => onElementChange({ item, prop: 'restriction', value: e.target.checked })}
                  />
                  {
                    formState[item.questionID].restriction && (
                      <FormStructureInputField
                        error={!formState[item.questionID]?.restrictionCount && 'Max required'}
                        handleBlur={handleBlur}
                        handleChange={value => onElementChange({ item, prop: 'restrictionCount', value })}
                        placeholder="Max Words"
                        sx={{ width: 110 }}
                        type="number"
                        value={formState[item.questionID]?.restrictionCount || ''}
                      />
                    )
                  }
                </>
              )
            }
            {
              formState[item.questionID]?.type === formFieldTypes.select && (
                <Button
                  onClick={() => createOption({ item })}
                  variant="contained"
                >Create Option</Button>
              )
            }
          </div>
          <div className="form-question-config-content">
            {
              (
                formState[item.questionID]?.type === formFieldTypes.input ||
                formState[item.questionID]?.type === formFieldTypes.textarea ||
                formState[item.questionID]?.type === formFieldTypes.checkbox
              ) && (
                <FormStructureSliderField
                  checked={formState[item.questionID].required}
                  label="Required"
                  onChange={e => onElementChange({ item, prop: 'required', value: e.target.checked })}
                />
              )
            }
            <Button
              className="trash"
              onClick={() => removeQuestion({ item })}
            ><FontAwesomeIcon icon={faTrashCanXmark} /></Button>
          </div>
        </div>
        {
          formState[item.questionID]?.type === formFieldTypes.select && (
            <div className="form-question-options">
              <DragDrop
                droppableId={`${item.questionID}-options`}
                handleDrag={event => {onOptionDrag({ item, optionList: event.list })}}
                list={item?.currentDropdownOptions || []}
                renderKey="dropdownOptionID"
                renderListArgs={{ question: item }}
                renderListItem={renderOptionItem}
                secondary
                setList={() => {}}
              />
            </div>
          )
        }
      </div>
    )
  }

  return (
    <StructureForm
      createButton={createButton}
      disabled={!formValid}
      handleClose={handleClose}
      isRequesting={isRequesting}
      list={list}
      onDrag={event => {onDrag({ questionList: event.list })}}
      onSubmit={handleSubmit}
      renderKey="questionID"
      renderListItem={renderListItem}
      setList={() => {}}
    />
  )
}

export default FormGenerator;
