import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import UserProfile from 'src/components/UserProfile';
import {
  categoryRestrictions,
  fieldTypes,
  productTypes,
  restrictionTypes,
  scholarshipRestrictions
} from 'src/constants';
import {
  convertContestJudgeProspectRequest,
  updateContestJudgeRequest,
  updateContestJudgeInvitationRequest,
} from 'src/redux/actions/contests';
import {
  assembleExistingList,
  existingCategoriesMap
} from 'src/utilities/existingCategory';
import { existingScholarshipsMap } from 'src/utilities/existingScholarship';

const JudgeForm = ({ judge, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const [configuration, setConfiguration] = useState();
  const [type, setType] = useState(judge?.type || restrictionTypes.all);

  useEffect(() => {
    setConfiguration(contest?.type === productTypes.contest ? createContestConfiguration() : createScholarshipConfiguration());
  }, []);

  useEffect(() => {
    setConfiguration(contest?.type === productTypes.contest ? createContestConfiguration() : createScholarshipConfiguration());
  }, [type]);

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.assignedCategories = assembleExistingList({ form: event });

    if (judge?.prospectID) {
      dispatch(convertContestJudgeProspectRequest({ clientURL: client.url, contestURL: contest.url, form: {...(judge || {}), ...event} }));
      return;
    }

    if (judge?.sendInvite || judge?.invitationKey) {
      dispatch(updateContestJudgeInvitationRequest({ clientURL: client.url, contestURL: contest.url, form: {...(judge || {}), ...event} }));
      return;
    }
    
    dispatch(updateContestJudgeRequest({ clientURL: client.url, contestURL: contest.url, form: {...(judge || {}), ...event} }));
  }

  const onChange = ({ element, value }) => {
    if (element?.name === 'type') {
      setType(value);
    }
  }

  const createContestConfiguration = () => {
    const config = {
      formElements: {
        rows: [{
          elements: [{
            label: 'Judge Assigned To',
            name: 'type',
            type: fieldTypes.select,
            options: categoryRestrictions,
            value: type
          }]
        }]
      },
      submitCTA: 'Save',
      cancelCTA: 'Cancel'
    };

    if (type === restrictionTypes.specific) {
      return { ...config, formElements: { ...config.formElements, rows: [...config.formElements.rows, ...existingCategoriesMap({
        categories: contest?.categories,
        entries: contest?.entries,
        currentlyAllowed: judge?.assignedCategories
      })] }};
    }

    return config;
  }

  const createScholarshipConfiguration = () => {
    const config = {
      formElements: {
        rows: [{
          elements: [{
            label: 'Judge Assigned To',
            name: 'type',
            type: fieldTypes.select,
            options: scholarshipRestrictions,
            value: type
          }]
        }]
      },
      submitCTA: 'Save',
      cancelCTA: 'Cancel'
    };

    if (type === restrictionTypes.specific) {
      return { ...config, formElements: { ...config.formElements, rows: [...config.formElements.rows, ...existingScholarshipsMap({
        scholarships: contest?.scholarships,
        currentlyAllowed: judge?.assignedCategories
      })] }};
    }

    return config;
  }

  return (
    <section className="judge-form">
      <div className="judge-form-assigned">
        {
          (judge?.sendInvite || judge?.invitationKey) && (
            <div className="judge-form-assigned-send-invite">
              {judge?.sendInvite || judge?.email}
            </div>
          )
        }
        {
          (!judge?.sendInvite && !judge?.invitationKey) && (
            <UserProfile
              showEmail
              user={judge?.user}
            ></UserProfile>
          )
        }
      </div>
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        onChange={onChange}
        requestPrefix={['UPDATE_CONTEST_JUDGE', 'UPDATE_CONTEST_JUDGE_INVITATION', 'CONVERT_CONTEST_JUDGE_PROSPECT']}
      />
    </section>
  )
}

export default JudgeForm;
