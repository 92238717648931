import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getClientRequest } from 'src/redux/actions/client';

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.userStore?.user);
  const client = useSelector(state => state.clientStore?.client);
  const isRequesting = useSelector(state => state.loadingStore.GET_CLIENT);
  const { clientURL } = useParams();

  useEffect(() => {
    if (user === false) {
      navigate('/login');
    }
  }, [user])

  useEffect(() => {
    if (!!clientURL && !isRequesting && ((!client && client !== false) || clientURL !== client?.url)) {
      dispatch(getClientRequest({ clientURL }));
    }
  }, [client, clientURL]);

  if (!clientURL) {
    return children;
  }
  
  return client && children;
}

export default PrivateRoute;
