import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateParadeTicketRequest } from 'src/redux/actions/parades';

const TicketForm = ({ ticket, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const dispatch = useDispatch();

  const handleSubmit = (evt) => {
    evt.sequence = evt.sequence || parade.tickets?.length || 0;
    dispatch(updateParadeTicketRequest({ clientURL: client.url, paradeURL: parade.url, form: {...(ticket || {}), ...evt} }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Ticket Label',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: ticket?.name || '',
          required: true
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="ticket-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_PARADE_TICKET'}
      />
    </section>
  )
}

export default TicketForm;
