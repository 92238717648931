import React from 'react';
import { Link } from 'react-router-dom';
import LoadingBars from 'src/components/Elements/LoadingBars';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const AppCard = ({
  children,
  className,
  editURL,
  footerLink,
  loading,
  title,
  titleVariant
}) => {
  return (
    <section className={`floating app-card ${className || ''}`}>
      <h3 className={`title ${titleVariant || ''}`}>
        <span>{title} {loading ? <LoadingCircle variant="dark" /> : ''}</span>
        {editURL && (
          <Link to={editURL}><span className="edit">Edit</span></Link>
        )}
      </h3>
      <div className="content">
        {loading ? <LoadingBars /> : children}
      </div>
      {
        (footerLink && !loading) && (
          <div className="footer-link">
            <Link to={footerLink.url}>{footerLink.label}</Link>
          </div>
        )
      }
    </section>
  )
};

export default AppCard;
