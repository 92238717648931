import React, { useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import createLoadingSelector from 'src/redux/loading';
import LoadingCircles from 'src/components/Elements/LoadingCircles';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';

const ImageCropper = ({ handleSubmit, requestPrefix }) => {
  const loadingSelector = createLoadingSelector((typeof requestPrefix === 'string' ? [requestPrefix] : [...requestPrefix]));
  const user = useSelector(state => state.userStore?.user);
  const isRequesting = useSelector(state => loadingSelector(state));

  const [crop, setCrop] = useState();
  const [src, setSrc] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const imgRef = useRef();

  const selectImage = (file) => {
    setSelectedFile(file);
    setSrc(URL.createObjectURL(file));
  };

  const onSubmit = (file) => {
    handleSubmit({ file });
  }

  const onImageLoad = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
  
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 75,
        },
        1,
        width,
        height
      ),
      width,
      height
    );
  
    setCrop(crop);
  }

  const clearImage = () => {
    setCrop(null);
    setSrc(null);
    setCompletedCrop(null);
    setSelectedFile(null);
  }

  const onSubmitCrop = () => {
    if (completedCrop) {
      const canvas = document.createElement("canvas");
      const image = imgRef.current;

      if (image) {
        const crop = completedCrop;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        if (ctx) {
          ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
          ctx.imageSmoothingQuality = "high";

          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
          );
        }

        const base64Image = canvas.toDataURL("image/jpeg");
        const formData = new FormData();
        const filename = selectedFile.name.replace(' ', '-').replace(new RegExp(/[^A-Za-z0-9.-]/g), '');
        formData.append('file', base64Image);
        formData.append('name', filename);
        onSubmit(formData);
      }
    }
  }

  return (
    <section className="image-cropper">
      { !src && <>
        <img className="my-account-headshot-editor-image" src={user.headshot} />
        <input
          accept="image/*"
          id="image-cropper-input"
          onChange={e => { selectImage(e.target.files[0]) }}
          style={{ display: 'none' }}
          type="file"
        />
        <label htmlFor="image-cropper-input">
          <Button
            className="image-cropper-upload"
            component="span"
            variant="contained"
          >Upload File</Button>
        </label>
      </> }
      { src && <>
        <ReactCrop
          aspect={1}
          circularCrop
          crop={crop}
          onChange={e => setCrop(e)}
          onComplete={e => setCompletedCrop(e)}
          minHeight={100}
          minWidth={100}
        >
          <img ref={imgRef} src={src} onLoad={onImageLoad} />
        </ReactCrop>
        <p>Drag/resize to fit in the circle</p>
        <div className="image-cropper-buttons">
          <Button
            disabled={isRequesting}
            onClick={onSubmitCrop}
            variant="contained"
          >{ isRequesting ? <LoadingCircles /> : 'Save' }</Button>
          <Button
            disabled={isRequesting}
            onClick={clearImage}
            variant="outlined"
          >Cancel</Button>
        </div>
      </> }
    </section>
  )
}

export default ImageCropper;
