import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import dialogActions from 'src/components/DialogContainer/DialogActions';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import LoadingCircles from '../Elements/LoadingCircles';
import { activeCloseDialog, closeDialog } from 'src/redux/actions/dialog';
import { delay } from 'lodash';
import { useLocation } from 'react-router-dom';

const DialogContainer = () => {
  const dialogRequest = useSelector(state => state.dialogStore?.dialog);
  const completedActions = useSelector(state => state.completedStore);
  const loadingActions = useSelector(state => state.loadingStore);
  const [dialogAction, setDialogAction] = useState();
  const [dialogTitle, setDialogTitle] = useState();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (dialogRequest && !dialogRequest?.activeClose) {
      const dialogAction = dialogActions[dialogRequest.key || ''] || {};
      setDialogAction(dialogAction);
      setDialogTitle(
        typeof dialogAction.title === 'function' ? 
          dialogAction.title(dialogRequest.props || {}) :
          dialogAction.title
      );
      setOpen(true);
    }
  }, [dialogRequest]);

  useEffect(() => {
    if ((completedActions || {})[dialogAction?.actionKey]) {
      dispatch({ type: `${dialogAction.actionKey}_CLEAR` });
      activeClearDialog();
    }
  }, [(completedActions || {})[dialogAction?.actionKey]]);

  useEffect(() => {
    activeClearDialog();
  }, [location]);

  const activeClearDialog = () => {
    setOpen(false);
    delay(() => {
      dispatch(activeCloseDialog());
    }, 50);
  }

  const clearDialog = () => {
    setOpen(false);
    delay(() => {
      dispatch(closeDialog());
    }, 50);
  }

  const handleConfirm = () => {
    if (typeof dialogAction?.confirm?.fn === 'function') {
      dialogAction?.confirm?.fn(dialogRequest.props);
      activeClearDialog();

      return;
    }
    if (typeof dialogAction?.confirm?.action === 'function') {
      dispatch(dialogAction?.confirm?.action(dialogRequest.props));
    }
  }

  const handleClose = (event, reason) => {
    if (!dialogAction?.title) {
      clearDialog();
      return;
    }
    if (reason !== 'backdropClick') {
      clearDialog();
    }
  }

  return (
    <Dialog
      disableEscapeKeyDown
      onClose={handleClose}
      open={open}
    >
      {
        dialogTitle && (
          <DialogTitle sx={{ lineHeight: 1 }}>
            <span>{dialogTitle}</span>
            <FontAwesomeIcon
              className="icon close"
              icon={faXmark}
              onClick={handleClose}
            />
          </DialogTitle>
        )
      }
      <DialogContent>
        <DialogContentText>{dialogAction?.text}</DialogContentText>
        <div className="buttons">
          <Button
            color={dialogAction?.confirm?.color || 'primary'}
            disabled={(loadingActions || {})[dialogAction?.actionKey]}
            onClick={handleConfirm}
            variant="contained"
          >{
            (loadingActions || {})[dialogAction?.actionKey] && (
              <LoadingCircles />
            )
          }
          {
            !(loadingActions || {})[dialogAction?.actionKey] && (
              dialogAction?.confirm?.label
            )
          }
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
          >{dialogAction?.cancel?.label}</Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DialogContainer;
