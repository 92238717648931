import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { phone } from 'src/utilities/strings';

const UserProfile = ({
  imageSize,
  linkPhone,
  linkEmail,
  showEmail,
  showPhone,
  user
}) => {
  return (
    <div className="user-profile">
      {
        user?.sendInvite && (
          <>
            <div className="envelope">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="user-profile-details">
              <span className="user-profile-details-name">Send Invitation</span>
            </div>
          </>
        )
      }
      {
        user?.typedValue && (
          <>
            <div className="envelope">
              <FontAwesomeIcon icon={faPlus} />
            </div>
            <div className="user-profile-details">
              <span className="user-profile-details-name">Select Typed Value</span>
            </div>
          </>
        )
      }
      {
        (!user?.sendInvite && !user?.typedValue) && (
          <>
            <img
              alt={`${user?.fullName} user profile`}
              className={`${imageSize || ''}`}
              src={user?.headshot}
            />
            <div className="user-profile-details">
              <span className="user-profile-details-name">{user?.fullName}</span>
              {
                (user?.email && showEmail && !linkEmail) && (
                  <span className="user-profile-details-email">
                    {user?.email || ''}
                  </span>
                )
              }
              {
                (user?.email && linkEmail) && (
                  <Link
                    className="user-profile-details-email"
                    to={`mailto:${user?.email || ''}`}
                  >
                    {user?.email || ''}
                  </Link>
                )
              }
              {
                (user?.phone && showPhone && !linkPhone) && (
                  <span className="user-profile-details-phone">
                    {phone(user?.phone || '')}
                  </span>
                )
              }
              {
                (user?.phone && linkPhone) && (
                  <Link
                    className="user-profile-details-phone"
                    to={`tel:${user?.phone || ''}`}
                  >
                    {phone(user?.phone || '')}
                  </Link>
                )
              }
            </div>
          </>
        )
      }
        
    </div>
  )
};

export default UserProfile;
