import { put, takeEvery } from 'redux-saga/effects';
import * as actions from 'src/redux/actions/drawer';

function* updateDrawerContentRequest(request) {
  const drawerContent = request.body;
  yield put({ type: actions.UPDATE_DRAWER_CONTENT_SUCCESS, drawerContent });
}

function* drawerContentSaga() {
  yield takeEvery(actions.UPDATE_DRAWER_CONTENT, updateDrawerContentRequest);
}

export default drawerContentSaga;
