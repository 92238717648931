import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateContestOptionalFeeRequest } from 'src/redux/actions/contests';

const ContestOptionalFeeForm = ({ fee, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.sequence = event.sequence || contest.fees?.length || 0;
    dispatch(updateContestOptionalFeeRequest({ clientURL: client.url, contestURL: contest.url, form: {...(fee || {}), ...event} }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Fee Name',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: fee?.name || '',
          required: true
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="optional-fee-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_CONTEST_OPTIONAL_FEE'}
      />
    </section>
  )
}

export default ContestOptionalFeeForm;
