import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes, supportingProductTypes, yesOrNo } from 'src/constants';
import {
  getFirstMinuteToday,
  getLastMinuteToday,
  parseDateTime
} from 'src/utilities/dates';
import defaultSelectOption from 'src/utilities/defaultSelectOption';

const SponsorTimeframeForm = ({ handleClose, handleSubmit, requestPrefix, items, timeframe }) => {
  const [configuration, setConfiguration] = useState();
  const [temporaryPrices, setTemporaryPrices] = useState({});
  const [startDate, setStartDate] = useState(timeframe?.startDate || getFirstMinuteToday());
  const [endDate, setEndDate] = useState(timeframe?.endDate || getLastMinuteToday());
  const [sameForAllItems, setSameForAllItems] = useState(defaultSelectOption({ defaultValue: true, value: timeframe?.sameForAllItems }));
  const dispatch = useDispatch();

  useEffect(() => {
    setConfiguration(createConfiguration());
  }, [sameForAllItems]);

  const nullCheck = (value) => {
    return value === 'null' ? null : value;
  }

  const assemblePriceStructure = (form) => {
    const prices = [];

    for (let property in form) {
      const keys = property.split('_');
      if (keys?.length === 4 && keys[3] === 'price') {
        keys[0] = nullCheck(keys[0]);

        const priceKey = `${keys[0]}_null_null_price`

        prices.push({
          element: keys[0],
          elementType: supportingProductTypes.sponsor,
          price: form[priceKey]
        });

        delete form[priceKey];
      }
    }

    return prices;
  }

  const handleSubmitFn = (event) => {
    const startDate = parseDateTime({ date: event.startDate });
    const endDate = parseDateTime({ date: event.endDate });
    const prices = assemblePriceStructure(event);
    dispatch(handleSubmit({ ...event, startDate, endDate, prices, type: supportingProductTypes.sponsor }));
  }

  const storeTemporaryPrice = ({ key, value }) => {
    setTemporaryPrices({
      ...temporaryPrices,
      [key]: value
    });
  }

  const onChange = ({ element, value }) => {
    switch(element?.name) {
      case 'startDate': setStartDate(value); break;
      case 'endDate': setEndDate(value); break;
      case 'sameForAllItems': setSameForAllItems(value); break;
      default: storeTemporaryPrice({ key: element.name, value });
    }
  }

  const createElementLabel = ({ item }) => {
    return !item ? `for all sponsorships` : `for ${item?.name}`
  }

  const hasTemporaryPrice = ({ key }) => {
    return (temporaryPrices || {}).hasOwnProperty(key) ? temporaryPrices[key] : false;
  }

  const createPrice = ({ item, timeframe }) => {
    const elementName = item?.sponsorTierID || 'null',
      elementLabel = createElementLabel({ item }),
      element = (timeframe?.prices || []).find(price => {
        return price?.element === item || price?.element === item?.sponsorTierID;
      }),
      price = hasTemporaryPrice({ key: `${elementName}_null_price` }) || element?.price || 0;

    return {
      elements: [{
        label: `Price ${elementLabel}`,
        name: `${elementName}_null_null_price`,
        type: fieldTypes.input,
        inputType: inputTypes.currency,
        required: true,
        value: price
      }]
    };
  }

  const createPrices = ({ items, timeframe }) => {
    if (sameForAllItems || !items?.length) {
      return [createPrice({ item: null, timeframe })];
    }
    return items.map(item => createPrice({ item, timeframe }))
  }

  const getSponsorTierOption = () => {
    return {
      elements: [{
        label: 'Same for each sponsorship tier?',
        name: 'sameForAllItems',
        type: fieldTypes.select,
        value: sameForAllItems,
        options: yesOrNo
      }]
    }
  }

  const createConfiguration = () => {
    const config = {
      formElements: {
        rows: [{
          heading: 'These prices are active from:'
        }, {
          elements: [{
            label: 'Start Date',
            name: 'startDate',
            dateRangeEnd: 'endDate',
            type: fieldTypes.input,
            inputType: inputTypes.dateTime,
            value: startDate,
            required: true
          }, {
            label: 'End Date',
            name: 'endDate',
            dateRangeStart: 'startDate',
            type: fieldTypes.input,
            inputType: inputTypes.dateTime,
            value: endDate,
            required: true
          }]
        }, {
          heading: 'Pricing settings:'
        }]
      },
      submitCTA: 'Save',
      cancelCTA: 'Cancel'
    };

    if ((items || []).length > 0) {
      config.formElements.rows.push(getSponsorTierOption());
    } 
    config.formElements.rows.push(...createPrices({ items, timeframe }));

    return config;
  };

  return (
    <section className="timeframe-form">
      {
        configuration && (
          <AutoForm
            configuration={configuration}
            handleCancel={handleClose}
            handleSubmit={handleSubmitFn}
            onChange={onChange}
            requestPrefix={requestPrefix}
          />
        )
      }
    </section>
  )
}

export default SponsorTimeframeForm;
