import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import {
  categoryRestrictions,
  fieldTypes,
  fileTypesAllowed,
  inputTypes,
  maxUploadsAllowed,
  minUploadsAllowed,
  oneToTen,
  productTypes,
  restrictionTypes,
  scholarshipRestrictions,
  yesOrNo
} from 'src/constants';
import { updateContestDocumentFolderRequest } from 'src/redux/actions/contests';
import defaultSelectOption from 'src/utilities/defaultSelectOption';
import {
  assembleExistingList,
  existingCategoriesMap
} from 'src/utilities/existingCategory';
import { existingScholarshipsMap } from 'src/utilities/existingScholarship';

const ContestDocumentFolderForm = ({ documentFolder, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const [configuration, setConfiguration] = useState();
  const [name, setName] = useState(documentFolder?.name || '');
  const [judgeVisible, setJudgeVisible] = useState(defaultSelectOption({ defaultValue: true, value: documentFolder?.judgeVisible }));
  const [inGallery, setInGallery] = useState(defaultSelectOption({ defaultValue: true, value: documentFolder?.inGallery }));
  const [hideDisqualifyWarning, setHideDisqualifyWarning] = useState(defaultSelectOption({ defaultValue: false, value: documentFolder?.hideDisqualifyWarning }));
  const [minUploads, setMinUploads] = useState(documentFolder?.minUploads || '0');
  const [maxUploads, setMaxUploads] = useState(documentFolder?.maxUploads || '15');
  const [maxFileSize, setMaxFileSize] = useState(documentFolder?.maxFileSize || '10');
  const [fileRestriction, setFileTypeRestriction] = useState(documentFolder?.fileTypeRestriction || 'image-pdf');
  const [description, setDescription] = useState(documentFolder?.description || '');
  const [restriction, setRestriction] = useState(documentFolder?.restriction || restrictionTypes.all);

  useEffect(() => {
    setConfiguration(contest?.type === productTypes.contest ? createContestConfiguration() : createScholarshipConfiguration());
  }, []);

  useEffect(() => {
    setConfiguration(contest?.type === productTypes.contest ? createContestConfiguration() : createScholarshipConfiguration());
  }, [restriction]);

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.sequence = event.sequence || contest.documentFolders?.length || 0;
    event.assignedCategories = assembleExistingList({ form: event });
    dispatch(updateContestDocumentFolderRequest({ clientURL: client.url, contestURL: contest.url, form: {...(documentFolder || {}), ...event} }));
  }

  const onChange = ({ element, value }) => {
    if (element?.name === 'name') {
      setName(value);
    }
    if (element?.name === 'judgeVisible') {
      setJudgeVisible(value);
    }
    if (element?.name === 'inGallery') {
      setInGallery(value);
    }
    if (element?.name === 'hideDisqualifyWarning') {
      setHideDisqualifyWarning(value);
    }
    if (element?.name === 'minUploads') {
      setMinUploads(value);
    }
    if (element?.name === 'maxUploads') {
      setMaxUploads(value);
    }
    if (element?.name === 'maxFileSize') {
      setMaxFileSize(value);
    }
    if (element?.name === 'fileTypeRestriction') {
      setFileTypeRestriction(value);
    }
    if (element?.name === 'description') {
      setDescription(value);
    }
    if (element?.name === 'restriction') {
      setRestriction(value);
    }
  }

  const createContestConfiguration = () => {
    const config = {
      formElements: {
        rows: [{
          elements: [{
            label: 'Document Folder Name',
            name: 'name',
            type: fieldTypes.input,
            inputType: inputTypes.text,
            value: name,
            required: true
          }]
        }, {
          elements: [{
            label: 'This Document Folder Is Visible To Judges',
            name: 'judgeVisible',
            type: fieldTypes.select,
            options: yesOrNo,
            value: judgeVisible
          }]
        }, {
          elements: [{
            label: 'This Document Folder Is Visible In The Winners Gallery',
            name: 'inGallery',
            type: fieldTypes.select,
            options: yesOrNo,
            value: inGallery
          }]
        }, {
          elements: [{
            label: 'Hide Disqualify Warning For This Document Folder',
            name: 'hideDisqualifyWarning',
            type: fieldTypes.select,
            options: yesOrNo,
            value: hideDisqualifyWarning
          }]
        }, {
          elements: [{
            label: 'Minimum Uploads Required',
            name: 'minUploads',
            maxValue: 'maxUploads',
            type: fieldTypes.select,
            options: minUploadsAllowed,
            value: minUploads
          }]
        }, {
          elements: [{
            label: 'Maximum Uploads Allowed',
            name: 'maxUploads',
            minValue: 'minUploads',
            type: fieldTypes.select,
            options: maxUploadsAllowed,
            value: maxUploads
          }]
        }, {
          elements: [{
            label: 'Maximum File Size (MB)',
            name: 'maxFileSize',
            type: fieldTypes.select,
            options: oneToTen,
            value: maxFileSize
          }]
        }, {
          elements: [{
            label: 'Upload File Type(s) Allowed',
            name: 'fileTypeRestriction',
            type: fieldTypes.select,
            options: fileTypesAllowed,
            value: fileRestriction
          }]
        }, {
          elements: [{
            label: 'Enhanced Document Folder Description',
            name: 'description',
            type: fieldTypes.textarea,
            value: description
          }]
        }, {
          elements: [{
            label: 'This Document Folder Is Available For',
            name: 'restriction',
            type: fieldTypes.select,
            options: categoryRestrictions,
            value: restriction
          }]
        }]
      },
      submitCTA: 'Save',
      cancelCTA: 'Cancel'
    };

    if (restriction === restrictionTypes.specific) {
      return { ...config, formElements: { ...config.formElements, rows: [...config.formElements.rows, ...existingCategoriesMap({
        categories: contest?.categories,
        currentlyAllowed: documentFolder?.assignedCategories
      })] }};
    }

    return config;
  }

  const createScholarshipConfiguration = () => {
    const config = {
      formElements: {
        rows: [{
          elements: [{
            label: 'Document Folder Name',
            name: 'name',
            type: fieldTypes.input,
            inputType: inputTypes.text,
            value: name,
            required: true
          }]
        }, {
          elements: [{
            label: 'Minimum Uploads Required',
            name: 'minUploads',
            maxValue: 'maxUploads',
            type: fieldTypes.select,
            options: minUploadsAllowed,
            value: minUploads
          }]
        }, {
          elements: [{
            label: 'Maximum Uploads Allowed',
            name: 'maxUploads',
            minValue: 'minUploads',
            type: fieldTypes.select,
            options: maxUploadsAllowed,
            value: maxUploads
          }]
        }, {
          elements: [{
            label: 'Maximum File Size (MB)',
            name: 'maxFileSize',
            type: fieldTypes.select,
            options: oneToTen,
            value: maxFileSize
          }]
        }, {
          elements: [{
            label: 'Upload File Type(s) Allowed',
            name: 'fileTypeRestriction',
            type: fieldTypes.select,
            options: fileTypesAllowed,
            value: fileRestriction
          }]
        }, {
          elements: [{
            label: 'Enhanced Document Folder Description',
            name: 'description',
            type: fieldTypes.textarea,
            value: description
          }]
        }, {
          elements: [{
            label: 'This Document Folder Is Available For',
            name: 'restriction',
            type: fieldTypes.select,
            options: scholarshipRestrictions,
            value: restriction
          }]
        }]
      },
      submitCTA: 'Save',
      cancelCTA: 'Cancel'
    };

    if (restriction === restrictionTypes.specific) {
      return { ...config, formElements: { ...config.formElements, rows: [...config.formElements.rows, ...existingScholarshipsMap({
        scholarships: contest?.scholarships,
        currentlyAllowed: documentFolder?.assignedCategories
      })] }};
    }

    return config;
  }

  return (
    <section className="document-folder-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        onChange={onChange}
        requestPrefix={'UPDATE_CONTEST_DOCUMENT_FOLDER'}
      />
    </section>
  )
}

export default ContestDocumentFolderForm;
