import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getEventRequest,
  sendEventNotificationsRequest
} from 'src/redux/actions/events';
import TinyMCE from 'src/components/TinyMCE';
import { MenuItem, Select, TextField } from '@mui/material';

const EventNotifications = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const submitted = useSelector(state => state.completedStore.SEND_EVENT_NOTIFICATIONS);
  const [notificationOption, setNotificationOption] = useState('registrants');
  const [subject, setSubject] = useState();
  const [touched, setTouched] = useState(false);
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventNotifications', client, event }));
    dispatch(updateDrawerContent({
      key: 'eventNotifications',
      options: { client, event } 
    }));
  }, [client, event]);

  useEffect(() => {
    if (submitted) {
      setSubject();
      setTouched(false);
    }
  }, [submitted]);

  const notificationOptions = [{
    label: 'All Registrants',
    value: 'registrants'
  }];

  const handleNotificationTypeChange = (value) => {
    setNotificationOption(value);
  }

  const handleSubjectChange = (value) => {
    setSubject(value);
  }

  const handleTouched = () => {
    setTouched(true);
  };

  const handleSubmit = (event) => {
    dispatch(sendEventNotificationsRequest({ clientURL: client.url, eventURL: event.url, form: {
      mailContent: {
        notificationText: event,
        notifySubject: subject
      },
      notificationOption
    } }));
  }

  return (
    event && (
      <TinyMCE
        clearOnSave
        CTA="Send"
        disabled={!subject}
        handleSubmit={(event => handleSubmit(event))}
        label="Email Message:"
        optionalFormData={() => 
          <div className="box-content optional-form-data">
            <div className="optional-form-data-select">
              <label>Send Email Notification To</label>
              <Select
                onChange={e => {handleNotificationTypeChange(e.target.value)}}
                value={notificationOption}
              >
                {
                  (notificationOptions || []).map(option => {
                    return (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                      >{option.label}</MenuItem>
                    )
                  })
                }
              </Select>
            </div>
            <div className="optional-form-data-subject">
              <label>Email Subject:</label>
              <TextField
                autoComplete="off"
                error={!!(touched && !subject)}
                helperText={touched && !subject ? 'Subject Required' : null}
                onChange={e => {handleSubjectChange(e.target.value)}}
                onFocus={handleTouched}
                required
                type="text"
                value={subject || ''}
              />
            </div>
          </div>
        }
        requestPrefix="SEND_EVENT_NOTIFICATIONS"
        required
        title="Send Email Notifications"
        value=""
      />
    )
  )
}

export default EventNotifications;
