import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginState from 'src/components/Header/LoginState';
import MobileNav from 'src/components/Header/MobileNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const HeaderComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="header-container">
      <div className="header">
        <div className={`mobile-trigger${isOpen ? ' active': ''}`}>
          <FontAwesomeIcon
            className="icon"
            icon={faBars}
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
        <Link onClick={() => {setIsOpen(false)}} to="/">
          <img src="/assets/images/logo-horiz.svg" alt="Logo" />
        </Link>
        <LoginState />
        <div className="header-navbar-mobile">
          <MobileNav isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
    </div>
  );
}

export default HeaderComponent;
