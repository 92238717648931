import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateContestCategoryRequest } from 'src/redux/actions/contests';

const CategoryForm = ({ category, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.sequence = event.sequence || contest.categories?.length || 0;
    dispatch(updateContestCategoryRequest({ clientURL: client.url, contestURL: contest.url, form: {...(category || {}), ...event} }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Category Label',
          name: 'label',
          type: fieldTypes.input,
          inputType: inputTypes.categoryLabel,
          value: category?.label || '',
          required: true
        }, {
          class: 'flex-3',
          label: 'Category Title',
          name: 'description',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: category?.description || '',
          required: true
        }]
      }, {
        elements: [{
          label: 'Category Verbose Description (only appears on awards program landing and registration pages)',
          name: 'verbose',
          type: fieldTypes.textarea,
          value: category?.verbose || ''
        }]
      }, {
        elements: [{
          label: 'Hide From Public Voting (Optional)',
          name: 'hidePublicVoting',
          type: fieldTypes.checkbox,
          value: category?.hidePublicVoting || false
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="category-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_CONTEST_CATEGORY'}
      />
    </section>
  )
}

export default CategoryForm;
