import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes, productTypes } from 'src/constants';
import { createContestRequest } from 'src/redux/actions/contests';

const CreateContestForm = ({ handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    dispatch(createContestRequest({ clientURL: client.url, form: event }));
  }

  const types = [{
    label: 'Online Competition',
    value: productTypes.contest
  }, {
    label: 'Scholarship/Grant Program',
    value: productTypes.scholarship
  }]

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Awards Program Name',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: '',
          required: true
        }]
      }, {
        elements: [{
          label: 'Awards Program Type',
          name: 'type',
          type: fieldTypes.select,
          options: types,
          value: types[0].value
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="create-contest">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'CREATE_CONTEST'}
      />
    </section>
  )
}

export default CreateContestForm;
