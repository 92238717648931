export const animationDirections = {
  bottom: 'bottom',
  left: 'left',
  none: 'none',
  right: 'right'
};

export const categoryRestrictions = [{
  label: 'All Categories',
  value: 'all'
}, {
  label: 'Specific Categories',
  value: 'specific'
}];

export const cpj_auth_key = 'cpj_auth_key';

export const defaultFilterOption = 'all';

export const documentFolderAttachmentTypes = {
  none: 'none',
  specific: 'specific'
};

export const fieldTypes = {
  input: 'input',
  textarea: 'textarea',
  select: 'select',
  checkbox: 'checkbox',
  radio: 'radio',
  multiSelect: 'multiSelect',
  image: 'image'
};

export const fileRestrictions = {
  jpg: {
    accept: 'image/jpeg',
    acceptLabel: 'JPG only'
  },
  images: {
    accept: 'image/jpeg,image/png',
    acceptLabel: 'Images only (JPG/PNG)'
  },
  image_pdf: {
    accept: 'image/jpeg,image/png,application/pdf',
    acceptLabel: 'Images (JPG/PNG) & PDFs only'
  },
  pdf: {
    accept: 'application/pdf',
    acceptLabel: 'PDFs only'
  },
  all: {
    accept: '',
    acceptLabel: 'Any file type'
  }
}

export const fileTypesAllowed = [{
  label: 'Any',
  value: 'all'
}, {
  label: 'Images (JPG/PNG) & PDF Only',
  value: 'image-pdf'
}, {
  label: 'Images Only (JPG/PNG)',
  value: 'images'
}, {
  label: 'PDF Only',
  value: 'pdf'
}];

export const formFieldPatterns = {
  catLabel: /^[A-Z0-9]{3,6}$/,
  date: /^\d{2}\/\d{2}\/\d{4}$/,
  domain: /^[a-zA-Z0-9_-]{3,15}$/,
  email: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  http: /^(http|https):\/\/\w+([-.]\w+)*\.\w+([-.]\w+)*\w+([-.?&#=\/]\w*)*$/,
  password: /^.*(?=.{7,})(?=.*\d)(?=.*[a-zA-Z]).*$/,
  phone: /^([0-9]{10})$/,
  promoCode: /^[a-zA-Z0-9]{3,50}$/,
  urlFragment: /^[a-zA-Z0-9_-]{3,100}$/,
  zipcode: /^([0-9]{5})$/
}

export const formFieldTypes = {
  heading: 'heading',
  paragraph: 'paragraph',
  input: 'input',
  checkbox: 'checkbox',
  select: 'select',
  radio: 'radio',
  multiSelect: 'multiSelect',
  textarea: 'textarea',
  image: 'image'
}

export const gatewayOptions = {
  demo: {
    label: 'Invoice Manually',
    value: 'demo'
  },
  stripe: {
    label: 'Stripe',
    url: 'https://stripe.com',
    value: 'stripe'
  },
  square: {
    label: 'Square',
    url: 'https://square.com',
    value: 'square'
  }
};

export const homePageAlignments = {
  bottom: 'bottom',
  center: 'center',
  top: 'top'
};

export const homePageBackgroundTypes = {
  color: 'color',
  image: 'image',
  parallax: 'parallax'
};

export const homePageContentTypes = {
  countdown: 'countdown',
  image: 'image',
  propertyList: 'propertyList',
  sponsors: 'sponsors',
  text: 'text'
};

export const homePageOverlayTypes = {
  dark: 'dark',
  light: 'light',
  none: 'none'
};

export const inputTypes = {
  categoryLabel: 'category-label',
  currency: 'currency',
  date: 'date',
  dateTime: 'dateTime',
  domain: 'domain',
  email: 'email',
  number: 'number',
  password: 'password',
  passwordAny: 'password-any',
  promoCode: 'promo-code',
  phone: 'phone',
  text: 'text',
  time: 'time',
  website: 'website',
  url: 'url',
  zip: 'zip'
}

export const maximumVotesPerGroup = [{
  label: '1 (Pick Best Overall)',
  value: 1
}, {
  label: '3 (Pick Top Three)',
  value: 3
}];

export const maxUploadsAllowed = (() => {
  var values = [
    {
      label: 'Maximum (20)',
      value: 20
    }
  ];

  for (let i = 1; i < 20; i++) {
    values.push({
      label: i.toString(),
      value: i
    });
  }

  return values;
})();

export const maxUploadsAllowedPermitZero = (() => {
  var values = [
    {
      label: 'Maximum (20)',
      value: 20
    }
  ];

  for (let i = 0; i < 20; i++) {
    values.push({
      label: i.toString(),
      value: i
    });
  }

  return values;
})();

export const minUploadsAllowed = (() => {
  var values = [
    {
      label: 'Minimum (0)',
      value: 0
    }
  ];

  for (let i = 1; i < 16; i++) {
    values.push({
      label: i.toString(),
      value: i
    });
  }

  return values;
})();

export const none = 'none';

export const oneToHundred = (() => {
  var values = [];

  for (let i = 1; i < 101; i++) {
    values.push({
      label: i,
      value: i
    });
  }

  return values;
})();

export const oneToTen = (() => {
  var values = [];

  for (let i = 1; i < 11; i++) {
    values.push({
      label: i,
      value: i
    });
  }

  return values;
})();

export const productTypes = {
  contest: 'contest',
  event: 'event',
  parade: 'parade',
  survey: 'survey',
  scholarship: 'scholarship'
};

export const publicVotingOptions = {
  no: 'no',
  perTicket: 'per-ticket',
  perScannedTicket: 'per-scanned-ticket',
  unrestricted: 'unrestricted'
};

export const purchaseTypes = {
  event: 'event',
  property: 'property',
  sponsor: 'sponsor'
};

export const restrictionTypes = {
  all: 'all',
  specific: 'specific'
};

export const scholarshipRestrictions = [{
  label: 'All Scholarships',
  value: 'all'
}, {
  label: 'Specific Scholarships',
  value: 'specific'
}];

export const sponsorPlacementTypes = {
  page: 'page',
  top: 'top',
  bottom: 'bottom'
};

export const sponsorSizes = [{
  value: 'large',
  label: 'Large'
}, {
  value: 'medium',
  label: 'Medium'
}, {
  value: 'small',
  label: 'Small'
}];

export const states = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' }
];

export const timezones = [{
  label: 'Eastern',
  value: 'US/Eastern'
}, {
  label: 'Central',
  value: 'US/Central'
}, {
  label: 'Mountain',
  value: 'US/Mountain'
}, {
  label: 'Arizona',
  value: 'US/Arizona'
}, {
  label: 'Pacific',
  value: 'US/Pacific'
}, {
  label: 'Alaska',
  value: 'US/Alaska'
}, {
  label: 'Hawaii',
  value: 'US/Hawaii'
}];

export const volunteerSpecializations = [{
  key: 'volunteerIDR',
  label: 'Residential Interior Design'
}, {
  key: 'volunteerIDC',
  label: 'Commercial Interior Design'
}, {
  key: 'volunteerHBR',
  label: 'Residential Home Building'
}, {
  key: 'volunteerHBC',
  label: 'Commercial Construction'
}, {
  key: 'volunteerKB',
  label: 'Kitchen/Bath'
}, {
  key: 'volunteerArchitecture',
  label: 'Architecture'
}, {
  key: 'volunteerLandscaping',
  label: 'Landscaping'
}, {
  key: 'volunteerMarketing',
  label: 'Marketing'
}, {
  key: 'volunteerFurniture',
  label: 'Furniture Design'
}];

export const sponsorPlacements = [{
  label: 'Separate Page',
  value: 'page'
}, {
  label: 'Top Of Landing Page',
  value: 'top'
}, {
  label: 'Bottom Of Landing Page',
  value: 'bottom'
}];

export const supportingProductTypes = {
  property: 'property',
  sponsor: 'sponsor'
};

export const yesOrNo = [{
  label: 'Yes',
  value: true
}, {
  label: 'No',
  value: false
}];
