import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import {
  fieldTypes,
  inputTypes,
  supportingProductTypes,
  yesOrNo
} from 'src/constants';
import {
  getFirstMinuteToday,
  getLastMinuteToday,
  parseDateTime
} from 'src/utilities/dates';
import defaultSelectOption from 'src/utilities/defaultSelectOption';

const PropertyTimeframeForm = ({ handleClose, handleSubmit, timeframe }) => {
  const [configuration, setConfiguration] = useState();
  const [startDate, setStartDate] = useState(timeframe?.startDate || getFirstMinuteToday());
  const [endDate, setEndDate] = useState(timeframe?.endDate || getLastMinuteToday());
  const [hasDiscount, setHasDiscount] = useState(defaultSelectOption({ defaultValue: false, value: timeframe?.hasDiscount }));
  const dispatch = useDispatch();

  useEffect(() => {
    setConfiguration(createConfiguration());
  }, [hasDiscount]);

  const nullCheck = (value) => {
    return value === 'null' ? null : value;
  }

  const onChange = ({ element, value }) => {
    switch(element?.name) {
      case 'startDate': setStartDate(value); break;
      case 'endDate': setEndDate(value); break;
      case 'hasDiscount': setHasDiscount(value); break;
      default: ;
    }
  }

  const assemblePriceStructure = (form) => {
    const prices = [];

    for (let property in form) {
      const keys = property.split('_');
      if (keys?.length === 4 && keys[3] === 'price') {
        keys[0] = nullCheck(keys[0]);

        const discountKey = `${keys[0]}_null_null_discount`;
        const priceKey = `${keys[0]}_null_null_price`;

        prices.push({
          discount: form[discountKey],
          element: keys[0],
          elementType: supportingProductTypes.property,
          price: form[priceKey]
        });

        delete form[discountKey];
        delete form[priceKey];
      }
    }

    return prices;
  }

  const handleSubmitFn = (event) => {
    const startDate = parseDateTime({ date: event.startDate });
    const endDate = parseDateTime({ date: event.endDate });
    const prices = assemblePriceStructure(event);
    dispatch(handleSubmit({ ...event, startDate, endDate, prices, type: supportingProductTypes.property }));
  }

  const createPrices = () => {
    return hasDiscount ? {
      elements: [{
        label: 'Price for all properties',
        name: `null_null_null_price`,
        type: fieldTypes.input,
        inputType: inputTypes.currency,
        required: true,
        value: (timeframe?.prices || [])[0]?.price || 0
      }, {
        label: 'Price for all properties after discount',
        name: `null_null_null_discount`,
        type: fieldTypes.input,
        inputType: inputTypes.currency,
        required: true,
        value: (timeframe?.prices || [])[0]?.discount || 0
      }]
    } : {
      elements: [{
        label: 'Price for all properties',
        name: `null_null_null_price`,
        type: fieldTypes.input,
        inputType: inputTypes.currency,
        required: true,
        value: (timeframe?.prices || [])[0]?.price || 0
      }]
    };
  }

  const createConfiguration = () => {
    const config = {
      formElements: {
        rows: [{
          heading: 'These prices are active from:'
        }, {
          elements: [{
            label: 'Start Date',
            name: 'startDate',
            dateRangeEnd: 'endDate',
            type: fieldTypes.input,
            inputType: inputTypes.dateTime,
            value: startDate || getFirstMinuteToday(),
            required: true
          }, {
            label: 'End Date',
            name: 'endDate',
            dateRangeStart: 'startDate',
            type: fieldTypes.input,
            inputType: inputTypes.dateTime,
            value: endDate || getLastMinuteToday(),
            required: true
          }]
        }, {
          elements: [{
            label: 'Discount for multiple purchased?',
            name: 'hasDiscount',
            type: fieldTypes.select,
            value: hasDiscount,
            options: yesOrNo
          }]
        }, {
          heading: 'Pricing settings:',
        }, ...[createPrices()]]
      },
      submitCTA: 'Save',
      cancelCTA: 'Cancel'
    };

    return config;
  };

  return (
    <section className="timeframe-form">
      {
        configuration && (
          <AutoForm
            configuration={configuration}
            handleCancel={handleClose}
            handleSubmit={handleSubmitFn}
            onChange={onChange}
            requestPrefix="UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME"
          />
        )
      }
    </section>
  )
}

export default PropertyTimeframeForm;
