import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import Typeahead from 'src/components/Typeahead';
import {
  clearViewUser,
  viewUserRequest
} from 'src/redux/actions/users';
import UserProfile from 'src/components/UserProfile';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const ViewUser = () => {
  const dispatch = useDispatch();
  const viewedUser = useSelector(state => state.userStore?.viewedUser);
  const userIsRequesting = useSelector(state => state.loadingStore.VIEW_USER);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'viewUser' }));
    dispatch(updateDrawerContent({ key: 'networkViewUser' }));
    dispatch(clearViewUser());
  }, []);

  const selectUser = (user) => {
    dispatch(viewUserRequest({ url: user.url }));
  }

  const renderCompany = () => {
    if (!viewedUser.title && !viewedUser.company) {
      return '';
    }

    if (viewedUser.title && viewedUser.company) {
      return <p className="view-user-item-content">{viewedUser.title} at {viewedUser.company}</p>
    }
    return <p className="view-user-item-content">{viewedUser.title || ''}{viewedUser.company || ''}</p>
  }
  
  return (
    <section className="view-user">
      <section className="box">
        <div className="box-title">
          <h3><span>View A User
          {
            userIsRequesting && (
              <LoadingCircle variant="dark" />
            )
          }
          </span></h3>
          
          { viewedUser &&
            <Button
              onClick={() => dispatch(clearViewUser())}
              size="small"
              variant="contained"
            >Clear Selected User</Button>
          }
        </div>
        <div className="box-content">
          {
            (!viewedUser && !userIsRequesting) &&
            <Typeahead
              placeholder="Find A User..."
              onSelect={event => selectUser(event)}
            />
          }
          {
            viewedUser && (
              <div className="view-user-item">
                <h4>Selected User:</h4>
                <UserProfile
                  linkPhone
                  linkEmail
                  user={viewedUser}
                />
                {
                  viewedUser.website &&
                  <Link
                    className="view-user-item-content"
                    to={viewedUser.website}
                  >{viewedUser.website}</Link>
                }
                {
                  renderCompany()
                }
                {
                  (viewedUser.memberships || [])?.length > 0 &&
                  <h4 className="view-user-item-content">Memberships:</h4>
                }
                {
                  (viewedUser.memberships || []).map(membership =>
                    <div
                      className="view-user-item-membership"
                      key={membership.clientID}
                    >
                      <img src={membership.logo} alt={membership.name} />
                      <p>{membership.name}</p>
                    </div>
                  )
                }
                {
                  (viewedUser.volunteerIDR ||
                  viewedUser.volunteerIDC ||
                  viewedUser.volunteerHBC ||
                  viewedUser.volunteerHBR ||
                  viewedUser.volunteerKB ||
                  viewedUser.volunteerArchitecture ||
                  viewedUser.volunteerLandscaping ||
                  viewedUser.volunteerMarketing ||
                  viewedUser.volunteerFurniture) && 
                  <p className="view-user-item-content"><b>User Is A Judge Volunteer</b></p>
                }
                {
                  viewedUser.volunteerIDR && <p style={{ marginTop: '8px' }}>&mdash; Residential Interior Design</p>
                }
                {
                  viewedUser.volunteerIDC && <p style={{ marginTop: '8px' }}>&mdash; Commercial Interior Design</p>
                }
                {
                  viewedUser.volunteerHBC && <p style={{ marginTop: '8px' }}>&mdash; Commercial Construction</p>
                }
                {
                  viewedUser.volunteerHBR && <p style={{ marginTop: '8px' }}>&mdash; Residential Home Building</p>
                }
                {
                  viewedUser.volunteerKB && <p style={{ marginTop: '8px' }}>&mdash; Kitchen/Bath</p>
                }
                {
                  viewedUser.volunteerArchitecture && <p style={{ marginTop: '8px' }}>&mdash; Architecture</p>
                }
                {
                  viewedUser.volunteerLandscaping && <p style={{ marginTop: '8px' }}>&mdash; Landscaping</p>
                }
                {
                  viewedUser.volunteerMarketing && <p style={{ marginTop: '8px' }}>&mdash; Marketing</p>
                }
                {
                  viewedUser.volunteerFurniture && <p style={{ marginTop: '8px' }}>&mdash; Furniture Design</p>
                }
              </div>
            )
          }
        </div>
      </section>
    </section>
  )
}

export default ViewUser;
