const reducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILED|CLEAR)/.exec(type);
  
  if (!matches) return state;  
  
  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: (requestState === 'SUCCESS' || requestState === 'FAILED'),
  };
};

export default reducer;