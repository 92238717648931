import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    height,
    width
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const handleResize = useMemo(() => debounce(() => setWindowDimensions(getWindowDimensions()), 100), []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}