import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TinyMCE from 'src/components/TinyMCE';
import { updateContestScholarshipRequirementsRequest } from 'src/redux/actions/contests';

const ScholarshipRequirementsForm = ({ handleClose, scholarship }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    dispatch(updateContestScholarshipRequirementsRequest({ clientURL: client.url, contestURL: contest.url, form: {...(scholarship || {}), requirements: event } }));
  }

  return (
    <TinyMCE
      handleSubmit={(event => handleSubmit(event))}
      handleCancel={handleClose}
      requestPrefix="UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS"
      value={scholarship?.requirements}
    />
  )
}

export default ScholarshipRequirementsForm;
