import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import CurrencyField from 'src/components/AutoForm/CurrencyField';
import LoadingCircles from 'src/components/Elements/LoadingCircles';
import UserProfile from 'src/components/UserProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCanXmark } from '@fortawesome/pro-light-svg-icons';
import {
  oneToHundred,
  purchaseTypes
} from 'src/constants';
import { createResourceID } from 'src/utilities/strings';
import { openDialog } from 'src/redux/actions/dialog';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';

const EditRegistration = ({ guestEmail, items, onSubmit, options, purchaseType, transactionID, updatePrefix, user }) => {
  const isUpdating = useSelector(state => state.loadingStore[updatePrefix]);
  const dispatch = useDispatch();
  const ref = useRef();
  const [list, setList] = useState(items);

  const availablePurchaseTypes = [{
    label: 'Event Registration',
    value: purchaseTypes.event
  }, {
    label: 'Sponsorship Registration',
    value: purchaseTypes.sponsor
  }];

  if (options[purchaseTypes.property]) {
    availablePurchaseTypes.push({
      label: 'Property Listing Registration',
      value: purchaseTypes.property
    })
  }

  const createItem = () => {
    const availableOptions = options[purchaseType]();

    const newItem = {
      itemID: availableOptions[0].value,
      label: availableOptions[0].label,
      quantity: 1,
      price: 0,
      transactionItemID: createResourceID()
    };

    setList([...list, newItem]);
  }

  const handleChange = ({ index, key, value }) => {
    const newList = cloneDeep(list);
    newList[index][key] = value;

    if (key === 'itemID') {
      const itemName = (options[purchaseType]()).find(option => option.value === value).label;
      newList[index].itemName = itemName;
    }

    setList(newList);
  };

  const handleSubmit = () => {
    onSubmit({
      form: {
        items: list
      },
      transactionID
    });
  }

  const removeItem = ({ item }) => {
    dispatch(
      openDialog({
        key: 'removeRegistrationItem',
        props: {
          fn: () => {
            const newList = cloneDeep(list);
            const index = list.findIndex(itemItem => itemItem.transactionItemID === item.transactionItemID);
            newList.splice(index, 1)
            setList(newList);
          }
        }
      })
    );
  }

  return <section className="edit-invoice">
    <div className="edit-invoice-heading">
      {
        !guestEmail && <UserProfile user={user} />
      }
      {
        guestEmail && <p>Guest Registration: <b>{guestEmail}</b></p>
      }
    </div>
    <div className="edit-invoice-content">
      {
        (list || []).map((item, index) => 
          <div
            className="edit-invoice-content-item"
            key={item.transactionItemID}
          >
            <Select
              className="edit-invoice-content-item-name"
              onChange={e => handleChange({ index, key: 'itemID', value: e.target.value })}
              value={item.itemID}
            >{
              (options[purchaseType]() || []).map(option => {
                return (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >{option.label}</MenuItem>
                )
              })
            }</Select>
            <div>
              <TextField
                className="edit-invoice-content-item-price"
                InputProps={{
                  inputComponent: CurrencyField,
                  inputRef: ref
                }}
                onChange={e => handleChange({ index, key: 'price', value: e.target.value })}
                type="text"
                value={item.price}
              />
              <Select
                className="edit-invoice-content-item-quantity"
                onChange={e => handleChange({ index, key: 'quantity', value: e.target.value })}
                value={item.quantity}
              >{
                oneToHundred.map(option => {
                  return (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                    >{option.label}</MenuItem>
                  )
                })
              }</Select>
              <Button
                className="trash"
                onClick={() => removeItem({ item })}
              ><FontAwesomeIcon icon={faTrashCanXmark} /></Button>
            </div>
          </div>
        )
      }
      {
        !(list || []).length && <p>No Items Found</p>
      }
    </div>
    <div className="edit-invoice-buttons">
      <Button
        color="confirm"
        disabled={isUpdating}
        onClick={() => handleSubmit()}
        variant="contained"
      >{ isUpdating ? <LoadingCircles /> : 'Save' }</Button>
      <Button
        disabled={isUpdating}
        onClick={() => createItem()}
        variant="contained"
      >Create Registration Item</Button>
    </div>
  </section>
}

export default EditRegistration;
