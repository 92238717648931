import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateEventOptionalFeeRequest } from 'src/redux/actions/events';

const EventOptionalFeeForm = ({ fee, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const dispatch = useDispatch();

  const handleSubmit = (evt) => {
    evt.sequence = evt.sequence || event.fees?.length || 0;
    dispatch(updateEventOptionalFeeRequest({ clientURL: client.url, eventURL: event.url, form: {...(fee || {}), ...evt} }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Fee Name',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: fee?.name || '',
          required: true
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="optional-fee-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_EVENT_OPTIONAL_FEE'}
      />
    </section>
  )
}

export default EventOptionalFeeForm;
