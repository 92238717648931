import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResourceList from 'src/components/ResourceList';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { getParadeListRequest } from 'src/redux/actions/parades';
import { Link, useNavigate } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import { updateDrawerContent } from 'src/redux/actions/drawer';

const Parades = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parades = useSelector(state => state.paradeStore?.parades);
  const paradesFetching = useSelector(state => state.loadingStore.GET_PARADE_LIST);
  const navigate = useNavigate();
  
  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client },
          key: 'createParade'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: parade => {
      navigate(parade.url);
    }
  }, {
    label: 'Delete',
    color: 'danger',
    variant: 'contained',
    requiresSelection: true,
    fn: parade => {
      dispatch(
        openDialog({
          props: { client, parade },
          key: 'removeParade'
        })
      )
    }
  }];

  const filters = {
    searchBy: ['name']
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'parades', client }));
    dispatch(updateDrawerContent({ key: 'clientParades', options: { client } }));

    if (!paradesFetching) {
      dispatch(getParadeListRequest({ clientURL: client.url }));
    }
  }, []);

  return (
    <ResourceList
      actions={actions}
      fetching={paradesFetching}
      filters={filters}
      list={parades}
      renderHeaders={() => 
        <div className="parade-list">
          <div className="parade-list-name">Name</div>
        </div>
      }
      renderItem={item => 
        <div className="parade-list">
          <div className="parade-list-name">
            <Link to={item.url}>{item.name}</Link>
          </div>
        </div>
      }
      renderKey="name"
      title="Parade Website List"
    />
  )
}

export default Parades;
