import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import createLoadingSelector from 'src/redux/loading';
import {
  getParadeRequest,
  updateParadeTicketTakerRequest,
  updateParadeTicketTakerInvitationRequest
} from 'src/redux/actions/parades';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import TypeaheadList from 'src/components/TypeaheadList';
import ResourceList from 'src/components/ResourceList';
import { openDialog } from 'src/redux/actions/dialog';
import UserProfile from 'src/components/UserProfile';
import { toast } from 'react-toastify';

const ParadeTicketTakers = ({ creatingTicketTaker }) => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  const handleCreateTicketTaker = (ticketTaker) => {
    if (ticketTaker.sendInvite) {
      dispatch(updateParadeTicketTakerInvitationRequest({
        clientURL: client.url, paradeURL: parade.url, form: ticketTaker
      }));

      return;
    }
    dispatch(updateParadeTicketTakerRequest({
      clientURL: client.url, paradeURL: parade.url, form: ticketTaker
    }));
  }

  const actions = [{
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: ticketTaker => {
      dispatch(
        openDialog({
          props: { client, parade, ticketTaker },
          key: 'removeParadeTicketTaker'
        })
      )
    }
  }];

  const invitationActions = [{
    color: 'confirm',
    label: 'Copy Link',
    requiresSelection: true,
    variant: 'contained',
    fn: invitation => {
      navigator.clipboard.writeText(`https://${client.url}.cpjam.com/signup/${invitation.invitationKey}`);
      toast.success('Link copied to clipboard.');
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: invitation => {
      dispatch(
        openDialog({
          props: { client, parade, invitation },
          key: 'removeParadeTicketTakerInvitation'
        })
      )
    }
  }];

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeTicketTakers', client, parade }));
    dispatch(updateDrawerContent({
      key: 'paradeTicketTakers',
      options: { client, parade }
    }));
  }, [client, parade]);
  
  return (
    <section className="parade-ticket-takers grid">
      {
        ((parade?.ticketTakerInvitations || []).length > 0) && (
          <ResourceList
            actions={invitationActions}
            list={parade.ticketTakerInvitations}
            renderItem={invitation => 
              <div className="invitation">
                <div className="invitation-details">
                  <span className="strong">{invitation.email}</span> &mdash; <span className="italic">expires: {invitation.exp}</span>
                </div>
                <div className="invitation-link">
                  {`https://${client.url}.cpjam.com/signup/${invitation.invitationKey}`}
                </div>
              </div>}
            renderKey="invitationKey"
            title="Pending Invitations"
          />
        )
      }
      <TypeaheadList
        actions={actions}
        create={handleCreateTicketTaker}
        fetching={paradeFetching}
        list={parade?.ticketTakers}
        permitInvitation
        placeholder="Type To Add Ticket Taker..."
        renderItem={ticketTaker =>
          <UserProfile
            showEmail
            user={ticketTaker}
          ></UserProfile>
        }
        renderKey="ticketTakerID"
        selecting={creatingTicketTaker}
        title="Assigned Ticket Takers"
      />
    </section>
  )
}

const creatingSelector = createLoadingSelector(['UPDATE_PARADE_TICKET_TAKER', 'UPDATE_PARADE_TICKET_TAKER_INVITATION']);
const mapStateToProps = (state) => ({ creatingTicketTaker: creatingSelector(state) });
export default connect(mapStateToProps)(ParadeTicketTakers);