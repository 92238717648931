import { put, takeEvery } from 'redux-saga/effects';
import * as actions from 'src/redux/actions/modal';

function* activeCloseModal(request) {
  yield put({ type: actions.ACTIVE_CLOSE_MODAL_SUCCESS, body: request.body });
}

function* closeModal(request) {
  yield put({ type: actions.CLOSE_MODAL_SUCCESS, body: request.body });
}

function* updateModalRequest(request) {
  yield put({ type: actions.OPEN_MODAL_SUCCESS, body: request.body });
}

function* modalSaga() {
  yield takeEvery(actions.ACTIVE_CLOSE_MODAL, activeCloseModal);
  yield takeEvery(actions.CLOSE_MODAL, closeModal);
  yield takeEvery(actions.OPEN_MODAL, updateModalRequest);
}

export default modalSaga;
