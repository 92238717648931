import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEventRequest } from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { MenuItem, Select } from '@mui/material';
import sortBy from 'src/utilities/sort';

const EventAttendance = () => {
  const number = 'number';
  const checkedin = 'checkedin';
  const notcheckedin = 'notcheckedin';
  const attendance = 'attendance';

  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const [tickets, setTickets] = useState([]);
  const [sortedTickets, setSortedTickets] = useState();
  const [sortingBy, setSortingBy] = useState(number);
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sortOptions = [{
    label: 'Ticket Number',
    value: number
  }, {
    label: 'Checked In',
    value: checkedin
  }, {
    label: 'Not Checked In',
    value: notcheckedin
  }];

  if (!eventURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);
  
  useEffect(() => {
    if (event?.purchasedTickets && !sortedTickets) {
      setTickets(event?.purchasedTickets || []);
      setSortedTickets(sortBy({ key: number, list: event?.purchasedTickets || [] }));
    }
  }, [event?.purchasedTickets]);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventAttendance', client, event }));
    dispatch(updateDrawerContent({
      key: 'eventAttendance',
      options: { client, event } 
    }));
  }, [client, event]);

  const handleSortChange = ({ value }) => {
    setSortingBy(value);
    let sortMethod;
    let reverse;

    switch(value) {
      case checkedin: sortMethod = attendance; reverse = true; break;
      case notcheckedin: sortMethod = attendance; break;
      default: sortMethod = number;
    }

    setSortedTickets(sortBy({ key: sortMethod, list: tickets, reverse }));
  }

  if (!event.enableCheckin) {
    return <section className="attendance">
      <section className="box">
        <h3 className="box-title">Event Attendance Summary</h3>
        <div className="box-content">
          <p>Checkin flow is disabled, therefore attendance cannot be tracked.</p>
        </div>
      </section>
    </section>
  }

  return <section className="attendance grid">
    <section className="box">
      <h3 className="box-title">Event Attendance Summary</h3>
      <div className="box-content">
        <div>
          <label>Sort By:</label>
          <Select 
            onChange={event => handleSortChange({ value: event.target.value })}
            value={sortingBy}
          >{
            (sortOptions || []).map(option => {
              return (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >{option.label}</MenuItem>
              )
            })
          }</Select>
        </div>
      </div>
    </section>
    <section className="box">
      <h3 className="box-title">Tickets</h3>
      <div className="box-content">
        <div className="attendance-list">
          { (sortedTickets || []).map(ticket => <div
            className={`attendance-list-item${!!ticket.attendance ? ' complete' : ''}${!ticket.attendance ? ' error' : ''}`}
          >
            <div>Ticket <b>#{ticket.number}</b> : {ticket.email}</div>
            <div>{ !ticket.attendance ? 'Not ' : ''}Checked In</div>
          </div>)
          }
        </div>
      </div>
    </section>
  </section>
}

export default EventAttendance;
