import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateInvoiceRequest } from 'src/redux/actions/app';
import { parseDate } from 'src/utilities/dates';
import { Button } from '@mui/material';
import SelectField from 'src/components/TemplateForms/InvoiceForm/InvoiceLineItem/SelectField';
import DateField from 'src/components/TemplateForms/InvoiceForm/InvoiceLineItem/DateField';
import InvoiceLineItem from 'src/components/TemplateForms/InvoiceForm/InvoiceLineItem';
import { productTypes } from 'src/constants';
import moment from 'moment';
import { createResourceID } from 'src/utilities/strings';
import { useSelector } from 'react-redux';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const InvoiceForm = ({ clients, handleClose, invoice, invoiceItems }) => {
  const check = 'Check';
  const creditCard = 'Credit Card';
  const unpaid = 'Unpaid';
  const service = 'service';
  const custom = 'custom';

  const isRequesting = useSelector(state => state.loadingStore.UPDATE_INVOICE);

  const reg = new RegExp(/[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}/);

  const [formValid, setFormValid] = useState(true);
  const [formState, setFormState] = useState({});
  const [items, setItems] = useState([])
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const { clientID, invoiceDate, items, paidBy, paidDate } = (invoice || {});
    setItems(items);
    const newItems = (items || []).reduce((acc, item) => {
      const { amount, description, eventID, eventName, itemID, qty, type } = item;
      return { ...acc, [itemID]: {
        amount,
        description,
        eventID,
        eventName,
        itemID,
        qty,
        type: !!productTypes[type] ? service : custom
      } };
    }, {});
    const newState = { clientID, invoiceDate, paidBy, paidDate, ...newItems };
    setFormState(newState);
    setFormValid(errorCheck(newState));
  }, []);

  const assembleLineItemList = () => {
    const list = [];
    for (const key in formState) {
      if (key?.length === 11 && reg.test(key)) {
        list.push({
          amount: parseFloat(formState[key].amount),
          description: formState[key].type === custom ? formState[key].description : null,
          eventID: formState[key].type === service ? formState[key].eventID : null,
          itemID: formState[key].type === custom ? key : invoiceItems.find(item => item.eventID === formState[key].eventID).itemID,
          qty: parseInt(formState[key].qty)
        });
      }
    }
    return list;
  };

  const getClientURL = () => {
    return (clients.find(client => client.clientID === (invoice?.clientURL || formState.clientID)) || clients[0]).url;
  };

  const errorCheck = (formState) => {
    const validDates = moment(formState['invoiceDate'] || null).isValid() &&
      (formState['paidBy'] === check ? moment(formState['paidDate'] || null).isValid() : true);

    const invalidData = (value) => {
      return typeof value === 'undefined' || value === null || (typeof value === 'string' && value.trim() === '');
    }

    if (!validDates) {
      return false;
    }

    for (const key in formState) {
      if (key?.length === 11 && reg.test(key)) {
        if ((formState[key]?.type === custom && invalidData(formState[key]?.description)) ||
          invalidData(formState[key]?.qty) ||
          invalidData(formState[key]?.amount)
        ) {
          return false;
        }
        for (const k in formState) {
          if (k?.length === 11 && reg.test(k)) {
            if (key !== k && !!formState[key].eventID && formState[key].eventID === formState[k].eventID) {
              return false;
            }
          }
        }
      }
    }

    return true;
  };

  const createNewItem = () => {
    const itemID = createResourceID();
    setItems([...(items || []), { itemID, type: custom }]);
    const newState = { ...formState, [itemID]: { itemID, type: custom } };
    setFormState(newState);
    setFormValid(errorCheck(newState));
  };

  const removeItem = (item) => {
    setItems(items.filter(obj => obj.itemID !== item.itemID));
    delete formState[item.itemID];
    setFormValid(errorCheck(formState));
  };

  const handleChange = ({ key, value }) => {
    let newState ;
    if (key === 'clientID') {
      setItems([]);
      const { invoiceDate, paidBy, paidDate } = formState;
      newState = {
        invoiceDate: invoiceDate || '',
        paidBy: paidBy || unpaid,
        paidDate: paidDate || ''
      };
    } else {
      newState = {
        ...formState
      };
    }
    newState = {
      ...newState,
      [key]: value
    };
    setFormState(newState);
    setFormValid(errorCheck(newState));
  }

  const handleSubmit = () => {
    const invoiceDate = parseDate({ date: formState.invoiceDate });
    const paidDate = (!formState.paidBy || formState.paidBy === unpaid) ? null : parseDate({ date: formState.paidDate });
    const items = assembleLineItemList();

    dispatch(updateInvoiceRequest({
      clientURL: getClientURL(),
      form: {
        invoiceID: (invoice || {}).invoiceID || null,
        invoiceDate,
        paidBy: formState.paidBy,
        paidDate,
        items
      }
    }));
  };

  const paymentStatusOptions = [{
    label: unpaid,
    value: unpaid
  }, {
    label: creditCard,
    value: creditCard
  }, {
    label: check,
    value: check
  }];

  return (
    <section className="invoice-form">
      <form className="form">
        <div className="form-content">
          <div className="form-field">
            <SelectField
              disabled={!!invoice?.invoiceID}
              label="Client"
              name="clientID"
              onChange={value => handleChange({ key: 'clientID', value })}
              options={(clients || []).map(client => {
                return {
                  label: client.name,
                  value: client.clientID
                };
              })}
              value={formState['clientID'] || clients[0].clientID}
            />
            <DateField
              errors={errors}
              label="Invoice Date"
              name="invoiceDate"
              onChange={value => handleChange({ key: 'invoiceDate', value })}
              required
              setErrors={setErrors}
              value={formState['invoiceDate'] || ''}
            />
          </div>
          <div className="form-field">
            <SelectField
              label="Payment Status"
              name="paidBy"
              onChange={value => handleChange({ key: 'paidBy', value })}
              options={paymentStatusOptions}
              value={formState['paidBy'] || unpaid}
            />
          { formState['paidBy'] === check && 
            <DateField
              errors={errors}
              label="Payment Date"
              name="paidDate"
              onChange={value => handleChange({ key: 'paidDate', value })}
              required
              setErrors={setErrors}
              value={formState['paidDate'] || ''}
            />
          }
          </div>
          <h3>Items</h3>
            {
              (items || []).map(item => <InvoiceLineItem
                clientID={formState.clientID}
                invoiceItems={invoiceItems}
                item={formState[item.itemID]}
                key={item.itemID}
                onChange={value => handleChange({ key: item.itemID, value })}
                onDelete={item => removeItem(item)}
              />
              )
            }
            {
              !(items || []).length && <p>No Items Found</p>
            }
        </div>
        <div className="form-buttons">
          <Button
            className="invoice-form-button"
            onClick={createNewItem}
            variant="contained"
          >Create New Item</Button>
          <div>
            <Button
              className="invoice-form-button"
              disabled={!formValid || isRequesting}
              onClick={handleSubmit}
              variant="contained"
            >{ isRequesting ? <LoadingCircle /> : 'Save' }</Button>
            <Button
              className="invoice-form-button"
              onClick={handleClose}
              variant="outlined"
            >Cancel</Button>
          </div>
        </div>
      </form>
    </section>
  )
}

export default InvoiceForm;
