import { call, put, takeEvery } from 'redux-saga/effects';
import handleFetch from 'src/utilities/fetch';
import * as searchActions from 'src/redux/actions/search';
import { formFieldPatterns } from 'src/constants';
import { toast } from 'react-toastify';

async function getSearchList({ search }) {
  return await handleFetch({ url: `/users/find/${encodeURI(search)}` });
}

function* getSearchListRequest(request) {
  try {
    const context = yield call(() => getSearchList(request.body));
    if (request.body.permitInvitation) {
      context.sendInvite = (formFieldPatterns.email.test(request.body?.search || '') && !(context.results || []).some(result => {
        return (result?.email || '').toLowerCase() === (request.body.search || '').toLowerCase();
      })) && request.body.search;
    } else if (request.body.permitTypedValue) {
      context.typedValue = request.body.search;
    }
    yield put({ type: searchActions.GET_SEARCH_LIST_SUCCESS, context });
  } catch (error) {
    toast.error(error.message);
    yield put({ type: searchActions.GET_SEARCH_LIST_FAILED });
  }
}

function* searchSaga() {
  yield takeEvery(searchActions.GET_SEARCH_LIST_REQUEST, getSearchListRequest);
}

export default searchSaga;
