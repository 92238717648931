import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContestRequest } from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { updateContestTimeframeRequest } from 'src/redux/actions/contests';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';

const ContestTimeframes = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: {
            contest,
            elementType: 'category',
            fees: (contest.fees || []),
            handleSubmit: (event) => updateContestTimeframeRequest({ clientURL: client.url, contestURL: contest.url, form: event }),
            items: (contest.categories || []),
            requestPrefix: 'UPDATE_CONTEST_TIMEFRAME'
          },
          key: 'createContestTimeframe'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: timeframe => {
      dispatch(
        openModal({
          props: {
            contest,
            elementType: 'category',
            fees: (contest.fees || []),
            handleSubmit: (event) => updateContestTimeframeRequest({ clientURL: client.url, contestURL: contest.url, form: {...(timeframe || {}), ...event} }),
            items: (contest.categories || []),
            requestPrefix: 'UPDATE_CONTEST_TIMEFRAME',
            timeframe
          },
          key: 'editContestTimeframe'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: timeframe => {
      dispatch(
        openDialog({
          props: { client, contest, timeframe },
          key: 'removeContestTimeframe'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestTimeframes', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestTimeframes',
      options: { client, contest } 
    }));
  }, [client, contest]);
  
  return (
    <ResourceList
      actions={actions}
      fetching={contestFetching}
      list={contest?.timeframes}
      renderHeaders={() => 
        <div className="timeframe">
          <div className="timeframe-start">Pricing Timeframe Starts</div>
          <div className="timeframe-end">Pricing Timeframe Ends</div>
        </div>
      }
      renderItem={timeframe => 
        <div className="timeframe">
          <div className="timeframe-start">{timeframe.startDisplayDate}</div>
          <div className="timeframe-end">{timeframe.endDisplayDate}</div>
        </div>
      }
      renderKey="timeframeID"
      title="Pricing & Availability"
    />
  )
}

export default ContestTimeframes;