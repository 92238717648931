export const ACTIVE_CLOSE_MODAL = 'ACTIVE_CLOSE_MODAL';
export const ACTIVE_CLOSE_MODAL_SUCCESS = 'ACTIVE_CLOSE_MODAL_SUCCESS';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_MODAL_SUCCESS = 'CLOSE_MODAL_SUCCESS';

export const OPEN_MODAL = 'OPEN_MODAL';
export const OPEN_MODAL_SUCCESS = 'OPEN_MODAL_SUCCESS';

export function activeCloseModal() {
  return {
    type: ACTIVE_CLOSE_MODAL
  }
}

export function closeModal() {
  return {
    type: CLOSE_MODAL
  }
}

export function openModal (body) {
  return {
    body,
    type: OPEN_MODAL
  }
}
