import { call, put, takeEvery } from 'redux-saga/effects';
import handleFetch from 'src/utilities/fetch';
import * as actions from 'src/redux/actions/events';
import * as userActions from 'src/redux/actions/users';
import { toast } from 'react-toastify';

async function archiveEvent({ clientURL, eventURL }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/archive`, form: {} });
}

async function createEvent({ clientURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events`, form });
}

async function downloadEventRegistrations({ clientURL, eventURL, eventID }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/download-registrations`,
    download: `event_registrations_${eventID}.xlsx`
  });
}

async function getEvent({ clientURL, eventURL }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/edit` });
}

async function getEventList({ clientURL }) {
  return await handleFetch({ url: `/${clientURL}/events/edit` });
}

async function removeEventBannerImage({ clientURL, form }) {
  return await handleFetch({ url: `/clients/${clientURL}/banners/remove`, form });
}

async function removeEventCoordinator({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/coordinators/remove`, form });
}

async function removeEventDownloadableFile({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/files/remove`, form });
}

async function removeEventOptionalFee({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/fees/remove`, form });
}

async function removeEventPromoCode({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/promo-codes/remove`, form });
}

async function removeEventRegistration({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/transactions/remove`, form });
}

async function removeEventSlideshowImage({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/slideshow-images/remove`, form });
}

async function removeEventSponsorTier({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/sponsors/tiers/remove`, form });
}

async function removeEventSupportingProductTimeframe({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/supporting-product-timeframes/remove`, form });
}

async function removeEventTicket({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/tickets/remove`, form });
}

async function removeEventTimeframe({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/timeframes/remove`, form });
}

async function reorderEventCoordinators({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/coordinators/order`, form });
}

async function reorderEventOptionalFees({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/fees/order`, form });
}

async function reorderEventSlideshowImages({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/slideshow-images/order`, form });
}

async function reorderEventSponsorTiers({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/sponsors/tiers/order`, form });
}

async function reorderEventTickets({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/tickets/order`, form });
}

async function selectEventBannerImage({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/banners/select`, form });
}

async function sendEventNotifications({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/notifications`, form });
}

async function updateEventConfiguration({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/options`, form });
}

async function updateEventConfirmationEmail({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/confirmation-email`, form });
}

async function updateEventConnectedContest({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/contest`, form });
}

async function updateEventCoordinator({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/coordinators`, form });
}

async function updateEventDetails({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/details`, form });
}

async function updateEventLocation({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/location`, form });
}

async function updateEventName({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/info`, form });
}

async function updateEventOptionalFee({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/fees`, form });
}

async function updateEventPartnerPage({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/partner-page`, form });
}

async function updateEventPromoCode({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/promo-codes`, form });
}

async function updateEventRegistration({ clientURL, eventURL, transactionID, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/transactions/${transactionID}`, form });
}

async function updateEventRegistrationQuestions({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/registration-questions`, form });
}

async function updateEventSEO({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/seo`, form });
}

async function updateEventSponsorTier({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/sponsors/tiers`, form });
}

async function updateEventSponsorOptions({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/sponsor-options`, form });
}

async function updateEventSupportingProductTimeframe({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/supporting-product-timeframes`, form });
}

async function updateEventTicket({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/tickets`, form });
}

async function updateEventTimeframe({ clientURL, eventURL, form }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/timeframes`, form });
}

async function uploadEventBannerImage({ clientURL, file, handleProgress }) {
  return await handleFetch({ url: `/clients/${clientURL}/banners`, form: { file }, handleProgress });
}

async function uploadEventDownloadableFile({ clientURL, eventURL, file, handleProgress }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/files`, form: { file }, handleProgress });
}

async function uploadEventSlideshowImage({ clientURL, eventURL, file, handleProgress }) {
  return await handleFetch({ url: `/${clientURL}/events/${eventURL}/slideshow-images`, form: { file }, handleProgress });
}

function* archiveEventRequest(request) {
  try {
    const context = yield call(() => archiveEvent(request.body));
    yield put({ type: actions.ARCHIVE_EVENT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.ARCHIVE_EVENT_FAILED, error });
  }
}

function* createEventRequest(request) {
  try {
    const context = yield call(() => createEvent(request.body));
    yield put({ type: actions.CREATE_EVENT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CREATE_EVENT_FAILED, error });
  }
}

function* downloadEventRegistrationsRequest(request) {
  try {
    const context = yield call(() => downloadEventRegistrations(request.body));
    yield put({ type: actions.DOWNLOAD_EVENT_REGISTRATIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.DOWNLOAD_EVENT_REGISTRATIONS_FAILED, error });
  }
}

function* getEventRequest(request) {
  try {
    const context = yield call(() => getEvent(request));
    yield put({ type: actions.GET_EVENT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_EVENT_FAILED, error });
  }
}

function* getEventListRequest(request) {
  try {
    const context = yield call(() => getEventList(request));
    yield put({ type: actions.GET_EVENT_LIST_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_EVENT_LIST_FAILED, error });
  }
}

function* removeEventBannerImageRequest(request) {
  try {
    const context = yield call(() => removeEventBannerImage(request.body));
    yield put({ type: actions.REMOVE_EVENT_BANNER_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_EVENT_BANNER_IMAGE_FAILED, error });
  }
}

function* removeEventCoordinatorRequest(request) {
  try {
    const context = yield call(() => removeEventCoordinator(request.body));
    yield put({ type: actions.REMOVE_EVENT_COORDINATOR_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_EVENT_COORDINATOR_FAILED, error });
  }
}

function* removeEventDownloadableFileRequest(request) {
  try {
    const context = yield call(() => removeEventDownloadableFile(request.body));
    yield put({ type: actions.REMOVE_EVENT_DOWNLOADABLE_FILE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_EVENT_DOWNLOADABLE_FILE_FAILED, error });
  }
}

function* removeEventOptionalFeeRequest(request) {
  try {
    const context = yield call(() => removeEventOptionalFee(request.body));
    yield put({ type: actions.REMOVE_EVENT_OPTIONAL_FEE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_EVENT_OPTIONAL_FEE_FAILED, error });
  }
}

function* removeEventRegistrationRequest(request) {
  try {
    const context = yield call(() => removeEventRegistration(request.body));
    yield put({ type: actions.REMOVE_EVENT_REGISTRATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_EVENT_REGISTRATION_FAILED, error });
  }
}

function* removeEventSlideshowImageRequest(request) {
  try {
    const context = yield call(() => removeEventSlideshowImage(request.body));
    yield put({ type: actions.REMOVE_EVENT_SLIDESHOW_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_EVENT_SLIDESHOW_IMAGE_FAILED, error });
  }
}

function* removeEventSponsorTierRequest(request) {
  try {
    const context = yield call(() => removeEventSponsorTier(request.body));
    yield put({ type: actions.REMOVE_EVENT_SPONSOR_TIER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_EVENT_SPONSOR_TIER_FAILED, error });
  }
}

function* removeEventSupportingProductTimeframeRequest(request) {
  try {
    const context = yield call(() => removeEventSupportingProductTimeframe(request.body));
    yield put({ type: actions.REMOVE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_FAILED, error });
  }
}

function* removeEventTicketRequest(request) {
  try {
    const context = yield call(() => removeEventTicket(request.body));
    yield put({ type: actions.REMOVE_EVENT_TICKET_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_EVENT_TICKET_FAILED, error });
  }
}

function* removeEventTimeframeRequest(request) {
  try {
    const context = yield call(() => removeEventTimeframe(request.body));
    yield put({ type: actions.REMOVE_EVENT_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_EVENT_TIMEFRAME_FAILED, error });
  }
}

function* reorderEventCoordinatorsRequest(request) {
  try {
    const context = yield call(() => reorderEventCoordinators(request.body));
    yield put({ type: actions.REORDER_EVENT_COORDINATORS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_EVENT_COORDINATORS_FAILED, error });
  }
}

function* reorderEventOptionalFeesRequest(request) {
  try {
    const context = yield call(() => reorderEventOptionalFees(request.body));
    yield put({ type: actions.REORDER_EVENT_OPTIONAL_FEES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_EVENT_OPTIONAL_FEES_FAILED, error });
  }
}

function* removeEventPromoCodeRequest(request) {
  try {
    const context = yield call(() => removeEventPromoCode(request.body));
    yield put({ type: actions.REMOVE_EVENT_PROMO_CODE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_EVENT_PROMO_CODE_FAILED, error });
  }
}

function* reorderEventSlideshowImagesRequest(request) {
  try {
    const context = yield call(() => reorderEventSlideshowImages(request.body));
    yield put({ type: actions.REORDER_EVENT_SLIDESHOW_IMAGES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_EVENT_SLIDESHOW_IMAGES_FAILED, error });
  }
}

function* reorderEventSponsorTiersRequest(request) {
  try {
    const context = yield call(() => reorderEventSponsorTiers(request.body));
    yield put({ type: actions.REORDER_EVENT_SPONSOR_TIERS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_EVENT_SPONSOR_TIERS_FAILED, error });
  }
}

function* reorderEventTicketsRequest(request) {
  try {
    const context = yield call(() => reorderEventTickets(request.body));
    yield put({ type: actions.REORDER_EVENT_TICKETS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_EVENT_TICKETS_FAILED, error });
  }
}

function* resetEventURLChanged() {
  yield put({ type: actions.RESET_EVENT_URL_CHANGED_SUCCESS });
}

function* selectEventBannerImageRequest(request) {
  try {
    const context = yield call(() => selectEventBannerImage(request.body));
    yield put({ type: actions.SELECT_EVENT_BANNER_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.SELECT_EVENT_BANNER_IMAGE_FAILED, error });
  }
}

function* sendEventNotificationsRequest(request) {
  try {
    const context = yield call(() => sendEventNotifications(request.body));
    yield put({ type: actions.SEND_EVENT_NOTIFICATIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.SEND_EVENT_NOTIFICATIONS_FAILED, error });
  }
}

function* updateEventConfigurationRequest(request) {
  try {
    const context = yield call(() => updateEventConfiguration(request.body));
    yield put({ type: actions.UPDATE_EVENT_CONFIGURATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_CONFIGURATION_FAILED, error });
  }
}

function* updateEventConfirmationEmailRequest(request) {
  try {
    const context = yield call(() => updateEventConfirmationEmail(request.body));
    yield put({ type: actions.UPDATE_EVENT_CONFIRMATION_EMAIL_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_CONFIRMATION_EMAIL_FAILED, error });
  }
}

function* updateEventConnectedContestRequest(request) {
  try {
    const context = yield call(() => updateEventConnectedContest(request.body));
    yield put({ type: actions.UPDATE_EVENT_CONNECTED_CONTEST_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_CONNECTED_CONTEST_FAILED, error });
  }
}

function* updateEventCoordinatorRequest(request) {
  try {
    const context = yield call(() => updateEventCoordinator(request.body));
    yield put({ type: actions.UPDATE_EVENT_COORDINATOR_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_COORDINATOR_FAILED, error });
  }
}

function* updateEventDetailsRequest(request) {
  try {
    const context = yield call(() => updateEventDetails(request.body));
    yield put({ type: actions.UPDATE_EVENT_DETAILS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_DETAILS_FAILED, error });
  }
}

function* updateEventLocationRequest(request) {
  try {
    const context = yield call(() => updateEventLocation(request.body));
    yield put({ type: actions.UPDATE_EVENT_LOCATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_LOCATION_FAILED, error });
  }
}

function* updateEventNameRequest(request) {
  try {
    const context = yield call(() => updateEventName(request.body));
    yield put({ type: actions.UPDATE_EVENT_NAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_NAME_FAILED, error });
  }
}

function* updateEventOptionalFeeRequest(request) {
  try {
    const context = yield call(() => updateEventOptionalFee(request.body));
    yield put({ type: actions.UPDATE_EVENT_OPTIONAL_FEE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_OPTIONAL_FEE_FAILED, error });
  }
}

function* updateEventPartnerPageRequest(request) {
  try {
    const context = yield call(() => updateEventPartnerPage(request.body));
    yield put({ type: actions.UPDATE_EVENT_PARTNER_PAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_PARTNER_PAGE_FAILED, error });
  }
}

function* updateEventPromoCodeRequest(request) {
  try {
    const context = yield call(() => updateEventPromoCode(request.body));
    yield put({ type: actions.UPDATE_EVENT_PROMO_CODE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_PROMO_CODE_FAILED, error });
  }
}

function* updateEventRegistrationRequest(request) {
  try {
    const context = yield call(() => updateEventRegistration(request.body));
    yield put({ type: actions.UPDATE_EVENT_REGISTRATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_REGISTRATION_FAILED, error });
  }
}

function* updateEventRegistrationQuestionsRequest(request) {
  try {
    const context = yield call(() => updateEventRegistrationQuestions(request.body));
    yield put({ type: actions.UPDATE_EVENT_REGISTRATION_QUESTIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_REGISTRATION_QUESTIONS_FAILED, error });
  }
}

function* updateEventSEORequest(request) {
  try {
    const context = yield call(() => updateEventSEO(request.body));
    yield put({ type: actions.UPDATE_EVENT_SEO_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_SEO_FAILED, error });
  }
}

function* updateEventSponsorTierRequest(request) {
  try {
    const context = yield call(() => updateEventSponsorTier(request.body));
    yield put({ type: actions.UPDATE_EVENT_SPONSOR_TIER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_SPONSOR_TIER_FAILED, error });
  }
}

function* updateEventSponsorOptionsRequest(request) {
  try {
    const context = yield call(() => updateEventSponsorOptions(request.body));
    yield put({ type: actions.UPDATE_EVENT_SPONSOR_OPTIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_SPONSOR_OPTIONS_FAILED, error });
  }
}

function* updateEventTicketRequest(request) {
  try {
    const context = yield call(() => updateEventTicket(request.body));
    yield put({ type: actions.UPDATE_EVENT_TICKET_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_TICKET_FAILED, error });
  }
}

function* updateEventSupportingProductTimeframeRequest(request) {
  try {
    const context = yield call(() => updateEventSupportingProductTimeframe(request.body));
    yield put({ type: actions.UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_FAILED, error });
  }
}

function* updateEventTimeframeRequest(request) {
  try {
    const context = yield call(() => updateEventTimeframe(request.body));
    yield put({ type: actions.UPDATE_EVENT_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_EVENT_TIMEFRAME_FAILED, error });
  }
}

function* uploadEventBannerImageRequest(request) {
  try {
    const context = yield call(() => uploadEventBannerImage(request.body));
    yield put({ type: actions.UPLOAD_EVENT_BANNER_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPLOAD_EVENT_BANNER_IMAGE_FAILED, error });
  }
}

function* uploadEventDownloadableFileRequest(request) {
  try {
    const context = yield call(() => uploadEventDownloadableFile(request.body));
    yield put({ type: actions.UPLOAD_EVENT_DOWNLOADABLE_FILE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPLOAD_EVENT_DOWNLOADABLE_FILE_FAILED, error });
  }
}

function* uploadEventSlideshowImageRequest(request) {
  try {
    const context = yield call(() => uploadEventSlideshowImage(request.body));
    yield put({ type: actions.UPLOAD_EVENT_SLIDESHOW_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPLOAD_EVENT_SLIDESHOW_IMAGE_FAILED, error });
  }
}

function* apiFailed(request) {
  toast.error(request.error.message);
  if (request.error.status === 403) {
    yield put({ type: userActions.CLEAR_USER });
  }
}

function* eventSagas() {
  yield takeEvery(actions.ARCHIVE_EVENT_REQUEST, archiveEventRequest);
  yield takeEvery(actions.CREATE_EVENT_REQUEST, createEventRequest);
  yield takeEvery(actions.DOWNLOAD_EVENT_REGISTRATIONS_REQUEST, downloadEventRegistrationsRequest);
  yield takeEvery(actions.GET_EVENT_REQUEST, getEventRequest);
  yield takeEvery(actions.GET_EVENT_LIST_REQUEST, getEventListRequest);
  yield takeEvery(actions.REMOVE_EVENT_BANNER_IMAGE_REQUEST, removeEventBannerImageRequest);
  yield takeEvery(actions.REMOVE_EVENT_COORDINATOR_REQUEST, removeEventCoordinatorRequest);
  yield takeEvery(actions.REMOVE_EVENT_DOWNLOADABLE_FILE_REQUEST, removeEventDownloadableFileRequest);
  yield takeEvery(actions.REMOVE_EVENT_OPTIONAL_FEE_REQUEST, removeEventOptionalFeeRequest);
  yield takeEvery(actions.REMOVE_EVENT_PROMO_CODE_REQUEST, removeEventPromoCodeRequest);
  yield takeEvery(actions.REMOVE_EVENT_REGISTRATION_REQUEST, removeEventRegistrationRequest);
  yield takeEvery(actions.REMOVE_EVENT_SLIDESHOW_IMAGE_REQUEST, removeEventSlideshowImageRequest);
  yield takeEvery(actions.REMOVE_EVENT_SPONSOR_TIER_REQUEST, removeEventSponsorTierRequest);
  yield takeEvery(actions.REMOVE_EVENT_TICKET_REQUEST, removeEventTicketRequest);
  yield takeEvery(actions.REMOVE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST, removeEventSupportingProductTimeframeRequest);
  yield takeEvery(actions.REMOVE_EVENT_TIMEFRAME_REQUEST, removeEventTimeframeRequest);
  yield takeEvery(actions.REORDER_EVENT_COORDINATORS_REQUEST, reorderEventCoordinatorsRequest);
  yield takeEvery(actions.REORDER_EVENT_OPTIONAL_FEES_REQUEST, reorderEventOptionalFeesRequest);
  yield takeEvery(actions.REORDER_EVENT_SLIDESHOW_IMAGES_REQUEST, reorderEventSlideshowImagesRequest);
  yield takeEvery(actions.REORDER_EVENT_SPONSOR_TIERS_REQUEST, reorderEventSponsorTiersRequest);
  yield takeEvery(actions.REORDER_EVENT_TICKETS_REQUEST, reorderEventTicketsRequest);
  yield takeEvery(actions.RESET_EVENT_URL_CHANGED, resetEventURLChanged);
  yield takeEvery(actions.SELECT_EVENT_BANNER_IMAGE_REQUEST, selectEventBannerImageRequest);
  yield takeEvery(actions.SEND_EVENT_NOTIFICATIONS_REQUEST, sendEventNotificationsRequest);
  yield takeEvery(actions.UPDATE_EVENT_CONFIGURATION_REQUEST, updateEventConfigurationRequest);
  yield takeEvery(actions.UPDATE_EVENT_CONFIRMATION_EMAIL_REQUEST, updateEventConfirmationEmailRequest);
  yield takeEvery(actions.UPDATE_EVENT_CONNECTED_CONTEST_REQUEST, updateEventConnectedContestRequest);
  yield takeEvery(actions.UPDATE_EVENT_COORDINATOR_REQUEST, updateEventCoordinatorRequest);
  yield takeEvery(actions.UPDATE_EVENT_DETAILS_REQUEST, updateEventDetailsRequest);
  yield takeEvery(actions.UPDATE_EVENT_LOCATION_REQUEST, updateEventLocationRequest);
  yield takeEvery(actions.UPDATE_EVENT_NAME_REQUEST, updateEventNameRequest);
  yield takeEvery(actions.UPDATE_EVENT_OPTIONAL_FEE_REQUEST, updateEventOptionalFeeRequest);
  yield takeEvery(actions.UPDATE_EVENT_PARTNER_PAGE_REQUEST, updateEventPartnerPageRequest);
  yield takeEvery(actions.UPDATE_EVENT_PROMO_CODE_REQUEST, updateEventPromoCodeRequest);
  yield takeEvery(actions.UPDATE_EVENT_REGISTRATION_REQUEST, updateEventRegistrationRequest);
  yield takeEvery(actions.UPDATE_EVENT_REGISTRATION_QUESTIONS_REQUEST, updateEventRegistrationQuestionsRequest);
  yield takeEvery(actions.UPDATE_EVENT_SEO_REQUEST, updateEventSEORequest);
  yield takeEvery(actions.UPDATE_EVENT_SPONSOR_TIER_REQUEST, updateEventSponsorTierRequest);
  yield takeEvery(actions.UPDATE_EVENT_SPONSOR_OPTIONS_REQUEST, updateEventSponsorOptionsRequest);
  yield takeEvery(actions.UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST, updateEventSupportingProductTimeframeRequest);
  yield takeEvery(actions.UPDATE_EVENT_TICKET_REQUEST, updateEventTicketRequest);
  yield takeEvery(actions.UPDATE_EVENT_TIMEFRAME_REQUEST, updateEventTimeframeRequest);
  yield takeEvery(actions.UPLOAD_EVENT_BANNER_IMAGE_REQUEST, uploadEventBannerImageRequest);
  yield takeEvery(actions.UPLOAD_EVENT_DOWNLOADABLE_FILE_REQUEST, uploadEventDownloadableFileRequest);
  yield takeEvery(actions.UPLOAD_EVENT_SLIDESHOW_IMAGE_REQUEST, uploadEventSlideshowImageRequest);

  yield takeEvery(actions.ARCHIVE_EVENT_FAILED, apiFailed);
  yield takeEvery(actions.CREATE_EVENT_FAILED, apiFailed);
  yield takeEvery(actions.DOWNLOAD_EVENT_REGISTRATIONS_FAILED, apiFailed);
  yield takeEvery(actions.GET_EVENT_FAILED, apiFailed);
  yield takeEvery(actions.GET_EVENT_LIST_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_EVENT_BANNER_IMAGE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_EVENT_COORDINATOR_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_EVENT_DOWNLOADABLE_FILE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_EVENT_OPTIONAL_FEE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_EVENT_PROMO_CODE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_EVENT_REGISTRATION_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_EVENT_SLIDESHOW_IMAGE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_EVENT_SPONSOR_TIER_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_EVENT_TICKET_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_EVENT_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_EVENT_COORDINATORS_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_EVENT_OPTIONAL_FEES_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_EVENT_SLIDESHOW_IMAGES_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_EVENT_SPONSOR_TIERS_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_EVENT_TICKETS_FAILED, apiFailed);
  yield takeEvery(actions.SELECT_EVENT_BANNER_IMAGE_FAILED, apiFailed);
  yield takeEvery(actions.SEND_EVENT_NOTIFICATIONS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_CONFIGURATION_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_CONFIRMATION_EMAIL_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_CONNECTED_CONTEST_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_COORDINATOR_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_DETAILS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_LOCATION_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_NAME_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_OPTIONAL_FEE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_PARTNER_PAGE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_PROMO_CODE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_REGISTRATION_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_REGISTRATION_QUESTIONS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_SEO_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_SPONSOR_TIER_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_SPONSOR_OPTIONS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_TICKET_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_EVENT_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.UPLOAD_EVENT_BANNER_IMAGE_FAILED, apiFailed);
  yield takeEvery(actions.UPLOAD_EVENT_DOWNLOADABLE_FILE_FAILED, apiFailed);
  yield takeEvery(actions.UPLOAD_EVENT_SLIDESHOW_IMAGE_FAILED, apiFailed);
}

export default eventSagas;
