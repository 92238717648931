import React from 'react';
import { useDispatch } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateParadeDateRequest } from 'src/redux/actions/parades';
import {
  getFirstMinuteToday,
  getLastMinuteToday,
  getToday,
  parseDate,
  parseTime
} from 'src/utilities/dates';

const ParadeDateForm = ({ client, date, handleClose, parade, restrictedDates }) => {
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    const newDate = parseDate({ date: event.date });
    const startTime = parseTime({ date: event.startTime });
    const endTime = parseTime({ date: event.endTime });
    dispatch(updateParadeDateRequest({
      clientURL: client.url,
      paradeURL: parade.url,
      form: {
        ...date,
        startDate: `${newDate}T${startTime}`,
        endDate: `${newDate}T${endTime}`
      }
    }));
  }

  const configuration = {
    formElements: {
      rows: [{
        heading: 'Select a start/end time for a single day:'
      }, {
        elements: [{
          label: 'Date',
          class: 'flex-2',
          name: 'date',
          timeRangeEnd: 'endTime',
          timeRangeStart: 'startTime',
          type: fieldTypes.input,
          inputType: inputTypes.date,
          value: date?.startDate || getToday(),
          restrictedDates,
          required: true
        }, {
          label: 'Start Time',
          name: 'startTime',
          timeRangeDate: 'date',
          timeRangeEnd: 'endTime',
          type: fieldTypes.input,
          inputType: inputTypes.time,
          value: date?.startDate || getFirstMinuteToday(),
          restrictedDates,
          required: true
        }, {
          label: 'End Time',
          name: 'endTime',
          timeRangeDate: 'date',
          timeRangeStart: 'startTime',
          type: fieldTypes.input,
          inputType: inputTypes.time,
          value: date?.endDate || getLastMinuteToday(),
          restrictedDates,
          required: true
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="parade-date-form">
      {
        configuration && (
          <AutoForm
            configuration={configuration}
            handleCancel={handleClose}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_PARADE_DATE'}
          />
        )
      }
    </section>
  )
}

export default ParadeDateForm;
