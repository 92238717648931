import React from 'react';
import { cloneDeep } from 'lodash';

const ViewScoringProgress = ({ scoringProgress }) => {
  const criteria = cloneDeep(scoringProgress.scoresheetCriteria);

  let totalPossible = 0;
  let totalScored = 0;
  let totalNA = 0;

  for (let i = 0; i < criteria.length; i++) {
    if (criteria[i].name === 'comment') {
      continue;
    }

    totalPossible += criteria[i].maximumValue;

    if (criteria[i].value < 0) {
      totalNA += criteria[i].maximumValue;
    } else {
      totalScored += criteria[i].value;
    }
  }

  
  criteria.splice((criteria.length - 1), 0, {
    label: 'Total',
    maximumValue: totalPossible - totalNA,
    name: 'total',
    value: totalScored
  })

  const renderRow = ({ criteria }) => {
    if (criteria.name === 'comment') {
      return (
        <div className="view-scoring-progress-criteria-comment">Comment: {criteria.value || '[None]'}</div>
      )
    }

    return (
      <>
        <div className={`view-scoring-progress-criteria-label${criteria.value < 0 ? ' strike' : ''}`}>
          {criteria.label}
        </div>
        <div className="view-scoring-progress-criteria-points">{
          criteria.value < 0 ? 'NA' : `${criteria.value} / ${criteria.maximumValue}`
        }</div>
      </>
    );
  }

  return (
    <section className="view-scoring-progress">
      {
        criteria.map(criteria => {
          return (
            <div
              className={`view-scoring-progress-criteria${criteria.name === 'total' ? ' total' : ''}`}
              key={criteria.name}
            >
              { renderRow({ criteria }) }
            </div>
          )
        })
      }
    </section>
  )
}

export default ViewScoringProgress;
