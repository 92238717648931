import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateParadeOptionalFeeRequest } from 'src/redux/actions/parades';

const ParadeOptionalFeeForm = ({ fee, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const dispatch = useDispatch();

  const handleSubmit = (evt) => {
    evt.sequence = evt.sequence || parade.fees?.length || 0;
    dispatch(updateParadeOptionalFeeRequest({ clientURL: client.url, paradeURL: parade.url, form: {...(fee || {}), ...evt} }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Fee Name',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: fee?.name || '',
          required: true
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="optional-fee-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_PARADE_OPTIONAL_FEE'}
      />
    </section>
  )
}

export default ParadeOptionalFeeForm;
