import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes, formFieldPatterns } from 'src/constants';
import { updateEventCoordinatorRequest } from 'src/redux/actions/events';

const ManualEventCoordinator = ({ coordinator, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const dispatch = useDispatch();

  const isEmail = formFieldPatterns.email.test(coordinator?.typedValue || '');
  const handleSubmit = (form) => {
    dispatch(updateEventCoordinatorRequest({ clientURL: client.url, eventURL: event.url, form: {...coordinator, ...form} }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Name',
          name: 'fullName',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: coordinator?.typedValue ? (isEmail ? '' : coordinator?.typedValue) : coordinator?.fullName,
          required: true
        }]
      }, {
        elements: [{
          label: 'Email',
          name: 'email',
          type: fieldTypes.input,
          inputType: inputTypes.email,
          value: coordinator?.typedValue ? (isEmail ? coordinator?.typedValue : '') : coordinator.email,
        }]
      }, {
        elements: [{
          label: 'Phone',
          name: 'phone',
          type: fieldTypes.input,
          inputType: inputTypes.phone,
          value: coordinator?.phone || ''
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="manual-event-coordinator">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_EVENT_COORDINATOR'}
      />
    </section>
  )
}

export default ManualEventCoordinator;
