import Cookies from 'js-cookie';

const windowExists = () => {
  return typeof window?.localStorage === 'object';
}

export const getCookie = ({ key }) => {
  let storedValue = Cookies.get(key);

  if (!storedValue && windowExists()) {
    storedValue = window.localStorage.getItem(key);
  }

  return storedValue;
}

export const removeCookie = ({ key }) => {
  Cookies.remove(key);

  if (windowExists()) {
    window.localStorage.removeItem(key);
  }
}

export const setCookie = ({ key, options = {}, value }) => {
  if (!value) {
    return;
  }

  Cookies.set(key, value, options);
  const storedValue = Cookies.get(key);

  if (storedValue !== value || !storedValue) {
    if (windowExists()) {
      window.localStorage.setItem(key, value);
    }
  }
}
