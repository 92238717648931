import React, { useRef, useState } from 'react';
import moment from 'moment';
import { inputTypes } from 'src/constants';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  TextField
} from '@mui/material';
import CurrencyField from 'src/components/AutoForm/CurrencyField';
import InputMask from 'src/components/AutoForm/InputField/InputMask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const InputField = ({
  element,
  enterKeypress,
  error,
  handleChange,
  handleBlur,
  value
}) => {
  const [touched, setTouched] = useState(false);
  const [showPassword, setShowPassword] = useState();
  const ref = useRef();
  const handleTouched = () => {
    setTouched(true);
  };

  const handleKeypress = (event) => {
    if (typeof enterKeypress === 'function') {
      enterKeypress(event);
    }
  }

  const inputs = [
    inputTypes.categoryLabel,
    inputTypes.promoCode,
    inputTypes.domain,
    inputTypes.email,
    inputTypes.text,
    inputTypes.url,
    inputTypes.website
  ];

  return (
    <div className={`form-field ${element.class || 'flex-1'}`}>
      <label>
        <span>
          {element.required && (<span>* </span>)}
          {element.label}
        </span>
      </label>
      {
        inputs.includes(element.inputType) && (
        <TextField
          autoComplete={element.autoComplete || 'off'}
          disabled={element.disabled}
          error={!!(touched && error)}
          helperText={touched && error ? error : null}
          id={element.name}
          name={element.name}
          onChange={e => {handleChange(e.target.value, element)}}
          onBlur={handleBlur}
          onFocus={handleTouched}
          onKeyUp={handleKeypress}
          placeholder={element.placeholder}
          required={element.required}
          type="text"
          value={value || ''}
        />
      )}
      {
        (element.inputType === inputTypes.number || element.inputType === inputTypes.zip) && (
          <TextField
            autoComplete={element.autoComplete || 'off'}
            disabled={element.disabled}
            error={!!(touched && error)}
            helperText={touched && error ? error : null}
            id={element.name}
            name={element.name}
            onChange={e => {handleChange(e.target.value, element)}}
            onBlur={handleBlur}
            onFocus={handleTouched}
            onKeyUp={handleKeypress}
            required={element.required}
            type="number"
            value={value || ''}
          />
        )
      }
      {element.inputType === inputTypes.phone && (
        <FormControl variant="standard">
          <OutlinedInput
            autoComplete={element.autoComplete || 'off'}
            className="input-phone"
            disabled={element.disabled}
            error={!!(touched && error)}
            id={element.name}
            inputComponent={InputMask}
            onChange={e => {handleChange(e.target.value, element)}}
            onFocus={handleTouched}
            name={element.name}
            value={value || ''}
          />
          {
            (error && touched) && (
              <FormHelperText className="Mui-error">{error}</FormHelperText>
            )
          }
        </FormControl>
      )}
      {
        element.inputType === inputTypes.currency && (
          <TextField
            className="currency"
            disabled={element.disabled}
            error={!!(touched && error)}
            helperText={touched && error ? error : null}
            id={element.name}
            InputProps={{
              inputComponent: CurrencyField,
              inputRef: ref
            }}
            name={element.name}
            onChange={e => {handleChange(e.target.value, element)}}
            onBlur={handleBlur}
            onFocus={handleTouched}
            required={element.required}
            type="text"
            value={value}
          />
      )}
      {element.inputType === inputTypes.date && (
        <div onFocus={handleTouched}>
          <DatePicker
            autoComplete={element.autoComplete || 'off'}
            className={`date-picker-container${error ? ' error' : ''}${touched ? ' touched' : ''}`}
            id={element.name}
            name={element.name}
            onChange={value => {handleChange(value, element)}}
            slotProps={{
              field: { clearable: true },
              textField: {
                error: !!(touched && error),
                helperText: touched && error,
                onChange: value => {handleChange(value, element)},
                required: element.required
              },
            }}
            type="text"
            value={moment.utc(value)}
          />
        </div>
      )}
      {element.inputType === inputTypes.dateTime && (
        <div onFocus={handleTouched}>
          <DateTimePicker
            autoComplete={element.autoComplete || 'off'}
            className={`date-time-picker-container${error ? ' error' : ''}${touched ? ' touched' : ''}`}
            id={element.name}
            name={element.name}
            onChange={value => {handleChange(value, element)}}
            slotProps={{
              field: { clearable: true },
              textField: {
                error: !!(touched && error),
                helperText: touched && error,
                onChange: value => {handleChange(value, element)},
                required: element.required
              },
            }}
            type="text"
            value={moment.utc(value)}
          />
        </div>
      )}
      {element.inputType === inputTypes.time && (
        <div onFocus={handleTouched}>
          <TimePicker
            autoComplete={element.autoComplete || 'off'}
            className={`time-picker-container${error ? ' error' : ''}${touched ? ' touched' : ''}`}
            id={element.name}
            name={element.name}
            onChange={value => {handleChange(value, element)}}
            slotProps={{
              textField: {
                error: !!(touched && error),
                helperText: touched && error,
                onChange: value => {handleChange(value, element)},
                required: element.required
              },
            }}
            type="text"
            value={moment.utc(value)}
          />
        </div>
      )}
      {element.inputType === inputTypes.password && (
        <TextField
          error={!!(touched && error)}
          helperText={touched && error ? error : null}
          id={element.name}
          InputProps={{
            autoComplete: element.autoComplete || 'new-password',
            endAdornment: element.permitShowPassword && <section
              className="show-password-toggle"
              onClick={() => setShowPassword(!showPassword)}
            >
              <InputAdornment position="end">{
                showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />
              }</InputAdornment>
            </section>
          }}
          name={element.name}
          onChange={e => {handleChange(e.target.value, element)}}
          onBlur={handleBlur}
          onFocus={handleTouched}
          onKeyUp={handleKeypress}
          required={element.required}
          type={showPassword ? "text" : "password"}
          value={value || ''}
        />
      )}
      {element.inputType === inputTypes.passwordAny && (
        <TextField
          error={!!(touched && error)}
          helperText={touched && error ? error : null}
          id={element.name}
          InputProps={{
            autoComplete: element.autoComplete || 'new-password',
            endAdornment: element.permitShowPassword && <section
              className="show-password-toggle"
              onClick={() => setShowPassword(!showPassword)}
            >
              <InputAdornment position="end">{
                showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />
              }</InputAdornment>
            </section>
          }}
          name={element.name}
          onChange={e => {handleChange(e.target.value, element)}}
          onBlur={handleBlur}
          onFocus={handleTouched}
          onKeyUp={handleKeypress}
          required={element.required}
          type={showPassword ? "text" : "password"}
          value={value || ''}
        />
      )}
    </div>
  )
}

export default InputField;
