import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup
} from '@mui/material';

const RadioField = ({
  element,
  handleChange,
  handleBlur,
  value
}) => {
  return (
    <div className={`form-field ${element.class || 'flex-1'}`}>
      <label>{element.label}</label>
      <FormControl>
        <RadioGroup
          id={element.name}
          name={element.name}
          onBlur={handleBlur}
          onChange={e => handleChange(e.target.value, element)}
          value={typeof value === 'undefined' ? '' : value}
        >
          {
            (element.options || []).map(option => {
              return (
                <FormControlLabel
                  control={<Radio />}
                  disabled={element.disabled}
                  key={option.value}
                  label={option.label}
                  value={option.value}
                />
              )
            })
          }
        </RadioGroup>
      </FormControl>
      {
        element.afterward && <FormHelperText>{element.afterward}</FormHelperText>
      }
      
    </div>
  )
}

export default RadioField;
