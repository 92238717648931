import appStore from './app';
import breadcrumbStore from './breadcrumbs';
import clientStore from './client';
import completedStore from './completed';
import contestStore from './contests';
import dialogStore from './dialog';
import drawerContentStore from './drawer';
import eventStore from './events';
import loadingStore from './loading';
import modalStore from './modal';
import paradeStore from './parades';
import surveyStore from './surveys';
import searchStore from './search';
import userStore from './users';

const rootReducer = {
  appStore,
  breadcrumbStore,
  clientStore,
  completedStore,
  contestStore,
  dialogStore,
  drawerContentStore,
  eventStore,
  loadingStore,
  modalStore,
  paradeStore,
  surveyStore,
  searchStore,
  userStore
};

export default rootReducer;
