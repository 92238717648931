import * as actions from 'src/redux/actions/events';
import { toast } from 'react-toastify';
import sortBy from 'src/utilities/sort';
import { cloneDeep } from 'lodash';
import { supportingProductTypes } from 'src/constants';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.ARCHIVE_EVENT_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      if (action.context?.event.eventID) {
        const updatedEvents = [...state?.events];
        const index = updatedEvents.findIndex(event => event.eventID === action.context.event.eventID);
        if (index !== -1) {
          updatedEvents[index] = {...updatedEvents[index], ...action.context.event};
          toast.success('Event archive status updated.');
        }
        return { ...state, events: updatedEvents };
      }

      return state;

    case actions.CREATE_EVENT_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('Event created.');

      return { ...state, events: [action.context.event, ...state.events] };

    case actions.GET_EVENT_REQUEST:
      return { ...state, event: null };

    case actions.GET_EVENT_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return action.context?.event ? { ...state, event: action.context.event, contest: null, parade: null } : state;

    case actions.GET_EVENT_LIST_REQUEST:
        return { ...state, events: [] };

    case actions.GET_EVENT_LIST_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

    return action.context?.events ? { ...state, events: action.context.events } : state;

      case actions.REMOVE_EVENT_BANNER_IMAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.bannerID) {
        toast.success('Banner image removed.');
        const updatedBannerImages = state.event.bannerImages.filter(bannerImage => bannerImage.bannerID !== action.context.bannerID);

        return { ...state, event: { ...state.event, bannerImages: updatedBannerImages } };
      }

      return state;

    case actions.REMOVE_EVENT_COORDINATOR_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.coordinator) {
        toast.success('Event coordinator removed.');
        const updatedCoordinators = state.event.coordinators.filter(coordinator => {
          return coordinator.coordinatorID !== action.context.coordinator.coordinatorID;
        });
        return { ...state, event: { ...state.event, coordinators: updatedCoordinators } };
      }

      return state;

    case actions.REMOVE_EVENT_DOWNLOADABLE_FILE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.file) {
        toast.success('Downloadable file removed.');
        const updatedFiles = state.event.files.filter(file => {
          return file.fileID !== action.context.file.fileID;
        });
        return { ...state, event: { ...state.event, files: updatedFiles } };
      }

      return state;

    case actions.REMOVE_EVENT_OPTIONAL_FEE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.feeID) {
        toast.success('Event optional fee removed.');
        const updatedFees = state.event.fees.filter(fee => {
          return fee.feeID !== action.context.feeID;
        });
        return { ...state, event: { ...state.event, fees: updatedFees } };
      }

      return state;

    case actions.REMOVE_EVENT_PROMO_CODE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.promoCodeID) {
        toast.success('Event promo code removed.');
        const updatedPromoCodes = state.event.promoCodes.filter(promoCode => {
          return promoCode.promoCodeID !== action.context.promoCodeID;
        });
        return { ...state, event: { ...state.event, promoCodes: updatedPromoCodes } };
      }

      return state;

    case actions.REMOVE_EVENT_REGISTRATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
    
      if (action.context?.transactionID) {
        toast.success('Event registration removed.');
        const updatedRegistrations = state.event.registrations.filter(registration => {
          return registration.transactionID !== action.context.transactionID;
        });
        return { ...state, event: { ...state.event, registrations: updatedRegistrations } };
      }
    
      return state;
    
    case actions.REMOVE_EVENT_SLIDESHOW_IMAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.slideshowImageID) {
        toast.success('Slideshow image removed.');
        const updatedSlideshowImages = state.event.slideshowImages.filter(slideshowImage => {
          return slideshowImage.slideshowImageID !== action.context.slideshowImageID;
        });
        return { ...state, event: { ...state.event, slideshowImages: updatedSlideshowImages } };
      }

      return state;

    case actions.REMOVE_EVENT_SPONSOR_TIER_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.sponsorTierID) {
        toast.success('Event sponsor tier removed.');
        const updatedSponsorTiers = state.event.sponsorTiers.filter(sponsorTier => {
          return sponsorTier.sponsorTierID !== action.context.sponsorTierID;
        });
        return { ...state, event: { ...state.event, sponsorTiers: updatedSponsorTiers } };
      }

      return state;

    case actions.REMOVE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.timeframeID && action.context?.type === supportingProductTypes.sponsor) {
        toast.success('Event sponsor timeframe removed.');
        const updatedTimeframes = state.event.sponsorshipTimeframes.filter(timeframe => {
          return timeframe.timeframeID !== action.context.timeframeID;
        });
        return { ...state, event: { ...state.event, sponsorshipTimeframes: updatedTimeframes } };
      }

      return state;

    case actions.REMOVE_EVENT_TICKET_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.ticketID) {
        toast.success('Event ticket removed.');
        const updatedTickets = state.event.tickets.filter(ticket => {
          return ticket.ticketID !== action.context.ticketID;
        });
        return { ...state, event: { ...state.event, tickets: updatedTickets } };
      }

      return state;

    case actions.REMOVE_EVENT_TIMEFRAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.timeframeID) {
        toast.success('Event timeframe removed.');
        const updatedTimeframes = state.event.timeframes.filter(timeframe => {
          return timeframe.timeframeID !== action.context.timeframeID;
        });
        return { ...state, event: { ...state.event, timeframes: updatedTimeframes } };
      }

      return state;

    case actions.REORDER_EVENT_COORDINATORS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.coordinators) {
        toast.success('Event coordinator order updated.');
        return { ...state, event: { ...state.event, coordinators: action.context?.coordinators } };
      }

      return state;

    case actions.REORDER_EVENT_OPTIONAL_FEES_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.fees) {
        toast.success('Event optional fee order updated.');
        return { ...state, event: { ...state.event, fees: action.context?.fees } };
      }

      return state;

    case actions.REORDER_EVENT_SLIDESHOW_IMAGES_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.slideshowImages) {
        toast.success('Event slideshow image order updated.');
        return { ...state, event: { ...state.event, slideshowImages: action.context?.slideshowImages } };
      }

      return state;

    case actions.REORDER_EVENT_SPONSOR_TIERS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.sponsorTiers) {
        toast.success('Event sponsor tier order updated.');
        return { ...state, event: { ...state.event, sponsorTiers: action.context?.sponsorTiers } };
      }

      return state;

    case actions.REORDER_EVENT_TICKETS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.tickets) {
        toast.success('Event ticket order updated.');
        return { ...state, event: { ...state.event, tickets: action.context?.tickets } };
      }

      return state;

    case actions.RESET_EVENT_URL_CHANGED:
      const updatedEvent = { ...state.event, urlChanged: false }
      return { ...state, event: updatedEvent };

    case actions.SELECT_EVENT_BANNER_IMAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.bannerImage) {
        toast.success('Banner image selected.');
        return { ...state, event: { ...state.event, bannerImage: action.context.bannerImage } };
      }
      return state;

    case actions.SEND_EVENT_NOTIFICATIONS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('Notifications have been sent.');

      return state;

    case actions.UPDATE_EVENT_CONFIGURATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.event) {
        const updatedEvent = Object.assign({ ...state.event }, { ...action.context?.event });
        toast.success('Changes saved.');
        return { ...state, event: updatedEvent };
      }

      return state;

    case actions.UPDATE_EVENT_CONFIRMATION_EMAIL_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.event?.confirmationEmailText) {
        toast.success('Confirmation email updated.');
        return { ...state, event: { ...state.event, confirmationEmailText: action.context.event?.confirmationEmailText } };
      }
      
      return state;

    case actions.UPDATE_EVENT_CONNECTED_CONTEST_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.event) {
        const updatedEvent = Object.assign({ ...state.event }, { ...action.context?.event });
        toast.success('Changes saved.');
        return { ...state, event: updatedEvent };
      }

      return state;

    case actions.UPDATE_EVENT_COORDINATOR_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.coordinator) {
        const newState = cloneDeep(state);

        const index = newState.event.coordinators.findIndex(coordinator => coordinator.coordinatorID === action.context.coordinator.coordinatorID);
        if (index !== -1) {
          newState.event.coordinators[index] = {...newState.event.coordinators[index], ...action.context.coordinator};
          toast.success('Event coordinator updated.');
        } else {
          newState.event.coordinators = [...newState.event.coordinators, action.context.coordinator];
          toast.success('Event coordinator assigned.');
        }
        const updatedCoordinators = sortBy({ key: 'fullName', list: newState.event.coordinators });
        return { ...newState, event: { ...newState.event, coordinators: updatedCoordinators } };
      }
      return state;

    case actions.UPDATE_EVENT_DETAILS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.event?.pageText) {
        toast.success('Event details updated.');
        return { ...state, event: { ...state.event, pageText: action.context.event?.pageText } };
      }
      
      return state;

    case actions.UPDATE_EVENT_LOCATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.event) {
        toast.success('Event location updated.');
        return { ...state, event: {
          ...state.event,
          ...action.context.event
        } };
      }
      return state;

    case actions.UPDATE_EVENT_NAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.event) {
        const updatedEvent = Object.assign({ ...state.event }, { ...action.context?.event });

        if (action.context?.event?.url !== state.event.url) {
          updatedEvent.urlChanged = action.context?.event?.url;
        }

        toast.success('Changes saved.')
        return { ...state, event: updatedEvent };
      }

      return state;

    case actions.UPDATE_EVENT_OPTIONAL_FEE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.fee) {
        const index = state.event.fees.findIndex(fee => fee.feeID === action.context.fee.feeID);
        let updatedFees;
        if (index !== -1) {
          updatedFees = [...state.event.fees];
          updatedFees[index] = {...updatedFees[index], ...action.context.fee};
          toast.success('Event optional fee updated.');
        } else {
          updatedFees = [...state.event.fees, action.context.fee];
          toast.success('Event optional fee created.');
        }

        return { ...state, event: { ...state.event, fees: updatedFees } };
      }

      return state;

    case actions.UPDATE_EVENT_PARTNER_PAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.event.partnerPageText) {
        toast.success('Partner page updated.');
        return { ...state, event: { ...state.event, partnerPageText: action.context.event.partnerPageText } };
      }
      
      return state;

    case actions.UPDATE_EVENT_PROMO_CODE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.promoCode) {
        const index = state.event.promoCodes.findIndex(promoCode => promoCode.promoCodeID === action.context.promoCode.promoCodeID);
        let updatedPromoCodes;
        if (index !== -1) {
          updatedPromoCodes = [...state.event.promoCodes];
          updatedPromoCodes[index] = {...updatedPromoCodes[index], ...action.context.promoCode};
          toast.success('Event promo code updated.');
        } else {
          updatedPromoCodes = [...state.event.promoCodes, action.context.promoCode];
          toast.success('Event promo code created.');
        }

        return { ...state, event: { ...state.event, promoCodes: updatedPromoCodes } };
      }

      return state;

    case actions.UPDATE_EVENT_REGISTRATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
    
      if (action.context?.transaction) {
        const index = state.event.registrations.findIndex(registration => registration.transactionID === action.context.transaction.transactionID);
        const updatedRegistrations = cloneDeep(state.event.registrations);
        updatedRegistrations[index].items = action.context.transaction.items;
        toast.success('Event registration updated.');
        return { ...state, event: { ...state.event, registrations: updatedRegistrations } };
      }
    
      return state;

    case actions.UPDATE_EVENT_REGISTRATION_QUESTIONS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.registrationQuestions) {
        toast.success('Optional registration questions updated.');
        return { ...state, event: { ...state.event, registrationQuestions: action.context.registrationQuestions } };
      }
      
      return state;

    case actions.UPDATE_EVENT_SEO_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.event) {
        const updatedEvent = Object.assign({ ...state.event }, { ...action.context?.event });
        toast.success('Changes saved.');
        return { ...state, event: updatedEvent };
      }

      return state;

    case actions.UPDATE_EVENT_SPONSOR_TIER_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.sponsorTier) {
        const index = state.event.sponsorTiers.findIndex(sponsorTier => sponsorTier.sponsorTierID === action.context.sponsorTier.sponsorTierID);
        let updatedSponsorTiers;
        if (index !== -1) {
          updatedSponsorTiers = [...state.event.sponsorTiers];
          updatedSponsorTiers[index] = {...updatedSponsorTiers[index], ...action.context.sponsorTier};
          toast.success('Event sponsor tier updated.');
        } else {
          updatedSponsorTiers = [...state.event.sponsorTiers, action.context.sponsorTier];
          toast.success('Event sponsor tier created.');
        }

        return { ...state, event: { ...state.event, sponsorTiers: updatedSponsorTiers } };
      }

      return state;

    case actions.UPDATE_EVENT_SPONSOR_OPTIONS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.event) {
        toast.success('Changes saved.');
        return { ...state, event: { ...state.event, ...action.context?.event } };
      }

      return state;

    case actions.UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context.timeframe?.type === supportingProductTypes.sponsor) {
        const index = state.event.sponsorshipTimeframes.findIndex(timeframe => timeframe.timeframeID === action.context.timeframe.timeframeID);
        let updatedTimeframes;
        if (index !== -1) {
          updatedTimeframes = [...state.event.sponsorshipTimeframes];
          updatedTimeframes[index] = {...updatedTimeframes[index], ...action.context.timeframe};
          toast.success('Event sponsor timeframe updated.');
        } else {
          updatedTimeframes = [...state.event.sponsorshipTimeframes, action.context.timeframe];
          toast.success('Event sponsor timeframe created.');
        }

        return { ...state, event: { ...state.event, sponsorshipTimeframes: updatedTimeframes } };
      }

      return state;

    case actions.UPDATE_EVENT_TICKET_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.ticket) {
        const index = state.event.tickets.findIndex(ticket => ticket.ticketID === action.context.ticket.ticketID);
        let updatedTickets;
        if (index !== -1) {
          updatedTickets = [...state.event.tickets];
          updatedTickets[index] = {...updatedTickets[index], ...action.context.ticket};
          toast.success('Event ticket updated.');
        } else {
          updatedTickets = [...state.event.tickets, action.context.ticket];
          toast.success('Event ticket created.');
        }

        return { ...state, event: { ...state.event, tickets: updatedTickets } };
      }

      return state;

    case actions.UPDATE_EVENT_TIMEFRAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.timeframe) {
        const index = state.event.timeframes.findIndex(timeframe => timeframe.timeframeID === action.context.timeframe.timeframeID);
        let updatedTimeframes;
        if (index !== -1) {
          updatedTimeframes = [...state.event.timeframes];
          updatedTimeframes[index] = {...updatedTimeframes[index], ...action.context.timeframe};
          toast.success('Event timeframe updated.');
        } else {
          updatedTimeframes = [...state.event.timeframes, action.context.timeframe];
          toast.success('Event timeframe created.');
        }

        return { ...state, event: { ...state.event, timeframes: updatedTimeframes } };
      }

      return state;

    case actions.UPLOAD_EVENT_BANNER_IMAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.bannerImage) {
        toast.success('Banner image uploaded.');
        const defaultBanners = state.event.bannerImages.filter(bannerImage => bannerImage.default);
        const customBanners = state.event.bannerImages.filter(bannerImage => !bannerImage.default);
        const updatedCustomBannerImages = sortBy({ key: 'name', list: [...customBanners, action.context.bannerImage] });
        return { ...state, event: { ...state.event, bannerImages: [...updatedCustomBannerImages, ...defaultBanners] } };
      }
      return state; 

    case actions.UPLOAD_EVENT_DOWNLOADABLE_FILE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.file) {
        toast.success('File uploaded.');
        const updatedFiles = sortBy({ key: 'name', list: [...state.event.files, action.context.file] });
        return { ...state, event: { ...state.event, files: updatedFiles } };
      }
      return state;

    case actions.UPLOAD_EVENT_SLIDESHOW_IMAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.slideshowImage) {
        toast.success('Slideshow image uploaded.');
        return { ...state, event: { ...state.event, slideshowImages: [...state.event.slideshowImages || [], action.context?.slideshowImage] } };
      }
      return state;

    default:
      return state;
  }
}

export default reducer;
