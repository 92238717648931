import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventRequest,
  updateEventConfigurationRequest
} from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { fieldTypes, yesOrNo } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const EventAdvancedConfiguration = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const [configuration, setConfiguration] = useState(null);
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  const handleSubmit = (event) => {
    dispatch(updateEventConfigurationRequest({ clientURL: client.url, eventURL, form: event }));
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventConfiguration', client, event }));
    dispatch(updateDrawerContent({
      key: 'eventConfiguration',
      options: { client, event } 
    }));

    if (!configuration && event) {
      setConfiguration({
        formElements: {
          rows: [{
            heading: 'Operational settings'
          }, {
            elements: [{
              label: 'Discounts are automatically applied for users who purchase again at a later time?',
              name: 'autoApplyDiscount',
              type: fieldTypes.select,
              options: yesOrNo,
              value: !!event?.autoApplyDiscount
            }]
          }, {
            elements: [{
              label: 'Enable checkin flow and QR codes for this event?',
              name: 'enableCheckin',
              type: fieldTypes.select,
              options: yesOrNo,
              value: !!event?.enableCheckin
            }]
          }, {
            heading: 'Change how elements are displayed',
          }, {
            elements: [{
              label: 'Event is private (not shown on home page)?',
              name: 'isPrivate',
              type: fieldTypes.select,
              options: yesOrNo,
              value: !!event?.isPrivate
            }]
          }, {
            elements: [{
              label: 'Optional CSS (Developers Only)',
              name: 'optionalCSS',
              type: fieldTypes.textarea,
              value: event?.optionalCSS
            }]
          }]
        },
        submitCTA: 'Save'
      });
    }
  }, [client, event]);
  
  return (
    <section className="box">
      <div className="box-title">
        <h3>
          {
            !eventFetching && (
              <span>Advanced Configuration</span>
            )
          }
          {
            eventFetching && (
              <LoadingCircle variant="dark" />
            )
          }
        </h3>
      </div>
      {
        (!eventFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_EVENT_CONFIGURATION'}
          />
        </div>
        )
      }
    </section>
  )
}

export default EventAdvancedConfiguration;
