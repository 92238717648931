import * as actions from 'src/redux/actions/search';
import { toast } from 'react-toastify';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.GET_SEARCH_LIST_REQUEST:
      return { ...state, sendInvite: null, typedValue: null, results: [] };

    case actions.GET_SEARCH_LIST_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return action.context?.results ? { ...state, ...action.context } : state;

    default: 
      return state;
  }
}

export default reducer;
