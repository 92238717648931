import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getContestRequest,
  updateContestInstructionsRequest,
  updateContestScoringInstructionsRequest
} from 'src/redux/actions/contests';
import TinyMCE from 'src/components/TinyMCE';


const ContestOptionalInstructions = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestOptionalInstructions', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestOptionalInstructions',
      options: { client, contest } 
    }));
  }, [client, contest]);

  const handleInstructionsSubmit = (event) => {
    dispatch(updateContestInstructionsRequest({ clientURL: client.url, contestURL: contest.url, form: { instructions: event } }));
  }

  const handleScoringInstructionsSubmit = (event) => {
    dispatch(updateContestScoringInstructionsRequest({ clientURL: client.url, contestURL: contest.url, form: {scoringInstructions: event } }));
  }

  return (
    contest && (
      <>
        <TinyMCE
          handleSubmit={event => handleInstructionsSubmit(event)}
          requestPrefix="UPDATE_CONTEST_INSTRUCTIONS"
          title="Optional Entry Instructions"
          value={contest?.instructions}
        />
        <TinyMCE
          handleSubmit={event => handleScoringInstructionsSubmit(event)}
          requestPrefix="UPDATE_CONTEST_SCORING_INSTRUCTIONS"
          title="Optional Scoring Instructions"
          value={contest?.scoringInstructions}
        />
      </>
    )
  )
}

export default ContestOptionalInstructions;