import React, { useState } from 'react';
import SelectField from './SelectField';
import InputField from './InputField';
import NumberField from './NumberField';
import AmountField from './CurrencyField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCanXmark } from '@fortawesome/pro-light-svg-icons';
import { Button } from '@mui/material';


const InvoiceLineItem = ({ clientID, invoiceItems, item, onChange, onDelete }) => {
  const [formState, setFormState] = useState({ ...item });
  const service = 'service';
  const custom = 'custom';
  const availableItems = invoiceItems.filter(item => item.clientID === clientID);

  const lineItemTypes = !!availableItems.length ? [{
    label: 'Service',
    value: service
  }, {
    label: 'Custom',
    value: custom
  }] : [{
    label: 'Custom',
    value: custom
  }];

  const handleChange = ({ name, value }) => {
    const newItem = { ...item, [name]: value };

    if (name === 'type') {
      if (value === service) {
        newItem.eventID = availableItems[0]?.eventID;
        newItem.amount = availableItems[0]?.amount;
        newItem.qty = 1;
      } else {
        newItem.eventID = null;
        newItem.amount = 0;
        newItem.qty = null;
      }
    }

    onChange(newItem);
    setFormState(newItem);
  };

  return <div className="form-field line-item">
    <div>
      <SelectField
        className="type"
        label="Type"
        name="type"
        onChange={value => handleChange({ name: 'type', value })}
        options={lineItemTypes}
        value={formState['type']}
      />
      {
        formState['type'] === custom ?
          <InputField
            className="description"
            label="Description"
            name="description"
            onChange={value => handleChange({ name: 'description', value })}
            value={formState['description']}
          /> :
          <SelectField
            className="event"
            label="Service"
            name="eventID"
            onChange={value => handleChange({ name: 'eventID', value })}
            options={availableItems.map(item => {
              return {
                label: item.eventName,
                value: item.eventID
              }
            })}
            value={formState['eventID']}
          />
      }
    </div>
    <div>
      <NumberField
        className="qty"
        label="Quantity"
        name="qty"
        onChange={value => handleChange({ name: 'qty', value })}
        value={formState['qty']}
      />
      <AmountField
        className="amount"
        label="Price"
        name="amount"
        onChange={value => handleChange({ name: 'amount', value })}
        value={formState['amount']}
      />
      <Button
        className="trash"
        onClick={() => onDelete(item) }
      ><FontAwesomeIcon icon={faTrashCanXmark} /></Button>
    </div>
  </div>
}

export default InvoiceLineItem;
