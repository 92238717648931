import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const DateField = ({ className, label, name, onChange, required, value }) => {
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState();

  const handleTouched = () => {
    setTouched(true);
  };

  const handleChange = (value) => {
    const parsedValue = moment.isMoment(value) ? value : moment.utc(value);
    setError(parsedValue.isValid() ? undefined : 'Valid date required');
    onChange(value);
  };

  return <div className={className} onFocus={handleTouched}>
    <label>{label}</label>
    <DatePicker
      autoComplete="off"
      className={`date-picker-container${error ? ' error' : ''}${touched ? ' touched' : ''}`}
      id={name}
      name={name}
      onChange={value => { handleChange(value)}}
      slotProps={{
        field: { clearable: true },
        textField: {
          error: !!(touched && error),
          helperText: touched && error,
          onChange: value => {handleChange(value)},
          required
        },
      }}
      type="text"
      value={moment.utc(value)}
    />
  </div>
}

export default DateField;
