import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getActionPanelRequest } from 'src/redux/actions/users';
import LoadingBars from '../Elements/LoadingBars';

const ActionPanel = ({ handleClose }) => {
  const user = useSelector(state => state.userStore?.user);
  const isRequesting = useSelector(state => state.loadingStore.GET_ACTION_PANEL);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActionPanelRequest());
  }, []);

  if (isRequesting) {
    return <section className="action-panel">
      <LoadingBars />
    </section>
  }

  if (!(user?.actionPanel?.myContestsToScore || [])?.length &&
    !(user?.actionPanel?.myCurrentContests || [])?.length &&
    !(user?.actionPanel?.myCurrentParades || [])?.length) {
      return <section className="action-panel">
        <p>There are no items currently needing your attention.</p>
      </section>
  }

  return (
    <section className="action-panel">
      {
        (user?.actionPanel?.myContestsToScore || [])?.length > 0 && 
          <div className="action-panel-item">
            <h3 className="action-panel-item-title">My Scoring Assignments</h3>
            <div className="action-panel-item-list">
            {
              (user.actionPanel.myContestsToScore || []).map(contest =>
                <Link
                  className="action-panel-item-list-item"
                  key={contest.url}
                  onClick={handleClose}
                  to={contest.url}
                >
                  <img
                    className="action-panel-item-list-item-image"
                    src={contest.bannerImage}
                  />
                  <div className="action-panel-item-list-item-details">
                    <div className="action-panel-item-list-item-details-client">{contest.clientName}</div>
                    <div className="action-panel-item-list-item-details-contest">{contest.contestName}</div>
                  </div>
                </Link>
              )
            }
            </div>
          </div>
      }
      {
        (user?.actionPanel?.myCurrentContests || [])?.length > 0 && 
          <div className="action-panel-item">
            <h3 className="action-panel-item-title">My Awards Program Entries</h3>
            <div className="action-panel-item-list">
            {
              (user.actionPanel.myCurrentContests || []).map(contest =>
                <Link
                  className="action-panel-item-list-item"
                  key={contest.url}
                  onClick={handleClose}
                  to={contest.url}
                >
                  <img
                    className="action-panel-item-list-item-image"
                    src={contest.bannerImage}
                  />
                  <div className="action-panel-item-list-item-details">
                    <div className="action-panel-item-list-item-details-client">{contest.clientName}</div>
                    <div className="action-panel-item-list-item-details-contest">{contest.contestName}</div>
                  </div>
                </Link>
              )
            }
            </div>
          </div>
      }
      {
        (user?.actionPanel?.myCurrentParades || [])?.length > 0 && 
          <div className="action-panel-item">
            <h3 className="action-panel-item-title">My Home Tour Ticket Verifications</h3>
            <div className="action-panel-item-list">
            {
              (user.actionPanel.myCurrentParades || []).map(parade =>
                <Link
                  className="action-panel-item-list-item"
                  key={parade.url}
                  onClick={handleClose}
                  to={parade.url}
                >
                  <img
                    className="action-panel-item-list-item-image"
                    src={parade.bannerImage}
                  />
                  <div className="action-panel-item-list-item-details">
                    <div className="action-panel-item-list-item-details-client">{parade.clientName}</div>
                    <div className="action-panel-item-list-item-details-contest">{parade.paradeName}</div>
                  </div>
                </Link>
              )
            }
            </div>
          </div>
      }
    </section>
  )
}

export default ActionPanel;
