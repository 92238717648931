import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { duplicateScoresheetRequest } from 'src/redux/actions/contests';

const DuplicateScoresheetForm = ({ scoresheet, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    dispatch(duplicateScoresheetRequest({ clientURL: client.url, contestURL: contest.url, form: {
      scoresheetID: scoresheet.scoresheetID,
      name: event.name,
      sequence: contest?.scoresheets?.length || 0
    } }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Name',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: '',
          required: true
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="duplicate-scoresheet">
      <p>Duplicating will create a new scoresheet using existing settings and criteria structure.</p>
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'DUPLICATE_SCORESHEET'}
      />
    </section>
  )
}

export default DuplicateScoresheetForm;
