import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPasswordRequestsRequest } from 'src/redux/actions/app';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import ResourceList from 'src/components/ResourceList';
import { toast } from 'react-toastify';

const PasswordRequests = () => {
  const passwordRequests = useSelector(state => state.appStore?.passwordRequests);
  const requestsFetching = useSelector(state => state.loadingStore.GET_PASSWORD_REQUESTS);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'passwordRequests' }));
    dispatch(updateDrawerContent({ key: 'networkPasswordRequests' }));

    if (!requestsFetching) {
      dispatch(getPasswordRequestsRequest());
    }
  }, []);

  const actions = [{
    color: 'confirm',
    label: 'Copy Link',
    requiresSelection: true,
    variant: 'contained',
    fn: request => {
      navigator.clipboard.writeText(`https://cpjam.com/password/reset/${request.passwordKey}`);
      toast.success('Link copied to clipboard.');
    }
  }];

  const filters = {
    searchBy: ['email']
  };
  
  return (
    <ResourceList
      actions={actions}
      fetching={requestsFetching}
      filters={filters}
      list={passwordRequests}
      renderItem={request => 
        <div className="password-request">
          <div className="password-request-date">{request.exp}</div>
          <div className="password-request-email">{request.email}</div>
          <div className="password-request-url">{`https://cpjam.com/password/reset/${request.passwordKey}`}</div>
        </div>
      }
      renderKey="passwordKey"
      title="Password Change Requests"
    />
  )
}

export default PasswordRequests;