import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup
} from '@mui/material';
import moment from 'moment';

const ViewParadeAttendance = ({ attendance, properties, ticket }) => {
  const selectedProperties = properties.map(property => {
    const record = (attendance || []).find(item => item.propertyID === property.propertyID);

    return {
      ...property,
      selected: record ? {
        ticketTaker: record.ticketTaker,
        timestamp: moment.utc(record.timestamp).format('LLL')
      } : null
    }
  });

  return <section className="view-parade-attendance">
    {
      (selectedProperties || []).map(property => {
        return (
          <FormControl
            className="form-field"
            key={property?.propertyID}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!property.selected}
                    disabled={true}
                  />
                }
                label={`${property?.address}${property.selected ? ` (${property.selected.timestamp} by ${property.selected.ticketTaker})` : ''}`}
              />
            </FormGroup>
          </FormControl>
        )
      })
    }
  </section>
}

export default ViewParadeAttendance;
