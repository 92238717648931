import React from 'react';
import { MenuItem, Select } from '@mui/material';

const FormStructureSelectField = ({ error, handleBlur, handleChange, item, options, value }) => {
  return (
    <Select
      error={error}
      onBlur={handleBlur}
      onChange={e => {handleChange(e.target.value, item)}}
      value={typeof value === 'undefined' ? '' : value}
    >
      {
        (options || []).map(option => {
          return (
            <MenuItem
              key={option.value}
              value={option.value}
            >{option.label}</MenuItem>
          )
        })
      }
    </Select>
  )
}

export default FormStructureSelectField;
