import React from 'react';
import { Button } from '@mui/material';
import LoadingCircles from 'src/components/Elements/LoadingCircles';
import createLoadingSelector from 'src/redux/loading';
import { useSelector } from 'react-redux';

const FileUploader = ({
  accept,
  className,
  color,
  disabled,
  id,
  label,
  multiple,
  onChange,
  params,
  requestPrefix
}) => {
  const loadingSelector = createLoadingSelector([requestPrefix]);
  const fileFetching = useSelector(state => loadingSelector(state));

  const handleOnChange = (event) => {
    if (!event.target?.files?.length) {
      return;
    }

    for (let i = 0; i < event.target?.files?.length; i++) {
      const file = event.target.files[i];
      const formData = new FormData();
      const filename = file.name.replace(' ', '-').replace(new RegExp(/[^A-Za-z0-9.-]/g), '');
      formData.append('file', file, filename);
      if (params && !multiple) {
        for (const key in params) {
          formData.append(key, params[key]);
        }
      }
      onChange(formData);
    };
  };

  return (
    <>
      <input
        accept={accept}
        id={id}
        multiple={multiple || undefined}
        onChange={handleOnChange}
        style={{ display: 'none' }}
        type="file"
      />
      <label htmlFor={id}>
        <Button
          className={className || ''}
          color={color || 'primary'}
          component="span"
          disabled={disabled || fileFetching}
          variant="contained"
        >
          {fileFetching && (
            <LoadingCircles />
          )}
          {!fileFetching && (
          <>
            {label}
          </>
          )}
        </Button>
      </label>
    </>
  )
}

export default FileUploader;
