import React from 'react';
import {
  MenuItem,
  Select
} from '@mui/material';

const SelectField = ({ className, disabled, label, name, onChange, options, value }) => {
  return <div className={className}>
    <label>{label}</label>
    <Select
      disabled={disabled}
      id={`invoice-select-${name}`}
      name={name}
      onChange={e => {onChange(e.target.value)}}
      value={value}
    >
      {
        (options || []).map(option => {
          return (
            <MenuItem
              key={option.value}
              value={option.value}
            >{option.label}</MenuItem>
          )
        })
      }
    </Select>
  </div>
};

export default SelectField;
