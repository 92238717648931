import * as actions from 'src/redux/actions/client';
import { toast } from 'react-toastify';
import sortBy from 'src/utilities/sort';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.AUTHORIZE_SQUARE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      window.location.href = `${process.env.REACT_APP_SQUARE_API}/oauth2/authorize?client_id=${action.context.applicationID}&scope=PAYMENTS_WRITE+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_READ+MERCHANT_PROFILE_READ&state=${action.context.state}`;
      return state;

    case actions.AUTHORIZE_STRIPE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write&client_id=${action.context.clientID}&state=${action.context.state}`;
      return state;

    case actions.CLEAR_CLIENT_LIST:
      return { ...state, clients: [], client: false };

    case actions.GET_CLIENT_REQUEST:
      return { ...state, client: false };

    case actions.GET_CLIENT_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return action.context?.client ? { ...state, client: action.context.client } : state;

    case actions.GET_CLIENT_INVOICES_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return { ...state, client: { ...state.client, invoices: action.context.invoices } };

    case actions.GET_CLIENT_LIST_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return action.context?.clients ? { ...state, clients: action.context.clients } : state;

    case actions.GET_CLIENT_VOLUNTEER_LIST_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return action.context?.clients ? { ...state, volunteerClients: action.context.clients } : state;

    case actions.PAY_INVOICE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.invoice) {
        toast.success('Payment successful.');
        const updatedInvoices = state.client.invoices.map(invoice => {
          return invoice.invoiceID === action.context.invoice.invoiceID ? action.context.invoice : invoice;
        })

        return { ...state, client: { ...state.client, invoices: updatedInvoices } };
      }

      return state;

    case actions.REMOVE_ADMINISTRATOR_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.administratorID) {
        toast.success('Administrator removed.');
        const updatedAdministrators = state.client.administrators.filter(administrator => {
          return administrator.administratorID !== action.context.administratorID;
        });
        return { ...state, client: { ...state.client, administrators: updatedAdministrators } };
      }

      return state;

    case actions.REMOVE_ADMINISTRATOR_INVITATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.invitationKey) {
        toast.success('Administrator invitation removed.');
        const updatedInvitations = state.client.invitations.filter(invitation => {
          return invitation.invitationKey !== action.context.invitationKey;
        });
        return { ...state, client: { ...state.client, invitations: updatedInvitations } };
      }

      return state;

    case actions.REMOVE_LOGO_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.logo) {
        toast.success('Logo removed.');
        return { ...state, client: { ...state.client, image: false, logo: action.context.logo } };
      }

      return state;

    case actions.REVOKE_SQUARE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      return { ...state, client: { ...state.client, square: null } };

    case actions.REVOKE_STRIPE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return { ...state, client: { ...state.client, stripe: null } };

    case actions.UPDATE_ADMINISTRATOR_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.administrator) {
        const index = state.client.administrators.findIndex(administrator => administrator.administratorID === action.context.administrator.administratorID);
        let updatedAdministrators;
        if (index !== -1) {
          updatedAdministrators = [...state.client.administrators];
          updatedAdministrators[index] = {...updatedAdministrators[index], ...action.context.administrator};
          toast.success('Client administrator updated.');
        } else {
          updatedAdministrators = [...state.client.administrators, action.context.administrator];
          toast.success('Client administrator created.');
        }

        return { ...state, client: { ...state.client, administrators: sortBy({ key: 'fullName', list: updatedAdministrators }) } };
      }

      return state;

    case actions.UPDATE_ADMINISTRATOR_INVITATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.invitation) {
        const index = state.client.invitations.findIndex(invitation => invitation.invitationKey === action.context.invitation.invitationKey);
        let updatedInvitations;
        if (index !== -1) {
          updatedInvitations = [...state.client.invitations];
          updatedInvitations[index] = {...updatedInvitations[index], ...action.context.invitation};
          toast.success('Administrator invitation updated.');
        } else {
          updatedInvitations = [...state.client.invitations, action.context.invitation];
          toast.success('Administrator invitation created.');
        }

        return { ...state, client: { ...state.client, invitations: sortBy({ key: 'email', list: updatedInvitations }) } };
      }

      return state;

    case actions.UPDATE_CLIENT_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.client) {
        let updatedClients = [ ...(state.clients || []) ];
        const index = updatedClients.findIndex(client => client.clientID === action.context.client.clientID);
        if (index !== -1) {
          updatedClients[index] = {...updatedClients[index], ...action.context.client};
          toast.success('Client updated.');
        } else {
          updatedClients = [...updatedClients, action.context.client];
          toast.success('Client inserted.');
        }
        return { ...state, clients: sortBy({ key: 'name', list: updatedClients }) };
      }
      return state;

    case actions.UPDATE_CLIENT_GATEWAY_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.client) {
        toast.success('Gateway updated.');
        return { ...state, client: { ...state.client, ...action.context.client } };
      }
      return state;

    case actions.UPDATE_CLIENT_OPTIONS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.client) {
        toast.success('Options updated.');
        return { ...state, client: { ...state.client, ...action.context.client } };
      }
      return state;

    case actions.UPDATE_CLIENT_SETTINGS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('Settings updated.');
      return { ...state, client: { ...state.client, ...action.context.client }};

    case actions.UPDATE_CLIENT_SOCIAL_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('Social links updated.');
      return { ...state, client: { ...state.client, ...action.context.client }};

    case actions.UPLOAD_LOGO_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.logo) {
        toast.success('Logo uploaded.');
        return { ...state, client: { ...state.client, image: true, logo: action.context.logo } };
      }
      return state;

    default:
      return state;
  }
}

export default reducer;
