import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventRequest,
  updateEventSEORequest
} from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { fieldTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const EventSEO = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const [configuration, setConfiguration] = useState(null);
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  const handleSubmit = (event) => {
    dispatch(updateEventSEORequest({ clientURL: client.url, eventURL, form: event }));
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventSEO', client, event }));
    dispatch(updateDrawerContent({
      key: 'eventSEO',
      options: { client, event } 
    }));

    if (!configuration && event) {
      setConfiguration({
        formElements: {
          rows: [{
            elements: [{
              label: 'Description',
              name: 'description',
              type: fieldTypes.textarea,
              value: event?.description
            }]
          }]
        },
        submitCTA: 'Save'
      });
    }
  }, [client, event]);

  return (
    <section className="event-name box">
      <div className="box-title">
        <h3>
            {
              !eventFetching && (
                <span>Search Engine Optimization</span>
              )
            }
            {
              eventFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        (!eventFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_EVENT_SEO'}
          />
        </div>
        )
      }
    </section>
  )
}

export default EventSEO;
