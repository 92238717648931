import * as actions from 'src/redux/actions/surveys';
import { toast } from 'react-toastify';
import sortBy from 'src/utilities/sort';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.CREATE_SURVEY_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('Survey created.');

      return { ...state, surveys: [action.context.survey, ...state.surveys] };

    case actions.GET_SURVEY_REQUEST:
      return { ...state, survey: null };

    case actions.GET_SURVEY_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return action.context?.survey ? { ...state,survey: action.context.survey } : state;

    case actions.GET_SURVEY_LIST_REQUEST:
        return { ...state, surveys: [] };

    case actions.GET_SURVEY_LIST_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return action.context?.surveys ? { ...state, surveys: action.context.surveys } : state;

    case actions.REMOVE_SURVEY_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.surveyID) {
        toast.success('Survey removed.');
        const updatedSurveys = state.surveys.filter(survey => survey.surveyID !== action.context.surveyID);
        return { ...state, surveys: updatedSurveys };
      }

      return state;

    case actions.REMOVE_SURVEY_IMAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.file) {
        toast.success('Survey image removed.');
        const updatedFiles = state.survey.images.filter(file => {
          return file.fileID !== action.context.file.fileID;
        });
        return { ...state, survey: { ...state.survey, images: updatedFiles, imageRemoved: action.context.file.fileID } };
      }

      return state;

    case actions.REMOVE_SURVEY_VOTE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.voteID) {
        toast.success('Vote removed.');
        const updatedVotes = state.survey.votes.filter(voter => voter.voteID !== action.context.voteID);
        return { ...state, survey: { ...state.survey, votes: updatedVotes } };
      }

      return state;

    case actions.RESET_SURVEY_URL_CHANGED:
      const updatedSurvey = { ...state.survey, urlChanged: false };
      return { ...state, survey: updatedSurvey };

    case actions.UPDATE_SURVEY_NAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.survey) {
        const updatedSurvey = { ...action.context.survey };
        if (action.context?.survey?.url !== state.survey.url) {
          updatedSurvey.urlChanged = action.context?.survey?.url;
        }

        toast.success('Survey name updated.');
        return { ...state, survey: {
          ...state.survey,
          ...updatedSurvey
        } };
      }
      return state;

    case actions.UPDATE_SURVEY_QUESTIONS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.survey) {
        toast.success('Survey configuration updated.');
        return { ...state, survey: {
          ...state.survey,
          ...action.context.survey
        } };
      }
      return state;

    case actions.UPDATE_SURVEY_VOTING_TIMEFRAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.survey) {
        toast.success('Survey voting times updated.');
        return { ...state, survey: {
          ...state.survey,
          ...action.context.survey
        } };
      }
      return state;

    case actions.UPLOAD_SURVEY_IMAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.file) {
        toast.success('Survey image uploaded.');
        const updatedFiles = sortBy({ key: 'name', list: [...state.survey.images, action.context.file] });
        return { ...state, survey: { ...state.survey, images: updatedFiles } };
      }
      return state;

    default:
      return state;
  }
}

export default reducer;
