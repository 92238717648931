import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import {
  clearForceReloadRequest,
  getInvoicesRequest
} from 'src/redux/actions/app';
import { getClientListRequest } from 'src/redux/actions/client';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { currency } from 'src/utilities/strings';
import ResourceList from 'src/components/ResourceList';
import { openModal } from 'src/redux/actions/modal';
import { openDialog } from 'src/redux/actions/dialog';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import {
  MenuItem,
  Select
} from '@mui/material';

const Invoices = () => {
  const services = 'services';
  const pending = 'pending';
  const paid = 'paid';

  const year = new Date().getFullYear();

  const clients = useSelector(state => state.clientStore?.clients);
  const [receiptFilters, setReceiptFilters] = useState();
  const [totalInvoiceRevenue, setTotalInvoiceRevenue] = useState(0);
  const [totalReceiptRevenue, setTotalReceiptRevenue] = useState(0);
  const [totalPendingRevenue, setTotalPendingRevenue] = useState(0);
  const [selectedYear, setSelectedYear] = useState(year);
  const [currentReport, setCurrentReport] = useState(services);
  const invoices = useSelector(state => state.appStore?.invoices);
  const invoiceItems = useSelector(state => state.appStore?.invoiceItems);
  const eventRevenue = useSelector(state => state.appStore?.eventRevenue);
  const forceReload = useSelector(state => state.appStore.forceReload);
  const invoicesFetching = useSelector(state => state.loadingStore.GET_INVOICES);
  const clientsFetching = useSelector(state => state.loadingStore.GET_CLIENTS);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'invoices' }));
    dispatch(updateDrawerContent({ key: 'networkInvoices' }));

    if (!invoicesFetching) {
      dispatch(getInvoicesRequest());
    }
    if (!(clients || []).length && !clientsFetching) {
      dispatch(getClientListRequest());
    }
  }, []);

  useEffect(() => {
    if (forceReload) {
      dispatch(clearForceReloadRequest());
    }
  }, [forceReload]);

  useEffect(() => {
    if (invoices) {
      const years = {};
      for (let i = year; i > 2019; i--) {
        years[i] = {
          fn: item => item.invoiceYear === i,
          label: i
        };
      }

      setReceiptFilters({
        default: year,
        options: years,
        noAll: true,
        searchBy: ['clientName']
      });

      setTotalInvoiceRevenue(invoices.filter(invoice => !invoice.paidDate)
        .reduce((acc, invoice) => acc + invoice.amount, 0));

      setTotalReceiptRevenue(invoices.filter(invoice => invoice.invoiceYear === year && !!invoice.paidDate)
        .reduce((acc, invoice) => acc + invoice.amount, 0));

      setTotalPendingRevenue(invoiceItems.reduce((acc, invoice) => acc + invoice.amount, 0));
    }
  }, [invoices]);

  const handleInvoiceFilterChange = ({ list }) => {
    setTotalInvoiceRevenue(list.reduce((acc, invoice) => acc + invoice.amount, 0));
  };

  const handleReceiptFilterChange = ({ list, selectedFilterOption }) => {
    setTotalReceiptRevenue(list.reduce((acc, invoice) => acc + invoice.amount, 0));
    setSelectedYear(selectedFilterOption);
  };

  const handleReportChange = (value) => {
    setCurrentReport(value);
  }

  const reports = [{
    label: 'Uninvoiced Services',
    value: services
  }, {
    label: 'Outstanding Invoices',
    value: pending
  }, {
    label: 'Paid Invoices',
    value: paid
  }];

  const lineItemActions = [{
    label: 'Create Invoice',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { clients, invoiceItems },
          key: 'editInvoice'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: item => {
      dispatch(
        openDialog({
          props: { itemID: item.itemID },
          key: 'removeInvoiceLineItem'
        })
      )
    }
  }];

  const invoiceActions = [{
    label: 'View',
    requiresSelection: true,
    fn: invoice => window.open(`https://${invoice.clientURL}.cpjam.com/invoices/${invoice.invoiceID}`)
  }, {
    label: 'Edit',
    variant: 'contained',
    requiresSelection: true,
    fn: invoice => {
      const items = [...(((invoice || {}).items || [])), ...invoiceItems.filter(item => item.clientID === invoice.clientID)];
      dispatch(
        openModal({
          props: { clients, invoice, invoiceItems: items },
          key: 'editInvoice'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: invoice => {
      dispatch(
        openDialog({
          props: { clientURL: invoice.clientURL, invoice },
          key: 'removeInvoice'
        })
      )
    }
  }];

  const paidActions = [{
    label: 'View',
    requiresSelection: true,
    fn: invoice => window.open(`https://${invoice.clientURL}.cpjam.com/invoices/${invoice.invoiceID}`)
  }, {
    label: 'Edit',
    variant: 'contained',
    requiresSelection: true,
    fn: invoice => {
      const items = [...(((invoice || {}).items || [])), ...invoiceItems.filter(item => item.clientID === invoice.clientID)];
      dispatch(
        openModal({
          props: { clients, invoice, invoiceItems: items },
          key: 'editInvoice'
        })
      )
    }
  }];

  return <section className="invoices grid">
    <section className="box">
      <div className="box-title">
        <h3>
          {
            clientsFetching ?
              <LoadingCircle variant="dark" /> :
              <span>Invoicing Summary
                { invoicesFetching && <LoadingCircle variant="dark" /> }
              </span>
          }
          { currentReport === services && <>{currency(totalPendingRevenue)} Pending</> }
          { currentReport === pending && <>{currency(totalInvoiceRevenue)} Pending</> }
          { currentReport === paid && <div>
            <div>{currency(selectedYear >= 2024 ?
              totalReceiptRevenue + (((eventRevenue || {})[selectedYear] || 0) * 0.02) :
              totalReceiptRevenue)} Total Revenue</div>
            </div>
          }
        </h3>
      </div>
      <div className="box-content">
        <div>
          <label>Report:</label>
          <Select 
            onChange={event => handleReportChange(event.target.value)}
            sx={{ marginLeft: '8px' }}
            value={currentReport}
          >{
            (reports || [])?.map(item => {
              return (
                <MenuItem
                  key={item.value}
                  value={item.value}
                >{item.label}</MenuItem>
              )
            })
          }</Select>
        </div>
        { (currentReport === paid && selectedYear >= 2024) && <h3 className="invoices-report-summary">
            <div>{currency(totalReceiptRevenue)} Service Revenue</div>
            <div>{currency(((eventRevenue || {})[selectedYear] || 0) * 0.02)} Event Revenue</div>
          </h3>
        }
      </div>
    </section>
    { currentReport === services &&
      <ResourceList
        actions={lineItemActions}
        fetching={invoicesFetching}
        list={invoiceItems}
        renderItem={invoice => 
          <div className="receipt">
            <div className="receipt-client">{invoice.clientName}</div>
            <div className="receipt-total">
              <span>{invoice.eventName || invoice.description}</span>
              <span className="emphasize">{currency(invoice.amount)}</span>
            </div>
          </div>
        }
        renderKey="itemID"
        title="Uninvoiced Services"
      />
    }
    { currentReport === pending &&
      <ResourceList
        actions={invoiceActions}
        fetching={invoicesFetching}
        filters={{ searchBy: ['clientName'] }}
        list={(invoices || []).filter(invoice => !invoice.paidDate)}
        onFilterChange={handleInvoiceFilterChange}
        pageSize={10}
        renderItem={invoice => 
          <div className="receipt">
            <div className="receipt-client">{invoice.clientName}</div>
            <div className="receipt-items">
              {invoice.items.map(item => 
                <div key={item.itemID} className="receipt-items-item">
                  <span>{item.eventName || item.description}</span>
                  <span>{currency(item.amount)}</span>
                </div>
              )}
              {
                !(invoice.items || []).length && <div className="receipt-items-item">
                  <span>[No Items In Invoice]</span>
                </div>
              }
            </div>
            <div className="receipt-total">
              <span>Total: </span>
              <span className="emphasize">{currency(invoice.amount)}</span>
            </div>
          </div>
        }
        renderKey="invoiceID"
        title="Outstanding Invoices"
      />
    }
    { currentReport === paid &&
      <ResourceList
        actions={paidActions}
        fetching={invoicesFetching}
        filters={receiptFilters}
        list={(invoices || []).filter(invoice => !!invoice.paidDate)}
        onFilterChange={handleReceiptFilterChange}
        pageSize={10}
        renderItem={invoice => 
          <div className="receipt">
            <div className="receipt-client">{invoice.clientName}</div>
            <div className="receipt-items">
              {invoice.items.map(item => 
                <div key={item.itemID} className="receipt-items-item">
                  <span>{item.eventName || item.description}</span>
                  <span>{currency(item.amount)}</span>
                </div>
              )}
              {
                !(invoice.items || []).length && <div className="receipt-items-item">
                  <span>[No Items In Invoice]</span>
                </div>
              }
            </div>
            <div className="receipt-total">
              <span>Paid On: <span className="emphasize">{invoice.paidDisplayDate}</span></span>
              <span className="emphasize">{currency(invoice.amount)}</span>
            </div>
          </div>
        }
        renderKey="invoiceID"
        title="Paid Invoices"
      />
    }
  </section>
}

export default Invoices;
