import React, { useEffect, Suspense } from 'react';
import './scss/styles.scss';
import { RouterProvider } from "react-router-dom";
import router from './router';
import { getClientListRequest } from 'src/redux/actions/client';
import { getUserRequest } from 'src/redux/actions/users';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { ThemeProvider, createTheme } from '@mui/material/styles';

function App() {
  if (typeof window === 'undefined') {
    global.window = {}
  }

  const dispatch = useDispatch();
  const user = useSelector(state => state.userStore?.user);
  const clients = useSelector(state => state.clientStore?.clients);
  const userIsRequesting = useSelector(state => state.loadingStore.GET_USER);

  const theme = createTheme({
    palette: {
      confirm: {
        main: 'hsl(124, 44%, 42%)',
        contrastText: 'hsl(0, 0%, 93%)'
      },
      danger: {
        main: 'hsl(354, 70%, 44%)',
        contrastText: 'hsl(0, 0%, 93%)'
      },
      primary: {
        main: 'hsl(203, 100%, 20%)',
        contrastText: 'hsl(0, 0%, 93%)'
      }
    },
  });

  useEffect(() => {
    if (!user && user !== false && !userIsRequesting) {
      dispatch(getUserRequest());
    }
  }, []);

  useEffect(() => {
    if (!!user && !clients?.length) {
      dispatch(getClientListRequest());
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {(user || (user === false && !userIsRequesting)) && (
          <Suspense fallback={<></>}>
            <RouterProvider router={router} />
          </Suspense>
        )}
      </LocalizationProvider>
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={4000} />
    </ThemeProvider>
  );
}

export default App;
