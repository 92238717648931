import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import UserProfile from 'src/components/UserProfile';
import { logoutRequest } from 'src/redux/actions/users';
import { openModal } from 'src/redux/actions/modal';
import { Link, useNavigate } from 'react-router-dom';

const MobileNav = ({ isOpen, setIsOpen }) => {
  const user = useSelector(state => state.userStore?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(logoutRequest());
    setIsOpen(false);
    navigate('/');
  }

  const goToActionPanel = () => {
    dispatch(openModal({
      key: 'actionPanel'
    }));
    setIsOpen(false);
  }

  return (
    <section className="mobile-nav">
      {
        isOpen && <div className="mobile-nav-content">
          <div className="mobile-nav-content-close">
            <FontAwesomeIcon
              className="icon close"
              icon={faXmark}
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="mobile-nav-content-links">
            <div className="mobile-nav-content-links-group">
              {
                user ? <>
                  <div className="mobile-nav-content-links-item">
                    <UserProfile user={user} showEmail />
                  </div>
                  <span className="mobile-nav-content-links-user-item" onClick={() => goToActionPanel()}>My Action Panel</span>
                  <Link className="mobile-nav-content-links-user-item" onClick={() => setIsOpen(false)} to="/account">My Account</Link>
                  <Link className="mobile-nav-content-links-user-item" onClick={() => {logout(); setIsOpen(false);} } to="/">Logout</Link>
                </> : <Link className="mobile-nav-content-links-user-item" to="/login">Sign In</Link>
              }
            </div>
          </div>
        </div>
      }
    </section>
  )
}

export default MobileNav;
