import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes, productTypes } from 'src/constants';
import { updateContestAwardRequest } from 'src/redux/actions/contests';

const AwardForm = ({ award, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.sequence = event.sequence || contest.categories?.length || 0;
    dispatch(updateContestAwardRequest({ clientURL: client.url, contestURL: contest.url, form: {...(award || {}), ...event} }));
  }

  const contestConfiguration = {
    formElements: {
      rows: [{
        forward: 'Awards marked as high priority will cause any entries tagged with them to be displayed before all other entries, regardless of category. It is encouraged that only a few entries are tagged with priority awards. (ex: \'Best In Show\')'
      }, {
        elements: [{
          label: 'Award Name',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: award?.name || '',
          required: true
        }]
      }, {
        elements: [{
          label: 'High Priority Award',
          name: 'priority',
          type: fieldTypes.checkbox,
          value: award?.priority || false
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  const scholarshipConfiguration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Award Name',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: award?.name || '',
          required: true
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="award-form">
      <AutoForm
        configuration={contest?.type === productTypes.contest ? contestConfiguration : scholarshipConfiguration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_CONTEST_AWARD'}
      />
    </section>
  )
}

export default AwardForm;
