import React from 'react';
import { useSelector } from 'react-redux';
import { productTypes } from 'src/constants';
import ContestArchiveForm from './ContestArchiveForm';
import ScholarshipArchiveForm from './ScholarshipArchiveForm';

const ArchiveForm = ({ archive }) => {
  const contest = useSelector(state => state.contestStore?.contest);

  if (contest?.type === productTypes.contest) {
    return <ContestArchiveForm archive={archive} />
  }

  if (contest?.type === productTypes.scholarship) {
    return <ScholarshipArchiveForm archive={archive} />
  }
}

export default ArchiveForm;
