import React, { useEffect, useState } from 'react';
import {
  connect,
  useDispatch,
  useSelector
} from 'react-redux';
import {
  downloadContestEntryFormRequest,
  downloadContestScoresRequest,
  downloadContestWinnersRequest,
  getContestRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select
} from '@mui/material';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import LoadingCircles from 'src/components/Elements/LoadingCircles';
import createLoadingSelector from 'src/redux/loading';

const ContestFinalReports = ({ isDownloading }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const [downloadType, setDownloadType] = useState('none');
  const [entryForm, setEntryForm] = useState('');
  const [scoresheet, setScoresheet] = useState('');
  const [onlyWinners, setOnlyWinners] = useState(false);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  const downloadTypes = [{
    label: 'Choose One',
    value: 'none'
  }, {
    label: 'Entry Form Responses',
    value: 'entry_form'
  }, {
    label: 'Entry/Winner List',
    value: 'entries'
  }, {
    label: 'Scoring Report',
    value: 'scoring'
  }];

  const handleEntryFormChange = ({ value }) => {
    setEntryForm(value);
  }

  const handleScoresheetChange = ({ value }) => {
    setScoresheet(value);
  }

  const handleDownload = () => {
    if (downloadType === 'entry_form') {
      dispatch(downloadContestEntryFormRequest({
        clientURL: client?.url,
        contestURL: contest?.url,
        entryFormID: entryForm,
        onlyWinners
      }));

      return;
    }
    if (downloadType === 'scoring') {
      dispatch(downloadContestScoresRequest({
        clientURL: client?.url,
        contestURL: contest?.url,
        scoresheetID: scoresheet,
        onlyWinners
      }));

      return;
    }
    if (downloadType === 'entries') {
      dispatch(downloadContestWinnersRequest({
        clientURL: client?.url,
        contestURL: contest?.url,
        contestID: contest?.contestID,
        onlyWinners
      }));

      return;
    }
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestFinalReports', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestFinalReports',
      options: { client, contest } 
    }));
  }, [client, contest]);

  useEffect(() => {
    if (contest?.entryForms?.length) {
      setEntryForm(contest?.entryForms[0].entryFormID);
    }
  }, [contest?.entryForms]);

  useEffect(() => {
    if (contest?.scoresheets?.length) {
      setScoresheet(contest?.scoresheets[0].scoresheetID);
    }
  }, [contest?.scoresheets]);

  return (
    <section className="final-reports grid">
      <section className="box">
        <div className="box-title">
          <h3>
            {
              contestFetching ? <LoadingCircle variant="dark" /> :
              <span>Download Reports {
                isDownloading && <LoadingCircle variant="dark" />
              }
              </span>
            }
          </h3>
        </div>
        {
          !contestFetching && <div className="box-content options">
            <div className="select">
              <label>Select A Report:</label>
              <Select
                onChange={e => setDownloadType(e.target.value)}
                value={downloadType}
              >{
                downloadTypes?.map(type => {
                  return (
                    <MenuItem
                      key={type.value}
                      value={type.value}
                    >{type.label}</MenuItem>
                  )
                })
              }</Select>
            </div>
            {
              downloadType === 'entry_form' && <div className="select">
                <label>Select An Entry Form:</label>
                <Select 
                  onChange={event => handleEntryFormChange({ value: event.target.value })}
                  value={entryForm}
                >{
                  (contest?.entryForms || [])?.map(entryForm => {
                    return (
                      <MenuItem
                        key={entryForm.entryFormID}
                        value={entryForm.entryFormID}
                      >{entryForm.name}</MenuItem>
                    )
                  })
                }</Select>
              </div>
            }
            {
              downloadType === 'scoring' && <div className="select">
                <label>Select A Scoresheet:</label>
                <Select 
                  onChange={event => handleScoresheetChange({ value: event.target.value })}
                  value={scoresheet}
                >{
                  (contest?.scoresheets || [])?.map(scoresheet => {
                    return (
                      <MenuItem
                        key={scoresheet.scoresheetID}
                        value={scoresheet.scoresheetID}
                      >{scoresheet.name}</MenuItem>
                    )
                  })
                }</Select>
              </div>
            }
            { downloadType !== 'none' && <>
                <FormControl className="form-field">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={onlyWinners}
                          onChange={e => setOnlyWinners(e.target.checked)}
                          sx={{ fontSize: '14px', padding: '0 9px' }}
                        />
                      }
                      label="Only Show Winners"
                    />
                  </FormGroup>
                </FormControl>
                <Button
                  color="confirm"
                  onClick={handleDownload}
                  variant="contained"
                >{ isDownloading ? <LoadingCircles /> : 'Download' }</Button>
              </>
            }
          </div>
        }
      </section>
    </section>
  )
}

const loadingSelector = createLoadingSelector([
  'DOWNLOAD_CONTEST_ENTRY_FORM',
  'DOWNLOAD_CONTEST_SCORES',
  'DOWNLOAD_CONTEST_WINNERS'
]);
const mapStateToProps = (state) => ({ isDownloading: loadingSelector(state) });
export default connect(mapStateToProps)(ContestFinalReports);
