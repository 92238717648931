import React from 'react';

const ViewImage = ({ image }) => {

  return (
    <section className="view-image">
      <img src={image.url} alt={image.name} />
    </section>
  )
}

export default ViewImage;
