import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getParadeRequest,
  updateParadeSEORequest
} from 'src/redux/actions/parades';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { fieldTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const ParadeSEO = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const [configuration, setConfiguration] = useState(null);
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  const handleSubmit = (event) => {
    dispatch(updateParadeSEORequest({ clientURL: client.url, paradeURL, form: event }));
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeSEO', client, parade }));
    dispatch(updateDrawerContent({
      key: 'paradeSEO',
      options: { client, parade } 
    }));

    if (!configuration && parade) {
      setConfiguration({
        formElements: {
          rows: [{
            elements: [{
              label: 'Description',
              name: 'description',
              type: fieldTypes.textarea,
              value: parade?.description
            }]
          }]
        },
        submitCTA: 'Save'
      });
    }
  }, [client, parade]);

  return (
    <section className="parade-name box">
      <div className="box-title">
        <h3>
            {
              !paradeFetching && (
                <span>Search Engine Optimization</span>
              )
            }
            {
              paradeFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        (!paradeFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_PARADE_SEO'}
          />
        </div>
        )
      }
    </section>
  )
}

export default ParadeSEO;
