import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContestRequest,
  reorderContestSponsorTiersRequest,
  updateContestSponsorOptionsRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import { fieldTypes, sponsorPlacements, sponsorSizes, yesOrNo } from 'src/constants';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import ResourceList from 'src/components/ResourceList';
import AutoForm from 'src/components/AutoForm';

const ContestSponsors = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const updatingSponsorSettings = useSelector(state => state.loadingStore.UPDATE_CONTEST_SPONSOR_SETTINGS);
  const updatingSponsorTiers = useSelector(state => state.loadingStore.REORDER_CONTEST_SPONSOR_TIERS);

  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  const groupActions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, contest },
          key: 'createContestSponsorTier'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: sponsorTier => {
      dispatch(
        openModal({
          props: { client, contest, sponsorTier },
          key: 'editContestSponsorTier'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: sponsorTier => {
      dispatch(
        openDialog({
          props: { client, contest, sponsorTier },
          key: 'removeContestSponsorTier'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestSponsorOptions', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestSponsorOptions',
      options: { client, contest } 
    }));
  }, [client, contest]);

  const reorderSponsorTierList = ({ list }) => {
    dispatch(reorderContestSponsorTiersRequest({ clientURL: client.url, contestURL: contest.url, form: { sponsorTiers: list } }));
  }

  const handleSettingsChange = (event) => {
    dispatch(updateContestSponsorOptionsRequest({ clientURL: client.url, contestURL: contest.url, form: event }));
  }

  const configuration = {
    formElements: {
      rows: [{
        forward: 'Tiers allow sponsors to be grouped into sections for more prominent display styles. Examples include \'Platinum\', \'Gold\', etc.'
      }, {
        elements: [{
          label: 'Sponsors Will Be Grouped Into Tiers',
          name: 'tieredSponsors',
          type: fieldTypes.select,
          options: yesOrNo,
          value: contest?.tieredSponsors || false
        }, {
          label: 'Default Sponsor Size',
          name: 'defaultSponsorSize',
          hide: { key: 'tieredSponsors', value: true },
          type: fieldTypes.select,
          options: sponsorSizes,
          value: contest?.defaultSponsorSize || 'medium'
        }]
      }, {
        elements: [{
          label: 'Sponsor list location',
          name: 'sponsorPlacement',
          type: fieldTypes.select,
          options: sponsorPlacements,
          value: contest?.sponsorPlacement || 'page'
        }]
      }]
    },
    submitCTA: 'Save'
  };
  
  return (
    <section className="sponsors grid">
      <section className="box">
        <div className="box-title">
          <h3>
              {
                !contestFetching && (
                  <span>Sponsor Tiers & Options
                  {
                    updatingSponsorSettings && (
                      <LoadingCircle variant="dark" />
                    )
                  }
                  </span>
                )
              }
              {
                contestFetching && (
                  <LoadingCircle variant="dark" />
                )
              }
            </h3>
        </div>
        {
          !contestFetching && (
          <div className="box-content options">
            <AutoForm
              configuration={configuration}
              handleSubmit={handleSettingsChange}
              requestPrefix={'UPDATE_CONTEST_SPONSOR_OPTIONS'}
            />
          </div>
          )
        }
      </section>
      { (!contestFetching && contest?.tieredSponsors) &&
        <ResourceList
          actions={groupActions}
          draggable
          fetching={contestFetching}
          handleDrag={reorderSponsorTierList}
          list={contest?.sponsorTiers}
          renderItem={sponsorTier =>
            <div className="sponsor-tier">{sponsorTier.name}</div>
          }
          renderKey="sponsorTierID"
          title="Sponsor Tiers"
          updating={updatingSponsorTiers}
        />
      }
    </section>
  )
}

export default ContestSponsors;