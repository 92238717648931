export const AUTHORIZE_SQUARE_REQUEST = 'AUTHORIZE_SQUARE_REQUEST';
export const AUTHORIZE_SQUARE_SUCCESS = 'AUTHORIZE_SQUARE_SUCCESS';
export const AUTHORIZE_SQUARE_FAILED = 'AUTHORIZE_SQUARE_FAILED';

export const AUTHORIZE_STRIPE_REQUEST = 'AUTHORIZE_STRIPE_REQUEST';
export const AUTHORIZE_STRIPE_SUCCESS = 'AUTHORIZE_STRIPE_SUCCESS';
export const AUTHORIZE_STRIPE_FAILED = 'AUTHORIZE_STRIPE_FAILED';

export const CLEAR_CLIENT_LIST = 'CLEAR_CLIENT_LIST';

export const GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILED = 'GET_CLIENT_FAILED';

export const GET_CLIENT_INVOICES_REQUEST = 'GET_CLIENT_INVOICES_REQUEST';
export const GET_CLIENT_INVOICES_SUCCESS = 'GET_CLIENT_INVOICES_SUCCESS';
export const GET_CLIENT_INVOICES_FAILED = 'GET_CLIENT_INVOICES_FAILED';

export const GET_CLIENT_LIST_REQUEST = 'GET_CLIENT_LIST_REQUEST';
export const GET_CLIENT_LIST_SUCCESS = 'GET_CLIENT_LIST_SUCCESS';
export const GET_CLIENT_LIST_FAILED = 'GET_CLIENT_LIST_FAILED';

export const GET_CLIENT_VOLUNTEER_LIST_REQUEST = 'GET_CLIENT_VOLUNTEER_LIST_REQUEST';
export const GET_CLIENT_VOLUNTEER_LIST_SUCCESS = 'GET_CLIENT_VOLUNTEER_LIST_SUCCESS';
export const GET_CLIENT_VOLUNTEER_LIST_FAILED = 'GET_CLIENT_VOLUNTEER_LIST_FAILED';

export const PAY_INVOICE_REQUEST = 'PAY_INVOICE_REQUEST';
export const PAY_INVOICE_SUCCESS = 'PAY_INVOICE_SUCCESS';
export const PAY_INVOICE_FAILED = 'PAY_INVOICE_FAILED';

export const REMOVE_ADMINISTRATOR_REQUEST = 'REMOVE_ADMINISTRATOR_REQUEST';
export const REMOVE_ADMINISTRATOR_SUCCESS = 'REMOVE_ADMINISTRATOR_SUCCESS';
export const REMOVE_ADMINISTRATOR_FAILED = 'REMOVE_ADMINISTRATOR_FAILED';

export const REMOVE_ADMINISTRATOR_INVITATION_REQUEST = 'REMOVE_ADMINISTRATOR_INVITATION_REQUEST';
export const REMOVE_ADMINISTRATOR_INVITATION_SUCCESS = 'REMOVE_ADMINISTRATOR_INVITATION_SUCCESS';
export const REMOVE_ADMINISTRATOR_INVITATION_FAILED = 'REMOVE_ADMINISTRATOR_INVITATION_FAILED';

export const REMOVE_LOGO_REQUEST = 'REMOVE_LOGO_REQUEST';
export const REMOVE_LOGO_SUCCESS = 'REMOVE_LOGO_SUCCESS';
export const REMOVE_LOGO_FAILED = 'REMOVE_LOGO_FAILED';

export const REVOKE_SQUARE_REQUEST = 'REVOKE_SQUARE_REQUEST';
export const REVOKE_SQUARE_SUCCESS = 'REVOKE_SQUARE_SUCCESS';
export const REVOKE_SQUARE_FAILED = 'REVOKE_SQUARE_FAILED';

export const REVOKE_STRIPE_REQUEST = 'REVOKE_STRIPE_REQUEST';
export const REVOKE_STRIPE_SUCCESS = 'REVOKE_STRIPE_SUCCESS';
export const REVOKE_STRIPE_FAILED = 'REVOKE_STRIPE_FAILED';

export const UPDATE_ADMINISTRATOR_REQUEST = 'UPDATE_ADMINISTRATOR_REQUEST';
export const UPDATE_ADMINISTRATOR_SUCCESS = 'UPDATE_ADMINISTRATOR_SUCCESS';
export const UPDATE_ADMINISTRATOR_FAILED = 'UPDATE_ADMINISTRATOR_FAILED';

export const UPDATE_ADMINISTRATOR_INVITATION_REQUEST = 'UPDATE_ADMINISTRATOR_INVITATION_REQUEST';
export const UPDATE_ADMINISTRATOR_INVITATION_SUCCESS = 'UPDATE_ADMINISTRATOR_INVITATION_SUCCESS';
export const UPDATE_ADMINISTRATOR_INVITATION_FAILED = 'UPDATE_ADMINISTRATOR_INVITATION_FAILED';

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILED = 'UPDATE_CLIENT_FAILED';

export const UPDATE_CLIENT_GATEWAY_REQUEST = 'UPDATE_CLIENT_GATEWAY_REQUEST';
export const UPDATE_CLIENT_GATEWAY_SUCCESS = 'UPDATE_CLIENT_GATEWAY_SUCCESS';
export const UPDATE_CLIENT_GATEWAY_FAILED = 'UPDATE_CLIENT_GATEWAY_FAILED';

export const UPDATE_CLIENT_OPTIONS_REQUEST = 'UPDATE_CLIENT_OPTIONS_REQUEST';
export const UPDATE_CLIENT_OPTIONS_SUCCESS = 'UPDATE_CLIENT_OPTIONS_SUCCESS';
export const UPDATE_CLIENT_OPTIONS_FAILED = 'UPDATE_CLIENT_OPTIONS_FAILED';

export const UPDATE_CLIENT_SETTINGS_REQUEST = 'UPDATE_CLIENT_SETTINGS_REQUEST';
export const UPDATE_CLIENT_SETTINGS_SUCCESS = 'UPDATE_CLIENT_SETTINGS_SUCCESS';
export const UPDATE_CLIENT_SETTINGS_FAILED = 'UPDATE_CLIENT_SETTINGS_FAILED';

export const UPDATE_CLIENT_SOCIAL_REQUEST = 'UPDATE_CLIENT_SOCIAL_REQUEST';
export const UPDATE_CLIENT_SOCIAL_SUCCESS = 'UPDATE_CLIENT_SOCIAL_SUCCESS';
export const UPDATE_CLIENT_SOCIAL_FAILED = 'UPDATE_CLIENT_SOCIAL_FAILED';

export const UPLOAD_LOGO_REQUEST = 'UPLOAD_LOGO_REQUEST';
export const UPLOAD_LOGO_SUCCESS = 'UPLOAD_LOGO_SUCCESS';
export const UPLOAD_LOGO_FAILED = 'UPLOAD_LOGO_FAILED';

export function authorizeSquareRequest (body) {
  return {
    body,
    type: AUTHORIZE_SQUARE_REQUEST
  }
}

export function authorizeStripeRequest (body) {
  return {
    body,
    type: AUTHORIZE_STRIPE_REQUEST
  }
}

export function clearClientRequest () {
  return {
    type: CLEAR_CLIENT_LIST
  }
}

export function getClientRequest (body) {
  return {
    body,
    type: GET_CLIENT_REQUEST
  }
}

export function getClientInvoicesRequest (body) {
  return {
    body,
    type: GET_CLIENT_INVOICES_REQUEST
  }
}

export function getClientListRequest () {
  return {
    type: GET_CLIENT_LIST_REQUEST
  }
}

export function getClientVolunteerListRequest () {
  return {
    type: GET_CLIENT_VOLUNTEER_LIST_REQUEST
  }
}

export function payInvoiceRequest (body) {
  return {
    body,
    type: PAY_INVOICE_REQUEST
  }
}

export function removeAdministratorRequest(body) {
  return {
    body,
    type: REMOVE_ADMINISTRATOR_REQUEST
  }
}

export function removeAdministratorInvitationRequest(body) {
  return {
    body,
    type: REMOVE_ADMINISTRATOR_INVITATION_REQUEST
  }
}

export function removeLogoRequest (body) {
  return {
    body,
    type: REMOVE_LOGO_REQUEST
  }
}

export function revokeSquareRequest (body) {
  return {
    body,
    type: REVOKE_SQUARE_REQUEST
  }
}

export function revokeStripeRequest (body) {
  return {
    body,
    type: REVOKE_STRIPE_REQUEST
  }
}

export function updateAdministratorRequest(body) {
  return {
    body,
    type: UPDATE_ADMINISTRATOR_REQUEST
  }
}

export function updateAdministratorInvitationRequest(body) {
  return {
    body,
    type: UPDATE_ADMINISTRATOR_INVITATION_REQUEST
  }
}

export function updateClientRequest (body) {
  return {
    body,
    type: UPDATE_CLIENT_REQUEST
  }
}

export function updateClientGatewayRequest (body) {
  return {
    body,
    type: UPDATE_CLIENT_GATEWAY_REQUEST
  }
}

export function updateClientOptionsRequest (body) {
  return {
    body,
    type: UPDATE_CLIENT_OPTIONS_REQUEST
  }
}

export function updateClientSettingsRequest (body) {
  return {
    body,
    type: UPDATE_CLIENT_SETTINGS_REQUEST
  }
}

export function updateClientSocialRequest (body) {
  return {
    body,
    type: UPDATE_CLIENT_SOCIAL_REQUEST
  }
}

export function uploadLogoRequest (body) {
  return {
    body,
    type: UPLOAD_LOGO_REQUEST
  }
}

