import React, { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

const CurrencyField = forwardRef((props, ref) => {
  const { onChange, ...rest } = props;
  return (
    <NumericFormat
      {...rest}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      fixedDecimalScale
      decimalScale={2}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
});

export default CurrencyField;
