import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContestRequest,
  reorderContestFeesRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';

const ContestOptionalFees = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const updatingFees = useSelector(state => state.loadingStore.REORDER_CONTEST_OPTIONAL_FEES);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, contest },
          key: 'createContestOptionalFee'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: fee => {
      dispatch(
        openModal({
          props: { client, contest, fee },
          key: 'editContestOptionalFee'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: fee => {
      dispatch(
        openDialog({
          props: { client, contest, fee },
          key: 'removeContestOptionalFee'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestOptionalFees', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestOptionalFees',
      options: { client, contest } 
    }));
  }, [client, contest]);

  const reorderFeeList = ({ list }) => {
    dispatch(reorderContestFeesRequest({ clientURL: client.url, contestURL: contest.url, form: { fees: list } }));
  }
  
  return (
    <ResourceList
      actions={actions}
      draggable
      fetching={contestFetching}
      handleDrag={reorderFeeList}
      list={contest?.fees}
      renderItem={fee => 
        <div className="fee">
          <div className="fee-name">{fee.name}</div>
        </div>
      }
      renderKey="name"
      title="Optional Fees"
      updating={updatingFees}
    />
  )
}

export default ContestOptionalFees;