import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import HeaderComponent from 'src/components/Header';
import BreadcrumbsComponent from 'src/components/Breadcrumbs';
import ModalContainer from 'src/components/ModalContainer';
import DialogContainer from 'src/components/DialogContainer';
import DrawerComponent from 'src/components/Drawer';
import MobileDrawerContainer from '../MobileDrawerContainer';

const Layout = () => {
  const location = useLocation();
  const ref = useRef();

  const [leftDrawerOpen, setLeftDrawerOpen] = useState();
  const [rightDrawerOpen, setRightDrawerOpen] = useState();

  useEffect(() => {
    ref.current.scrollTo(0,0);
  }, [location]);

  return (
    <section className="layout">
      <HeaderComponent />
      <section className="layout-container">
        <MobileDrawerContainer
          leftDrawerOpen={leftDrawerOpen}
          rightDrawerOpen={rightDrawerOpen}
          setLeftDrawerOpen={setLeftDrawerOpen}
          setRightDrawerOpen={setRightDrawerOpen}
        />
        <DrawerComponent isOpen={leftDrawerOpen} setOpenState={setLeftDrawerOpen} />
        <section className="layout-container-active">
          <BreadcrumbsComponent />
          <div className="outlet" ref={ref}>
            <Outlet />
          </div>
        </section>
        <DrawerComponent right isOpen={rightDrawerOpen} setOpenState={setRightDrawerOpen}/>
      </section>
      <ModalContainer />
      <DialogContainer />
    </section>
  )
};

export default Layout;
