import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getContestRequest,
  updateContestDatesRequest
} from 'src/redux/actions/contests';
import { fieldTypes, inputTypes, productTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import {
  getFirstMinuteToday,
  getLastMinuteToday,
  parseDateTime
} from 'src/utilities/dates';

const ContestDates = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestDates', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestDates',
      options: { client, contest } 
    }));
  }, [client, contest]);

  const handleContestSubmit = (event) => {
    const entryStartDate = parseDateTime({ date: event.entryStartDate });
    const entryEndDate = parseDateTime({ date: event.entryEndDate });
    const judgingStartDate = parseDateTime({ date: event.judgingStartDate });
    const judgingEndDate = parseDateTime({ date: event.judgingEndDate });
    dispatch(updateContestDatesRequest({ clientURL: client.url, contestURL: contest.url, form: {
      ...event, entryStartDate, entryEndDate, judgingStartDate, judgingEndDate
    } }));
  };

  const handleScholarshipSubmit = (event) => {
    const judgingStartDate = parseDateTime({ date: event.judgingStartDate });
    const judgingEndDate = parseDateTime({ date: event.judgingEndDate });
    dispatch(updateContestDatesRequest({ clientURL: client.url, contestURL: contest.url, form: {
      ...event, judgingStartDate, judgingEndDate
    } }));
  };

  const contestConfiguration = {
    formElements: {
      rows: [{
        heading: 'Entry Management Window'
      }, {
        elements: [{
          label: 'Start Date',
          name: 'entryStartDate',
          dateRangeEnd: 'entryEndDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: contest?.entryStartDate || getFirstMinuteToday(),
          required: true
        }, {
          label: 'End Date',
          name: 'entryEndDate',
          dateRangeStart: 'entryStartDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: contest?.entryEndDate || getLastMinuteToday(),
          required: true
        }]
      }, {
        heading: 'Scoring Window'
      }, {
        elements: [{
          label: 'Start Date',
          name: 'judgingStartDate',
          dateRangeEnd: 'judgingEndDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: contest?.judgingStartDate || getFirstMinuteToday(),
          required: true
        }, {
          label: 'End Date',
          name: 'judgingEndDate',
          dateRangeStart: 'judgingStartDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: contest?.judgingEndDate || getLastMinuteToday(),
          required: true
        }]
      }]
    },
    submitCTA: 'Save'
  };

  const scholarshipConfiguration = {
    formElements: {
      rows: [{
        heading: 'Scoring Window'
      }, {
        elements: [{
          label: 'Start Date',
          name: 'judgingStartDate',
          dateRangeEnd: 'judgingEndDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: contest?.judgingStartDate || getFirstMinuteToday(),
          required: true
        }, {
          label: 'End Date',
          name: 'judgingEndDate',
          dateRangeStart: 'judgingStartDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: contest?.judgingEndDate || getLastMinuteToday(),
          required: true
        }]
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="box">
      <div className="box-title">
        <h3>
            {
              !contestFetching && (
                <span>Dates</span>
              )
            }
            {
              contestFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        (!contestFetching && (contestConfiguration || scholarshipConfiguration)) && (
        <div className="box-content">
          <AutoForm
            configuration={contest?.type === productTypes.contest ? contestConfiguration : scholarshipConfiguration}
            handleSubmit={contest?.type === productTypes.contest ? handleContestSubmit : handleScholarshipSubmit}
            requestPrefix={'UPDATE_CONTEST_DATES'}
          />
        </div>
        )
      }
    </section>
  )
}

export default ContestDates;
