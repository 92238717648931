import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import {
  categoryRestrictions,
  fieldTypes,
  inputTypes,
  productTypes,
  restrictionTypes,
  scholarshipRestrictions,
  yesOrNo
} from 'src/constants';
import { updateContestScoresheetRequest } from 'src/redux/actions/contests';
import defaultSelectOption from 'src/utilities/defaultSelectOption';
import {
  assembleExistingList,
  existingCategoriesMap
} from 'src/utilities/existingCategory';
import { existingScholarshipsMap } from 'src/utilities/existingScholarship';

const ScoresheetForm = ({ scoresheet, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const [configuration, setConfiguration] = useState();
  const [otherSheetHasFullRestriction, setOtherSheetHasFullRestriction] = useState(false);
  const [otherSheetHasSelectedItems, setOtherSheetHasSelectedItems] = useState(false);
  const [name, setName] = useState(scoresheet?.name || '');
  const [requireComment, setRequireComment] = useState(defaultSelectOption({ defaultValue: true, value: scoresheet?.requireComment }));
  const [description, setDescription] = useState(scoresheet?.description || '');
  const [restriction, setRestriction] = useState(scoresheet?.restriction || restrictionTypes.all);

  useEffect(() => {
    setConfiguration(contest?.type === productTypes.contest ? createContestConfiguration() : createScholarshipConfiguration());
  }, []);

  useEffect(() => {
    if (contest?.name) {
      setOtherSheetHasFullRestriction(hasFullRestriction());
      setOtherSheetHasSelectedItems(hasSelectedItems());
    }
  }, [contest?.name]);

  useEffect(() => {
    setConfiguration(contest?.type === productTypes.contest ? createContestConfiguration() : createScholarshipConfiguration());
  }, [restriction, otherSheetHasFullRestriction]);

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.sequence = event.sequence || contest.scoresheets?.length || 0;
    event.assignedCategories = assembleExistingList({ form: event });
    dispatch(updateContestScoresheetRequest({ clientURL: client.url, contestURL: contest.url, form: {...(scoresheet || {}), ...event} }));
  }

  const onChange = ({ element, value }) => {
    if (element?.name === 'name') {
      setName(value);
    }
    if (element?.name === 'description') {
      setDescription(value);
    }
    if (element?.name === 'requireComment') {
      setRequireComment(value);
    }
    if (element?.name === 'restriction') {
      setRestriction(value);
    }
  }

  const restrictedCategoryMap = () => {
    const map = new Map();

    if (otherSheetHasFullRestriction) {
      contest?.categories.forEach(category => map.set(category.categoryID, true));
      return map;
    }

    contest?.scoresheets
      .filter(sheet => sheet.scoresheetID !== scoresheet?.scoresheetID)
      .forEach(sheet => {
        sheet.assignedCategories.forEach(category => map.set(category, true));
      });

    return map;
  }

  const restrictedScholarshipMap = () => {
    const map = new Map();

    if (otherSheetHasFullRestriction) {
      contest?.scholarships.forEach(scholarship => map.set(scholarship.scholarshipID, true));
      return map;
    }

    contest?.scoresheets
      .filter(sheet => sheet.scoresheetID !== scoresheet?.scoresheetID)
      .forEach(sheet => {
        sheet.assignedCategories.forEach(scholarship => map.set(scholarship, true));
      });

    return map;
  }

  const hasFullRestriction = () => {
    return contest?.scoresheets
      .filter(sheet => sheet.scoresheetID !== scoresheet?.scoresheetID)
      .some(sheet => sheet.restriction === restrictionTypes.all);
  }

  const hasSelectedItems = () => {
    return contest?.scoresheets
      .filter(sheet => sheet.scoresheetID !== scoresheet?.scoresheetID)
      .some(sheet => !!sheet.assignedCategories?.length);
  }

  const createContestConfiguration = () => {
    const config = {
      formElements: {
        rows: [{
          elements: [{
            label: 'Scoresheet Name',
            name: 'name',
            type: fieldTypes.input,
            inputType: inputTypes.text,
            value: name,
            required: true
          }]
        }, {
          elements: [{
            label: 'Require Comments For This Scoresheet',
            name: 'requireComment',
            type: fieldTypes.select,
            options: yesOrNo,
            value: requireComment
          }]
        }, {
          elements: [{
            label: 'Enhanced Scoresheet Description',
            name: 'description',
            type: fieldTypes.textarea,
            value: description
          }]
        }, {
          elements: [{
            label: 'This Scoresheet Is Available For',
            name: 'restriction',
            type: fieldTypes.select,
            options: categoryRestrictions,
            value: restriction
          }]
        }]
      },
      submitDisabled: ((otherSheetHasSelectedItems || otherSheetHasFullRestriction) && restriction === restrictionTypes.all) ?
        'Please note - only one scoresheet per category is permitted.' : null,
      submitCTA: 'Save',
      cancelCTA: 'Cancel'
    };

    if (restriction === restrictionTypes.specific) {
      const restrictedMap = restrictedCategoryMap();
      return { ...config, formElements: { ...config.formElements, rows: [...config.formElements.rows, ...existingCategoriesMap({
        categories: contest?.categories,
        currentlyAllowed: scoresheet?.assignedCategories,
        restrictedMap
      })] }};
    }

    return config;
  }

  const createScholarshipConfiguration = () => {
    const config = {
      formElements: {
        rows: [{
          elements: [{
            label: 'Scoresheet Name',
            name: 'name',
            type: fieldTypes.input,
            inputType: inputTypes.text,
            value: name,
            required: true
          }]
        }, {
          elements: [{
            label: 'Require Comments For This Scoresheet',
            name: 'requireComment',
            type: fieldTypes.select,
            options: yesOrNo,
            value: requireComment
          }]
        }, {
          elements: [{
            label: 'Enhanced Scoresheet Description',
            name: 'description',
            type: fieldTypes.textarea,
            value: description
          }]
        }, {
          elements: [{
            label: 'This Scoresheet Is Available For',
            name: 'restriction',
            type: fieldTypes.select,
            options: scholarshipRestrictions,
            value: restriction
          }]
        }]
      },
      submitDisabled: ((otherSheetHasSelectedItems || otherSheetHasFullRestriction) && restriction === restrictionTypes.all) ?
        'Please note - only one scoresheet per scholarship is permitted.' : null,
      submitCTA: 'Save',
      cancelCTA: 'Cancel'
    };

    if (restriction === restrictionTypes.specific) {
      const restrictedMap = restrictedScholarshipMap();
      return { ...config, formElements: { ...config.formElements, rows: [...config.formElements.rows, ...existingScholarshipsMap({
        scholarships: contest?.scholarships,
        currentlyAllowed: scoresheet?.assignedCategories,
        restrictedMap
      })] }};
    }

    return config;
  }

  return (
    <section className="scoresheet-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        onChange={onChange}
        requestPrefix={'UPDATE_CONTEST_SCORESHEET'}
      />
    </section>
  )
}

export default ScoresheetForm;
