import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getContestRequest,
  updateContestPartnerPageRequest
} from 'src/redux/actions/contests';
import TinyMCE from 'src/components/TinyMCE';

const ContestPartnerPage = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestPartnerPage', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestPartnerPage',
      options: { client, contest } 
    }));
  }, [client, contest]);

  const handleSubmit = (event) => {
    dispatch(updateContestPartnerPageRequest({ clientURL: client.url, contestURL: contest.url, form: { partnerPageText: event } }));
  }

  return (
    contest && (
      <TinyMCE
        handleSubmit={(event => handleSubmit(event))}
        requestPrefix="UPDATE_CONTEST_PARTNER_PAGE"
        title="Partner Page Content"
        value={contest?.partnerPageText}
      />
    )
  )
}

export default ContestPartnerPage;
