import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { fieldTypes, inputTypes } from 'src/constants';
import { validateFormField } from 'src/utilities/validate-form-field';
import createLoadingSelector from 'src/redux/loading';
import LoadingCircles from 'src/components/Elements/LoadingCircles';
import CheckboxField from './CheckboxField';
import InputField from './InputField';
import MultiSelectField from './MultiSelectField';
import RadioField from './RadioField';
import SelectField from './SelectField';
import TextareaField from './TextareaField';
import { Button, FormHelperText } from '@mui/material';
import { parseDate, parseTime } from 'src/utilities/dates';

const AutoForm = ({
  configuration,
  handleCancel,
  handleRemove,
  handleSubmit,
  onChange,
  requestPrefix,
  submitOnEnter
}) => {
  const loadingSelector = createLoadingSelector((typeof requestPrefix === 'string' ? [requestPrefix] : [...requestPrefix]));
  const isRequesting = useSelector(state => loadingSelector(state));
  const [errors, setErrors] = useState({});
  const [formState, setFormState] = useState({});
  const [formValid, setFormValid] = useState(true);

  const formIsValid = (errors) => {
    return Array.from(Object.values(errors)).some(error => {
      return !!error;
    });
  }

  const comparePasswords = (element) => {
    if (element.value !== formState[(element.mustMatchPassword || element.passwordToMatch)]) {
      return 'Passwords must match';
    }

    return undefined;
  }

  const compareDates = (element) => {
    const startValue = element.dateRangeStart ? formState[element.dateRangeStart] : element.value;
    const endValue = element.dateRangeEnd ? formState[element.dateRangeEnd] : element.value;
    if (startValue && endValue && moment(startValue).isAfter(endValue)) {
      return 'End date must come after start date';
    }

    return undefined;
  }

  const compareTimes = (element) => {
    const startValue = element.timeRangeStart ? formState[element.timeRangeStart] : element.value;
    const endValue = element.timeRangeEnd ? formState[element.timeRangeEnd] : element.value;
    if (startValue && endValue && moment(startValue).isAfter(endValue)) {
      return 'End time must come after start time';
    }

    return undefined;
  }

  const isRestrictedTime = (element) => {
    const dateObj = parseDate({ date: element.name === 'date' ? element.value : formState['date'] });
    const startValue = parseTime({ date: element.timeRangeStart ? formState[element.timeRangeStart] : element.value });
    const endValue = parseTime({ date: element.timeRangeEnd ? formState[element.timeRangeEnd] : element.value });
    if (startValue && endValue && element.restrictedDates.some(date => {
      const mStartValue = moment.utc(`${dateObj}T${startValue}`);
      const eStartValue = moment.utc(`${dateObj}T${endValue}`);
      const mDateValue = moment.utc(date.startDate);
      const eDateValue = moment.utc(date.endDate);

      return mStartValue.isSame(mDateValue) ||
        eStartValue.isSame(eDateValue) ||
        (mStartValue.isAfter(mDateValue) && mStartValue.isBefore(eDateValue)) ||
        (eStartValue.isAfter(mDateValue) && eStartValue.isBefore(eDateValue))
    })) {
      return 'Cannot overlap another date / time';
    }
    
    return undefined;
  }

  const compareValues = (element) => {
    const minValue = element.minValue ? formState[element.minValue] : element.value;
    const maxValue = element.maxValue ? formState[element.maxValue] : element.value;
    if (minValue && maxValue && minValue > maxValue) {
      return 'Minimum value cannot exceed maximum';
    }

    return undefined;
  }

  const errorCheck = (element) => {
    const {
      [element.name]: oldError,
      [element.dateRangeEnd]: oldDateRangeError,
      [element.timeRangeStart]: oldTimeStartError,
      [element.timeRangeEnd]: oldTimeRangeError,
      [element.mustMatchPassword]: oldComparePasswordError,
      ...otherErrors
    } = errors;
    const validError = validateFormField(element);
    const updatedErrors = { [element.name]: validError, ...otherErrors };

    if (element.mustMatchPassword || element.passwordToMatch) {
      updatedErrors[(element.mustMatchPassword || element.name)] = comparePasswords(element) || updatedErrors[(element.mustMatchPassword || element.name)];
    }

    if (element.dateRangeStart || element.dateRangeEnd) {
      updatedErrors[(element.dateRangeEnd || element.name)] = compareDates(element) || updatedErrors[(element.dateRangeEnd || element.name)];
    }

    if ((element.timeRangeStart || element.timeRangeEnd) && element.name !== 'name') {
      updatedErrors[(element.timeRangeEnd || element.name)] = compareTimes(element) || updatedErrors[(element.timeRangeEnd || element.name)];
    }
    
    if (element.restrictedDates) {
      updatedErrors[(element.timeRangeStart || element.name)] = isRestrictedTime(element) || updatedErrors[(element.timeRangeStart || element.name)];
    }

    if (element.minValue || element.maxValue) {
      updatedErrors[(element.maxValue || element.name)] = compareValues(element);
    }

    const formInvalid = formIsValid(updatedErrors);

    setFormValid(!formInvalid);
    setErrors(updatedErrors);
  }

  const enterKeypress = (event) => {
    if (event.key === 'Enter' && submitOnEnter && typeof window === 'object') {
      document.querySelector('#form-submit').click();
      return;
    }
  }
  
  const handleBlur = (element) => {
    errorCheck(element);
  }

  const handleChange = (value, element) => {
    if (typeof onChange === 'function') {
      onChange({ element, formState, value });
    }
    setFormState({
      ...formState,
      [element.name]: value
    });
    errorCheck({ ...element, value });
  }

  const parseFormResponses = () => {
    (configuration?.formElements.rows || []).forEach(row => {
      return (row.elements || []).forEach(element => {
        if (element.inputType === inputTypes.phone) {
          formState[element.name] = (formState[element.name] || '').replace(/[^0-9]/g, '');
        }
        if (element.inputType === inputTypes.date || element.inputType === inputTypes.dateTime) {
          if (!moment.isMoment(formState[element.name])) {
            formState[element.name] = moment.utc(formState[element.name]);
          }
        }
      });
    });
  }

  const createInitialState = () => {
    return (configuration?.formElements.rows || []).reduce((acc, row) => {
      const values = (row.elements || []).reduce((acc, element) => {
        return {...acc, [element.name]: (element.value === null || element.value === undefined) ? '' : element.value}
      }, {});
      return {...acc, ...values};
    }, {});
  }

  const submit = () => {
    parseFormResponses();
    handleSubmit(formState);
  }

  useEffect(() => {
    setFormState(createInitialState());
    const errors = (configuration?.formElements.rows || []).reduce((acc, row) => {
      const errors = (row.elements || []).filter(element => {
        switch (typeof element?.hide) {
          case 'boolean': return element?.hide === false;
          case 'object': if (element.hide?.value) {
            return formState[element.hide?.key] !== element.hide?.value;
          }

          if (element.hide?.values) {
            return !element.hide?.values.includes(formState[element.hide?.key]);
          }

          return true;

          default: return true;
        }
      }).reduce((acc, element) => {
        return {...acc, [element.name]: validateFormField(element)};
      }, {});
      return {...acc, ...errors};
    }, {});

    const formInvalid = formIsValid(errors);
    setFormValid(!formInvalid);
    setErrors(errors);
  }, [configuration?.formElements.rows]);

  return (
    <form className="form">
      <div className="form-content">
        {
          (configuration?.formElements?.rows || []).map((row, i) => {
            return (
              <div
                className="form-content-row"
                key={i}
              >
                { row.heading && (
                  <div className="heading">{row.heading}</div> 
                ) }
                { row.forward && (
                  <div className="forward">{row.forward}</div> 
                ) }
                { (row.elements || [])
                  .filter(element => {
                    switch (typeof element?.hide) {
                      case 'boolean': return element?.hide === false;
                      case 'object': if (element.hide?.value) {
                        return formState[element.hide?.key] !== element.hide?.value;
                      }
            
                      if (element.hide?.values) {
                        return !element.hide?.values.includes(formState[element.hide?.key]);
                      }
            
                      return true;
                      default: return true;
                    }
                  })
                  .map(element => {
                    if (element.type === fieldTypes.input) {
                      return (
                        <InputField
                          element={element}
                          enterKeypress={enterKeypress}
                          error={errors[element.name]}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          key={element.name}
                          value={formState[element.name]}
                        />
                      )
                    }
                    if (element.type === fieldTypes.textarea) {
                      return (
                        <TextareaField
                          element={element}
                          error={errors[element.name]}
                          key={element.name}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={formState[element.name]}
                        />
                      )
                    }
                    if (element.type === fieldTypes.checkbox) {
                      return (
                        <CheckboxField
                          element={element}
                          error={errors[element.name]}
                          key={element.name}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={formState[element.name]}
                        />
                      )
                    }
                    if (element.type === fieldTypes.select) {
                      return (
                        <SelectField
                          element={element}
                          error={errors[element.name]}
                          key={element.name}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={formState[element.name]}
                        />
                      )
                    }
                    if (element.type === fieldTypes.radio) {
                      return (
                        <RadioField
                          element={element}
                          key={element.name}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={formState[element.name]}
                        />
                      )
                    }
                    if (element.type === fieldTypes.multiSelect) {
                      return (
                        <MultiSelectField
                          element={element}
                          formState={formState}
                          key={element.name}
                          handleChange={handleChange}
                        />
                      )
                    }
                    if (element.type === fieldTypes.image) {
                      return (
                        <div className="form-image" key={`image-${i}`}>
                          <div className="blurred-background" style={{backgroundImage: `url(${element.image})`}}></div>
                          <img src={element.image} alt=""/>
                        </div>
                      )
                    }
                    return null;
                  })
                }
              </div>
            )
          })
        }
      </div>
      <div className="form-buttons">
        { configuration?.submitCTA && 
          <Button
            disabled={!formValid || isRequesting || !!configuration?.submitDisabled}
            id="form-submit"
            onClick={submit}
            variant="contained"
          >
            {isRequesting && (
              <LoadingCircles />
            )}
            {!isRequesting && (
              <>
                {configuration?.submitCTA}
              </>
            )}
          </Button>
        }
        { configuration?.cancelCTA && (
          <Button
            onClick={handleCancel}
            variant="outlined"
          >{configuration.cancelCTA}</Button>
        ) }
        { configuration?.removeCTA && (
          <Button
            onClick={handleRemove}
            variant="contained"
          >{configuration.removeCTA}</Button>
        ) }
      </div>
      { configuration?.submitDisabled && <FormHelperText
        sx={{ mt: 2, fontSize: 14 }}
        className="Mui-error"
        >{configuration?.submitDisabled}</FormHelperText > }
    </form>
  )
}

export default AutoForm;
