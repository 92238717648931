import React, { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

const InputMask = forwardRef(
  function InputMask(props, ref) {
    const { onChange, ...other } = props;

    const handleChange = (value, event) => {
      if (event) {
        onChange({ target: { name: props.name, value } });
      }
    }

    return (
      <IMaskInput
        {...other}
        mask="(000) 000-0000"
        inputRef={ref}
        onAccept={(value, mask, event) => handleChange(value, event)}
      />
    );
  }
);

export default InputMask;
