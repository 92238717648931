import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventRequest,
  selectEventBannerImageRequest
} from 'src/redux/actions/events';
import { uploadEventBannerImageRequest } from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';
import { fileRestrictions } from 'src/constants';

const EventBannerImage = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const [progress, setProgress] = useState();
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);

  const actions = [{
    fileUpload: {
      accept: fileRestrictions.jpg.accept,
      label: 'Upload',
      multiple: true,
      onChange: file => {
        dispatch(uploadEventBannerImageRequest({ clientURL: client.url, file, handleProgress: setProgress }));
      },
      requestPrefix: 'UPLOAD_EVENT_BANNER_IMAGE'
    }
  }, {
    label: 'View',
    requiresSelection: true,
    variant: 'contained',
    fn: image => {
      dispatch(
        openModal({
          props: { client, event, image },
          key: 'viewImage'
        })
      )
    }
  }, {
    color: 'confirm',
    label: 'Select',
    requiresSelection: true,
    variant: 'contained',
    fn: bannerImage => {
      dispatch(selectEventBannerImageRequest({ clientURL: client.url, eventURL: event.url, form: bannerImage }))
    }
  }, {
    color: 'danger',
    disabled: bannerImage => !!bannerImage.default,
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: bannerImage => {
      dispatch(
        openDialog({
          props: { client, bannerImage },
          key: 'removeEventBannerImage'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventBannerImage', client, event }));
    dispatch(updateDrawerContent({
      key: 'eventBannerImage',
      options: { client, event } 
    }));
  }, [client, event]);
  
  return (
    <ResourceList
      actions={actions}
      activeItem={(item) => item.url === event.bannerImage}
      fetching={eventFetching}
      list={event?.bannerImages}
      renderItem={item => 
        <div className="selectable-image">
          <img src={item.url} alt={item.name}/>
          <div className="selectable-image-details">
            <span className="selectable-image-details-name">{item.name}</span>
            <span className="selectable-image-details-size">{item.size}</span>
          </div>
        </div>
      }
      renderKey="name"
      progress={(!!progress && progress < 100) && progress}
      title="Banner Image"
    />
  )
}

export default EventBannerImage;