import * as actions from 'src/redux/actions/dialog';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.ACTIVE_CLOSE_DIALOG_SUCCESS:
      return { ...state, dialog: { activeClose: true } }
    case actions.CLOSE_DIALOG_SUCCESS:
      return { ...state, dialog: null }
    case actions.OPEN_DIALOG:
      return { ...state, dialog: { ...action.body, activeClose: false} };
    default:
      return state;
  }
}

export default reducer;
