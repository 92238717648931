import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateClientSocialRequest } from 'src/redux/actions/client';

const ClientSocial = () => {
  const client = useSelector(state => state.clientStore?.client);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    dispatch(updateClientSocialRequest({ clientURL: client.url, form: event }));
  };

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Facebook',
          name: 'facebook',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: client.facebook || ''
        }]
      }, {
        elements: [{
          label: 'Pinterest',
          name: 'pinterest',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: client.pinterest || ''
        }]
      }, {
        elements: [{
          label: 'Instagram',
          name: 'instagram',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: client.instagram || ''
        }]
      }, {
        elements: [{
          label: 'Houzz',
          name: 'houzz',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: client.houzz || ''
        }]
      }, {
        elements: [{
          label: 'Linkedin',
          name: 'linkedin',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: client.linkedin || ''
        }]
      }, {
        elements: [{
          label: 'YouTube',
          name: 'youtube',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: client.youtube || ''
        }]
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="client-social">
      <AutoForm
        configuration={configuration}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_CLIENT_SOCIAL'}
      />
    </section>
  )
}

export default ClientSocial;
