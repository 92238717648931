import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContestRequest,
  reorderContestScoresheetsRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';

const ContestScoresheets = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const updatingScoresheets = useSelector(state => state.loadingStore.REORDER_CONTEST_SCORESHEETS);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, contest },
          key: 'createContestScoresheet'
        })
      )
    }
  }, {
    label: 'Duplicate',
    requiresSelection: true,
    fn: scoresheet => {
      dispatch(
        openModal({
          props: { client, contest, scoresheet },
          key: 'duplicateScoresheet'
        })
      )
    }
  }, {
    label: 'Settings',
    requiresSelection: true,
    fn: scoresheet => {
      dispatch(
        openModal({
          props: { client, contest, scoresheet },
          key: 'editContestScoresheet'
        })
      )
    }
  }, {
    label: 'Structure',
    requiresSelection: true,
    fn: scoresheet => {
      dispatch(
        openModal({
          props: { client, contest, scoresheet },
          key: 'editContestScoresheetStructure'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: scoresheet => {
      dispatch(
        openDialog({
          props: { client, contest, scoresheet },
          key: 'removeContestScoresheet'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestScoresheets', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestScoresheets',
      options: { client, contest } 
    }));
  }, [client, contest]);

  const reorderScoresheetList = ({ list }) => {
    dispatch(reorderContestScoresheetsRequest({ clientURL: client.url, contestURL: contest.url, form: { scoresheets: list } }));
  }
  
  return (
    <ResourceList
      actions={actions}
      draggable
      fetching={contestFetching}
      handleDrag={reorderScoresheetList}
      list={contest?.scoresheets}
      renderItem={scoresheet => 
        <div className="entry-form">
          <div className="entry-form-name">{scoresheet.name}</div>
        </div>
      }
      renderKey="name"
      title="Scoresheet List"
      updating={updatingScoresheets}
    />
  )
}

export default ContestScoresheets;