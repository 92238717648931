import * as actions from 'src/redux/actions/users';
import {
  removeCookie,
  setCookie
} from 'src/utilities/cookies';
import { cpj_auth_key } from 'src/constants';
import { toast } from 'react-toastify';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.CHANGE_USER_PASSWORD_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('Password successfully changed.');

      return { ...state, user: { ...state.user, passwordChanged: true } };

    case actions.CHANGE_USER_PASSWORD_BY_EMAIL_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('Password successfully changed.');

      return { ...state, changeRequest: { ...state.changeRequest, passwordChanged: true } };

    case actions.CLEAR_FORGOT_PASSWORD_CHANGED:
      return { ...state, forgotPasswordChanged: undefined };

    case actions.CLEAR_USER:
      return { ...state, user: false };

    case actions.CLEAR_USER_PASSWORD_CHANGED:
      return { ...state, user: { ...state.user, passwordChanged: undefined } };

    case actions.CLEAR_VIEW_USER:
      return { ...state, viewedUser: false };

    case actions.CREATE_USER_MEMBERSHIP_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('User membership affiliation created.');
      
      return { ...state, user: { ...state.user, memberships: [...(state.user.memberships || []), action.context.membership] } };
  
    case actions.FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgotPasswordChanged: true };

    case actions.GET_ACTION_PANEL_SUCCESS:
      return { ...state, user: { ...state.user, actionPanel: action.context.actionPanel }};

    case actions.GET_INVITATION_FAILED:
      return { ...state, user: false };

    case actions.GET_INVITATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return { ...state, user: false };
      }

      return action.context?.invitation ? { ...state, invitation: action.context.invitation } : state;

    case actions.GET_PASSWORD_KEY_SUCCESS:
      return { ...state, changeRequest: action.context.changeRequest };

    case actions.GET_USER_FAILED:
      return { ...state, user: false };

    case actions.GET_USER_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return { ...state, user: false };
      }
      return action.context?.user ? { ...state, user: action.context.user } : state;

    case actions.LOGIN_FAILED:
      return { ...state, user: false };

    case actions.LOGIN_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return { ...state, user: false };
      }
      setCookie({
        key: cpj_auth_key,
        value: action.context.token,
        options: { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: 365 }
      });
      return action.context?.user ? { ...state, user: action.context.user } : state;

    case actions.LOGOUT_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
      }
      removeCookie({ key: cpj_auth_key });
      return { ...state, user: false };

    case actions.REMOVE_HEADSHOT_SUCCESS: 
      if (action.context?.error) {
        toast.error(action.context?.error);
      }

      toast.success('Profile image removed.');

      return { ...state, user: { ...state.user, image: false, headshot: action.context.headshot } };

    case actions.REMOVE_USER_MEMBERSHIP_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.clientID) {
        toast.success('Membership affiliation removed.');
        const updatedMemberships = (state.user.memberships || []).filter(membership => {
          return membership.clientID !== action.context.clientID;
        });
        return { ...state, user: { ...state.user, memberships: updatedMemberships } };
      }

      return state;

    case actions.SIGNUP_REQUEST:
      return { ...state, user: false };

    case actions.SIGNUP_FAILED:
      return { ...state, user: false };

    case actions.SIGNUP_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return { ...state, user: false };
      }
      setCookie({
        key: cpj_auth_key,
        value: action.context.token,
        options: { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: 365 }
      });
      return action.context?.user ? { ...state, user: action.context.user } : state;

    case actions.UPDATE_USER_CONTACT_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return { ...state };
      }

      toast.success('User profile settings updated.');

      return { ...state, user: { ...state.user, ...action.context.user } };

    case actions.UPDATE_USER_PERSONAL_INFORMATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return { ...state };
      }

      toast.success('User profile settings updated.');

      return { ...state, user: { ...state.user, ...action.context.user } };

    case actions.UPDATE_USER_PRIVACY_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return { ...state };
      }

      toast.success('User profile settings updated.');

      return { ...state, user: { ...state.user, ...action.context.user } };

    case actions.UPDATE_USER_VOLUNTEER_SETTINGS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return { ...state };
      }

      toast.success('User profile settings updated.');

      return { ...state, user: { ...state.user, ...action.context.user } };

    case actions.UPLOAD_HEADSHOT_SUCCESS: 
      if (action.context?.error) {
        toast.error(action.context?.error);
      }

      toast.success('Profile image uploaded.');

      return { ...state, user: { ...state.user, image: true, headshot: action.context.headshot } };

    case actions.VIEW_USER_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return { ...state, viewedUser: false };
      }
      return action.context?.user ? { ...state, viewedUser: action.context.user } : state;

    default: 
      return state;
  }
}

export default reducer;
