import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderComponent from 'src/components/Header';
import DialogContainer from 'src/components/DialogContainer';
import ModalContainer from 'src/components/ModalContainer';

const Layout = () => {
  return (
    <section className="root-layout">
      <HeaderComponent />
      <section className="root-layout-container">
        <section className="root-layout-container-active">
          <div className="outlet">
            <Outlet />
          </div>
        </section>
      </section>
      <DialogContainer />
      <ModalContainer />
    </section>
  )
};

export default Layout;
