import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'src/components/Elements/Card';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import ClientLogo from 'src/pages/Client/ClientLogo';
import ClientSettings from 'src/pages/Client/ClientSettings';
import ClientGateway from 'src/pages/Client/ClientGateway';
import ClientSocial from 'src/pages/Client/ClientSocial';

const Client = () => {
  const client = useSelector(state => state.clientStore?.client);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'client', client }));
    dispatch(updateDrawerContent({ key: 'clientSettings',  options: { client } }));
  }, [client]);

  return (
    <section className="app-grid">
      <div className="app-grid-row">
        <Card
          className="flex-1"
          title="Client Portal Logo"
        >
          <ClientLogo />
        </Card>
        <Card
          className="flex-2"
          title="Client Payment Gateway"
        >
          <ClientGateway />
        </Card>
      </div>
      <div className="app-grid-row">
        <Card
          className="flex-1"
          title="Client General Settings"
        >
          <ClientSettings />
        </Card>
        <Card
          className="flex-1"
          title="Client Social Links"
        >
          <ClientSocial />
        </Card>
      </div>
    </section>
  )
}

export default Client;
