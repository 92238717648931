import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEventRequest } from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { updateEventSupportingProductTimeframeRequest } from 'src/redux/actions/events';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';
import { supportingProductTypes } from 'src/constants';

const EventSponsorTimeframes = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: {
            event,
            handleSubmit: (evt) => updateEventSupportingProductTimeframeRequest({ clientURL: client.url, eventURL: event.url, form: evt }),
            items: (event.sponsorTiers || []),
            requestPrefix: 'UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME'
          },
          key: 'createEventSponsorTimeframe'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: timeframe => {
      dispatch(
        openModal({
          props: {
            event,
            handleSubmit: (event) => updateEventSupportingProductTimeframeRequest({ clientURL: client.url, eventURL: event.url, form: {...(timeframe || {}), ...event} }),
            items: (event.sponsorTiers || []),
            requestPrefix: 'UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME',
            timeframe
          },
          key: 'editEventSponsorTimeframe'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: timeframe => {
      timeframe.type = supportingProductTypes.sponsor;

      dispatch(
        openDialog({
          props: { client, event, timeframe },
          key: 'removeEventSponsorTimeframe'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventSponsorTimeframes', client, event }));
    dispatch(updateDrawerContent({
      key: 'eventSponsorTimeframes',
      options: { client, event } 
    }));
  }, [client, event]);
  
  return (
    <ResourceList
      actions={actions}
      fetching={eventFetching}
      list={event?.sponsorshipTimeframes}
      renderHeaders={() => 
        <div className="timeframe">
          <div className="timeframe-start">Pricing Timeframe Starts</div>
          <div className="timeframe-end">Pricing Timeframe Ends</div>
        </div>
      }
      renderItem={timeframe => 
        <div className="timeframe">
          <div className="timeframe-start">{timeframe.startDisplayDate}</div>
          <div className="timeframe-end">{timeframe.endDisplayDate}</div>
        </div>
      }
      renderKey="timeframeID"
      title="Sponsorship Pricing & Availability"
    />
  )
}

export default EventSponsorTimeframes;