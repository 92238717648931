import React, { useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText
} from '@mui/material';

const CheckboxField = ({
  element,
  error,
  handleChange,
  handleBlur,
  value
}) => {
  const [touched, setTouched] = useState(false);
  const handleTouched = () => {
    setTouched(true);
  };

  return (
    <div className={`form-field checkbox ${element.class || 'flex-1'}`}>
      <FormControl
        className="form-field"
        error={touched && error}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!value}
                className={`${error ? ' error' : ''}${touched ? ' touched' : ''}`}
                disabled={element.disabled}
                id={element.name}
                name={element.name}
                onBlur={handleBlur}
                onFocus={handleTouched}
                onChange={(e) => {handleChange(e.target.checked, element)}}
                required={element.required}
              />
            }
            label={
              <span>
                {element.required && (<span>* </span>)}
                {element.label}
              </span>
            }
          />
        </FormGroup>
        {
          (error && touched) && (
            <FormHelperText>{error}</FormHelperText>
          )
        }
      </FormControl>
    </div>
  )
}

export default CheckboxField;
