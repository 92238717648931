import { fieldTypes } from "src/constants";

export const existingScholarshipsMap = ({ currentlyAllowed, restrictedMap, scholarships }) => {
  const map = new Map();

  (currentlyAllowed || []).forEach(scholarship => {
    map.set(scholarship, true);
  });

  const mappedScholarships = (scholarships || []).map(scholarship => {
    return {
      disabled: restrictedMap ? restrictedMap.has(scholarship?.scholarshipID) : false,
      label: scholarship?.name,
      name: scholarship?.scholarshipID,
      type: fieldTypes.checkbox,
      value: map.has(scholarship?.scholarshipID)
    }
  });

  const chunkedScholarships = mappedScholarships.map((elem, i) => {
    return i % 2 ? [] : mappedScholarships.slice(i, i + 2);
  }).filter(chunk => {
    return !!chunk?.length;
  });

  return chunkedScholarships.map(chunk => {
    return {
      elements: [...chunk]
    };
  });
}
