import { fieldTypes } from "src/constants";

export const existingCategoriesMap = ({ categories, currentlyAllowed, entries, restrictedMap }) => {
  const map = new Map();
  let entryMap;

  if ((entries || [])?.length > 0) {
    entryMap = new Map();
    entries.forEach(entry => {
      if (entryMap.has(entry.categoryID)) {
        entryMap.set(entry.categoryID, (entryMap.get(entry.categoryID) + 1));
      } else {
        entryMap.set(entry.categoryID, 1);
      }
    })
  }

  (currentlyAllowed || []).forEach(category => {
    map.set(category, true);
  });

  const mappedCategories = (categories || []).map(category => {
    return {
      disabled: restrictedMap ? restrictedMap.has(category?.categoryID) : false,
      label: `${category?.label}: ${category?.description}${entryMap ? ` (${entryMap.get(category.categoryID) || 0})` : ''}`,
      name: category?.categoryID,
      type: fieldTypes.checkbox,
      value: map.has(category?.categoryID)
    }
  });

  const chunkedCategories = mappedCategories.map((elem, i) => {
    return i % 2 ? [] : mappedCategories.slice(i, i + 2);
  }).filter(chunk => {
    return !!chunk?.length;
  });

  return chunkedCategories.map(chunk => {
    return {
      elements: [...chunk]
    };
  });
}

export const assembleExistingList = ({ form }) => {
  const reg = new RegExp(/[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}/);
  const list = [];
  for (const key in form) {
    if (form[key] === true && key?.length === 11 && reg.test(key)) {
      list.push(key);
    }
  }
  return list;
}
