import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import modalActions from './ModalActions';
import { activeCloseModal, closeModal } from 'src/redux/actions/modal';
import { delay } from 'lodash';
import { useLocation } from 'react-router-dom';

const ModalContainer = () => {
  const modalRequest = useSelector(state => state.modalStore?.modal);
  const completedActions = useSelector(state => state.completedStore);
  const [modalAction, setModalAction] = useState();
  const [ModalContent, setModalContent] = useState();
  const [modalTitle, setModalTitle] = useState();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if ((completedActions || {})[modalAction?.actionKey]) {
      dispatch({ type: `${modalAction.actionKey}_CLEAR` });
      activeClearModal();
    }
  }, [(completedActions || {})[modalAction?.actionKey]]);

  useEffect(() => {
    if (modalRequest && !modalRequest?.activeClose) {
      const modalAction = modalActions[modalRequest.key || ''] || {};
      setModalAction(modalAction);
      const Component = modalAction.content;
      setModalContent(() => <Component {...modalRequest.props} handleClose={handleClose} />);
      setModalTitle(typeof modalAction?.title === 'function' ? modalAction?.title(modalRequest.props) : modalAction?.title);
      setOpen(true);
    }
  }, [modalRequest]);

  useEffect(() => {
    activeClearModal();
  }, [location]);

  const activeClearModal = () => {
    setOpen(false);
    delay(() => {
      dispatch(activeCloseModal());
    }, 50);
  }

  const clearModal = () => {
    setOpen(false);
    delay(() => {
      dispatch(closeModal());
    }, 50);
  }

  const handleClose = (event, reason) => {
    if (!modalTitle) {
      clearModal();
      return;
    }
    if (reason !== 'backdropClick') {
      clearModal();
    }
  }

  return (
    <Modal
      disableEnforceFocus
      disableEscapeKeyDown
      onClose={handleClose}
      open={open}
    >
      <section
        className={`modal ${modalAction?.width ? modalAction?.width : ''}`}
        id="modal"
      >
        {
          modalTitle && (
            <div className="modal-title">
              <h3>{modalTitle}</h3>
              <FontAwesomeIcon
                className="icon close"
                icon={faXmark}
                onClick={handleClose}
              />
            </div>
          )
        }
        <div className="modal-content">
          {ModalContent}
        </div>
      </section>
    </Modal>
  )
}

export default ModalContainer;
