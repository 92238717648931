import React from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

const FormStructureSliderField = ({ checked, label, onChange }) => {
  return (
    <FormGroup>
      <FormControlLabel control={
        <Switch
          checked={checked || false}
          onChange={onChange}
          size="small"
        />
      } label={label} />
    </FormGroup>
  )
}

export default FormStructureSliderField;
