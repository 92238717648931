import { get } from "lodash";

const sortBy = ({ key, list, reverse }) => {
  return list.sort((a, b) => {
    const first = get(a, key);
    const second = get(b, key);
    if (typeof first === 'string' && typeof second === 'string') {
      const firstLower = first.toLowerCase();
      const secondLower = second.toLowerCase();

      if (firstLower < secondLower) {
        return reverse ? 1 : -1;
      }
  
      if (firstLower > secondLower) {
        return reverse ? -1 : 1;
      }
  
      return 0;
    }

    if (typeof first === 'number' && typeof second === 'number') {
      return reverse ? second - first : first - second;
    }

    return 0;
  });
}

export default sortBy;
