import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes, yesOrNo } from 'src/constants';
import { updateParadeTimeframeRequest } from 'src/redux/actions/parades';
import {
  displayDate,
  displayTime,
  getFirstMinuteToday,
  getLastMinuteToday,
  parseDateTime
} from 'src/utilities/dates';
import defaultSelectOption from 'src/utilities/defaultSelectOption';

const TimeframeForm = ({
  client,
  handleClose,
  parade,
  timeframe
}) => {
  const [configuration, setConfiguration] = useState();
  const [temporaryPrices, setTemporaryPrices] = useState({});
  const [startDate, setStartDate] = useState(timeframe?.startDate || getFirstMinuteToday());
  const [endDate, setEndDate] = useState(timeframe?.endDate || getLastMinuteToday());
  const [sameForAllItems, setSameForAllItems] = useState(defaultSelectOption({ defaultValue: true, value: timeframe?.sameForAllItems }));
  const dispatch = useDispatch();

  const fee = 'fee';
  const ticket = 'ticket';

  useEffect(() => {
    setConfiguration(createConfiguration());
  }, [sameForAllItems]);

  const nullCheck = (value) => {
    return value === 'null' ? null : value;
  }

  const assemblePriceStructure = (form) => {
    const prices = [];

    for (let property in form) {
      const keys = property.split('_');
      if (keys?.length === 4 && keys[3] === 'price') {
        keys[0] = nullCheck(keys[0]);
        keys[1] = nullCheck(keys[1]);

        const priceKey = `${keys[0]}_${keys[1]}_${keys[2]}_price`;

        prices.push({
          element: keys[0],
          date: keys[1],
          elementType: keys[2],
          price: form[priceKey]
        });

        delete form[priceKey];
      }
    }

    return prices;
  }

  const handleSubmit = (event) => {
    const startDate = parseDateTime({ date: event.startDate });
    const endDate = parseDateTime({ date: event.endDate });
    const prices = assemblePriceStructure(event);
    dispatch(updateParadeTimeframeRequest({
      clientURL: client.url,
      paradeURL: parade.url,
      form: {
        ...(timeframe || {}),
        ...event,
        startDate,
        endDate,
        prices
      }}));
  }

  const storeTemporaryPrice = ({ key, value }) => {
    setTemporaryPrices({
      ...temporaryPrices,
      [key]: value
    });
  }

  const onChange = ({ element, value }) => {
    switch(element?.name) {
      case 'startDate': setStartDate(value); break;
      case 'endDate': setEndDate(value); break;
      case 'sameForAllItems': setSameForAllItems(value); break;
      default: storeTemporaryPrice({ key: element.name, value });
    }
  }

  const createElementName = ({ date, item }) => {
    return `${item?.ticketID || item?.feeID || 'null'}_${date?.dateID || 'null'}`;
  }

  const createElementLabel = ({ date, elementType, item }) => {
    const itemTypeLabel = elementType === 'ticket' ? 'tickets' : 'optional fees',
      itemLabelText = !item ? null : item?.name || `${item?.label}: ${item?.description}`,
      itemLabel = !item ? `for all ${itemTypeLabel}` : `for ${itemLabelText}`,
      dateLabel = !date ? 'for all dates' : `for ${displayDate({ date: date.startDate })} ${displayTime({ date: date.startDate })} - ${displayTime({ date: date.endDate })}`;

    return `${itemLabel} ${dateLabel}`;
  }

  const hasTemporaryPrice = ({ key }) => {
    return (temporaryPrices || {}).hasOwnProperty(key) ? temporaryPrices[key] : false;
  }

  const createPrice = ({ date, elementType, item, timeframe }) => {
    const elementName = createElementName({ date, elementType, item }),
      elementLabel = createElementLabel({ date, elementType, item }),
      element = (timeframe?.prices || []).find(price => {
        return (
          price?.elementType === elementType &&
          (price?.date === date || price?.date === date?.dateID) &&
          (price?.element === item || price?.element === (item?.ticketID || item?.feeID))
        );
      }),
      price = hasTemporaryPrice({ key: `${elementName}_${elementType}_price` }) || element?.price || 0;

    return {
      elements: [{
        label: `Price ${elementLabel}`,
        name: `${elementName}_${elementType}_price`,
        type: fieldTypes.input,
        inputType: inputTypes.currency,
        required: true,
        value: price
      }]
    };
  }

  const createPrices = ({ elementType, items, timeframe }) => {
    if (!(elementType === fee || !sameForAllItems)) {
      return [createPrice({ elementType, item: null, date: null, timeframe })];
    }

    return items.map(item => {
      return createPrice({ date: null, elementType, item, timeframe });
    });
  }

  const createSecondaryConfiguration = () => {
    return [{
      heading: 'Optional Fee Prices:'
    }, ...createPrices({ elementType: fee, items: parade?.fees || [], timeframe })];
  }

  const createConfiguration = () => {
    const config = {
      formElements: {
        rows: [{
          heading: 'These prices are active from:'
        }, {
          elements: [{
            label: 'Start Date',
            name: 'startDate',
            dateRangeEnd: 'endDate',
            type: fieldTypes.input,
            inputType: inputTypes.dateTime,
            value: startDate,
            required: true
          }, {
            label: 'End Date',
            name: 'endDate',
            dateRangeStart: 'startDate',
            type: fieldTypes.input,
            inputType: inputTypes.dateTime,
            value: endDate,
            required: true
          }]
        }, {
          heading: 'Pricing settings:'
        }, {
          elements: [{
            label: `Same for each ticket?`,
            name: 'sameForAllItems',
            type: fieldTypes.select,
            value: sameForAllItems,
            options: yesOrNo
          }]
        }, {
          heading: 'Prices:'
        }, ...createPrices({ elementType: ticket, items: parade?.tickets || [], timeframe })
        ]
      },
      submitCTA: 'Save',
      cancelCTA: 'Cancel'
    };
    if (parade?.fees?.length) {
      return { ...config, formElements: { ...config.formElements, rows: [...config.formElements.rows, ...createSecondaryConfiguration()] }};
    }
    return config;
  };

  return (
    <section className="timeframe-form">
      {
        configuration && (
          <AutoForm
            configuration={configuration}
            handleCancel={handleClose}
            handleSubmit={handleSubmit}
            onChange={onChange}
            requestPrefix={'UPDATE_PARADE_TIMEFRAME'}
          />
        )
      }
    </section>
  )
}

export default TimeframeForm;
