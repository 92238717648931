import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateParadePropertyGroupRequest } from 'src/redux/actions/parades';

const PropertyGroupForm = ({ handleClose, propertyGroup }) => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.sequence = event.sequence || parade.propertyGroups?.length || 0;
    dispatch(updateParadePropertyGroupRequest({ clientURL: client.url, paradeURL: parade.url, form: {...(propertyGroup || {}), ...event} }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Property Group Name',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: propertyGroup?.name || '',
          required: true
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="property-group-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_PARADE_PROPERTY_GROUP'}
      />
    </section>
  )
}

export default PropertyGroupForm;
