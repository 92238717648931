import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getSurveyRequest,
  updateSurveyVotingTimeframeRequest
} from 'src/redux/actions/surveys';
import { fieldTypes, inputTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import {
  getFirstMinuteToday,
  getLastMinuteToday,
  parseDateTime
} from 'src/utilities/dates';

const SurveyVotingTimeframe = () => {
  const client = useSelector(state => state.clientStore?.client);
  const survey = useSelector(state => state.surveyStore?.survey);
  const surveyFetching = useSelector(state => state.loadingStore.GET_SURVEY);
  const { surveyURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!surveyURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!survey) {
      dispatch(getSurveyRequest({ clientURL: client.url, surveyURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'surveyTimeframe', client, survey }));
    dispatch(updateDrawerContent({
      key: 'surveyTimeframe',
      options: { client, survey } 
    }));
  }, [client, survey]);

  const handleSubmit = (event) => {
    const startDate = parseDateTime({ date: event.startDate });
    const endDate = parseDateTime({ date: event.endDate });
    dispatch(updateSurveyVotingTimeframeRequest({ clientURL: client.url, surveyURL: survey.url, form: {
      ...event, startDate, endDate
    } }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Start Date',
          name: 'startDate',
          dateRangeEnd: 'endDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: survey?.startDate || getFirstMinuteToday(),
          required: true
        }, {
          label: 'End Date',
          name: 'endDate',
          dateRangeStart: 'startDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: survey?.endDate || getLastMinuteToday(),
          required: true
        }]
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="box">
      <div className="box-title">
        <h3>
            {
              !surveyFetching && (
                <span>Submission Timeframe</span>
              )
            }
            {
              surveyFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        (!surveyFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_SURVEY_VOTING_TIMEFRAME'}
          />
        </div>
        )
      }
    </section>
  )
}

export default SurveyVotingTimeframe;
