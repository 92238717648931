import React from 'react';
import DragDrop from 'src/components/DragDrop';
import { Button } from '@mui/material';
import LoadingCircles from 'src/components/Elements/LoadingCircles';

const StructureForm = ({
  createButton,
  disabled,
  handleClose,
  isRequesting,
  list,
  onDrag,
  onSubmit,
  renderKey,
  renderListItem,
  setList
}) => {
  return (
    <section
      className="structure-form"
    >
      <div className="structure-form-content">
        <DragDrop
          droppableId="form-structure"
          handleDrag={onDrag}
          list={list}
          renderKey={renderKey}
          renderListItem={renderListItem}
          setList={setList}
        />
      </div>
      <div className="structure-form-buttons">
        <Button
          disabled={disabled || isRequesting}
          onClick={onSubmit}
          variant="contained"
        >
          {isRequesting && (
            <LoadingCircles />
          )}
          {!isRequesting && (
            <>Save</>
          )}
        </Button>
        {
          typeof handleClose === 'function' && (
            <Button
              onClick={handleClose}
              variant="outlined"
            >Cancel</Button>
          )
        }
        {
          typeof createButton === 'function' && createButton()
        }
      </div>
    </section>
  )
}

export default StructureForm;
