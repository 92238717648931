import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import {
  categoryRestrictions,
  fieldTypes,
  inputTypes,
  productTypes,
  restrictionTypes,
  scholarshipRestrictions,
  yesOrNo
} from 'src/constants';
import { updateContestEntryFormRequest } from 'src/redux/actions/contests';
import defaultSelectOption from 'src/utilities/defaultSelectOption';
import {
  assembleExistingList,
  existingCategoriesMap
} from 'src/utilities/existingCategory';
import { existingScholarshipsMap } from 'src/utilities/existingScholarship';

const EntryFormForm = ({ entryForm, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const [configuration, setConfiguration] = useState();
  const [name, setName] = useState(entryForm?.name || '');
  const [judgeVisible, setJudgeVisible] = useState(defaultSelectOption({ defaultValue: true, value: entryForm?.judgeVisible }));
  const [hideDisqualifyWarning, setHideDisqualifyWarning] = useState(defaultSelectOption({ defaultValue: false, value: entryForm?.hideDisqualifyWarning }));
  const [restriction, setRestriction] = useState(entryForm?.restriction || restrictionTypes.all);

  useEffect(() => {
    setConfiguration(contest?.type === productTypes.contest ? createContestConfiguration() : createScholarshipConfiguration());
  }, []);

  useEffect(() => {
    setConfiguration(contest?.type === productTypes.contest ? createContestConfiguration() : createScholarshipConfiguration());
  }, [restriction]);

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.sequence = event.sequence || contest.entryForms?.length || 0;
    event.assignedCategories = assembleExistingList({ form: event });
    dispatch(updateContestEntryFormRequest({ clientURL: client.url, contestURL: contest.url, form: {...(entryForm || {}), ...event} }));
  }

  const onChange = ({ element, value }) => {
    if (element?.name === 'name') {
      setName(value);
    }
    if (element?.name === 'judgeVisible') {
      setJudgeVisible(value);
    }
    if (element?.name === 'hideDisqualifyWarning') {
      setHideDisqualifyWarning(value);
    }
    if (element?.name === 'restriction') {
      setRestriction(value);
    }
  }

  const createContestConfiguration = () => {
    const config = {
      formElements: {
        rows: [{
          elements: [{
            label: 'Entry Form Name',
            name: 'name',
            type: fieldTypes.input,
            inputType: inputTypes.text,
            value: name,
            required: true
          }]
        }, {
          elements: [{
            label: 'This Entry Form Is Visible To Judges',
            name: 'judgeVisible',
            type: fieldTypes.select,
            options: yesOrNo,
            value: judgeVisible
          }]
        }, {
          elements: [{
            label: 'Hide Disqualify Warning For This Entry Form',
            name: 'hideDisqualifyWarning',
            type: fieldTypes.select,
            options: yesOrNo,
            value: hideDisqualifyWarning
          }]
        }, {
          elements: [{
            label: 'This Entry Form Is Available For',
            name: 'restriction',
            type: fieldTypes.select,
            options: categoryRestrictions,
            value: restriction
          }]
        }]
      },
      submitCTA: 'Save',
      cancelCTA: 'Cancel'
    };

    if (restriction === restrictionTypes.specific) {
      return { ...config, formElements: { ...config.formElements, rows: [...config.formElements.rows, ...existingCategoriesMap({
        categories: contest?.categories,
        currentlyAllowed: entryForm?.assignedCategories
      })] }};
    }

    return config;
  }

  const createScholarshipConfiguration = () => {
    const config = {
      formElements: {
        rows: [{
          elements: [{
            label: 'Entry Form Name',
            name: 'name',
            type: fieldTypes.input,
            inputType: inputTypes.text,
            value: name,
            required: true
          }]
        }, {
          elements: [{
            label: 'This Entry Form Is Available For',
            name: 'restriction',
            type: fieldTypes.select,
            options: scholarshipRestrictions,
            value: restriction
          }]
        }]
      },
      submitCTA: 'Save',
      cancelCTA: 'Cancel'
    };

    if (restriction === restrictionTypes.specific) {
      return { ...config, formElements: { ...config.formElements, rows: [...config.formElements.rows, ...existingScholarshipsMap({
        scholarships: contest?.scholarships,
        currentlyAllowed: entryForm?.assignedCategories
      })] }};
    }

    return config;
  }

  return (
    <section className="entry-folder-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        onChange={onChange}
        requestPrefix={'UPDATE_CONTEST_ENTRY_FORM'}
      />
    </section>
  )
}

export default EntryFormForm;
