import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getContestRequest,
  updateContestConfirmationEmailRequest
} from 'src/redux/actions/contests';
import { fieldTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const ContestConfirmationEmail = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestConfirmationEmail', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestConfirmationEmail',
      options: { client, contest } 
    }));
  }, [client, contest]);

  const handleSubmit = (event) => {
    dispatch(updateContestConfirmationEmailRequest({ clientURL: client.url, contestURL: contest.url, form: event }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Confirmation Email Text',
          name: 'confirmationEmailText',
          type: fieldTypes.textarea,
          value: contest?.confirmationEmailText || ''
        }]
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="box">
      <div className="box-title">
        <h3>
            {
              !contestFetching && (
                <span>Confirmation Email Text</span>
              )
            }
            {
              contestFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        (!contestFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_CONTEST_CONFIRMATION_EMAIL'}
          />
        </div>
        )
      }
    </section>
  )
}

export default ContestConfirmationEmail;
