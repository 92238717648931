import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import LoadingCircles from 'src/components/Elements/LoadingCircles';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { FormHelperText } from '@mui/material';

const TinyMCE = ({
  clearOnSave,
  CTA,
  disabled,
  handleCancel,
  handleSubmit,
  label,
  optionalFormData,
  requestPrefix,
  required,
  title,
  value
}) => {
  const [touched, setTouched] = useState(false);
  const [editorValue, setEditorValue] = useState(value || '');
  const editorRef = useRef(null);
  const updating = useSelector(state => state.loadingStore[requestPrefix]);
  const completed = useSelector(state => state.completedStore[requestPrefix]);

  const clearValue = () => {
    setEditorValue('');
    setTouched(false);
  }

  const handleChange = (value) => {
    setEditorValue(value);
    setTouched(true);
  }

  useEffect(() => {
    if (completed && clearOnSave) {
      clearValue();
    }
  }, [clearOnSave, completed]);

  return (
    <section className="box mce">
      { title && <div className="box-title">
          <h3>{title}</h3>
        </div>
      }
      { 
        (typeof optionalFormData === 'function' && (!clearOnSave || !updating)) && (
          optionalFormData()
        )
      }
      <div className="box-content">
        { (!clearOnSave || !updating) && (
          <>
            {
              label && <label className="label">{label}</label>
            }
            <Editor
              apiKey="yill2b134m31c5e76wrnpl2xefg1wqa6vd39zd2f1z95gug7"
              init={{
                height: 500,
                plugins: 'lists code link',
                toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | link bullist numlist outdent indent | ' +
                'code',
                link_assume_external_targets: true,
                relative_urls: false,
                remove_script_host: false,
                content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }'
              }}
              onEditorChange={(newValue, editor) => handleChange(newValue)}
              onInit={(evt, editor) => editorRef.current = editor}
              value={editorValue}
            />
            {
              (required && !editorValue && touched) && <FormHelperText className='Mui-error'>Message Required</FormHelperText>
            }
          </>
        )}
        <div className="buttons">
          <Button
            disabled={updating || disabled || (required && !editorValue)}
            onClick={() => handleSubmit(editorRef.current.getContent())}
            variant="contained"
          >
            {updating && (
              <LoadingCircles />
            )}
            {!updating && (
              <>
                { CTA || <>Save</> }
              </>
            )}
          </Button>
          {
            handleCancel && <Button
              onClick={handleCancel}
              variant="outlined"
            >Cancel</Button>
          }
        </div>
      </div>
    </section>
  )
}

export default TinyMCE;
