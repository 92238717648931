export const CREATE_SURVEY_REQUEST = 'CREATE_SURVEY_REQUEST';
export const CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS';
export const CREATE_SURVEY_FAILED = 'CREATE_SURVEY_FAILED';

export const DOWNLOAD_SURVEY_SUBMISSIONS_REQUEST = 'DOWNLOAD_SURVEY_SUBMISSIONS_REQUEST';
export const DOWNLOAD_SURVEY_SUBMISSIONS_SUCCESS = 'DOWNLOAD_SURVEY_SUBMISSIONS_SUCCESS';
export const DOWNLOAD_SURVEY_SUBMISSIONS_FAILED = 'DOWNLOAD_SURVEY_SUBMISSIONS_FAILED';

export const GET_SURVEY_REQUEST = 'GET_SURVEY_REQUEST';
export const GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';
export const GET_SURVEY_FAILED = 'GET_SURVEY_FAILED';

export const GET_SURVEY_LIST_REQUEST = 'GET_SURVEY_LIST_REQUEST';
export const GET_SURVEY_LIST_SUCCESS = 'GET_SURVEY_LIST_SUCCESS';
export const GET_SURVEY_LIST_FAILED = 'GET_SURVEY_LIST_FAILED';

export const REMOVE_SURVEY_REQUEST = 'REMOVE_SURVEY_REQUEST';
export const REMOVE_SURVEY_SUCCESS = 'REMOVE_SURVEY_SUCCESS';
export const REMOVE_SURVEY_FAILED = 'REMOVE_SURVEY_FAILED';

export const REMOVE_SURVEY_IMAGE_REQUEST = 'REMOVE_SURVEY_IMAGE_REQUEST';
export const REMOVE_SURVEY_IMAGE_SUCCESS = 'REMOVE_SURVEY_IMAGE_SUCCESS';
export const REMOVE_SURVEY_IMAGE_FAILED = 'REMOVE_SURVEY_IMAGE_FAILED';

export const REMOVE_SURVEY_VOTE_REQUEST = 'REMOVE_SURVEY_VOTE_REQUEST';
export const REMOVE_SURVEY_VOTE_SUCCESS = 'REMOVE_SURVEY_VOTE_SUCCESS';
export const REMOVE_SURVEY_VOTE_FAILED = 'REMOVE_SURVEY_VOTE_FAILED';

export const RESET_SURVEY_URL_CHANGED = 'RESET_SURVEY_URL_CHANGED';
export const RESET_SURVEY_URL_CHANGED_SUCCESS = 'RESET_SURVEY_URL_CHANGED_SUCCESS';

export const UPDATE_SURVEY_NAME_REQUEST = 'UPDATE_SURVEY_NAME_REQUEST';
export const UPDATE_SURVEY_NAME_SUCCESS = 'UPDATE_SURVEY_NAME_SUCCESS';
export const UPDATE_SURVEY_NAME_FAILED = 'UPDATE_SURVEY_NAME_FAILED';

export const UPDATE_SURVEY_QUESTIONS_REQUEST = 'UPDATE_SURVEY_QUESTIONS_REQUEST';
export const UPDATE_SURVEY_QUESTIONS_SUCCESS = 'UPDATE_SURVEY_QUESTIONS_SUCCESS';
export const UPDATE_SURVEY_QUESTIONS_FAILED = 'UPDATE_SURVEY_QUESTIONS_FAILED';

export const UPDATE_SURVEY_VOTING_TIMEFRAME_REQUEST = 'UPDATE_SURVEY_VOTING_TIMEFRAME_REQUEST';
export const UPDATE_SURVEY_VOTING_TIMEFRAME_SUCCESS = 'UPDATE_SURVEY_VOTING_TIMEFRAME_SUCCESS';
export const UPDATE_SURVEY_VOTING_TIMEFRAME_FAILED = 'UPDATE_SURVEY_VOTING_TIMEFRAME_FAILED';

export const UPLOAD_SURVEY_IMAGE_REQUEST = 'UPLOAD_SURVEY_IMAGE_REQUEST';
export const UPLOAD_SURVEY_IMAGE_SUCCESS = 'UPLOAD_SURVEY_IMAGE_SUCCESS';
export const UPLOAD_SURVEY_IMAGE_FAILED = 'UPLOAD_SURVEY_IMAGE_FAILED';

export function createSurveyRequest (body) {
  return {
    body,
    type: CREATE_SURVEY_REQUEST
  }
}

export function downloadSurveySubmissionsRequest (body) {
  return {
    body,
    type: DOWNLOAD_SURVEY_SUBMISSIONS_REQUEST
  }
}

export function getSurveyRequest ({ clientURL, surveyURL }) {
  return {
    clientURL,
    surveyURL,
    type: GET_SURVEY_REQUEST
  }
}

export function getSurveyListRequest ({ clientURL }) {
  return {
    clientURL,
    type: GET_SURVEY_LIST_REQUEST
  }
}

export function removeSurveyRequest (body) {
  return {
    body,
    type: REMOVE_SURVEY_REQUEST
  }
}

export function removeSurveyImageRequest (body) {
  return {
    body,
    type: REMOVE_SURVEY_IMAGE_REQUEST
  }
}

export function removeSurveyVoteRequest (body) {
  return {
    body,
    type: REMOVE_SURVEY_VOTE_REQUEST
  }
}

export function resetSurveyURLChanged () {
  return {
    type: RESET_SURVEY_URL_CHANGED
  }
}

export function updateSurveyNameRequest (body) {
  return {
    body,
    type: UPDATE_SURVEY_NAME_REQUEST
  }
}

export function updateSurveyQuestionsRequest (body) {
  return {
    body,
    type: UPDATE_SURVEY_QUESTIONS_REQUEST
  }
}

export function updateSurveyVotingTimeframeRequest (body) {
  return {
    body,
    type: UPDATE_SURVEY_VOTING_TIMEFRAME_REQUEST
  }
}

export function uploadSurveyImageRequest (body) {
  return {
    body,
    type: UPLOAD_SURVEY_IMAGE_REQUEST
  }
}
