import { put, takeEvery } from 'redux-saga/effects';
import * as actions from 'src/redux/actions/dialog';

function* activeCloseDialog(request) {
  yield put({ type: actions.ACTIVE_CLOSE_DIALOG_SUCCESS, body: request.body });
}

function* closeDialog(request) {
  yield put({ type: actions.CLOSE_DIALOG_SUCCESS, body: request.body });
}

function* updateDialogRequest(request) {
  yield put({ type: actions.OPEN_DIALOG_SUCCESS, body: request.body });
}

function* dialogSaga() {
  yield takeEvery(actions.ACTIVE_CLOSE_DIALOG, activeCloseDialog);
  yield takeEvery(actions.CLOSE_DIALOG, closeDialog);
  yield takeEvery(actions.OPEN_DIALOG, updateDialogRequest);
}

export default dialogSaga;
