import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateContestEntryFormStructureRequest } from 'src/redux/actions/contests';
import FormGenerator from 'src/components/TemplateForms/Common/FormGenerator';

const EntryFormStructureForm = ({ entryForm, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const isRequesting = useSelector(state => state.loadingStore.UPDATE_CONTEST_ENTRY_FORM_STRUCTURE);
  const dispatch = useDispatch();

  const onSubmit = ({ formState }) => {
    dispatch(updateContestEntryFormStructureRequest({ clientURL: client.url, contestURL: contest.url, form: {
      entryFormID: entryForm.entryFormID,
      entryFormQuestions: formState }
    }));
  }

  return (
    <FormGenerator
      form={entryForm?.entryFormQuestions}
      handleClose={handleClose}
      isRequesting={isRequesting}
      onSubmit={onSubmit}
    />
  )
}

export default EntryFormStructureForm;
