import { UPDATE_DRAWER_CONTENT } from 'src/redux/actions/drawer';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_DRAWER_CONTENT:
      return { ...state, drawerContent: action.body };
    default:
      return state;
  }
}

export default reducer;
