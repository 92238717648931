import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContestRequest,
  reorderContestCategoriesRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';

const ContestCategories = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const updatingCategories = useSelector(state => state.loadingStore.REORDER_CONTEST_CATEGORIES);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, contest },
          key: 'createContestCategory'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: category => {
      dispatch(
        openModal({
          props: { client, contest, category },
          key: 'editContestCategory'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: category => {
      dispatch(
        openDialog({
          props: { client, contest, category },
          key: 'removeContestCategory'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestCategories', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestCategories',
      options: { client, contest } 
    }));
  }, [client, contest]);

  const reorderCategoryList = ({ list }) => {
    dispatch(reorderContestCategoriesRequest({ clientURL: client.url, contestURL: contest.url, form: { categories: list } }));
  }
  
  return (
    <ResourceList
      actions={actions}
      draggable
      fetching={contestFetching}
      handleDrag={reorderCategoryList}
      list={contest?.categories}
      renderItem={category => 
        <div className="category">
          <div className="category-label">{category.label}</div>
          <div className="category-description">{category.description}</div>
        </div>
      }
      renderKey="categoryID"
      title="Category List"
      updating={updatingCategories}
    />
  )
}

export default ContestCategories;