import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getParadePublicVotingRequest,
  getParadeRequest,
  updateParadePublicVotingRequest
} from 'src/redux/actions/parades';
import {
  getFirstMinuteToday,
  getLastMinuteToday,
  parseDateTime
} from 'src/utilities/dates';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import {
  fieldTypes,
  inputTypes,
  maximumVotesPerGroup,
  publicVotingOptions
} from 'src/constants';
import { AgCharts } from 'ag-charts-react';

const ParadePublicVotingOptions = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const fetchingPublicVotes = useSelector(state => state.loadingStore.GET_PARADE_PUBLIC_VOTES);
  const { paradeURL } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  useEffect(() => {
    if (parade?.url && !parade?.publicVotes) {
      dispatch(getParadePublicVotingRequest({ clientURL: client.url, paradeURL }));
    }
  }, [parade?.url]);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradePublicVoting', client, parade }));
    dispatch(updateDrawerContent({
      key: 'paradePublicVoting',
      options: { client, parade } 
    }));
  }, [client, parade]);

  const handleSubmit = (event) => {
    event.votingStartDate = event.enablePublicVoting === publicVotingOptions.no ? null : parseDateTime({ date: event.votingStartDate });
    event.votingEndDate = event.enablePublicVoting === publicVotingOptions.no ? null : parseDateTime({ date: event.votingEndDate });

    dispatch(updateParadePublicVotingRequest({
      clientURL: client.url,
      paradeURL: parade.url,
      form: event
    }));
  };

  const options = [{
    label: 'No',
    value: publicVotingOptions.no
  }, {
    label: 'Yes - Voting Unrestricted',
    value: publicVotingOptions.unrestricted
  }, {
    label: 'Yes - Once Per Ticket',
    value: publicVotingOptions.perTicket
  }, {
    label: 'Yes - Once Per Scanned Ticket',
    value: publicVotingOptions.perScannedTicket
  }];

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Enable Public Voting',
          name: 'enablePublicVoting',
          type: fieldTypes.select,
          options,
          value: parade?.enablePublicVoting || publicVotingOptions.no
        }]
      }, {
        elements: [{
          label: 'Voting Start Date',
          name: 'votingStartDate',
          hide: { key: 'enablePublicVoting', value: publicVotingOptions.no },
          dateRangeEnd: 'votingEndDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: parade?.votingStartDate || getFirstMinuteToday(),
          required: true
        }, {
          label: 'Voting End Date',
          name: 'votingEndDate',
          hide: { key: 'enablePublicVoting', value: publicVotingOptions.no },
          dateRangeStart: 'votingStartDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: parade?.votingEndDate || getLastMinuteToday(),
          required: true
        }]
      }, {
        elements: [{
          label: 'Maximum Votes Per Group',
          name: 'maxPublicVotes',
          type: fieldTypes.select,
          options: maximumVotesPerGroup,
          value: parade?.maxPublicVotes
        }]
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="public-voting grid">
      <section className="box">
        <div className="box-title">
          <h3>
            <span>Public Voting Options
              {
                (paradeFetching || fetchingPublicVotes) && <LoadingCircle variant="dark" />
              }
            </span>
          </h3>
        </div>
        {
          parade && 
          <div className="box-content">
            <p>Public Voting URL: <a
                href={`https://${client.url}.cpjam.com/${parade?.url}/vote`}
                rel="noreferrer"
                target="_blank"
              >{`https://${client.url}.cpjam.com/${parade?.url}/vote`}</a>
            </p>
            <AutoForm
              configuration={configuration}
              handleSubmit={handleSubmit}
              requestPrefix={'UPDATE_PARADE_PUBLIC_VOTING'}
            />
          </div>
        }
      </section>
      {
        !!parade?.publicVotes &&
          <section className="public-voting-results box">
            <div className="box-title">
              <h3>
                Total Number Of Votes Cast
              </h3>
            </div>
            <div className="box-content">
              <p>
                {parade.publicVotes.count || 0} Vote{`${parade.publicVotes.count === 1 ? '' : 's'}`} Cast
              </p>
              {
                (parade?.publicVotes?.propertyGroups || []).map(group => 
                  <div
                    className="public-voting-results-section"
                    key={group.propertyGroupID}
                  >
                    <div className="public-voting-results-section-name">{group.name}</div>
                    <AgCharts options={{
                      data: group.properties.map(property => {
                        return {
                          name: property.address,
                          value: property.votes
                        };
                      }),
                      series: [{
                        direction: 'horizontal',
                        type: 'bar',
                        xKey: 'name',
                        yKey: 'value'
                      }]
                    }} />
                  </div>
                )
              }
              {
                (parade?.publicVotes?.properties || []).length &&
                  <div className="public-voting-results-section">
                    <AgCharts options={{
                      data: parade?.publicVotes?.properties.map(property => {
                        return {
                          name: property.address,
                          value: property.votes
                        };
                      }),
                      series: [{
                        direction: 'horizontal',
                        type: 'bar',
                        xKey: 'name',
                        yKey: 'value'
                      }]
                    }} />
                  </div>
              }
              
            </div>
          </section>
      }
    </section>
  )
}

export default ParadePublicVotingOptions;
