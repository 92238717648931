import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResourceList from 'src/components/ResourceList';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { getSurveyListRequest } from 'src/redux/actions/surveys';
import { Link, useNavigate } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import { updateDrawerContent } from 'src/redux/actions/drawer';

const Surveys = () => {
  const client = useSelector(state => state.clientStore?.client);
  const surveys = useSelector(state => state.surveyStore?.surveys);
  const surveysFetching = useSelector(state => state.loadingStore.GET_SURVEY_LIST);
  const navigate = useNavigate();
  
  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client },
          key: 'createSurvey'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: survey => {
      navigate(survey.url);
    }
  }, {
    label: 'Delete',
    color: 'danger',
    variant: 'contained',
    requiresSelection: true,
    fn: survey => {
      dispatch(
        openDialog({
          props: { client, survey },
          key: 'removeSurvey'
        })
      )
    }
  }];

  const filters = {
    searchBy: ['name']
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'surveys', client }));
    dispatch(updateDrawerContent({ key: 'clientSurveys', options: { client } }));

    if (!surveysFetching) {
      dispatch(getSurveyListRequest({ clientURL: client.url }));
    }
  }, []);

  return (
    <ResourceList
      actions={actions}
      fetching={surveysFetching}
      filters={filters}
      list={surveys}
      renderHeaders={() => 
        <div className="survey-list">
          <div className="survey-list-name">Name</div>
        </div>
      }
      renderItem={item => 
        <div className="survey-list">
          <div className="survey-list-name">
            <Link to={item.url}>{item.name}</Link>
          </div>
        </div>
      }
      renderKey="name"
      title="Survey List"
    />
  )
}

export default Surveys;
