import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getParadeRequest,
  reorderParadeTicketsRequest
} from 'src/redux/actions/parades';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';

const ParadeTickets = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const updatingTickets = useSelector(state => state.loadingStore.REORDER_PARADE_TICKETS);
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, parade },
          key: 'createParadeTicket'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: ticket => {
      dispatch(
        openModal({
          props: { client, parade, ticket },
          key: 'editParadeTicket'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: ticket => {
      dispatch(
        openDialog({
          props: { client, parade, ticket },
          key: 'removeParadeTicket'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeTickets', client, parade }));
    dispatch(updateDrawerContent({
      key: 'paradeTickets',
      options: { client, parade } 
    }));
  }, [client, parade]);

  const reorderTicketList = ({ list }) => {
    dispatch(reorderParadeTicketsRequest({ clientURL: client.url, paradeURL: parade.url, form: { tickets: list } }));
  }
  
  return (
    <ResourceList
      actions={actions}
      draggable
      fetching={paradeFetching}
      handleDrag={reorderTicketList}
      list={parade?.tickets}
      renderItem={ticket => 
        <div className="ticket">
          <div className="ticket-name">{ticket.name}</div>
        </div>
      }
      renderKey="ticketID"
      title="Ticket List"
      updating={updatingTickets}
    />
  )
}

export default ParadeTickets;