import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes, timezones } from 'src/constants';
import { updateClientSettingsRequest } from 'src/redux/actions/client';

const ClientSettings = () => {
  const client = useSelector(state => state.clientStore?.client);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    dispatch(updateClientSettingsRequest({ clientURL: client.url, form: event }));
  };

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Timezone',
          name: 'timezone',
          type: fieldTypes.select,
          options: timezones,
          value: client.timezone || timezones[0].value
        }]
      }, {
        elements: [{
          label: 'About Our Organization',
          name: 'about',
          type: fieldTypes.textarea,
          value: client.about || ''
        }]
      }, {
        elements: [{
          label: 'Email Address',
          name: 'email',
          type: fieldTypes.input,
          inputType: inputTypes.email,
          value: client.email || ''
        }]
      }, {
        elements: [{
          label: 'Phone Number',
          name: 'phone',
          type: fieldTypes.input,
          inputType: inputTypes.phone,
          value: client.phone || ''
        }]
      }, {
        elements: [{
          label: 'Website',
          name: 'website',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: client.website || ''
        }]
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="client-settings">
      <AutoForm
        configuration={configuration}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_CLIENT_SETTINGS'}
      />
    </section>
  )
}

export default ClientSettings;
