import React from 'react';
import { useDispatch } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateSystemNotificationRequest } from 'src/redux/actions/app';

const SystemNotificationForm = ({ handleClose, notification }) => {
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    dispatch(updateSystemNotificationRequest({ form: {...(notification || {}), ...event}} ));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Notification Text',
          name: 'text',
          type: fieldTypes.textarea,
          value: notification?.text || '',
          required: true
        }]
      }, {
        elements: [{
          label: 'Notification Expiration Date',
          name: 'exp',
          type: fieldTypes.input,
          inputType: inputTypes.date,
          value: notification?.exp || '',
          required: true
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="system-notification-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_SYSTEM_NOTIFICATION'}
      />
    </section>
  )
}

export default SystemNotificationForm;
