import React from 'react';

const Card = ({ children, className, title }) => {
  return (
    <section className={`floating card ${className || ''}`}>
      {title && (
        <h3 className="title">
          <span>{title}</span>
        </h3>
      )}
      <div className="content">
        {children}
      </div>
    </section>
  )
};

export default Card;
