import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from 'src/components/CreditCardForms/Stripe/CreditCardForm';

const StripeCheckout = ({
  handleTokenResponse,
  setTokenRequesting,
  stripePublishKey,
  tokenRequesting
}) => {
  const stripePromise = loadStripe(stripePublishKey);

  const handleStripeToken = ({ error, token }) => {
    handleTokenResponse({ error, token });
  }

  return (
    <Elements stripe={stripePromise} >
      <StripeCheckoutForm
        handleStripeToken={handleStripeToken}
        setTokenRequesting={setTokenRequesting}
        tokenRequesting={tokenRequesting}
      />
    </Elements>
  );
}

export default StripeCheckout;
