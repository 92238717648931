import { call, put, takeEvery } from 'redux-saga/effects';
import handleFetch from 'src/utilities/fetch';
import * as actions from 'src/redux/actions/users';
import { toast } from 'react-toastify';

async function changeUserPassword({ form }) {
  return await handleFetch({ url: `/users/password`, form });
}

async function changeUserPasswordByEmail({ form }) {
  return await handleFetch({ url: `/users/password/by-email`, form });
}

async function createUserMembership({ form }) {
  return await handleFetch({ url: `/users/membership`, form });
}

async function forgotPassword({ form }) {
  return await handleFetch({ url: `/users/password/reset`, form });
}

async function getActionPanel() {
  return await handleFetch({ url: `/users/action-panel` });
}

async function getInvitation({ invitationKey }) {
  return await handleFetch({ url: `/users/get-invitation/${invitationKey}` });
}

async function getPasswordKey({ passwordKey }) {
  return await handleFetch({ url: `/users/password/reset/${passwordKey}` });
}

async function getUser() {
  return await handleFetch({ url: `/users/admin` });
}

async function login({ form }) {
  return await handleFetch({ url: `/users/login`, form });
}

async function logout() {
  return await handleFetch({ url: `/users/logout`, form: {} });
}

async function removeHeadshot() {
  return await handleFetch({ url: `/users/image/remove`, form: {} });
}

async function removeUserMembership({ form }) {
  return await handleFetch({ url: `/users/membership/remove`, form });
}

async function signup({ form }) {
  return await handleFetch({ url: '/users', form });
}

async function updateUserContact({ form }) {
  return await handleFetch({ url: '/users/contact', form });
}

async function updateUserPersonalInformation({ form }) {
  return await handleFetch({ url: '/users/profile', form });
}

async function updateUserPrivacy({ form }) {
  return await handleFetch({ url: '/users/privacy', form });
}

async function updateUserVolunteerSettings({ form }) {
  return await handleFetch({ url: '/users/volunteer-settings', form });
}

async function uploadHeadshot({ file, handleProgress }) {
  return await handleFetch({ url: `/users/image`, form: { file }, handleProgress });
}

async function viewUser({ url }) {
  return await handleFetch({ url: `/users/view/${url}` });
}

function* changeUserPasswordRequest(request) {
  try {
    const context = yield call(changeUserPassword, request.body);
    yield put({ type: actions.CHANGE_USER_PASSWORD_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CHANGE_USER_PASSWORD_FAILED, error });
  }
}

function* changeUserPasswordByEmailRequest(request) {
  try {
    const context = yield call(changeUserPasswordByEmail, request.body);
    yield put({ type: actions.CHANGE_USER_PASSWORD_BY_EMAIL_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CHANGE_USER_PASSWORD_BY_EMAIL_FAILED, error });
  }
}

function* createUserMembershipRequest(request) {
  try {
    const context = yield call(createUserMembership, request.body);
    yield put({ type: actions.CREATE_USER_MEMBERSHIP_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CREATE_USER_MEMBERSHIP_FAILED, error });
  }
}

function* forgotPasswordRequest(request) {
  try {
    const context = yield call(forgotPassword, request.body);
    yield put({ type: actions.FORGOT_PASSWORD_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.FORGOT_PASSWORD_FAILED, error });
  }
}

function* getActionPanelRequest() {
  try {
    const context = yield call(getActionPanel);
    yield put({ type: actions.GET_ACTION_PANEL_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_ACTION_PANEL_FAILED, error });
  }
}

function* getInvitationRequest(request) {
  try {
    const context = yield call(() => getInvitation(request.params));
    yield put({ type: actions.GET_INVITATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_INVITATION_FAILED, error });
  }
}

function* getPasswordKeyRequest(request) {
  try {
    const context = yield call(getPasswordKey, request.body);
    yield put({ type: actions.GET_PASSWORD_KEY_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_PASSWORD_KEY_FAILED, error });
  }
}

function* getUserRequest(request) {
  try {
    const context = yield call(getUser, request.body);
    yield put({ type: actions.GET_USER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_USER_FAILED, error });
  }
}

function* loginRequest(request) {
  try {
    const context = yield call(login, request.body);
    yield put({ type: actions.LOGIN_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.LOGIN_FAILED, error });
  }
}

function* logoutRequest(request) {
  try {
    const context = yield call(logout, request.body);
    yield put({ type: actions.LOGOUT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.LOGOUT_FAILED, error });
  }
}

function* removeHeadshotRequest(request) {
  try {
    const context = yield call(removeHeadshot, request.body);
    yield put({ type: actions.REMOVE_HEADSHOT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_HEADSHOT_FAILED, error });
  }
}

function* removeUserMembershipRequest(request) {
  try {
    const context = yield call(removeUserMembership, request.body);
    yield put({ type: actions.REMOVE_USER_MEMBERSHIP_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_USER_MEMBERSHIP_FAILED, error });
  }
}

function* signupRequest(request) {
  try {
    const context = yield call(signup, request.body);
    yield put({ type: actions.SIGNUP_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.SIGNUP_FAILED, error });
  }
}

function* updateUserContactRequest(request) {
  try {
    const context = yield call(updateUserContact, request.body);
    yield put({ type: actions.UPDATE_USER_CONTACT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_USER_CONTACT_FAILED, error });
  }
}

function* updateUserPersonalInformationRequest(request) {
  try {
    const context = yield call(updateUserPersonalInformation, request.body);
    yield put({ type: actions.UPDATE_USER_PERSONAL_INFORMATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_USER_PERSONAL_INFORMATION_FAILED, error });
  }
}

function* updateUserPrivacyRequest(request) {
  try {
    const context = yield call(updateUserPrivacy, request.body);
    yield put({ type: actions.UPDATE_USER_PRIVACY_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_USER_PRIVACY_FAILED, error });
  }
}

function* updateUserVolunteerSettingsRequest(request) {
  try {
    const context = yield call(updateUserVolunteerSettings, request.body);
    yield put({ type: actions.UPDATE_USER_VOLUNTEER_SETTINGS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_USER_VOLUNTEER_SETTINGS_FAILED, error });
  }
}

function* uploadHeadshotRequest(request) {
  try {
    const context = yield call(uploadHeadshot, request.body);
    yield put({ type: actions.UPLOAD_HEADSHOT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPLOAD_HEADSHOT_FAILED, error });
  }
}

function* viewUserRequest(request) {
  try {
    const context = yield call(viewUser, request.body);
    yield put({ type: actions.VIEW_USER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.VIEW_USER_FAILED, error });
  }
}

function* apiFailed(request) {
  toast.error(request.error.message);
  if (request.error.status === 403) {
    yield put({ type: actions.CLEAR_USER });
  }
}

function* userSaga() {
  yield takeEvery(actions.CHANGE_USER_PASSWORD_REQUEST, changeUserPasswordRequest);
  yield takeEvery(actions.CHANGE_USER_PASSWORD_BY_EMAIL_REQUEST, changeUserPasswordByEmailRequest);
  yield takeEvery(actions.CREATE_USER_MEMBERSHIP_REQUEST, createUserMembershipRequest);
  yield takeEvery(actions.FORGOT_PASSWORD_REQUEST, forgotPasswordRequest);
  yield takeEvery(actions.GET_ACTION_PANEL_REQUEST, getActionPanelRequest);
  yield takeEvery(actions.GET_INVITATION_REQUEST, getInvitationRequest);
  yield takeEvery(actions.GET_PASSWORD_KEY_REQUEST, getPasswordKeyRequest);
  yield takeEvery(actions.GET_USER_REQUEST, getUserRequest);
  yield takeEvery(actions.LOGIN_REQUEST, loginRequest);
  yield takeEvery(actions.LOGOUT_REQUEST, logoutRequest);
  yield takeEvery(actions.REMOVE_HEADSHOT_REQUEST, removeHeadshotRequest);
  yield takeEvery(actions.REMOVE_USER_MEMBERSHIP_REQUEST, removeUserMembershipRequest);
  yield takeEvery(actions.SIGNUP_REQUEST, signupRequest);
  yield takeEvery(actions.UPDATE_USER_CONTACT_REQUEST, updateUserContactRequest);
  yield takeEvery(actions.UPDATE_USER_PERSONAL_INFORMATION_REQUEST, updateUserPersonalInformationRequest);
  yield takeEvery(actions.UPDATE_USER_PRIVACY_REQUEST, updateUserPrivacyRequest);
  yield takeEvery(actions.UPDATE_USER_VOLUNTEER_SETTINGS_REQUEST, updateUserVolunteerSettingsRequest);
  yield takeEvery(actions.UPLOAD_HEADSHOT_REQUEST, uploadHeadshotRequest);
  yield takeEvery(actions.VIEW_USER_REQUEST, viewUserRequest);

  yield takeEvery(actions.CHANGE_USER_PASSWORD_FAILED, apiFailed);
  yield takeEvery(actions.CHANGE_USER_PASSWORD_BY_EMAIL_FAILED, apiFailed);
  yield takeEvery(actions.CREATE_USER_MEMBERSHIP_FAILED, apiFailed);
  yield takeEvery(actions.FORGOT_PASSWORD_FAILED, apiFailed);
  yield takeEvery(actions.GET_ACTION_PANEL_FAILED, apiFailed);
  yield takeEvery(actions.GET_INVITATION_FAILED, apiFailed);
  yield takeEvery(actions.GET_PASSWORD_KEY_FAILED, apiFailed);
  yield takeEvery(actions.LOGIN_FAILED, apiFailed);
  yield takeEvery(actions.LOGOUT_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_HEADSHOT_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_USER_MEMBERSHIP_FAILED, apiFailed);
  yield takeEvery(actions.SIGNUP_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_USER_CONTACT_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_USER_PERSONAL_INFORMATION_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_USER_PRIVACY_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_USER_VOLUNTEER_SETTINGS_FAILED, apiFailed);
  yield takeEvery(actions.UPLOAD_HEADSHOT_FAILED, apiFailed);
  yield takeEvery(actions.VIEW_USER_FAILED, apiFailed);
}

export default userSaga;
