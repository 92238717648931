import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText
} from '@mui/material';

const MultiSelectField = ({
  element,
  formState,
  handleChange
}) => {
  return (
    <div className={`form-field multi-select ${element.class || 'flex-1'}`}>
      <label>{element.label}</label>
      <FormControl
        required
        component="fieldset"
        variant="standard"
      >
        <FormGroup>
          {
            (element.options || []).map(option => {
              return (
                <FormControlLabel
                  control={<Checkbox
                    checked={!!formState[option.name] || !!option.value}
                    onChange={e => handleChange(e.target.checked, option)}
                  />}
                  disabled={option.disabled}
                  key={option.value}
                  label={option.label}
                />
              )
            })
          }
        </FormGroup>
      </FormControl>
      {
        element.afterward && <FormHelperText>{element.afterward}</FormHelperText>
      }
      
    </div>
  )
}

export default MultiSelectField;
