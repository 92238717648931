import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getParadeRequest,
  updateParadeConfirmationEmailRequest
} from 'src/redux/actions/parades';
import { fieldTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const ParadeConfirmationEmail = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeConfirmationEmail', client, parade }));
    dispatch(updateDrawerContent({
      key: 'paradeConfirmationEmail',
      options: { client, parade } 
    }));
  }, [client, parade]);

  const handleSubmit = (evt) => {
    dispatch(updateParadeConfirmationEmailRequest({ clientURL: client.url, paradeURL: parade.url, form: evt }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Confirmation Email Text',
          name: 'confirmationEmailText',
          type: fieldTypes.textarea,
          value: parade?.confirmationEmailText || ''
        }]
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="box">
      <div className="box-title">
        <h3>
            {
              !paradeFetching && (
                <span>Confirmation Email Text</span>
              )
            }
            {
              paradeFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        (!paradeFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_PARADE_CONFIRMATION_EMAIL'}
          />
        </div>
        )
      }
    </section>
  )
}

export default ParadeConfirmationEmail;
