import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes, yesOrNo } from 'src/constants';
import {
  getFirstMinuteToday,
  getLastMinuteToday,
  parseDateTime
} from 'src/utilities/dates';
import defaultSelectOption from 'src/utilities/defaultSelectOption';

const TimeframeForm = ({ elementType, fees, handleClose, handleSubmit, requestPrefix, items, timeframe }) => {
  const [configuration, setConfiguration] = useState();
  const [temporaryPrices, setTemporaryPrices] = useState({});
  const [startDate, setStartDate] = useState(timeframe?.startDate || getFirstMinuteToday());
  const [endDate, setEndDate] = useState(timeframe?.endDate || getLastMinuteToday());
  const [sameForAllItems, setSameForAllItems] = useState(defaultSelectOption({ defaultValue: true, value: timeframe?.sameForAllItems }));
  const [sameForAllMemberTypes, setSameForAllMemberTypes] = useState(defaultSelectOption({ defaultValue: true, value: timeframe?.sameForAllMemberTypes }));
  const [hasDiscount, setHasDiscount] = useState(defaultSelectOption({ defaultValue: false, value: timeframe?.hasDiscount }));
  const [secondarySameForAllMemberTypes, setSecondarySameForAllMemberTypes] = useState(defaultSelectOption({ defaultValue: true, value: timeframe?.secondarySameForAllMemberTypes }));
  const [secondaryHasDiscount, setSecondaryHasDiscount] = useState(defaultSelectOption({ defaultValue: false, value: timeframe?.secondaryHasDiscount }));
  const dispatch = useDispatch();

  const fee = 'fee';

  useEffect(() => {
    setConfiguration(createConfiguration());
  }, []);

  useEffect(() => {
    setConfiguration(createConfiguration());
  }, [sameForAllItems, sameForAllMemberTypes, hasDiscount, secondarySameForAllMemberTypes, secondaryHasDiscount]);

  const nullCheck = (value) => {
    return value === 'null' ? null : value;
  }

  const assemblePriceStructure = (form) => {
    const prices = [];

    for (let property in form) {
      const keys = property.split('_');
      if (keys?.length === 4 && keys[3] === 'price') {
        keys[0] = nullCheck(keys[0]);
        keys[1] = nullCheck(keys[1]);

        const priceKey = `${keys[0]}_${keys[1]}_${keys[2]}_price`,
          discountKey = `${keys[0]}_${keys[1]}_${keys[2]}_discount`;

        prices.push({
          element: keys[0],
          memberType: keys[1],
          elementType: keys[2],
          price: form[priceKey],
          discount: form[discountKey]
        });

        delete form[priceKey];
        delete form[discountKey];
      }
    }

    return prices;
  }

  const handleSubmitFn = (event) => {
    const startDate = parseDateTime({ date: event.startDate });
    const endDate = parseDateTime({ date: event.endDate });
    const prices = assemblePriceStructure(event);
    dispatch(handleSubmit({ ...event, startDate, endDate, prices }));
  }

  const storeTemporaryPrice = ({ key, value }) => {
    setTemporaryPrices({
      ...temporaryPrices,
      [key]: value
    });
  }

  const onChange = ({ element, value }) => {
    switch(element?.name) {
      case 'startDate': setStartDate(value); break;
      case 'endDate': setEndDate(value); break;
      case 'sameForAllItems': setSameForAllItems(value); break;
      case 'sameForAllMemberTypes': setSameForAllMemberTypes(value); break;
      case 'hasDiscount': setHasDiscount(value); break;
      case 'secondarySameForAllMemberTypes': setSecondarySameForAllMemberTypes(value); break;
      case 'secondaryHasDiscount': setSecondaryHasDiscount(value); break;
      default: storeTemporaryPrice({ key: element.name, value });
    }
  }

  const memberTypes = [{
    label: 'all members',
    value: 'member'
  }, {
    label: 'all non-members',
    value: 'non-member'
  }];

  const createElementName = ({ item, memberType }) => {
    return `${item?.categoryID || item?.ticketID || item?.feeID || 'null'}_${memberType?.value || 'null'}`;
  }

  const createElementLabel = ({ elementType, item, memberType }) => {
    const itemTypeLabel = elementType === 'category' ? 'categories' :
        elementType === 'ticket' ? 'tickets' : 'optional fees',
      itemLabelText = !item ? null : item?.name || `${item?.label}: ${item?.description}`,
      itemLabel = !item ? `for all ${itemTypeLabel}` : `for ${itemLabelText}`,
      memberTypeLabel = !memberType ? 'for everyone' : `for ${memberType.label}`;

    return `${itemLabel} ${memberTypeLabel}`;
  }

  const hasTemporaryPrice = ({ key }) => {
    return (temporaryPrices || {}).hasOwnProperty(key) ? temporaryPrices[key] : false;
  }

  const createPrice = ({ elementType, item, memberType, timeframe }) => {
    const verifyHasDiscount = elementType === fee
        ? secondaryHasDiscount
        : hasDiscount,
      elementName = createElementName({ elementType, item, memberType }),
      elementLabel = createElementLabel({ elementType, item, memberType }),
      element = (timeframe?.prices || []).find(price => {
        return (
          price?.elementType === elementType &&
          (price?.memberType === memberType || price?.memberType === memberType?.value) &&
          (price?.element === item || price?.element === (item?.categoryID || item?.ticketID || item?.feeID))
        );
      }),
      price = hasTemporaryPrice({ key: `${elementName}_${elementType}_price` }) || element?.price || 0,
      discount = hasTemporaryPrice({ key: `${elementName}_${elementType}_discount` }) || element?.discount || 0;

    return verifyHasDiscount ? {
      elements: [{
        label: `Price ${elementLabel}`,
        name: `${elementName}_${elementType}_price`,
        type: fieldTypes.input,
        inputType: inputTypes.currency,
        required: true,
        value: price
      }, {
        label: `Price after discount ${elementLabel}`,
        name: `${elementName}_${elementType}_discount`,
        type: fieldTypes.input,
        inputType: inputTypes.currency,
        required: true,
        value: discount
      }]
    } : {
      elements: [{
        label: `Price ${elementLabel}`,
        name: `${elementName}_${elementType}_price`,
        type: fieldTypes.input,
        inputType: inputTypes.currency,
        required: true,
        value: price
      }]
    };
  }

  const createPrices = ({ elementType, items, timeframe }) => {
    const verifySameForAllMemberTypes =
        elementType === fee
          ? secondarySameForAllMemberTypes 
          : sameForAllMemberTypes;

    if (!(elementType === fee || !sameForAllItems)) {
      if (verifySameForAllMemberTypes) {
        return [createPrice({ elementType, item: null, memberType: null, timeframe })];
      } else {
        return memberTypes.map(memberType => {
          return createPrice({ elementType, item: null, memberType, timeframe });
        });
      } 
    }

    if (verifySameForAllMemberTypes) {
      return items.map(item => {
        return createPrice({ elementType, item, memberType: null, timeframe });
      })
    }

    const itemList = []
    items.forEach(item => {
      memberTypes.forEach(memberType => {
        itemList.push(createPrice({ elementType, item, memberType, timeframe }));
      })
    });

    return itemList;
  }

  const createSecondaryConfiguration = () => {
    return [{
      heading: 'Optional Fee Pricing Settings'
    }, {
      elements: [{
        label: 'Same for members and non-members?',
        name: 'secondarySameForAllMemberTypes',
        type: fieldTypes.select,
        value: secondarySameForAllMemberTypes,
        options: yesOrNo
      }, {
        label: 'Discount for multiple purchased?',
        name: 'secondaryHasDiscount',
        type: fieldTypes.select,
        value: secondaryHasDiscount,
        options: yesOrNo
      }]
    }, {
      heading: 'Optional Fee Prices:'
    }, ...createPrices({ elementType: fee, items: fees, timeframe })];
  }

  const createConfiguration = () => {
    const config = {
      formElements: {
        rows: [{
          heading: 'These prices are active from:'
        }, {
          elements: [{
            label: 'Start Date',
            name: 'startDate',
            dateRangeEnd: 'endDate',
            type: fieldTypes.input,
            inputType: inputTypes.dateTime,
            value: startDate,
            required: true
          }, {
            label: 'End Date',
            name: 'endDate',
            dateRangeStart: 'startDate',
            type: fieldTypes.input,
            inputType: inputTypes.dateTime,
            value: endDate,
            required: true
          }]
        }, {
          heading: 'Pricing settings:'
        }, {
          elements: [{
            label: `Same for each ${elementType}?`,
            name: 'sameForAllItems',
            type: fieldTypes.select,
            value: sameForAllItems,
            options: yesOrNo
          }, {
            label: 'Same for members and non-members?',
            name: 'sameForAllMemberTypes',
            type: fieldTypes.select,
            value: sameForAllMemberTypes,
            options: yesOrNo
          }, {
            label: 'Discount for multiple purchased?',
            name: 'hasDiscount',
            type: fieldTypes.select,
            value: hasDiscount,
            options: yesOrNo
          }]
        }, {
          heading: 'Prices:'
        }, ...createPrices({ elementType, items, timeframe })
        ]
      },
      submitCTA: 'Save',
      cancelCTA: 'Cancel'
    };
    if (fees?.length) {
      return { ...config, formElements: { ...config.formElements, rows: [...config.formElements.rows, ...createSecondaryConfiguration()] }};
    }
    return config;
  };

  return (
    <section className="timeframe-form">
      {
        configuration && (
          <AutoForm
            configuration={configuration}
            handleCancel={handleClose}
            handleSubmit={handleSubmitFn}
            onChange={onChange}
            requestPrefix={requestPrefix}
          />
        )
      }
    </section>
  )
}

export default TimeframeForm;
