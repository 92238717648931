import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import Layout from 'src/components/Layout';
import RootLayout from 'src/components/RootLayout';

import Home from 'src/pages/Home';

import ChangeEmail from 'src/pages/Network/ChangeEmail';
import ChangePassword from 'src/pages/Network/ChangePassword';
import Clients from 'src/pages/Network/Clients';
import CurrentResources from 'src/pages/Network/CurrentResources';
import EmailAdministrators from 'src/pages/Network/EmailAdministrators';
import ErrorLogs from 'src/pages/Network/ErrorLogs';
import Invoices from 'src/pages/Network/Invoices';
import MigrateUser from 'src/pages/Network/MigrateUser';
import NetworkHome from 'src/pages/Network/Home';
import PasswordRequests from 'src/pages/Network/PasswordRequests';
import SystemNotifications from 'src/pages/Network/SystemNotifications';
import ViewUser from 'src/pages/Network/ViewUser';

import Account from 'src/pages/Account';

import Client from 'src/pages/Client';
import ClientAdministrative from 'src/pages/Client/ClientAdministrative';
import ClientAdvancedConfiguration from 'src/pages/Client/ClientAdvancedConfiguration';
import ClientHome from 'src/pages/Client/ClientHome';
import ClientInvoices from 'src/pages/Client/ClientInvoices';

import Contests from 'src/pages/Contests';
import Contest from 'src/pages/Contests/Contest';
import ContestAdvancedConfiguration from 'src/pages/Contests/Contest/Settings/ContestAdvancedConfiguration';
import ContestAwards from 'src/pages/Contests/Contest/PhaseThree/ContestAwards';
import ContestBannerImage from 'src/pages/Contests/Contest/Settings/ContestBannerImage';
import ContestCategories from 'src/pages/Contests/Contest/PhaseOne/ContestCategories';
import ContestConfirmWinners from 'src/pages/Contests/Contest/PhaseThree/ConfirmWinners';
import ContestConfirmationEmail from 'src/pages/Contests/Contest/PhaseOne/ContestConfirmationEmail';
import ContestConnectedEvent from 'src/pages/Contests/Contest/Settings/ContestConnectedEvent';
import ContestCoordinators from 'src/pages/Contests/Contest/Settings/ContestCoordinators';
import ContestCustomPage from 'src/pages/Contests/Contest/Settings/ContestCustomPage';
import ContestDates from 'src/pages/Contests/Contest/PhaseOne/ContestDates';
import ContestDocumentFolders from 'src/pages/Contests/Contest/PhaseOne/ContestDocumentFolders';
import ContestDownloadableFiles from 'src/pages/Contests/Contest/Settings/ContestDownloadableFiles';
import ContestEntryForms from 'src/pages/Contests/Contest/PhaseOne/ContestEntryForms';
import ContestFileArchives from 'src/pages/Contests/Contest/PhaseThree/ContestFileArchives';
import ContestFinalReports from 'src/pages/Contests/Contest/PhaseThree/ContestFinalReports';
import ContestJudges from 'src/pages/Contests/Contest/PhaseTwo/ContestJudges';
import ContestName from 'src/pages/Contests/Contest/Settings/ContestName';
import ContestNotifications from 'src/pages/Contests/Contest/Utilities/ContestNotifications';
import ContestOptionalFees from 'src/pages/Contests/Contest/PhaseOne/ContestOptionalFees';
import ContestOptionalInstructions from 'src/pages/Contests/Contest/PhaseOne/ContestOptionalInstructions';
import ContestPartnerPage from 'src/pages/Contests/Contest/Partner/ContestPartnerPage';
import ContestPreview from 'src/pages/Contests/Contest/Utilities/ContestPreview';
import ContestPromoCodes from 'src/pages/Contests/Contest/PhaseOne/ContestPromoCodes';
import ContestPublicVoting from 'src/pages/Contests/Contest/PhaseTwo/ContestPublicVoting';
import ContestRegistrationList from 'src/pages/Contests/Contest/PhaseThree/ContestRegistrationList';
import ContestRules from 'src/pages/Contests/Contest/PhaseOne/ContestRules';
import ContestScholarships from 'src/pages/Contests/Contest/PhaseOne/ContestScholarships';
import ContestScoresheets from 'src/pages/Contests/Contest/PhaseTwo/ContestScoresheets';
import ContestScoringProgress from 'src/pages/Contests/Contest/PhaseTwo/ScoringProgress';
import ContestSEO from 'src/pages/Contests/Contest/Settings/ContestSEO';
import ContestSponsors from 'src/pages/Contests/Contest/Partner/ContestSponsors';
import ContestSponsorTimeframes from 'src/pages/Contests/Contest/Partner/ContestSponsorTimeframes';
import ContestTimeframes from 'src/pages/Contests/Contest/PhaseOne/ContestTimeframes';
import ContestWinnersGallery from 'src/pages/Contests/Contest/PhaseThree/ContestWinnersGallery';

import Surveys from 'src/pages/Surveys';
import Survey from 'src/pages/Surveys/Survey';
import SurveyName from 'src/pages/Surveys/Survey/Settings/SurveyName';
import SurveyQuestions from 'src/pages/Surveys/Survey/Settings/SurveyQuestions';
import SurveyVoterList from 'src/pages/Surveys/Survey/Results/VoterList';
import SurveyVotingResults from 'src/pages/Surveys/Survey/Results/VotingResults';
import SurveyVotingTimeframe from 'src/pages/Surveys/Survey/Settings/SurveyTimeframe';

import Events from 'src/pages/Events';
import Event from 'src/pages/Events/Event';
import EventAdvancedConfiguration from 'src/pages/Events/Event/Settings/EventAdvancedConfiguration';
import EventAttendance from 'src/pages/Events/Event/Registration/EventAttendance';
import EventBannerImage from 'src/pages/Events/Event/Settings/EventBannerImage';
import EventConfirmationEmail from 'src/pages/Events/Event/Questions/EventConfirmationEmail';
import EventConnectedContest from 'src/pages/Events/Event/Settings/EventConnectedContest';
import EventCoordinators from 'src/pages/Events/Event/Settings/EventCoordinators';
import EventDetails from 'src/pages/Events/Event/Questions/EventDetails';
import EventDownloadableFiles from 'src/pages/Events/Event/Settings/EventDownloadableFiles';
import EventName from 'src/pages/Events/Event/Settings/EventName';
import EventLocation from 'src/pages/Events/Event/Settings/EventLocation';
import EventOptionalFees from 'src/pages/Events/Event/Pricing/EventOptionalFees';
import EventNotifications from 'src/pages/Events/Event/Utilities/EventNotifications';
import EventPreview from 'src/pages/Events/Event/Utilities/EventPreview';
import EventPartnerPage from 'src/pages/Events/Event/Partner/EventPartnerPage';
import EventPromoCodes from 'src/pages/Events/Event/Pricing/EventPromoCodes';
import EventRegistrationList from 'src/pages/Events/Event/Registration/EventRegistrationList';
import EventRegistrationQuestions from 'src/pages/Events/Event/Questions/EventRegistrationQuestions';
import EventSEO from 'src/pages/Events/Event/Settings/EventSEO';
import EventSlideshowImages from 'src/pages/Events/Event/Settings/EventSlideshow';
import EventSponsors from 'src/pages/Events/Event/Partner/EventSponsors';
import EventSponsorTimeframes from 'src/pages/Events/Event/Partner/EventSponsorTimeframes';
import EventTickets from 'src/pages/Events/Event/Pricing/EventTickets';
import EventTimeframes from 'src/pages/Events/Event/Pricing/EventTimeframes';

import Parades from 'src/pages/Parades';
import Parade from 'src/pages/Parades/Parade';
import ParadeAttendance from 'src/pages/Parades/Parade/Registrations/ParadeAttendance';
import ParadeConfirmationEmail from 'src/pages/Parades/Parade/Registrations/ParadeConfirmationEmail';
import ParadeDates from 'src/pages/Parades/Parade/Settings/ParadeDates';
import ParadeDownloadableFiles from 'src/pages/Parades/Parade/Settings/ParadeDownloadableFiles';
import ParadeHomePageImages from 'src/pages/Parades/Parade/Settings/ParadeImages';
import ParadeHomePageGenerator from 'src/pages/Parades/Parade/Content';
import ParadeOptionalFees from 'src/pages/Parades/Parade/Pricing/ParadeOptionalFees';
import ParadeName from 'src/pages/Parades/Parade/Settings/ParadeName';
import ParadePartnerPage from 'src/pages/Parades/Parade/Partner/ParadePartnerPage';
import ParadePromoCodes from 'src/pages/Parades/Parade/Pricing/ParadePromoCodes';
import ParadePropertyGroups from 'src/pages/Parades/Parade/PublicVoting/ParadePropertyGroups';
import ParadePropertyTimeframes from 'src/pages/Parades/Parade/Partner/ParadePropertyTimeframes';
import ParadePublicVotingOptions from 'src/pages/Parades/Parade/PublicVoting/ParadePublicVotingOptions';
import ParadeRegistrationList from 'src/pages/Parades/Parade/Registrations/ParadeRegistrationList';
import ParadeSEO from 'src/pages/Parades/Parade/Settings/ParadeSEO';
import ParadeSponsors from 'src/pages/Parades/Parade/Partner/ParadeSponsors';
import ParadeSponsorTimeframes from 'src/pages/Parades/Parade/Partner/ParadeSponsorTimeframes';
import ParadeTickets from 'src/pages/Parades/Parade/Pricing/ParadeTickets';
import ParadeTicketTakers from 'src/pages/Parades/Parade/Settings/ParadeTicketTakers';
import ParadeTimeframes from 'src/pages/Parades/Parade/Pricing/ParadeTimeframes';

import ChangePasswordByEmail from 'src/pages/ChangePasswordByEmail';
import Login from 'src/pages/Login';
import SignUp from 'src/pages/SignUp';

import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [{
      path: '/',
      element: <PrivateRoute><Home /></PrivateRoute>
    }, {
      path: '/account',
      element: <PrivateRoute><Account /></PrivateRoute>
    }, {
      path: '/network',
      element: <AdminRoute><NetworkHome /></AdminRoute>,
    }]
  },
  {
    element: <Layout />,
    children: [{
      path: '/network/change-email',
      element: <AdminRoute><ChangeEmail /></AdminRoute>,
    }, {
      path: '/network/change-password',
      element: <AdminRoute><ChangePassword /></AdminRoute>,
    }, {
      path: '/network/clients',
      element: <AdminRoute><Clients /></AdminRoute>,
    }, {
      path: '/network/current-resources',
      element: <AdminRoute><CurrentResources /></AdminRoute>,
    }, {
      path: '/network/email-administrators',
      element: <AdminRoute><EmailAdministrators /></AdminRoute>,
    }, {
      path: '/network/error-logs',
      element: <AdminRoute><ErrorLogs /></AdminRoute>,
    }, {
      path: '/network/invoices',
      element: <AdminRoute><Invoices /></AdminRoute>,
    }, {
      path: '/network/migrate-user',
      element: <AdminRoute><MigrateUser /></AdminRoute>,
    }, {
      path: '/network/password-requests',
      element: <AdminRoute><PasswordRequests /></AdminRoute>,
    }, {
      path: '/network/system-notifications',
      element: <AdminRoute><SystemNotifications /></AdminRoute>,
    }, {
      path: '/network/view-user',
      element: <AdminRoute><ViewUser /></AdminRoute>,
    }, {
      path: '/:clientURL',
      element: <PrivateRoute><ClientHome /></PrivateRoute>
    }, {
      path: '/:clientURL/administrative',
      element: <PrivateRoute><ClientAdministrative /></PrivateRoute>,
    }, {
      path: '/:clientURL/client',
      element: <PrivateRoute><Client /></PrivateRoute>,
    }, {
      path: '/:clientURL/contests',
      element: <PrivateRoute><Contests /></PrivateRoute>,
    }, {
      path: '/:clientURL/contests/:contestURL',
      element: <PrivateRoute><Contest /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/archives',
      element: <PrivateRoute><ContestFileArchives /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/awards',
      element: <PrivateRoute><ContestAwards /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/banner',
      element: <PrivateRoute><ContestBannerImage /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/categories',
      element: <PrivateRoute><ContestCategories /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/configuration',
      element: <PrivateRoute><ContestAdvancedConfiguration /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/confirmation-email',
      element: <PrivateRoute><ContestConfirmationEmail /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/confirm-winners',
      element: <PrivateRoute><ContestConfirmWinners /></PrivateRoute>,
    }, {
      path: '/:clientURL/contests/:contestURL/coordinators',
      element: <PrivateRoute><ContestCoordinators /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/custom-page',
      element: <PrivateRoute><ContestCustomPage /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/dates',
      element: <PrivateRoute><ContestDates /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/document-folders',
      element: <PrivateRoute><ContestDocumentFolders /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/entry-forms',
      element: <PrivateRoute><ContestEntryForms /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/event',
      element: <PrivateRoute><ContestConnectedEvent /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/fees',
      element: <PrivateRoute><ContestOptionalFees /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/files',
      element: <PrivateRoute><ContestDownloadableFiles /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/gallery',
      element: <PrivateRoute><ContestWinnersGallery /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/instructions',
      element: <PrivateRoute><ContestOptionalInstructions /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/judges',
      element: <PrivateRoute><ContestJudges /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/name',
      element: <PrivateRoute><ContestName /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/notifications',
      element: <PrivateRoute><ContestNotifications /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/partner-page',
      element: <PrivateRoute><ContestPartnerPage /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/preview',
      element: <PrivateRoute><ContestPreview /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/promo-codes',
      element: <PrivateRoute><ContestPromoCodes /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/public-voting',
      element: <PrivateRoute><ContestPublicVoting /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/registration-list',
      element: <PrivateRoute><ContestRegistrationList /></PrivateRoute>,
    }, {
      path: '/:clientURL/contests/:contestURL/reports',
      element: <PrivateRoute><ContestFinalReports /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/rules',
      element: <PrivateRoute><ContestRules /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/scholarships',
      element: <PrivateRoute><ContestScholarships /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/scoresheets',
      element: <PrivateRoute><ContestScoresheets /></PrivateRoute>,
    }, {
      path: '/:clientURL/contests/:contestURL/scoring-progress',
      element: <PrivateRoute><ContestScoringProgress /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/seo',
      element: <PrivateRoute><ContestSEO /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/sponsor-options',
      element: <PrivateRoute><ContestSponsors /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/sponsor-timeframes',
      element: <PrivateRoute><ContestSponsorTimeframes /></PrivateRoute>, 
    }, {
      path: '/:clientURL/contests/:contestURL/timeframes',
      element: <PrivateRoute><ContestTimeframes /></PrivateRoute>, 
    }, {
      path: '/:clientURL/invoices',
      element: <PrivateRoute><ClientInvoices /></PrivateRoute>,
    }, {
      path: '/:clientURL/events',
      element: <PrivateRoute><Events /></PrivateRoute>,
    }, {
      path: '/:clientURL/events/:eventURL',
      element: <PrivateRoute><Event /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/attendance',
      element: <PrivateRoute><EventAttendance /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/awards-program',
      element: <PrivateRoute><EventConnectedContest /></PrivateRoute>,
    }, {
      path: '/:clientURL/events/:eventURL/banner',
      element: <PrivateRoute><EventBannerImage /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/configuration',
      element: <PrivateRoute><EventAdvancedConfiguration /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/confirmation-email',
      element: <PrivateRoute><EventConfirmationEmail /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/coordinators',
      element: <PrivateRoute><EventCoordinators /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/details',
      element: <PrivateRoute><EventDetails /></PrivateRoute>,
    }, {
      path: '/:clientURL/events/:eventURL/fees',
      element: <PrivateRoute><EventOptionalFees /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/files',
      element: <PrivateRoute><EventDownloadableFiles /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/location',
      element: <PrivateRoute><EventLocation /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/name',
      element: <PrivateRoute><EventName /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/notifications',
      element: <PrivateRoute><EventNotifications /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/partner-page',
      element: <PrivateRoute><EventPartnerPage /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/preview',
      element: <PrivateRoute><EventPreview /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/promo-codes',
      element: <PrivateRoute><EventPromoCodes /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/registration-list',
      element: <PrivateRoute><EventRegistrationList /></PrivateRoute>,
    }, {
      path: '/:clientURL/events/:eventURL/registration-questions',
      element: <PrivateRoute><EventRegistrationQuestions /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/seo',
      element: <PrivateRoute><EventSEO /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/slideshow',
      element: <PrivateRoute><EventSlideshowImages /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/sponsor-options',
      element: <PrivateRoute><EventSponsors /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/sponsor-timeframes',
      element: <PrivateRoute><EventSponsorTimeframes /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/tickets',
      element: <PrivateRoute><EventTickets /></PrivateRoute>, 
    }, {
      path: '/:clientURL/events/:eventURL/timeframes',
      element: <PrivateRoute><EventTimeframes /></PrivateRoute>, 
    }, {
      path: '/:clientURL/options',
      element: <PrivateRoute><ClientAdvancedConfiguration /></PrivateRoute>,
    }, {
      path: '/:clientURL/surveys',
      element: <PrivateRoute><Surveys /></PrivateRoute>,
    }, {
      path: '/:clientURL/surveys/:surveyURL',
      element: <PrivateRoute><Survey /></PrivateRoute>, 
    }, {
      path: '/:clientURL/surveys/:surveyURL/name',
      element: <PrivateRoute><SurveyName /></PrivateRoute>, 
    }, {
      path: '/:clientURL/surveys/:surveyURL/questions',
      element: <PrivateRoute><SurveyQuestions /></PrivateRoute>, 
    }, {
      path: '/:clientURL/surveys/:surveyURL/submissions',
      element: <PrivateRoute><SurveyVoterList /></PrivateRoute>, 
    }, {
      path: '/:clientURL/surveys/:surveyURL/summary',
      element: <PrivateRoute><SurveyVotingResults /></PrivateRoute>, 
    }, {
      path: '/:clientURL/surveys/:surveyURL/timeframe',
      element: <PrivateRoute><SurveyVotingTimeframe /></PrivateRoute>,
    }, {
      path: '/:clientURL/parades',
      element: <PrivateRoute><Parades /></PrivateRoute>,
    }, {
      path: '/:clientURL/parades/:paradeURL',
      element: <PrivateRoute><Parade /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/attendance',
      element: <PrivateRoute><ParadeAttendance /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/confirmation-email',
      element: <PrivateRoute><ParadeConfirmationEmail /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/dates',
      element: <PrivateRoute><ParadeDates /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/fees',
      element: <PrivateRoute><ParadeOptionalFees /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/home-page',
      element: <PrivateRoute><ParadeHomePageGenerator /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/home-page-images',
      element: <PrivateRoute><ParadeHomePageImages /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/files',
      element: <PrivateRoute><ParadeDownloadableFiles /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/name',
      element: <PrivateRoute><ParadeName /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/partner-page',
      element: <PrivateRoute><ParadePartnerPage /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/promo-codes',
      element: <PrivateRoute><ParadePromoCodes /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/property-groups',
      element: <PrivateRoute><ParadePropertyGroups /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/property-timeframes',
      element: <PrivateRoute><ParadePropertyTimeframes /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/public-voting',
      element: <PrivateRoute><ParadePublicVotingOptions /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/registration-list',
      element: <PrivateRoute><ParadeRegistrationList /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/seo',
      element: <PrivateRoute><ParadeSEO /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/sponsor-timeframes',
      element: <PrivateRoute><ParadeSponsorTimeframes /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/sponsor-options',
      element: <PrivateRoute><ParadeSponsors /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/ticket-takers',
      element: <PrivateRoute><ParadeTicketTakers /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/tickets',
      element: <PrivateRoute><ParadeTickets /></PrivateRoute>, 
    }, {
      path: '/:clientURL/parades/:paradeURL/timeframes',
      element: <PrivateRoute><ParadeTimeframes /></PrivateRoute>, 
    }]
  }, {
    path: '/password-reset/:passwordKey',
    element: <ChangePasswordByEmail />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/signup/:invitationKey',
    element: <SignUp />,
  }]
);

export default router;
