import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateClientOptionsRequest } from 'src/redux/actions/client';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { fieldTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const ClientAdvancedConfiguration = () => {
  const client = useSelector(state => state.clientStore?.client);
  const loading = useSelector(state => state.loadingStore.GET_CLIENT);
  const [configuration, setConfiguration] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    dispatch(updateClientOptionsRequest({ clientURL: client.url, form: event }));
  }
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'clientOptions', client }));
    dispatch(updateDrawerContent({ key: 'clientOptions', options: { client } }));

    if (!configuration) {
      setConfiguration({
        formElements: {
          rows: [{
            heading: 'Change how elements are displayed',
          }, {
            elements: [{
              label: 'Optional CSS (Developers Only)',
              name: 'optionalCSS',
              type: fieldTypes.textarea,
              value: client?.optionalCSS
            }]
          }]
        },
        submitCTA: 'Save'
      });
    }
  }, [client]);
  
  return (
    <section className="box">
      <div className="box-title">
        <h3>
          {
            !loading && (
              <span>Advanced Configuration</span>
            )
          }
          {
            loading && (
              <LoadingCircle variant="dark" />
            )
          }
        </h3>
      </div>
      {
        (!loading && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_CLIENT_OPTIONS'}
          />
        </div>
        )
      }
    </section>
  )
}

export default ClientAdvancedConfiguration;
