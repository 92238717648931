import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadSurveySubmissionsRequest, getSurveyRequest } from 'src/redux/actions/surveys';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import ResourceList from 'src/components/ResourceList';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';

const SurveyVoterList = () => {
  const client = useSelector(state => state.clientStore?.client);
  const survey = useSelector(state => state.surveyStore?.survey);
  const surveyFetching = useSelector(state => state.loadingStore.GET_SURVEY);
  const { surveyURL } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!surveyURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!survey) {
      dispatch(getSurveyRequest({ clientURL: client.url, surveyURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'surveyVoterList', client, survey }));
    dispatch(updateDrawerContent({
      key: 'surveyVoterList',
      options: { client, survey } 
    }));
  }, [client, survey]);

  const actions = [{
    color: 'confirm',
    label: 'Download All',
    variant: 'contained',
    fn: () => {
      dispatch(downloadSurveySubmissionsRequest({
        clientURL: client.url,
        surveyID: survey.surveyID,
        surveyURL: survey.url
      }))
    }
  }, {
    label: 'View',
    requiresSelection: true,
    fn: submission => {
      dispatch(openModal({
        props: { client, survey, submission },
        key: 'viewSurveyVote'
      }))
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: voter => {
      dispatch(
        openDialog({
          props: { client, survey, voter },
          key: 'removeSurveyVoter'
        })
      )
    }
  }];

  return (
    <ResourceList
      actions={actions}
      fetching={surveyFetching}
      list={survey?.votes}
      renderItem={voter => 
        <div className="voter-list">
          <div className="voter-list-name">{voter.email || '[Not Logged In]'}</div>
          <div className="voter-list-date">{voter.date}</div>
        </div>
      }
      renderKey="voteID"
      title="Survey Submissions"
    />
  )
}

export default SurveyVoterList;
