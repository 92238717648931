export const GET_SEARCH_LIST_REQUEST = 'GET_SEARCH_LIST_REQUEST';
export const GET_SEARCH_LIST_SUCCESS = 'GET_SEARCH_LIST_SUCCESS';
export const GET_SEARCH_LIST_FAILED = 'GET_SEARCH_LIST_FAILED';

export function getSearchListRequest (body) {
  return {
    body,
    type: GET_SEARCH_LIST_REQUEST
  }
}
