import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResourceList from 'src/components/ResourceList';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { getContestListRequest } from 'src/redux/actions/contests';
import { Link, useNavigate } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { defaultFilterOption, productTypes } from 'src/constants';

const Contests = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contests = useSelector(state => state.contestStore?.contests);
  const contestsFetching = useSelector(state => state.loadingStore.GET_CONTEST_LIST);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client },
          key: 'createContest'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: contest => {
      navigate(contest.url);
    }
  }, {
    label: (contest) => contest?.archived ? 'Restore' : 'Archive',
    requiresSelection: true,
    fn: contest => {
      dispatch(
        openDialog({
          props: { client, contest },
          key: 'archiveContest'
        })
      )
    }
  }, {
    label: 'Duplicate',
    requiresSelection: true,
    fn: contest => {
      dispatch(
        openModal({
          props: { client, contest },
          key: 'duplicateContest'
        })
      )
    }
  }];

  const filters = {
    searchBy: ['name'],
    default: defaultFilterOption,
    options: {
      active: {
        fn: item => !item.archived,
        label: 'Active'
      },
      archived: {
        fn: item => !!item.archived,
        label: 'Archived'
      }
    }
  };

  useEffect(() => {
    if (client) {
      dispatch(updateBreadcrumbs({ key: 'contests', client }));
      dispatch(updateDrawerContent({ key: 'clientContests', options: { client } }));

      if (!contestsFetching) {
        dispatch(getContestListRequest({ clientURL: client.url }));
      }
    }
  }, [client]);

  return (
    <ResourceList
      actions={actions}
      fetching={contestsFetching}
      filters={filters}
      list={contests}
      renderHeaders={() => 
        <div className="contest-list">
          <div className="contest-list-details">Name</div>
          <div className="contest-list-type">Type</div>
          <div className="contest-list-status">Status</div>
        </div>
      }
      renderItem={item => 
        <div className={`contest-list ${item.archived && 'archived'}`}>
          <div className="contest-list-details">
            <Link to={item.url}>{item.name}</Link>
            { (item.entryStartDate && item.entryEndDate) && 
              <div className="contest-list-details-dates">
                {item.entryStartDate} - {item.entryEndDate}
              </div>
            }
          </div>
          <div className="contest-list-type">
            {item.type === productTypes.contest ? 'Competition' : 'Scholarship'}
          </div>
          <div className="contest-list-status">{item.archived ? 'Archived' : 'Active'}</div>
        </div>
      }
      renderKey="contestID"
      title="Awards Program List"
    />
  )
}

export default Contests;
