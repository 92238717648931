import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes } from 'src/constants';
import { assignContestAwardsRequest } from 'src/redux/actions/contests';

const AssignAwardsForm = ({ category, entry, handleClose, scholarship }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    const map = new Map();
    for (const award in event) {
      map.set(award, event[award]);
    }
    const awards = [...(entry.awards || [])].map(award => {
      const newAward = { ...award };
      newAward.selected = map.get(award.awardID);

      return newAward;
    });

    const form = category ? {
      categoryID: category.categoryID,
      awards
    } : {
      scholarshipID: scholarship.scholarshipID,
      awards
    };

    dispatch(assignContestAwardsRequest({
      clientURL: client.url,
      contestURL: contest.url,
      entryID: entry.entryID,
      form
    }));
  }

  const awardsConfiguration = () => {
    const mappedAwards = (entry?.awards || []).map(award => {
      return {
        label: award.name,
        name: award?.awardID,
        type: fieldTypes.checkbox,
        value: award.selected
      }
    });
  
    const chunkedAwards = mappedAwards.map((elem, i) => {
      return i % 2 ? [] : mappedAwards.slice(i, i + 2);
    }).filter(chunk => {
      return !!chunk?.length;
    });
  
    return chunkedAwards.map(chunk => {
      return {
        elements: [...chunk]
      };
    });
  }

  const configuration = {
    formElements: {
      rows: awardsConfiguration()
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="assign-award-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'ASSIGN_CONTEST_AWARDS'}
      />
    </section>
  )
}

export default AssignAwardsForm;
