import moment from "moment";

export const displayDate = ({ date }) => {
  return moment.utc(date).format('dddd: MMMM Do, YYYY');
}

export const displayTime = ({ date }) => {
  return moment.utc(date).format('h:mma');
}

export const getFirstMinuteToday = () => {
  return `${new Date().toLocaleDateString('en-US')} 00:00 AM`;
}

export const getLastMinuteToday = () => {
  return `${new Date().toLocaleDateString('en-US')} 11:59 PM`;
}

export const getToday = () => {
  return new Date().toLocaleDateString('en-US');
}

export const advanceDate = ({ date }) => {
  return moment.utc(date).add(1, 'days').format('YYYY-MM-DD');
}

export const parseDate = ({ date }) => {
  return moment.utc(date).format('YYYY-MM-DD');
}

export const parseDateTime = ({ date }) => {
  return moment.utc(date).format('YYYY-MM-DDTHH:mm:ss');
}

export const parseTime = ({ date }) => {
  return moment.utc(date).format('HH:mm:ss');
}
