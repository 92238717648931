import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import Typeahead from 'src/components/Typeahead';
import DragDrop from 'src/components/DragDrop';

const TypeaheadList = ({
  actions,
  create,
  draggable,
  fetching,
  handleDrag,
  list,
  permitInvitation,
  permitTypedValue,
  placeholder,
  renderItem,
  renderKey,
  selecting,
  title,
  updating
}) => {
  const modalRequest = useSelector(state => state.modalStore?.modal);
  const dialogRequest = useSelector(state => state.modalStore?.dialog);
  const [selectedItem, setSelectedItem] = useState();
  const [filteredList, setFilteredList] = useState(list);

  useEffect(() => {
    const selectedItemInList = list?.some(item => item === selectedItem);
    if (!selectedItemInList) {
      setSelectedItem();
    }
    setFilteredList(list);
  }, [list]);

  useEffect(() => {
    if (modalRequest?.activeClose || dialogRequest?.activeClose) {
      setSelectedItem();
    }
  }, [modalRequest?.activeClose, dialogRequest?.activeClose]);

  const handleSelect = (event) => {
    create(event);
  }

  const renderListItem = ({ item }) => {
    return (
      <div
        className={`item${item === selectedItem ? ' selected' : ''}`}
        key={item[renderKey]}
        onClick={() => {
          setSelectedItem(item);
        }}
      >
        <input
          checked={item === selectedItem}
          onChange={() => setSelectedItem(item)}
          name="selectedItem"
          type="radio"
          value={item[renderKey]}
        />
        {
          typeof renderItem === 'function' && (
            renderItem(item)
          ) 
        }
      </div>
    )
  }

  return (
    <section className="typeahead-list box">
      <div className="box-title">
        <h3>
          <span>{title}
          {
            (fetching || selecting || updating) && (
              <LoadingCircle variant="dark" />
            )
          }
          </span>
        </h3>
        {
          (!fetching && actions?.length > 0) && 
          <div className="actions">
            {
              actions.map(action => {
                return (
                  <Button
                    className="action"
                    color={action.color || 'primary'}
                    disabled={(action.requiresSelection && !selectedItem) || (typeof action.disabled === 'function' && action.disabled(selectedItem))}
                    key={action.label}
                    onClick={() => {action.fn(selectedItem)}}
                    variant={action.variant || 'outlined'}
                  >{typeof action.label === 'function' ? action.label(selectedItem) : action.label}</Button>
                )
              })
            }
          </div>
        }
      </div>
      {
        !fetching && (
          <div className="box-content">
            <div className="search">
              <Typeahead
                selecting={selecting}
                onSelect={handleSelect}
                permitInvitation={permitInvitation}
                permitTypedValue={permitTypedValue}
                placeholder={placeholder}
              />
            </div>
            <div className="list">
              { draggable &&
                <DragDrop
                  droppableId={`${renderKey}-list`}
                  handleDrag={handleDrag}
                  list={filteredList}
                  renderKey={renderKey}
                  renderListItem={renderListItem}
                  setList={setFilteredList}
                />
              }
              { !draggable &&
                (list || []).map(user => {
                  return (
                    <div
                      className={`item ${user === selectedItem ? 'selected' : ''}`}
                      key={user[renderKey]}
                      onClick={() => setSelectedItem(user)}
                    >
                      <input
                        checked={user === selectedItem}
                        onChange={() => setSelectedItem(user)}
                        name="selectedItem"
                        type="radio"
                        value={user[renderKey]}
                      />
                      {
                        typeof renderItem === 'function' && (
                          renderItem(user)
                        ) 
                      }
                    </div>
                  )
                })
              }
              {
                (!list?.length) && (
                  <div className="user disabled">
                    No Records Found
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    </section>
  )
};

export default TypeaheadList;
