import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResourceList from 'src/components/ResourceList';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { getClientListRequest } from 'src/redux/actions/client';
import { useNavigate } from 'react-router-dom';
import { openModal } from 'src/redux/actions/modal';
import { updateDrawerContent } from 'src/redux/actions/drawer';

const Clients = () => {
  const clients = useSelector(state => state.clientStore?.clients);
  const clientsFetching = useSelector(state => state.loadingStore.GET_CLIENTS);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          key: 'createClient'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: client => {
      dispatch(
        openModal({
          props: { client },
          key: 'editClient'
        })
      )
    }
  }, {
    label: 'View',
    requiresSelection: true,
    fn: client => navigate(`/${client.url}`)
  }];

  const filters = {
    searchBy: ['name']
  };

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'network' }));
    dispatch(updateDrawerContent({ key: 'networkClientList' }));

    if (!clientsFetching) {
      dispatch(getClientListRequest());
    }
  }, []);

  return (
    <ResourceList
      actions={actions}
      fetching={clientsFetching}
      filters={filters}
      list={clients}
      renderItem={item => 
        <div className="client-list">{item.name}</div>
      }
      renderKey="name"
      title="Client List"
    />
  )
}

export default Clients;
