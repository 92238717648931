import * as actions from 'src/redux/actions/modal';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.ACTIVE_CLOSE_MODAL_SUCCESS:
      return { ...state, modal: { activeClose: true } }
    case actions.CLOSE_MODAL_SUCCESS:
      return { ...state, modal: null }
    case actions.OPEN_MODAL:
      return { ...state, modal: { ...action.body, activeClose: false } };
    default:
      return state;
  }
}

export default reducer;
