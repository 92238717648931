import React, { useState } from 'react';
import { TextField } from '@mui/material';

const NumberField = ({ className, label, name, onChange, value }) => {
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState();

  const handleTouched = () => {
    setTouched(true);
  };

  const handleChange = (value) => {
    setError(isNaN(value) ? `Number is required` : undefined);
    onChange(value);
  };

  return <div className={className}>
    <label>{label}</label>
    <TextField
      autoComplete="off"
      error={!!(touched && error)}
      helperText={touched && error ? error : null}
      id={`invoice-number-${name}`}
      name={name}
      onChange={e => {handleChange(e.target.value)}}
      onBlur={() => {}}
      onFocus={handleTouched}
      required={true}
      type="number"
      value={value || ''}
    />
  </div>
};

export default NumberField;
