import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateContestScholarshipRequest } from 'src/redux/actions/contests';
import { getFirstMinuteToday, getLastMinuteToday, parseDateTime } from 'src/utilities/dates';

const ScholarshipForm = ({ handleClose, scholarship }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const dispatch = useDispatch();

  const amountOptions = [{
    label: 'Varies',
    value: 'varies'
  }, {
    label: 'Defined',
    value: 'defined'
  }];

  const handleSubmit = (event) => {
    event.sequence = event.sequence || contest.scholarships?.length || 0;
    const applicationStartDate = parseDateTime({ date: event.applicationStartDate });
    const applicationEndDate = parseDateTime({ date: event.applicationEndDate });
    dispatch(updateContestScholarshipRequest({ clientURL: client.url, contestURL: contest.url, form: {...(scholarship || {}), ...event, applicationEndDate, applicationStartDate} }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Scholarship Name',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: scholarship?.name || '',
          required: true
        }]
      }, {
        elements: [{
          label: 'Application Start Date',
          name: 'applicationStartDate',
          dateRangeEnd: 'applicationEndDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: scholarship?.applicationStartDate || getFirstMinuteToday(),
          required: true
        }, {
          label: 'Application End Date',
          name: 'applicationEndDate',
          dateRangeStart: 'applicationStartDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: scholarship?.applicationEndDate || getLastMinuteToday(),
          required: true
        }]
      }, {
        elements: [{
          label: 'Scholarship Amount Type',
          name: 'amountType',
          type: fieldTypes.select,
          options: amountOptions,
          value: scholarship?.amountType || amountOptions[0].value
        }, {
          label: 'Scholarship Amount',
          name: 'amount',
          type: fieldTypes.input,
          inputType: inputTypes.currency,
          hide: { key: 'amountType', value: amountOptions[0].value },
          value: scholarship?.amount || ''
        }]
      }, {
        elements: [{
          label: 'Optional Verbose Scholarship Description (appears only on home page)',
          name: 'description',
          type: fieldTypes.textarea,
          value: scholarship?.description || ''
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="scholarship-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_CONTEST_SCHOLARSHIP'}
      />
      {
        scholarship && <>
          <hr />
          Public Landing Page: <Link
            target="_blank"
            to={`https://${client.url}.cpjam.com/${contest.url}/scholarships/${scholarship.url}`}
          >{`https://${client.url}.cpjam.com/${contest.url}/scholarships/${scholarship.url}`}</Link>
          <hr />
          Applications Page: <Link
            target="_blank"
            to={`https://${client.url}.cpjam.com/${contest.url}/scholarships/${scholarship.url}/applications`}
          >{`https://${client.url}.cpjam.com/${contest.url}/scholarships/${scholarship.url}/applications`}</Link>
          <hr />
          Scoring Page: <Link
            target="_blank"
            to={`https://${client.url}.cpjam.com/${contest.url}/scholarships/${scholarship.url}/scoring`}
          >{`https://${client.url}.cpjam.com/${contest.url}/scholarships/${scholarship.url}/scoring`}</Link>
        </>
      }
    </section>
  )
}

export default ScholarshipForm;
