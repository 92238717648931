import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResourceList from 'src/components/ResourceList';
import { gatewayOptions } from 'src/constants';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import {
  getClientInvoicesRequest,
  payInvoiceRequest
} from 'src/redux/actions/client';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { openModal } from 'src/redux/actions/modal';
import { currency } from 'src/utilities/strings';

const ClientInvoices = () => {
  const client = useSelector(state => state.clientStore?.client);
  const invoicesFetching = useSelector(state => state.loadingStore.GET_CLIENT_INVOICES);
  const [amountDue, setAmountDue] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'clientInvoicing', client }));
    dispatch(updateDrawerContent({ key: 'clientInvoicing', options: { client }  }));
    dispatch(getClientInvoicesRequest({ clientURL: client.url }));
  }, []);

  useEffect(() => {
    setAmountDue((client?.invoices || []).reduce((acc, item) => {
      return !item.paidDate ? (acc + item.amount) : acc;
    }, 0));
  }, [client?.invoices]);

  const actions = [{
    color: 'confirm',
    disabled: (invoice) => !!invoice.paidDate,
    label: 'Pay',
    requiresSelection: true,
    variant: 'contained',
    fn: invoice => {
      const { amount: balance, items } = invoice;
      const payInvoice = ({ token }) => {
        dispatch(payInvoiceRequest({ clientURL: client.url, form: { invoice, token } }));
      };
      dispatch(
        openModal({
          props: { balance, handleCheckout: payInvoice, gateway: gatewayOptions.stripe.value, items },
          key: 'payInvoice'
        })
      )
    }
  }, {
    label: 'View',
    requiresSelection: true,
    fn: invoice => {
      const { amount: balance, items } = invoice;
      dispatch(openModal({
        props: { balance, items },
        key: 'viewInvoice'
      }))
    }
  }, {
    label: 'View Full Page',
    requiresSelection: true,
    fn: invoice => window.open(`https://${client.url}.cpjam.com/invoices/${invoice.invoiceID}`)
  }];

  const filters = {
    options: {
      unpaid: {
        fn: item => !item.paidDate,
        label: 'Unpaid'
      },
      paid: {
        fn: item => !!item.paidDate,
        label: 'Paid'
      }
    }
  };

  return (
    <section className="client-invoices grid">
      { (!!amountDue && !invoicesFetching) &&
        <section className="box">
          <div className="box-title">
            <h3>
              <span>Amount Due: {currency(amountDue)}</span>
            </h3>
          </div>
        </section>
      }
      <ResourceList
        actions={actions}
        fetching={invoicesFetching}
        filters={filters}
        list={client.invoices}
        renderHeaders={() => 
          <div className="invoice">
            <div className="invoice-amount">Invoice Amount</div>
            <div className="invoice-date">Invoice Date</div>
            <div className="invoice-date-paid">Invoice Paid Date</div>
            <div className="invoice-paid-by">Invoice Paid By</div>
          </div>
        }
        renderItem={item => 
          <div className="invoice">
            <div className="invoice-amount">{currency(item.amount)}</div>
            <div className="invoice-date">{item.invoiceDisplayDate}</div>
            <div className="invoice-date-paid">{item.paidDisplayDate}</div>
            <div className="invoice-paid-by">{item.paidBy}</div>
          </div>
        }
        renderKey="invoiceID"
        title="CPJ Invoices"
      />
    </section>
  )
}

export default ClientInvoices;
