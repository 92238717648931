import { put, takeEvery } from 'redux-saga/effects';
import * as actions from 'src/redux/actions/breadcrumbs';

function* updateBreadcrumbsRequest(request) {
  const breadcrumbs = request.breadcrumbs;
  yield put({ type: actions.UPDATE_BREADCRUMB_SUCCESS, breadcrumbs });
}

function* breadcrumbSaga() {
  yield takeEvery(actions.UPDATE_BREADCRUMB, updateBreadcrumbsRequest);
}

export default breadcrumbSaga;
