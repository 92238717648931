export const CLEAR_USER = 'CLEAR_USER';
export const CLEAR_VIEW_USER = 'CLEAR_VIEW_USER';

export const CHANGE_USER_PASSWORD_REQUEST = 'CHANGE_USER_PASSWORD_REQUEST';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAILED = 'CHANGE_USER_PASSWORD_FAILED';

export const CHANGE_USER_PASSWORD_BY_EMAIL_REQUEST = 'CHANGE_USER_PASSWORD_BY_EMAIL_REQUEST';
export const CHANGE_USER_PASSWORD_BY_EMAIL_SUCCESS = 'CHANGE_USER_PASSWORD_BY_EMAIL_SUCCESS';
export const CHANGE_USER_PASSWORD_BY_EMAIL_FAILED = 'CHANGE_USER_PASSWORD_BY_EMAIL_FAILED';

export const CLEAR_FORGOT_PASSWORD_CHANGED = 'CLEAR_FORGOT_PASSWORD_CHANGED';
export const CLEAR_USER_PASSWORD_CHANGED = 'CLEAR_USER_PASSWORD_CHANGED';

export const CREATE_USER_MEMBERSHIP_REQUEST = 'CREATE_USER_MEMBERSHIP_REQUEST';
export const CREATE_USER_MEMBERSHIP_SUCCESS = 'CREATE_USER_MEMBERSHIP_SUCCESS';
export const CREATE_USER_MEMBERSHIP_FAILED = 'CREATE_USER_MEMBERSHIP_FAILED';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const GET_ACTION_PANEL_REQUEST = 'GET_ACTION_PANEL_REQUEST';
export const GET_ACTION_PANEL_SUCCESS = 'GET_ACTION_PANEL_SUCCESS';
export const GET_ACTION_PANEL_FAILED = 'GET_ACTION_PANEL_FAILED';

export const GET_INVITATION_REQUEST = 'GET_INVITATION_REQUEST';
export const GET_INVITATION_SUCCESS = 'GET_INVITATION_SUCCESS';
export const GET_INVITATION_FAILED = 'GET_INVITATION_FAILED';

export const GET_PASSWORD_KEY_REQUEST = 'GET_PASSWORD_KEY_REQUEST';
export const GET_PASSWORD_KEY_SUCCESS = 'GET_PASSWORD_KEY_SUCCESS';
export const GET_PASSWORD_KEY_FAILED = 'GET_PASSWORD_KEY_FAILED';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const REMOVE_HEADSHOT_REQUEST = 'REMOVE_HEADSHOT_REQUEST';
export const REMOVE_HEADSHOT_SUCCESS = 'REMOVE_HEADSHOT_SUCCESS';
export const REMOVE_HEADSHOT_FAILED = 'REMOVE_HEADSHOT_FAILED';

export const REMOVE_USER_MEMBERSHIP_REQUEST = 'REMOVE_USER_MEMBERSHIP_REQUEST';
export const REMOVE_USER_MEMBERSHIP_SUCCESS = 'REMOVE_USER_MEMBERSHIP_SUCCESS';
export const REMOVE_USER_MEMBERSHIP_FAILED = 'REMOVE_USER_MEMBERSHIP_FAILED';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';

export const UPDATE_USER_CONTACT_REQUEST = 'UPDATE_USER_CONTACT_REQUEST';
export const UPDATE_USER_CONTACT_SUCCESS = 'UPDATE_USER_CONTACT_SUCCESS';
export const UPDATE_USER_CONTACT_FAILED = 'UPDATE_USER_CONTACT_FAILED';

export const UPDATE_USER_PERSONAL_INFORMATION_REQUEST = 'UPDATE_USER_PERSONAL_INFORMATION_REQUEST';
export const UPDATE_USER_PERSONAL_INFORMATION_SUCCESS = 'UPDATE_USER_PERSONAL_INFORMATION_SUCCESS';
export const UPDATE_USER_PERSONAL_INFORMATION_FAILED = 'UPDATE_USER_PERSONAL_INFORMATION_FAILED';

export const UPDATE_USER_PRIVACY_REQUEST = 'UPDATE_USER_PRIVACY_REQUEST';
export const UPDATE_USER_PRIVACY_SUCCESS = 'UPDATE_USER_PRIVACY_SUCCESS';
export const UPDATE_USER_PRIVACY_FAILED = 'UPDATE_USER_PRIVACY_FAILED';

export const UPDATE_USER_VOLUNTEER_SETTINGS_REQUEST = 'UPDATE_USER_VOLUNTEER_SETTINGS_REQUEST';
export const UPDATE_USER_VOLUNTEER_SETTINGS_SUCCESS = 'UPDATE_USER_VOLUNTEER_SETTINGS_SUCCESS';
export const UPDATE_USER_VOLUNTEER_SETTINGS_FAILED = 'UPDATE_USER_VOLUNTEER_SETTINGS_FAILED';

export const UPLOAD_HEADSHOT_REQUEST = 'UPLOAD_HEADSHOT_REQUEST';
export const UPLOAD_HEADSHOT_SUCCESS = 'UPLOAD_HEADSHOT_SUCCESS';
export const UPLOAD_HEADSHOT_FAILED = 'UPLOAD_HEADSHOT_FAILED';

export const VIEW_USER_REQUEST = 'VIEW_USER_REQUEST';
export const VIEW_USER_SUCCESS = 'VIEW_USER_SUCCESS';
export const VIEW_USER_FAILED = 'VIEW_USER_FAILED';

export function changeUserPasswordRequest (body) {
  return {
    body,
    type: CHANGE_USER_PASSWORD_REQUEST
  }
}

export function changeUserPasswordByEmailRequest (body) {
  return {
    body,
    type: CHANGE_USER_PASSWORD_BY_EMAIL_REQUEST
  }
}

export function clearForgotPasswordChanged () {
  return {
    type: CLEAR_FORGOT_PASSWORD_CHANGED
  }
}

export function clearUserPasswordChanged () {
  return {
    type: CLEAR_USER_PASSWORD_CHANGED
  }
}

export function clearViewUser () {
  return {
    type: CLEAR_VIEW_USER
  }
}

export function createUserMembershipRequest (body) {
  return {
    body,
    type: CREATE_USER_MEMBERSHIP_REQUEST
  }
}

export function forgotPasswordRequest (body) {
  return {
    body,
    type: FORGOT_PASSWORD_REQUEST
  }
}

export function getActionPanelRequest () {
  return {
    type: GET_ACTION_PANEL_REQUEST
  }
}

export function getInvitationRequest (params) {
  return {
    params,
    type: GET_INVITATION_REQUEST
  }
}

export function getPasswordKeyRequest (body) {
  return {
    body,
    type: GET_PASSWORD_KEY_REQUEST
  }
}

export function getUserRequest () {
  return {
    type: GET_USER_REQUEST
  }
}

export function loginRequest (body) {
  return {
    body,
    type: LOGIN_REQUEST
  }
}

export function logoutRequest (body) {
  return {
    body,
    type: LOGOUT_REQUEST
  }
}

export function removeHeadshotRequest (body) {
  return {
    body,
    type: REMOVE_HEADSHOT_REQUEST
  }
}

export function removeUserMembershipRequest (body) {
  return {
    body,
    type: REMOVE_USER_MEMBERSHIP_REQUEST
  }
}

export function signupRequest (body) {
  return {
    body,
    type: SIGNUP_REQUEST
  }
}

export function updateUserContactRequest (body) {
  return {
    body,
    type: UPDATE_USER_CONTACT_REQUEST
  }
}

export function updateUserPersonalInformationRequest (body) {
  return {
    body,
    type: UPDATE_USER_PERSONAL_INFORMATION_REQUEST
  }
}

export function updateUserPrivacyRequest (body) {
  return {
    body,
    type: UPDATE_USER_PRIVACY_REQUEST
  }
}

export function updateUserVolunteerSettingsRequest (body) {
  return {
    body,
    type: UPDATE_USER_VOLUNTEER_SETTINGS_REQUEST
  }
}

export function uploadHeadshotRequest (body) {
  return {
    body,
    type: UPLOAD_HEADSHOT_REQUEST
  }
}

export function viewUserRequest (body) {
  return {
    body,
    type: VIEW_USER_REQUEST
  }
}
