import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes } from 'src/constants';
import { updateUserVolunteerSettingsRequest } from 'src/redux/actions/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const AccountVolunteer = ({ handleClose }) => {
  const user = useSelector(state => state.userStore?.user);
  const submitted = useSelector(state => state.completedStore.UPDATE_USER_VOLUNTEER_SETTINGS);

  useEffect(() => {
    if (submitted) {
      handleClose();
    }
  }, [submitted]);

  const dispatch = useDispatch();

  const volunteerOptions = [{
    key: 'volunteerIDR',
    label: 'Residential Interior Design'
  }, {
    key: 'volunteerIDC',
    label: 'Commercial Interior Design'
  }, {
    key: 'volunteerHBR',
    label: 'Residential Home Building'
  }, {
    key: 'volunteerHBC',
    label: 'Commercial Construction'
  }, {
    key: 'volunteerKB',
    label: 'Kitchen/Bath'
  }, {
    key: 'volunteerArchitecture',
    label: 'Architecture'
  }, {
    key: 'volunteerLandscaping',
    label: 'Landscaping'
  }, {
    key: 'volunteerMarketing',
    label: 'Marketing'
  }, {
    key: 'volunteerFurniture',
    label: 'Furniture Design'
  }];

  const volunteerOptionsConfiguration = () => {
    const mappedOptions = (volunteerOptions || []).map(option => {
      return {
        label: option.label,
        name: option?.key,
        type: fieldTypes.checkbox,
        value: !!(user || {})[option.key]
      }
    });
  
    const chunkedOptions = mappedOptions.map((elem, i) => {
      return i % 2 ? [] : mappedOptions.slice(i, i + 2);
    }).filter(chunk => {
      return !!chunk?.length;
    });
  
    return chunkedOptions.map(chunk => {
      return {
        elements: [...chunk]
      };
    });
  }

  const configuration = {
    formElements: {
      rows: [
        {
          forward: 'Which of these project specializations are you willing to volunteer for?'
        },
        ...volunteerOptionsConfiguration()
      ]
    },
    submitCTA: 'Save'
  };

  const handleSubmit = (event) => {
    dispatch(updateUserVolunteerSettingsRequest({ form: event }));
  }

  return (
    <section className="my-account-content-section">
      <div className="my-account-content-section-title">
        <span>Volunteer To Judge</span>
        <FontAwesomeIcon
          className="my-account-content-section-title-close"
          icon={faXmark}
          onClick={handleClose}
        />
      </div>
      <div className="my-account-content-section-sub-title">Volunteer to judge competitions on this platform</div>
      <AutoForm
        configuration={configuration}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_USER_VOLUNTEER_SETTINGS'}
      />
    </section>
  )
}

export default AccountVolunteer;
