import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faTrashCanXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  MenuItem,
  Select
} from '@mui/material';
import { createUserMembershipRequest } from 'src/redux/actions/users';
import { openDialog } from 'src/redux/actions/dialog';
import { getClientListRequest } from 'src/redux/actions/client';
import LoadingCircles from 'src/components/Elements/LoadingCircles';
import LoadingBars from 'src/components/Elements/LoadingBars';

const AccountMemberships = ({ handleClose }) => {
  const user = useSelector(state => state.userStore?.user);
  const isSubmitting = useSelector(state => state.loadingStore.CREATE_USER_MEMBERSHIP);
  const submitted = useSelector(state => state.completedStore.CREATE_USER_MEMBERSHIP);
  const clients = useSelector(state => state.clientStore.clients);
  const [selectedClient, setSelectedClient] = useState();
  const [filteredClients, setFilteredClients] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!clients?.length) {
      dispatch(getClientListRequest());
      return;
    }

    const map = new Map();
    (user?.memberships || []).forEach(membership => {
      map.set(membership.clientID, true);
    });
    const filteredClients = (clients || []).filter(client => !!client.hasMemberships && !map.has(client.clientID));
    setFilteredClients(filteredClients);
    setSelectedClient(filteredClients[0].clientID);
  }, [clients, user?.memberships]);

  useEffect(() => {
    if (submitted) {
      handleClose();
    }
  }, [submitted]);

  const handleCreate = () => {
    dispatch(createUserMembershipRequest({ form: { clientID: selectedClient }}))
  }

  const handleSelect = (value) => {
    setSelectedClient(value);
  }

  const handleRemove = (membership) => {
    dispatch(openDialog({
      props: { membership },
      key: 'removeMembership'
    }));
  }

  return (
    <section className="my-account-content-section">
      <div className="my-account-content-section-title">
        <span>
          Memberships & Affiliations
        </span>
        <FontAwesomeIcon
          className="my-account-content-section-title-close"
          icon={faXmark}
          onClick={handleClose}
        />
      </div>
      <div className="my-account-content-section-sub-title">Connect with and follow design organizations</div>
      {
        !selectedClient && <LoadingBars />
      }
      {
        selectedClient && <>
          <div className="my-account-content-section-selection">
            <label>Which organization are you a member of?</label>
            <Select 
              onChange={event => handleSelect(event.target.value)}
              value={selectedClient}
            >{
              (filteredClients || [])?.map(client => {
                return (
                  <MenuItem
                    key={client.clientID}
                    value={client.clientID}
                  >{client.name}</MenuItem>
                )
              })
            }</Select>
          </div>
          <Button
            className="my-account-content-section-membership-button"
            disabled={isSubmitting}
            onClick={handleCreate}
            variant="contained"
          >{ isSubmitting ? <LoadingCircles /> : <>
            <FontAwesomeIcon
              icon={faPlus}
            />Add Membership
            </> }</Button>
        </>
      }
      <hr className="my-account-content-section-separator" />
      <div className="my-account-content-section-heading">My Memberships:</div>
      <div className="my-account-content-section-memberships">
      {
        (user.memberships || []).map(membership => 
          <div
            className="my-account-content-section-membership"
            key={membership.clientID}
          >
            <div className="my-account-content-section-membership-details">
              <img
                className="my-account-content-section-membership-details-logo"
                src={membership.logo}
              />
              <div className="my-account-content-section-membership-details-name">{membership.name}</div>
            </div>
            <Button
              className="my-account-content-section-membership-button trash"
              disabled={isSubmitting}
              onClick={() => handleRemove(membership)}
            ><FontAwesomeIcon icon={faTrashCanXmark} /></Button>
          </div>
        ) 
      }
      </div>
      {
        !(user.memberships || []).length && <p>You are not a member of any organizations.</p>
      }
      
    </section>
  )
}

export default AccountMemberships;
