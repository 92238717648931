import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StructureForm from 'src/components/TemplateForms/Common/StructureForm';
import FormStructureInputField from 'src/components/TemplateForms/Common/StructureForm/FormStructureInputField';
import FormStructureSelectField from 'src/components/TemplateForms/Common/StructureForm/FormStructureSelectField';
import FormStructureSliderField from 'src/components/TemplateForms/Common/StructureForm/FormStructureSliderField';
import { Button } from '@mui/material';
import { createResourceID } from 'src/utilities/strings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCanXmark } from '@fortawesome/pro-light-svg-icons';
import { updateContestScoresheetCriteriaRequest } from 'src/redux/actions/contests';
import sortBy from 'src/utilities/sort';
import { openDialog } from 'src/redux/actions/dialog';
import { cloneDeep } from 'lodash';
import { oneToHundred } from 'src/constants';

const ScoresheetCriteriaForm = ({ scoresheet, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const isRequesting = useSelector(state => state.loadingStore.UPDATE_CONTEST_SCORESHEET_CRITERIA);
  const [list, setList] = useState();
  const [formState, setFormState] = useState({});
  const [formValid, setFormValid] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const list = (scoresheet?.scoresheetCriteria?.length && scoresheet?.scoresheetCriteria) || [createDefaultCriteria()];
    const newState = createInitialState({ list });
    setFormState(newState);
    setList(list);
    errorCheck(newState);
  }, []);

  const createInitialState = ({ list }) => {
    return list.reduce((acc, criteria, index) => {
      return {...acc, [criteria.criteriaID]: {
        maximumValue: criteria.formElements.maximumValue,
        verbose: criteria.formElements.verbose,
        permitNA: criteria.formElements.permitNA,
        sequence: index,
        label: (criteria.formElements.label === null || criteria.formElements.label === undefined) ? '' : criteria.formElements.label
      }}
    }, {});
  }

  const createButton = () => {
    return (
      <Button
        onClick={createCriteria}
        variant="contained"
      >Create Criteria</Button>
    )
  }

  const createDefaultCriteria = () => {
    return {
      formElements: {
        label: '',
        maximumValue: 1,
        permitNA: false,
        verbose: ''
      },
      criteriaID: createResourceID()
    };
  }

  const createCriteria = () => {
    const newCriteria = createDefaultCriteria();
    setList([...list, newCriteria]);

    const newState = {...formState, [newCriteria.criteriaID]: {
      sequence: list?.length || 0,
      maximumValue: 1,
      permitNA: false,
      label: '',
      verbose: ''
    }}
    
    setFormState(newState);
    errorCheck(newState);
  }

  const errorCheck = (newState) => {
    let inValid = false;
    for (const criteria in newState) {
      if (!newState[criteria].label) {
        inValid = true;
        break;
      }

      if (inValid) {
        break;
      }
    }
    setFormValid(!inValid);
  }

  const handleBlur = () => {
    errorCheck(formState);
  }

  const onElementChange = ({ item, prop, value }) => {
    const newState = cloneDeep(formState);
    newState[item.criteriaID][prop] = value;

    setFormState(newState);
    errorCheck(newState);
  }

  const onDrag = ({ criteriaList }) => {
    setList(criteriaList);
    const newState = cloneDeep(formState);
    
    (criteriaList || []).forEach((item, index) => {
      newState[item.criteriaID].sequence = index;
    });

    setFormState(newState);
  }

  const removeCriteria = ({ item }) => {
    dispatch(
      openDialog({
        key: 'removeContestScoresheetCriteria',
        props: {
          fn: () => {
            const newList = cloneDeep(list);
            const index = list.findIndex(criteria => criteria.criteriaID === item.criteriaID);
            newList.splice(index, 1)
            setList(newList);

            const newState = cloneDeep(formState);
            delete newState[item.criteriaID];

            setFormState(newState);
            errorCheck(newState);
          }
        }
      })
    );
  }

  const prepareFormValues = ({ formState }) => {
    const criteria = Array.from(Object.keys(formState)).map(key => {
      return {
        formElements: {
          label: formState[key].label,
          maximumValue: formState[key].maximumValue,
          permitNA: formState[key].permitNA,
          verbose: formState[key].verbose
        },
        criteriaID: key,
        sequence: formState[key].sequence
      }
    });
    return sortBy({ key: 'sequence', list: criteria });
  }

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(updateContestScoresheetCriteriaRequest({ clientURL: client.url, contestURL: contest.url, form: {
      scoresheetID: scoresheet.scoresheetID,
      scoresheetCriteria: prepareFormValues({ formState }) }
    }));
  }

  const renderListItem = ({ item }) => {
    return (
      <div
        className="scoresheet-criteria"
        key={item.criteriaID}
      >
        <div className="scoresheet-criteria-config">
          <div className="scoresheet-criteria-config-content">
            <FormStructureInputField
              error={!formState[item.criteriaID]?.label && 'Criteria title required'}
              handleBlur={handleBlur}
              handleChange={value => onElementChange({ item, prop: 'label', value })}
              placeholder="Criteria"
              value={formState[item.criteriaID]?.label || ''}
            />
            <FormStructureSelectField
              handleBlur={handleBlur}
              handleChange={value => onElementChange({ item, prop: 'maximumValue', value })}
              options={oneToHundred}
              value={formState[item.criteriaID]?.maximumValue}
            />
          </div>
          <div className="scoresheet-criteria-config-content">
            <FormStructureInputField
              handleBlur={handleBlur}
              handleChange={value => onElementChange({ item, prop: 'verbose', value })}
              placeholder="Optional Enhanced Description"
              value={formState[item.criteriaID]?.verbose || ''}
            />
            <FormStructureSliderField
              checked={formState[item.criteriaID].permitNA}
              label="Permit N/A"
              onChange={e => onElementChange({ item, prop: 'permitNA', value: e.target.checked })}
              sx={{ minWidth: 115, margin: 0 }}
            />
            <Button
              className="trash"
              onClick={() => removeCriteria({ item })}
            ><FontAwesomeIcon icon={faTrashCanXmark} /></Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <StructureForm
      createButton={createButton}
      disabled={!formValid}
      handleClose={handleClose}
      isRequesting={isRequesting}
      list={list}
      onDrag={event => {onDrag({ criteriaList: event.list })}}
      onSubmit={onSubmit}
      renderKey="criteriaID"
      renderListItem={renderListItem}
      setList={() => {}}
    />
  )
}

export default ScoresheetCriteriaForm;
