import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import {
  documentFolderAttachmentTypes,
  fieldTypes,
  restrictionTypes,
  yesOrNo
} from 'src/constants';
import { updateContestDownloadableFileRequest } from 'src/redux/actions/contests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';

const DownloadFileFoldersForm = ({ file, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const [configuration, setConfiguration] = useState();
  const [attachedTo, setAttachedTo] = useState(file?.attachedTo || documentFolderAttachmentTypes.none);
  const dispatch = useDispatch();

  const attachmentOptions = [{
    label: 'No Document Folders',
    value: documentFolderAttachmentTypes.none
  }, {
    label: 'Specific Document Folders',
    value: documentFolderAttachmentTypes.specific
  }];

  useEffect(() => {
    setConfiguration(createConfiguration());
  }, [attachedTo]);

  const handleSubmit = (event) => {
    const map = new Map();
    for (const documentFolder in event) {
      map.set(documentFolder, event[documentFolder]);
    }
    const attachedFolders = [...(contest.documentFolders || [])].filter(documentFolder => {
      return !!event[documentFolder.documentFolderID];
    }).map(folder => folder.documentFolderID);
    dispatch(updateContestDownloadableFileRequest({
      clientURL: client.url,
      contestURL: contest.url,
      form: {
        attachedFolders,
        attachedTo: event.attachedTo,
        displayOnLanding: event.displayOnLanding,
        fileID: file.fileID,
      }
    }));
  }

  const createConfiguration = () => {
    const config = {
      formElements: {
        rows: [{
          elements: [{
            label: 'File Is Displayed On Landing Page',
            name: 'displayOnLanding',
            type: fieldTypes.select,
            options: yesOrNo,
            value: !!file.displayOnLanding
          }]
        }, {
          elements: [{
            label: 'Attach Downloadable File To',
            name: 'attachedTo',
            type: fieldTypes.select,
            options: attachmentOptions,
            value: attachedTo
          }]
        }]
      },
      submitCTA: 'Save',
      cancelCTA: 'Cancel'
    };

    if (attachedTo === restrictionTypes.specific) {
      return { ...config, formElements: { ...config.formElements, rows: [...config.formElements.rows, ...foldersConfiguration()] }};
    }

    return config;
  }


  const onChange = ({ element, value }) => {
    if (element?.name === 'attachedTo') {
      setAttachedTo(value);
    }
  }

  const foldersConfiguration = () => {
    const mappedFolders = (contest.documentFolders || []).map(documentFolder => {
      return {
        label: documentFolder.name,
        name: documentFolder?.documentFolderID,
        type: fieldTypes.checkbox,
        value: (file.attachedFolders || []).includes(documentFolder?.documentFolderID)
      }
    });
  
    const chunkedFolders = mappedFolders.map((elem, i) => {
      return i % 2 ? [] : mappedFolders.slice(i, i + 2);
    }).filter(chunk => {
      return !!chunk?.length;
    });
  
    return chunkedFolders.map(chunk => {
      return {
        elements: [...chunk]
      };
    });
  }

  const handleClick = () => {
    navigator.clipboard.writeText(file.url);
    toast.success('Link copied to clipboard.');
  }

  return (
    <section className="downloadable-file-folders-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        onChange={onChange}
        requestPrefix={'UPDATE_CONTEST_DOWNLOADABLE_FILE'}
      />
      <hr />
      <div className="downloadable-file-folders-copy">
        <Button
          color="confirm"
          variant="contained"
        >
          <FontAwesomeIcon onClick={handleClick} icon={faCopy} />
        </Button>
        <TextField
          disabled
          sx={{ width: '100%' }}
          type="text"
          value={file.url}
        />
      </div>
    </section>
  )
}

export default DownloadFileFoldersForm;
