import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  reorderContestCoordinatorsRequest,
  updateContestCoordinatorRequest,
  getContestRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import TypeaheadList from 'src/components/TypeaheadList';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import UserProfile from 'src/components/UserProfile';

const ContestCoordinators = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const creatingCoordinator = useSelector(state => state.loadingStore.UPDATE_CONTEST_COORDINATOR);
  const updatingCoordinators = useSelector(state => state.loadingStore.REORDER_CONTEST_COORDINATORS);

  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  const handleCreateCoordinator = (coordinator) => {
    if (coordinator.typedValue) {
      dispatch(
        openModal({
          props: { client, contest, coordinator },
          key: 'createManualContestCoordinator'
        })
      );
      return;
    }
    dispatch(updateContestCoordinatorRequest({ clientURL: client.url, contestURL: contest.url, form: coordinator }));
  }

  const reorderCoordinatorList = ({ list }) => {
    dispatch(reorderContestCoordinatorsRequest({ clientURL: client.url, contestURL: contest.url, form: { coordinators: list } }));
  }

  const actions = [{
    disabled: (coordinator) => !!coordinator?.userID,
    label: 'Edit',
    requiresSelection: true,
    variant: 'contained',
    fn: coordinator => {
      dispatch(
        openModal({
          props: { client, contest, coordinator },
          key: 'editManualContestCoordinator'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: coordinator => {
      dispatch(
        openDialog({
          props: { client, contest, coordinator },
          key: 'removeContestCoordinator'
        })
      )
    }
  }];

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestCoordinators', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestCoordinators',
      options: { client, contest } 
    }));
  }, [client, contest]);
  
  return (
    <section className="contest-coordinators">
      <TypeaheadList
        actions={actions}
        create={handleCreateCoordinator}
        draggable
        fetching={contestFetching}
        handleDrag={reorderCoordinatorList}
        list={contest?.coordinators}
        placeholder="Type To Add Coordinator..."
        permitTypedValue
        renderItem={coordinator =>
          <UserProfile
            showEmail
            user={coordinator}
          ></UserProfile>
        }
        renderKey="email"
        selecting={creatingCoordinator}
        title="Coordinators"
        updating={updatingCoordinators}
      />
    </section>
  )
}

export default ContestCoordinators;