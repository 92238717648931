import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { productTypes } from 'src/constants';
import { getContestRequest } from 'src/redux/actions/contests';
import ContestConfirmWinners from './ContestConfirmWinners';
import ScholarshipConfirmWinners from './ScholarshipConfirmWinners';

const ConfirmWinners = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const { contestURL } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  if (contest?.type === productTypes.contest) {
    return <ContestConfirmWinners />
  }

  if (contest?.type === productTypes.scholarship) {
    return <ScholarshipConfirmWinners />
  }
}

export default ConfirmWinners;
