import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { createBreadcrumbMap, parseBreadcrumbMap } from './hierarchy';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const BreadcrumbsComponent = () => {
  const breadcrumbParams = useSelector(state => state.breadcrumbStore?.breadcrumbs);
  const clients = useSelector(state => state.clientStore?.clients);
  const isRequesting = useSelector(state => state.loadingStore.GET_CLIENT);
  const [iterableBreadcrumbs, setIterableBreadcrumbs] = useState();
  const [currentLocation, setCurrentLocation] = useState();
  const breadcrumbMap = useMemo(() => createBreadcrumbMap(), []);

  useEffect(() => {
    if (breadcrumbParams) {
      let parsedBreadcrumbs = parseBreadcrumbMap({ key: breadcrumbParams?.key, map: breadcrumbMap, params: breadcrumbParams });
      if (clients?.length === 1 && parsedBreadcrumbs?.length > 1) {
        parsedBreadcrumbs.splice(0, 1);
        parsedBreadcrumbs[0].label = 'Home';
      }
      setCurrentLocation(parsedBreadcrumbs.pop());
      setIterableBreadcrumbs(parsedBreadcrumbs);
    }
  }, [breadcrumbParams]);

  return (
    <section className="breadcrumbs">
      {
        isRequesting && <LoadingCircle variant="dark" />
      }
      { !isRequesting &&
        <Breadcrumbs separator={<FontAwesomeIcon
          className="icon"
          icon={faChevronRight}
        />}>
          {(iterableBreadcrumbs || []).map(breadcrumb => {
            return (
              <Link
                key={breadcrumb.url}
                to={breadcrumb.url}
              >{breadcrumb.label}</Link>
            )
          })}
          <span>{currentLocation?.label}</span>
        </Breadcrumbs>
      }
    </section>
  )
};

export default BreadcrumbsComponent;
