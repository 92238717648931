import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import {
  changeUserPasswordRequest,
  clearUserPasswordChanged
} from 'src/redux/actions/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const AccountChangePassword = ({ handleClose }) => {
  const user = useSelector(state => state.userStore?.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearUserPasswordChanged());
  }, []);

  useEffect(() => {
    if (user?.passwordChanged) {
      handleClose();
    }
  }, [user?.passwordChanged]);

  const handleSubmit = (event) => {
    dispatch(changeUserPasswordRequest({ form: event }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Password',
          name: 'oldPassword',
          type: fieldTypes.input,
          inputType: inputTypes.passwordAny,
          value: '',
          required: true
        }]
      }, {
        elements: [{
          label: 'Password',
          name: 'newPassword',
          mustMatchPassword: 'confirmPassword',
          type: fieldTypes.input,
          inputType: inputTypes.password,
          value: '',
          required: true,
          permitShowPassword: true
        }]
      }, {
        elements: [{
          label: 'Confirm Password',
          name: 'confirmPassword',
          passwordToMatch: 'newPassword',
          type: fieldTypes.input,
          inputType: inputTypes.passwordAny,
          value: '',
          required: true,
          permitShowPassword: true
        }]
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="my-account-content-section">
      <div className="my-account-content-section-title">
        <span>Change Password</span>
        <FontAwesomeIcon
          className="my-account-content-section-title-close"
          icon={faXmark}
          onClick={handleClose}
        />
      </div>
      <div className="my-account-content-section-sub-title">Choose a unique password for your account</div>
      { !user?.passwordChanged &&
        <AutoForm
          configuration={configuration}
          handleSubmit={handleSubmit}
          requestPrefix={'CHANGE_USER_PASSWORD'}
        />
      }
      {
        user?.passwordChanged && <p className="my-account-content-section-paragraph">Your password has been successfully changed.</p>
      }
    </section>
  )
}

export default AccountChangePassword;
