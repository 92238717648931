import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileUploader from 'src/components/Elements/FileUploader';
import ProgressBar from 'src/components/Elements/ProgressBar';
import { fileRestrictions } from 'src/constants';
import { uploadLogoRequest } from 'src/redux/actions/client';
import { openDialog } from 'src/redux/actions/dialog';
import { Button } from '@mui/material';

const ClientLogo = () => {
  const [progress, setProgress] = useState();
  const client = useSelector(state => state.clientStore?.client);
  const dispatch = useDispatch();

  const handleUpload = (file) => {
    dispatch(uploadLogoRequest({ clientURL: client.url, file, handleProgress: setProgress }));
  };

  const handleRemove = () => {
    setProgress();
    dispatch(openDialog({
      props: { client },
      key: 'removeLogo'
    }));
  };

  return (
    <section className="client-logo">
      <div className="client-logo-current">
        <img src={client.logo} alt="Client Logo" />
      </div>
      <div className="client-logo-actions">
        {
          client.image && (
            <Button
              color="error"
              onClick={handleRemove}
              variant="contained"
            >Remove Logo</Button>
          )
        }
        {
          !client.image && (
            <FileUploader
              accept={fileRestrictions.jpg.accept}
              id="client-logo"
              label="Upload Logo"
              onChange={handleUpload}
              requestPrefix="UPLOAD_LOGO"
            />
          )
        }
      </div>
      {
        (!client.image && progress) && (
          <ProgressBar progress={progress}/>
        )
      }
    </section>
  )
}

export default ClientLogo;
