import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  downloadParadeAttendanceRequest,
  getParadeAttendanceRequest,
  getParadeRequest
} from 'src/redux/actions/parades';
import { openModal } from 'src/redux/actions/modal';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { AgCharts } from 'ag-charts-react';
import Pagination from '@mui/material/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagnifyingGlass,
  faFileInvoiceDollar,
  faXmark
} from '@fortawesome/pro-light-svg-icons';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import sortBy from 'src/utilities/sort';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const ParadeAttendance = () => {
  const all = 'all';
  const attendance = 'attendance';
  const emails = 'emails';
  const highest = 'highest';
  const number = 'number';
  const scans = 'scans';
  const total = 'total';

  const maxItems = 20;

  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const isRequesting = useSelector(state => state.loadingStore.GET_PARADE_ATTENDANCE);
  const isDownloading = useSelector(state => state.loadingStore.DOWNLOAD_PARADE_ATTENDANCE);
  const [page, setPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(maxItems);
  const [selectedDate, setSelectedDate] = useState(all);
  const [selectedProperty, setSelectedProperty] = useState(all);
  const [search, setSearch] = useState('');
  const [tickets, setTickets] = useState();
  const [filteredTickets, setFilteredTickets] = useState();
  const [sortingBy, setSortingBy] = useState(number);
  const [dateMap, setDateMap] = useState();
  const [propertyMap, setPropertyMap] = useState();
  const [propertyDateMap, setPropertyDateMap] = useState();
  const [ticketMap, setTicketMap] = useState();
  const [dates, setDates] = useState(null);
  const [reportType, setReportType] = useState(scans);
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reportOptions = [{
    label: 'Ticket Scans Visualization',
    value: scans
  }, {
    label: 'Ticket List',
    value: attendance
  }, {
    label: 'Guest Email Report',
    value: emails
  }];

  const sortOptions = [{
    label: 'Ticket Number',
    value: number
  }, {
    label: 'Highest Attendance',
    value: highest
  }];

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  useEffect(() => {
    setSelectedDate(all);
    setSelectedProperty(all);
    setFilteredTickets(tickets);
    setSearch('');
    setSortingBy(number);
  }, [reportType]);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeAttendance', client, parade }));
    dispatch(updateDrawerContent({
      key: 'paradeAttendance',
      options: { client, parade } 
    }));

    if (parade && !dates) {
      setDates(parade?.dates.map(date => {
        return {
          label: `${moment.utc(date.startDate).format('l')} - ${moment.utc(date.endDate).format('l')}`,
          value: date.dateID
        };
      }));
    }

    if (parade && typeof parade?.attendance === 'undefined') {
      dispatch(getParadeAttendanceRequest({
        clientURL: client.url,
        paradeURL
      }));
    }
  }, [client, parade]);

  useEffect(() => {
    if (parade?.attendance && !dateMap) {
      const dateMap = new Map();
      const dateTimeMap = new Map();
      const propertyMap = new Map();
      const ticketMap = new Map();
      const propertyDateMap = new Map();
      (parade?.properties || []).forEach(property => {
        if (!propertyMap.has(property.propertyID)) {
          propertyMap.set(property.propertyID, { address: property.address, scans: [] });
        }
        propertyDateMap.set(property.propertyID, new Map());
      });
      (parade?.dates || []).forEach(date => {
        const key = moment.utc(date.startDate).format('L');
        dateTimeMap.set(key, date.dateID);
        const duration = moment.duration(moment(date.startDate).diff(moment(date.endDate)));
        const hours = new Map();
        for (let i = 0; i < Math.abs(duration.asHours()); i++) {
          const time = moment.utc(date.startDate).add(i, 'hours').format('H');
          hours.set(time, []);
        }
        dateMap.set(date.dateID, { displayValue: key, scans: hours });
        Array.from(propertyDateMap.keys()).map(propertyID => {
          if (!propertyDateMap.get(propertyID).has(key)) {
            propertyDateMap.get(propertyID).set(key, []);
          }
        });
      });
      (parade?.purchasedTickets || []).forEach(ticket => {
        if (!ticketMap.has(ticket.number)) {
          ticketMap.set(ticket.number, []);
        }
      });
      (parade?.attendance || []).forEach(item => {
        const key = moment.utc(item.timestamp || '').format('L');
        const time = moment.utc(item.timestamp || '').format('H');
        if (item.timestamp && dateTimeMap.has(key) && dateMap.get(dateTimeMap.get(key)).scans.has(time)) {
          dateMap.get(dateTimeMap.get(key)).scans.get(time).push(item);
        }
        if (item.propertyID && propertyMap.has(item.propertyID)) {
          propertyMap.get(item.propertyID).scans.push(item);
        }
        if (ticketMap.has(item.number)) {
          ticketMap.get(item.number).push(item);
        }
        if (propertyDateMap.get(item.propertyID).has(key)) {
          propertyDateMap.get(item.propertyID).get(key).push(item);
        }
      });
      const tickets = Array.from(ticketMap.entries()).map(([key, value]) => {
        return {
          guestEmail: value[0]?.guestEmail,
          number: key,
          total: (value || []).length,
          transactionID: value[0]?.transactionID
        }
      });
      setTickets(tickets);
      setFilteredTickets(tickets);
      setDateMap(dateMap);
      setPropertyMap(propertyMap);
      setTicketMap(ticketMap);
      setPropertyDateMap(propertyDateMap);
    }
  }, [parade?.attendance]);

  const handleClick = ({ attendance, ticket }) => {
    dispatch(openModal({
      props: { attendance, properties: parade?.properties, ticket },
      key: 'viewParadeAttendance'
    }))
  };

  const handleSearch = () => {
    if (/^-?\d+$/.test(search)) {
      setFilteredTickets(tickets.filter(ticket => ticket.number === parseInt(search)));
      return;
    }

    const lower = search.toLowerCase();

    setFilteredTickets(tickets.filter(ticket => (ticket.guestEmail || '').toLowerCase().includes(lower)));
  };

  const handleDownload = () => {
    const propertyID = selectedProperty === all ? null : selectedProperty;
    dispatch(downloadParadeAttendanceRequest({
      clientURL: client.url,
      paradeURL,
      paradeID: parade?.paradeID,
      propertyID
    }));
  }

  const onSearchChange = (event) => {
    if (event?.keyCode === 13) {
      handleSearch();
    }
  };

  const clearSearch = () => {
    setSearch('');
    setFilteredTickets(tickets);
  };

  const invalidSearch = () => {
    if ((search || '').length < 4) {
      return true;
    }

    if (/^-?\d+$/.test(search)) {
      return false;
    }

    return (search || '').length < 8;
  }

  const handleDateChange = ({ value }) => {
    setSelectedDate(value);
  };

  const handlePropertyChange = ({ value }) => {
    setSelectedProperty(value);
  };

  const handleReportChange = ({ value }) => {
    setReportType(value);
  };

  const handlePaginationChange = (event, page) => {
    setPage(page);
    setStartIndex((page - 1) * maxItems);
    setEndIndex(((page - 1) * maxItems) + maxItems);
  }

  const militaryToStandardTime = ({ time }) => {
    const ampm = time >= 12 ? 'PM' : 'AM';
    const standardHours = time % 12 || 12;
    return `${standardHours} ${ampm}`;
  };

  const handleSortChange = ({ value }) => {
    setSortingBy(value);
    let sortMethod;
    let reverse;

    switch(value) {
      case highest: sortMethod = total; reverse = true; break;
      default: sortMethod = number;
    }

    setFilteredTickets(sortBy({ key: sortMethod, list: filteredTickets, reverse }));
  }

  const getChartData = () => {
    if (!propertyMap || !dateMap) {
      return []
    }

    if (selectedDate === all) {
      return selectedProperty === all ? Array.from(propertyMap.values()).map(item => {
        return {
          name: item.address,
          value: (item.scans).length
        };
      }) : Array.from(propertyDateMap.get(selectedProperty).entries())
      .map(([key, value]) => {
        return {
          name: key,
          value: (value || []).length
        }
      })
    }

    return selectedProperty === all ? Array.from(dateMap.get(selectedDate).scans.entries())
      .map(([key, value]) => {
        return {
          name: `${militaryToStandardTime({ time: parseInt(key) })} - ${militaryToStandardTime({ time: parseInt(key) + 1 })}`,
          value: (value || []).length
        }
      }) : Array.from(dateMap.get(selectedDate).scans.entries())
      .map(([key, value]) => {
        return {
          name: `${militaryToStandardTime({ time: parseInt(key) })} - ${militaryToStandardTime({ time: parseInt(key) + 1 })}`,
          value: (value || []).filter(value => value.propertyID === selectedProperty).length
        }
      });
  }

  const getTotalSummary = (data) => {
    if (selectedDate === all && selectedProperty === all) {
      const total = (parade?.attendance || []).length;
      const usedTickets = ticketMap ? Array.from(ticketMap.entries()).filter(([key, value]) => {
        return !!value.length;
      }).length : 0;
      const availableTickets = (parade?.purchasedTickets || []).length;
      return <p>{total} Total Scans, {usedTickets} Tickets Out Of {availableTickets} Used ({((usedTickets / availableTickets) * 100).toFixed(2)}%)</p>;
    }

    const total = data.reduce((acc, item) => acc + item.value, 0);
    return <p>{total} Total Scans</p>;
  }

  const renderChart = () => {
    const series = [{
      direction: 'horizontal',
      type: selectedDate === all ? 'bar' : 'line',
      xKey: 'name',
      yKey: 'value'
    }];

    const data = getChartData();
    const summary = getTotalSummary(data);

    return <>
      { summary || '' }
      <AgCharts options={{
        data,
        series
      }} />
    </>
  }

  return <section className="attendance grid">
    <section className="box">
      <h3 className="box-title">{
        (isRequesting || !parade) ? <LoadingCircle variant="dark" /> : 'Attendance & Analytics'
      }</h3>
      { (!isRequesting && parade) &&
        <div className="box-content">
          <div>
            <label>Report Type:</label>
            <Select 
              onChange={event => handleReportChange({ value: event.target.value })}
              value={reportType}
            >{
              (reportOptions || []).map(option => {
                return (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >{option.label}</MenuItem>
                )
              })
            }</Select>
          </div>
        </div>
      }
    </section>
    {
      (!isRequesting && parade && reportType === scans) && <section className="box">
        <h3 className="box-title">Ticket Scans Visualization</h3>
        <div className="box-content">
          <div className="attendance-selections">
            <div className="attendance-selection">
              <label>Date:</label>
              <Select 
                onChange={event => handleDateChange({ value: event.target.value })}
                value={selectedDate}
              >{
                [{
                  label: 'All Dates',
                  value: all
                }, ...(dates || [])].map(option => {
                  return <MenuItem
                    key={option.value}
                    value={option.value}
                  >{option.label}</MenuItem>
                })
              }</Select>
            </div>
            <div className="attendance-selection">
              <label>Property:</label>
              <Select 
                onChange={event => handlePropertyChange({ value: event.target.value })}
                value={selectedProperty}
              >{
                [{
                  label: 'All Properties',
                  value: all
                }, ...(parade?.properties || []).map(property => {
                  return {
                    label: property.address,
                    value: property.propertyID
                  }
                })].map(option => {
                  return <MenuItem
                    key={option.value}
                    value={option.value}
                  >{option.label}</MenuItem>
                })
              }</Select>
            </div>
          </div>
          { renderChart() }
        </div>
      </section>
    }
    {
      (!isRequesting && parade && reportType === attendance) && <section className="box">
        <h3 className="box-title">Ticket List</h3>
        <div className="box-content">
          <div className="attendance-selections">
            <div className="attendance-selection">
              <label>Sort By:</label>
              <Select 
                onChange={event => handleSortChange({ value: event.target.value })}
                value={sortingBy}
              >{
                (sortOptions || []).map(option => {
                  return (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                    >{option.label}</MenuItem>
                  )
                })
              }</Select>
            </div>
            <div className="attendance-selection">
              <label>Search</label>
              <div className="attendance-selection-search">
                <TextField
                  autoComplete="off"
                  id="ticket-search"
                  onChange={e => setSearch(e.target.value)}
                  onKeyDown={e => onSearchChange(e)}
                  placeholder="Ticket # Or Email" 
                  type="text"
                  value={search}
                />
                <Button
                  className="attendance-selection-search-button"
                  color="confirm"
                  disabled={invalidSearch()}
                  onClick={handleSearch}
                  size="small"
                  variant="contained"
                ><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                <Button
                  className="attendance-selection-search-button"
                  color="danger"
                  disabled={!search}
                  onClick={clearSearch}
                  size="small"
                  variant="contained"
                ><FontAwesomeIcon icon={faXmark} /></Button>
              </div>
            </div>
          </div>
          <div className="attendance-list">
            <div className="attendance-list-item heading">
              <div className="number">Number</div>
              <div className="email">Guest Email</div>
              <div className="visited">Visited</div>
              <div className="view">View</div>
              <div className="receipt">Receipt</div>
            </div>
            { (filteredTickets || []).slice(startIndex, endIndex).map(ticket =>
              <div
                className={`attendance-list-item parade${ticket.total === (parade?.properties || []).length ? ' complete' : ''}${ticket.total === 0 ? ' error' : ''}`}
                key={ticket.number}
                onClick={() => handleClick({ attendance: ticketMap.get(ticket.number), ticket })}
              >
                <div className="number">{ticket.number}</div>
                <div className="email">{ticket.declineEmail ? '[DECLINED]' : ticket.guestEmail}</div>
                <div className="visited">{ticket.total} / {(parade?.properties || []).length}</div>
                <div className="view">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    onClick={() => handleClick({ attendance: ticketMap.get(ticket.number), ticket })}
                  />
                </div>
                <div className="receipt"><Link
                    to={`https://${client.url}.cpjam.com/${paradeURL}/receipts/${ticket.transactionID}`}
                    target="_blank"
                  ><FontAwesomeIcon icon={faFileInvoiceDollar} /></Link></div>
              </div>
            )}
            {
              !(filteredTickets || []).length && <p>No Tickets Found</p>
            }
          </div>
          { filteredTickets?.length > maxItems &&
            <Stack
              alignItems="center"
              sx={{ margin: '12px 0' }}
            >
              <Pagination
                count={Math.ceil((filteredTickets || []).length / maxItems)}
                color="primary"
                onChange={handlePaginationChange}
                page={page}
              />
            </Stack>
          }
        </div>
      </section>
    }
    {
      (!isRequesting && parade && reportType === emails) && <section className="box">
        <h3 className="box-title">Guest Email Report</h3>
        <div className="box-content">
          <div className="attendance-selections">
            <div className="attendance-selection">
              <label>Property:</label>
              <Select 
                onChange={event => handlePropertyChange({ value: event.target.value })}
                value={selectedProperty}
              >{
                [{
                  label: 'All Properties',
                  value: all
                }, ...(parade?.properties || []).map(property => {
                  return {
                    label: property.address,
                    value: property.propertyID
                  }
                })].map(option => {
                  return <MenuItem
                    key={option.value}
                    value={option.value}
                  >{option.label}</MenuItem>
                })
              }</Select>
            </div>
            <div className="attendance-selection">
              <Button
                color="confirm"
                disabled={isDownloading}
                onClick={handleDownload}
                size="small"
                variant="contained"
              >{ isDownloading ? <LoadingCircle /> : 'Download' }</Button>
            </div>
          </div>
        </div>
      </section>
    }
  </section>
}

export default ParadeAttendance;
