import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSystemNotificationsRequest } from 'src/redux/actions/app';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';

const SystemNotifications = () => {
  const systemNotifications = useSelector(state => state.appStore?.systemNotifications);
  const notificationsFetching = useSelector(state => state.loadingStore.GET_SYSTEM_NOTIFICATIONS);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'systemNotifications' }));
    dispatch(updateDrawerContent({ key: 'networkSystemNotifications' }));

    if (!notificationsFetching) {
      dispatch(getSystemNotificationsRequest());
    }
  }, []);

  const truncateText = (value) => {
    if (value?.length < 50) {
      return value;
    }

    return `${value.slice(0, 50)}…`;
  }

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          key: 'createSystemNotification'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: notification => {
      dispatch(
        openModal({
          props: { notification },
          key: 'editSystemNotification'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: notification => {
      dispatch(
        openDialog({
          props: { notification },
          key: 'removeSystemNotification'
        })
      )
    }
  }];
  
  return (
    <ResourceList
      actions={actions}
      fetching={notificationsFetching}
      list={systemNotifications}
      renderItem={notification => 
        <div className="notification">{truncateText(notification.text || '')}</div>
      }
      renderKey="notificationID"
      title="System Notifications"
    />
  )
}

export default SystemNotifications;