import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import Typeahead from 'src/components/Typeahead';
import { Button } from '@mui/material';
import { openDialog } from 'src/redux/actions/dialog';

const MigrateUser = () => {
  const submitted = useSelector(state => state.completedStore.MIGRATE_USER);
  const [selectedOldUser, setSelectedOldUser] = useState();
  const [selectedNewUser, setSelectedNewUser] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'migrateUser' }));
    dispatch(updateDrawerContent({ key: 'networkMigrateUser' }));
  }, []);

  useEffect(() => {
    if (submitted) {
      setSelectedOldUser(null);
      setSelectedNewUser(null);
    }
  }, [submitted]);

  const handleSubmit = () => {
    dispatch(
      openDialog({
        props: { oldUserID: selectedOldUser.userID, newUserID: selectedNewUser.userID },
        key: 'migrateUser'
      })
    )
  }

  return (
    <section className="migrate-user">
      <section className="box">
        <div className="box-title">
          <h3>Migrate User</h3>
        </div>
        <div className="box-content">
          <div className="selected">
            <h4>From This User:</h4>
            {
              !selectedOldUser && 
              <Typeahead
                placeholder="Find A User..."
                onSelect={event => setSelectedOldUser(event)}
              />
            }
            {
              selectedOldUser && (
                <>
                  <div className="selected-user">
                    <img src={selectedOldUser.headshot} alt={selectedOldUser.fullName} />
                    <div className="selected-user-detail">
                      <div className="selected-user-detail-name">
                        Name: <span>{selectedOldUser.fullName}</span>
                      </div>
                      <div className="selected-user-detail-email">
                        Email: <span>{selectedOldUser.email}</span>
                      </div>
                      <div className="selected-user-detail-url">
                        URL: <span>{selectedOldUser.url}</span>
                      </div>
                    </div>
                  </div>
                  <Button
                    className="button"
                    onClick={() => setSelectedOldUser(null)}
                    type="submit"
                    variant="outlined"
                  >Clear User</Button>
                </>
              )
            }
          </div>
          <div className="selected">
            <h4>To This User:</h4>
            {
              !selectedNewUser && 
              <Typeahead
                placeholder="Find A User..."
                onSelect={event => setSelectedNewUser(event)}
              />
            }
            {
              selectedNewUser && (
                <>
                  <div className="selected-user">
                    <img src={selectedNewUser.headshot} alt={selectedNewUser.fullName} />
                    <div className="selected-user-detail">
                      <div className="selected-user-detail-name">
                        Name: <span>{selectedNewUser.fullName}</span>
                      </div>
                      <div className="selected-user-detail-email">
                        Email: <span>{selectedNewUser.email}</span>
                      </div>
                      <div className="selected-user-detail-url">
                        URL: <span>{selectedNewUser.url}</span>
                      </div>
                    </div>
                  </div>
                  <Button
                    className="button"
                    onClick={() => setSelectedNewUser(null)}
                    type="submit"
                    variant="outlined"
                  >Clear User</Button>
                </>
              )
            }
          </div>
          <Button
            className="button"
            disabled={!selectedNewUser || !selectedOldUser}
            onClick={handleSubmit}
            variant="contained"
          >Migrate User</Button>
        </div>
      </section>
    </section>
  )
}

export default MigrateUser;
