import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { duplicateContestRequest } from 'src/redux/actions/contests';

const DuplicateContestForm = ({ contest, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    dispatch(duplicateContestRequest({ clientURL: client.url, contestURL: contest.url, form: event }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Awards Program Name',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: '',
          required: true
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="duplicate-contest">
      <p>Duplicating will create a new awards program using these categories, document folders, entry forms, scoresheets and awards. You will also be redirected to the new awards program in the editor.</p>
      <p>The new awards program name is required to be unique within your local organization.</p>
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'DUPLICATE_CONTEST'}
      />
    </section>
  )
}

export default DuplicateContestForm;
