import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrip } from '@fortawesome/free-solid-svg-icons';

const DragDrop = ({
  droppableId,
  gap,
  handleDrag,
  horizontal,
  list,
  renderKey,
  renderListArgs,
  renderListItem,
  secondary,
  setList
}) => {
  const onDragEnd = result => {
    const { destination, source, draggableId } = result;
    if (destination?.index === source?.index || destination?.droppableId !== source?.droppableId) {
      return;
    }

    const sourceItem = list.find(item => draggableId === item[renderKey]);
    const newList = [...list];

    newList.splice(source.index, 1);
    newList.splice(destination.index, 0, sourceItem);
    setList(newList);
    handleDrag({ list: newList });
  }

  if (!(list || []).length) {
    return '';
  }

  if (list.length === 1) {
    return <section className="droppable-list">
      <div className="draggable-item">
        { renderListItem({ item: list[0], index: 0, ...(renderListArgs || {}) }) }
      </div>
    </section>
  }

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
    >
      <Droppable droppableId={droppableId} direction={horizontal ? 'horizontal' : 'vertical'}>
        {
          droppableProvided => {
            const rect = document.getElementById('modal')?.getBoundingClientRect();
            return (
              <section
                className={`droppable-list${secondary ? ' secondary' : ''}${horizontal ? ' horizontal' : ''}`}
                ref={droppableProvided.innerRef}
                style={{gridGap: gap ? gap : null}}
                {...droppableProvided.droppableProps}
              >
                {
                  typeof renderListItem === 'function' && list.map((item, index) => 
                    <Draggable
                      draggableId={item[renderKey]}
                      index={index}
                      key={item[renderKey]}
                    >
                      {(draggableProvided, snapshot) => {
                        if (snapshot.isDragging && rect) {
                          draggableProvided.draggableProps.style = {
                            ...draggableProvided.draggableProps.style,
                            left: draggableProvided.draggableProps.style.left - rect.left,
                            top: draggableProvided.draggableProps.style.top - rect.top
                          }
                        }
                        return (
                          <div
                            className={`draggable-item${snapshot.isDragging ? ' dragging' : ''} ${item.dragAlignment || ''}`}
                            {...draggableProvided.draggableProps}
                            ref={draggableProvided.innerRef}
                          >
                            <div
                              className="drag-handle"
                              {...draggableProvided.dragHandleProps}
                            >
                              <FontAwesomeIcon icon={faGrip} />
                            </div>
                            {
                              renderListItem({ item, index, ...(renderListArgs || {}) })
                            }
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                }
                {droppableProvided.placeholder}
              </section>
            )
          }
        }
      </Droppable>
    </DragDropContext>
  )
}

export default DragDrop;
