import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateUserPersonalInformationRequest } from 'src/redux/actions/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const AccountPersonalInformation = ({ handleClose }) => {
  const user = useSelector(state => state.userStore?.user);
  const submitted = useSelector(state => state.completedStore.UPDATE_USER_PERSONAL_INFORMATION);
  const dispatch = useDispatch();

  useEffect(() => {
    if (submitted) {
      handleClose();
    }
  }, [submitted]);

  const handleSubmit = (event) => {
    dispatch(updateUserPersonalInformationRequest({ form: event }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'First Name',
          name: 'firstName',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          autoComplete: 'given-name',
          value: user?.firstName || '',
          required: true
        }, {
          label: 'Last Name',
          name: 'lastName',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: user?.lastName || '',
          autoComplete: 'family-name',
          required: true
        }]
      }, {
        elements: [{
          label: 'Title',
          name: 'title',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: user?.title || ''
        }, {
          label: 'Company',
          name: 'company',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: user?.company || ''
        }]
      }, {
        elements: [{
          label: 'Appellations & Designations',
          name: 'appellations',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: user?.appellations || ''
        }, {
          label: 'Website',
          name: 'website',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: user?.website || ''
        }]
      }, {
        elements: [{
          label: 'About Me',
          name: 'about',
          type: fieldTypes.textarea,
          value: user?.about || ''
        }]
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="my-account-content-section">
      <div className="my-account-content-section-title">
        <span>Personal Information</span>
        <FontAwesomeIcon
          className="my-account-content-section-title-close"
          icon={faXmark}
          onClick={handleClose}
        />
      </div>
      <div className="my-account-content-section-sub-title">Name, employer and company website</div>
      <AutoForm
        configuration={configuration}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_USER_PERSONAL_INFORMATION'}
      />
    </section>
  )
}

export default AccountPersonalInformation;
