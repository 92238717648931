import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import { getCurrentResourcesRequest } from 'src/redux/actions/app';
import { Link } from 'react-router-dom';

const CurrentResources = () => {
  const dispatch = useDispatch();
  const isRequesting = useSelector(state => state.loadingStore.GET_CURRENT_RESOURCES);
  const resources = useSelector(state => state.appStore?.resources);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'currentResources' }));
    dispatch(updateDrawerContent({ key: 'networkCurrentResources' }));
    dispatch(getCurrentResourcesRequest());
  }, []);
  
  return (
    <section className="current-resources grid">
      <section className="box">
        <div className="box-title">
          <h3><span>All Active Contests
          {
            isRequesting && (
              <LoadingCircle variant="dark" />
            )
          }
          </span></h3>
        </div>
        <div className="box-content">
          {
            (resources?.contests || []).map(contest => 
              <Link
                className="resource"
                to={`/${contest.clientURL}/contests/${contest?.contestURL}`}
                target="_blank"
              >
                <div className="resource-name">{contest.clientName} {contest.contestName}</div>
                <div className="resource-date">Entry Management: {contest.entryStartDate} &mdash; {contest.entryEndDate}</div>
                <div className="resource-date">Scoring Window: {contest.judgingStartDate} &mdash; {contest.judgingEndDate}</div>
              </Link>
            )
          }
        </div>
      </section>
      <section className="box">
        <div className="box-title">
          <h3><span>All Active Events
          {
            isRequesting && (
              <LoadingCircle variant="dark" />
            )
          }
          </span></h3>
        </div>
        <div className="box-content">
          {
            (resources?.events || []).map(event => 
              <Link
                className="resource"
                to={`/${event.clientURL}/contests/${event.eventURL}`}
                target="_blank"
              >
                <div className="resource-name">{event.clientName} {event.eventName}</div>
                <div className="resource-date">Event Time: {event.startDate} &mdash; {event.endDate}</div>
              </Link>
            )
          }
        </div>
      </section>
    </section>
  )
}

export default CurrentResources;
