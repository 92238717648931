import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import AppCard from 'src/components/Elements/AppCard';

const ClientHome = () => {
  const client = useSelector(state => state.clientStore?.client);
  const loading = useSelector(state => state.loadingStore.GET_CLIENT);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'client', client }));
    dispatch(updateDrawerContent({ key: 'client', options: { client } }));
  }, [client]);
  
  return (
    <section className="app-grid home-page">
      {
        client?.notifications?.length > 0 && (
          <div className="app-grid-row">
            <AppCard
              className="flex-1 shrinkable"
              titleVariant="primary"
              title="Important Notifications"
            >
              <div className="notifications">
                {(client?.notifications || []).map(notification => {
                  return (
                    <div
                      className="notification"
                      key={notification.notificationID}
                    >
                      {parse(notification.text)}
                    </div>
                  )
                }) }
              </div>
            </AppCard>
          </div>
        )
      }

      <div className="app-grid-row">
        <AppCard
          className="flex-2"
          editURL={`/${client?.url}/client`}
          loading={loading}
          title="Client Portal"
        >
          <div className="app-grid-title">
            <Link to={`/${client?.url}/client`}>
              <img src={client?.logo} alt="logo" />
              <div className="app-grid-title-details">
                <h1>
                  {client?.name}
                </h1>
              </div>
            </Link>
          </div>
        </AppCard>
        <div className="card-group">
          <AppCard
            className="shrinkable"
            title="Important URLs"
          >
            <div className="app-card-link">
              <label>Public Landing Page:</label>
              <Link
                className="item"
                target="_blank"
                to={`https://${client?.url}.cpjam.com`}
              >
                {`https://${client?.url}.cpjam.com`}
              </Link>
            </div>
          </AppCard>
        </div>
      </div>
    </section>
  )
}

export default ClientHome;
