import { call, put, takeEvery } from 'redux-saga/effects';
import handleFetch from 'src/utilities/fetch';
import * as actions from 'src/redux/actions/surveys';
import * as userActions from 'src/redux/actions/users';
import { toast } from 'react-toastify';

async function createSurvey({ clientURL, form }) {
  return await handleFetch({ url: `/${clientURL}/surveys`, form });
}

async function downloadSurveySubmissions({ clientURL, surveyURL, surveyID }) {
  return await handleFetch({ url: `/${clientURL}/surveys/${surveyURL}/download-submissions`,
    download: `survey_${surveyID}_submissions.xlsx`
  });
}

async function getSurveyList({ clientURL }) {
  return await handleFetch({ url: `/${clientURL}/surveys/edit` });
}

async function getSurvey({ clientURL, surveyURL }) {
  return await handleFetch({ url: `/${clientURL}/surveys/${surveyURL}/edit` });
}

async function removeSurvey({ clientURL, surveyURL }) {
  return await handleFetch({ url: `/${clientURL}/surveys/${surveyURL}/remove`, form: {} });
}

async function removeSurveyImage({ clientURL, surveyURL, form }) {
  return await handleFetch({ url: `/${clientURL}/surveys/${surveyURL}/images/remove`, form });
}

async function removeSurveyVote({ clientURL, surveyURL, form }) {
  return await handleFetch({ url: `/${clientURL}/surveys/${surveyURL}/vote/remove`, form });
}

async function updateSurveyName({ clientURL, surveyURL, form }) {
  return await handleFetch({ url: `/${clientURL}/surveys/${surveyURL}/info`, form });
}

async function updateSurveyQuestions({ clientURL, surveyURL, form }) {
  return await handleFetch({ url: `/${clientURL}/surveys/${surveyURL}/structure`, form });
}

async function updateSurveyVotingTimeframe({ clientURL, surveyURL, form }) {
  return await handleFetch({ url: `/${clientURL}/surveys/${surveyURL}/dates`, form });
}

async function uploadSurveyImage({ clientURL, surveyURL, file, handleProgress }) {
  return await handleFetch({ url: `/${clientURL}/surveys/${surveyURL}/images`, form: { file }, handleProgress });
}

function* createSurveyRequest(request) {
  try {
    const context = yield call(() => createSurvey(request.body));
    yield put({ type: actions.CREATE_SURVEY_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CREATE_SURVEY_FAILED, error });
  }
}

function* downloadSurveySubmissionsRequest(request) {
  try {
    const context = yield call(() => downloadSurveySubmissions(request.body));
    yield put({ type: actions.DOWNLOAD_SURVEY_SUBMISSIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.DOWNLOAD_SURVEY_SUBMISSIONS_FAILED, error });
  }
}

function* getSurveyRequest(request) {
  try {
    const context = yield call(() => getSurvey(request));
    yield put({ type: actions.GET_SURVEY_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_SURVEY_FAILED, error });
  }
}

function* getSurveyListRequest(request) {
  try {
    const context = yield call(() => getSurveyList(request));
    yield put({ type: actions.GET_SURVEY_LIST_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_SURVEY_LIST_FAILED, error });
  }
}

function* removeSurveyRequest(request) {
  try {
    const context = yield call(() => removeSurvey(request.body));
    yield put({ type: actions.REMOVE_SURVEY_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_SURVEY_FAILED, error });
  }
}

function* removeSurveyImageRequest(request) {
  try {
    const context = yield call(() => removeSurveyImage(request.body));
    yield put({ type: actions.REMOVE_SURVEY_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_SURVEY_IMAGE_FAILED, error });
  }
}

function* removeSurveyVoteRequest(request) {
  try {
    const context = yield call(() => removeSurveyVote(request.body));
    yield put({ type: actions.REMOVE_SURVEY_VOTE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_SURVEY_VOTE_FAILED, error });
  }
}

function* resetSurveyURLChanged() {
  yield put({ type: actions.RESET_SURVEY_URL_CHANGED_SUCCESS });
}

function* updateSurveyNameRequest(request) {
  try {
    const context = yield call(() => updateSurveyName(request.body));
    yield put({ type: actions.UPDATE_SURVEY_NAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_SURVEY_NAME_FAILED, error });
  }
}

function* updateSurveyQuestionsRequest(request) {
  try {
    const context = yield call(() => updateSurveyQuestions(request.body));
    yield put({ type: actions.UPDATE_SURVEY_QUESTIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_SURVEY_QUESTIONS_FAILED, error });
  }
}

function* updateSurveyVotingTimeframeRequest(request) {
  try {
    const context = yield call(() => updateSurveyVotingTimeframe(request.body));
    yield put({ type: actions.UPDATE_SURVEY_VOTING_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_SURVEY_VOTING_TIMEFRAME_FAILED, error });
  }
}

function* uploadSurveyImageRequest(request) {
  try {
    const context = yield call(() => uploadSurveyImage(request.body));
    yield put({ type: actions.UPLOAD_SURVEY_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPLOAD_SURVEY_IMAGE_FAILED, error });
  }
}

function* apiFailed(request) {
  toast.error(request.error.message);
  if (request.error.status === 403) {
    yield put({ type: userActions.CLEAR_USER });
  }
}

function* surveySagas() {
  yield takeEvery(actions.CREATE_SURVEY_REQUEST, createSurveyRequest);
  yield takeEvery(actions.DOWNLOAD_SURVEY_SUBMISSIONS_REQUEST, downloadSurveySubmissionsRequest);
  yield takeEvery(actions.GET_SURVEY_REQUEST, getSurveyRequest);
  yield takeEvery(actions.GET_SURVEY_LIST_REQUEST, getSurveyListRequest);
  yield takeEvery(actions.REMOVE_SURVEY_REQUEST, removeSurveyRequest);
  yield takeEvery(actions.REMOVE_SURVEY_IMAGE_REQUEST, removeSurveyImageRequest);
  yield takeEvery(actions.REMOVE_SURVEY_VOTE_REQUEST, removeSurveyVoteRequest);
  yield takeEvery(actions.RESET_SURVEY_URL_CHANGED, resetSurveyURLChanged);
  yield takeEvery(actions.UPDATE_SURVEY_NAME_REQUEST, updateSurveyNameRequest);
  yield takeEvery(actions.UPDATE_SURVEY_QUESTIONS_REQUEST, updateSurveyQuestionsRequest);
  yield takeEvery(actions.UPDATE_SURVEY_VOTING_TIMEFRAME_REQUEST, updateSurveyVotingTimeframeRequest);
  yield takeEvery(actions.UPLOAD_SURVEY_IMAGE_REQUEST, uploadSurveyImageRequest);

  yield takeEvery(actions.CREATE_SURVEY_FAILED, apiFailed);
  yield takeEvery(actions.DOWNLOAD_SURVEY_SUBMISSIONS_FAILED, apiFailed);
  yield takeEvery(actions.GET_SURVEY_FAILED, apiFailed);
  yield takeEvery(actions.GET_SURVEY_LIST_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_SURVEY_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_SURVEY_IMAGE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_SURVEY_VOTE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_SURVEY_NAME_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_SURVEY_QUESTIONS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_SURVEY_VOTING_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.UPLOAD_SURVEY_IMAGE_FAILED, apiFailed);
}

export default surveySagas;
