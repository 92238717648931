import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import AutoForm from 'src/components/AutoForm';
import Typeahead from 'src/components/Typeahead';
import UserProfile from 'src/components/UserProfile';
import {
  fieldTypes,
  inputTypes
} from 'src/constants';
import { changeEmailRequest } from 'src/redux/actions/app';

const ChangeEmail = () => {
  const submitted = useSelector(state => state.completedStore.CHANGE_EMAIL);
  const [selectedUser, setSelectedUser] = useState();
  const [configuration, setConfiguration] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'changeEmail' }));
    dispatch(updateDrawerContent({ key: 'networkChangeEmail' }));
  }, []);

  useEffect(() => {
    if (submitted) {
      setSelectedUser(null);
    }
  }, [submitted]);

  const handleSubmit = (event) => {
    dispatch(changeEmailRequest({ form: { ...event, userID: selectedUser.userID }}));
  }

  const selectUser = (user) => {
    setSelectedUser(user);
    setConfiguration({
      formElements: {
        rows: [{
          elements: [{
            label: 'Email',
            name: 'email',
            type: fieldTypes.input,
            inputType: inputTypes.email,
            value: '',
            required: true
          }]
        }]
      },
      submitCTA: 'Save',
      cancelCTA: 'Change User'
    })
  }
  
  return (
    <section className="change-email">
      <section className="box">
        <div className="box-title">
          <h3>Change User Email</h3>
        </div>
        <div className="box-content">
          {
            !selectedUser && 
            <Typeahead
              placeholder="Find A User..."
              onSelect={event => selectUser(event)}
            />
          }
          {
            selectedUser && (
              <>
                <h4>Selected User:</h4>
                <UserProfile
                  user={selectedUser}
                  showEmail
                />
                <AutoForm
                  configuration={configuration}
                  handleCancel={() => setSelectedUser(null)}
                  handleSubmit={handleSubmit}
                  requestPrefix={'CHANGE_EMAIL'}
                />
              </>
            )
          }
        </div>
      </section>
    </section>
  )
}

export default ChangeEmail;
