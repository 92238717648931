import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import UserProfile from 'src/components/UserProfile';
import { fieldTypes, volunteerSpecializations } from 'src/constants';
import { Button } from '@mui/material';
import {
  clearContestVolunteersRequest,
  requestContestJudgeVolunteerListRequest
} from 'src/redux/actions/contests';
import { openDialog } from 'src/redux/actions/dialog';
import { cloneDeep } from 'lodash';

const RequestJudgeVolunteerForm = ({ handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const clients = useSelector(state => state.clientStore?.volunteerClients);
  const contest = useSelector(state => state.contestStore?.contest);
  const listFetching = useSelector(state => state.loadingStore.REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST);
  const dispatch = useDispatch();
  const [configuration, setConfiguration] = useState();
  const [currentState, setCurrentState] = useState({});

  useEffect(() => {
    dispatch(clearContestVolunteersRequest());
    setConfiguration(createConfiguration());
  }, []);

  const handleSubmit = (event) => {
    const {
      volunteerIDR,
      volunteerIDC,
      volunteerHBR,
      volunteerHBC,
      volunteerKB,
      volunteerArchitecture,
      volunteerLandscaping,
      volunteerMarketing,
      volunteerFurniture,
      ...memberships
    } = event;
    dispatch(requestContestJudgeVolunteerListRequest({ clientURL: client.url, contestURL: contest.url, form: {
      specializations: {
        volunteerIDR,
        volunteerIDC,
        volunteerHBR,
        volunteerHBC,
        volunteerKB,
        volunteerArchitecture,
        volunteerLandscaping,
        volunteerMarketing,
        volunteerFurniture
      }, memberships: Object.keys(memberships).filter(key => !!memberships[key])
    } }));
  }

  const requestVolunteer = ({ volunteer }) => {
    dispatch(openDialog({
      props: { client, contest, volunteer },
      key: 'requestContestVolunteer'
    }))
  }

  const assembleSpecializationForm = () => {
    const specializations = (volunteerSpecializations || []).map(specialization => {
      return {
        label: specialization.label,
        name: specialization.key,
        type: fieldTypes.checkbox,
        value: currentState[specialization.key] || false
      }
    });
  
    const chunkedSpecializations = specializations.map((elem, i) => {
      return i % 2 ? [] : specializations.slice(i, i + 2);
    }).filter(chunk => {
      return !!chunk?.length;
    });
  
    return chunkedSpecializations.map(chunk => {
      return {
        elements: [...chunk]
      };
    });
  }

  const assembleMembershipsForm = () => {
    const memberships = (clients || [])
      .filter(client => !!client.hasMemberships)
      .map(client => {
        return {
          label: client.name,
          name: client.clientID,
          type: fieldTypes.checkbox,
          value: currentState[client.clientID] || false
        }
      });
  
    const chunkedClients = memberships.map((elem, i) => {
      return i % 2 ? [] : memberships.slice(i, i + 2);
    }).filter(chunk => {
      return !!chunk?.length;
    });
  
    return chunkedClients.map(chunk => {
      return {
        elements: [...chunk]
      };
    });
  }

  const createConfiguration = () => {
    return {
      formElements: {
        rows: [{
          forward: 'What project specialization(s) are you looking for? The list of users that will be returned from your search will contain at least one of the specializations you select.'
        },
          ...assembleSpecializationForm(),
        {
          forward: '(Optional) What organizations should the volunteers be members of? Each volunteer will be a member of at least one of these selections.'
        },
          ...assembleMembershipsForm()
        ]
      },
      submitCTA: 'Search',
      cancelCTA: 'Cancel'
    };
  }

  const onChange = ({ element, value }) => {
    setCurrentState({ ...currentState, [element.name]: value })
  }

  return (
    <section className="volunteer-form">
      {
        !(contest?.volunteers || [])?.length && (
          <AutoForm
            configuration={configuration}
            handleCancel={handleClose}
            handleSubmit={handleSubmit}
            onChange={onChange}
            requestPrefix={'REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST'}
          />
        )
      }
      {
        ((contest?.volunteers || [])?.length > 0) && (
          <>
            <div className="volunteer-form-judges">
              {
                contest.volunteers.map(prospect => {
                  const volunteer = cloneDeep(prospect);
                  volunteer.asked = (contest.judgeProspects || []).some(prospect => volunteer.userID === prospect?.user?.userID);

                  return (
                    <div
                      className="volunteer-form-judges-item"
                      key={volunteer.userID}
                    >
                      <UserProfile
                        linkEmail
                        linkPhone
                        user={volunteer}
                      ></UserProfile>
                      <div className="volunteer-form-judges-item-specialize">
                        Specializes in: <span>{
                          `${(volunteerSpecializations || [])
                            .filter(specialization => volunteer[specialization.key])
                            .map(specialization => specialization.label)
                            .join(', ')}`
                        }</span>
                      </div>
                      <div className="volunteer-form-judges-item-scored">
                        <span>Awards programs scored: {volunteer?.scored || 0}</span>
                        <Button
                          disabled={volunteer?.asked || listFetching}
                          onClick={() => requestVolunteer({ volunteer })}
                          size="small"
                          variant="contained"
                        >{ volunteer?.asked ? 'Asked!' : 'Ask?' }</Button>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div className="volunteer-form-judges-buttons">
              <Button
                disabled={listFetching}
                onClick={() => {
                  setConfiguration(createConfiguration());
                  dispatch(clearContestVolunteersRequest());
                }}
                variant="contained"
              >Change Search</Button>
            </div>
          </>
        )
      }
    </section>
  )
}

export default RequestJudgeVolunteerForm;
