import { call, put, takeEvery } from 'redux-saga/effects';
import handleFetch from 'src/utilities/fetch';
import * as actions from 'src/redux/actions/client';
import * as userActions from 'src/redux/actions/users';
import { toast } from 'react-toastify';

async function authorizeSquare({ clientURL }) {
  return await handleFetch({ url: `/clients/${clientURL}/square/request`, form: {} });
}

async function authorizeStripe({ clientURL }) {
  return await handleFetch({ url: `/clients/${clientURL}/stripe/request`, form: {} });
}

async function getClient({ clientURL }) {
  return await handleFetch({ url: `/clients/${clientURL}` });
}

async function getClientInvoices({ clientURL }) {
  return await handleFetch({ url: `/clients/${clientURL}/invoices` });
}

async function getClientList() {
  return await handleFetch({ url: '/clients/admin-list', });
}

async function getClientVolunteerList() {
  return await handleFetch({ url: '/clients/volunteer-list', });
}

async function payInvoice({ clientURL, form }) {
  return await handleFetch({ url: `/clients/${clientURL}/invoices/pay/cc`, form });
}

async function removeAdministrator({ clientURL, administratorID }) {
  return await handleFetch({ url: `/clients/${clientURL}/administrators/remove`, form: { administratorID } });
}

async function removeAdministratorInvitation({ clientURL, invitationKey }) {
  return await handleFetch({ url: `/clients/${clientURL}/invitations/remove`, form: { invitationKey } });
}

async function removeLogo({ clientURL }) {
  return await handleFetch({ url: `/clients/${clientURL}/logo/remove`, form: {} });
}

async function revokeSquare({ clientURL }) {
  return await handleFetch({ url: `/clients/${clientURL}/square/clear`, form: {} });
}

async function revokeStripe({ clientURL }) {
  return await handleFetch({ url: `/clients/${clientURL}/stripe/clear`, form: {} });
}

async function updateAdministrator({ clientURL, form }) {
  return await handleFetch({ url: `/clients/${clientURL}/administrators`, form });
}

async function updateAdministratorInvitation({ clientURL, form }) {
  return await handleFetch({ url: `/clients/${clientURL}/invitations`, form });
}

async function updateClient({ form }) {
  return await handleFetch({ url: '/clients', form });
}

async function updateConfiguration({ clientURL, form }) {
  return await handleFetch({ url: `/clients/${clientURL}/options`, form });
}

async function updateGateway({ clientURL, form }) {
  return await handleFetch({ url: `/clients/${clientURL}/gateway`, form });
}

async function updateSettings({ clientURL, form }) {
  return await handleFetch({ url: `/clients/${clientURL}/settings`, form });
}

async function updateSocial({ clientURL, form }) {
  return await handleFetch({ url: `/clients/${clientURL}/social`, form });
}

async function uploadLogo({ clientURL, file, handleProgress }) {
  return await handleFetch({ url: `/clients/${clientURL}/logo`, form: { file }, handleProgress });
}

function* authorizeSquareRequest(request) {
  try {
    const context = yield call(() => authorizeSquare(request.body));
    yield put({ type: actions.AUTHORIZE_SQUARE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.AUTHORIZE_STRIPE_FAILED, error });
  }
}

function* authorizeStripeRequest(request) {
  try {
    const context = yield call(() => authorizeStripe(request.body));
    yield put({ type: actions.AUTHORIZE_STRIPE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.AUTHORIZE_STRIPE_FAILED, error });
  }
}

function* getClientRequest(request) {
  try {
    const context = yield call(() => getClient(request.body));
    yield put({ type: actions.GET_CLIENT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_CLIENT_FAILED, error });
  }
}

function* getClientInvoicesRequest(request) {
  try {
    const context = yield call(() => getClientInvoices(request.body));
    yield put({ type: actions.GET_CLIENT_INVOICES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_CLIENT_INVOICES_FAILED, error });
  }
}

function* getClientListRequest() {
  try {
    const context = yield call(() => getClientList());
    yield put({ type: actions.GET_CLIENT_LIST_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_CLIENT_LIST_FAILED, error });
  }
}

function* getClientVolunteerListRequest() {
  try {
    const context = yield call(() => getClientVolunteerList());
    yield put({ type: actions.GET_CLIENT_VOLUNTEER_LIST_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_CLIENT_VOLUNTEER_LIST_FAILED, error });
  }
}

function* payInvoiceRequest(request) {
  try {
    const context = yield call(() => payInvoice(request.body));
    yield put({ type: actions.PAY_INVOICE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.PAY_INVOICE_FAILED, error });
  }
}

function* removeAdministratorRequest(request) {
  try {
    const context = yield call(() => removeAdministrator(request.body));
    yield put({ type: actions.REMOVE_ADMINISTRATOR_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_ADMINISTRATOR_FAILED, error });
  }
}

function* removeAdministratorInvitationRequest(request) {
  try {
    const context = yield call(() => removeAdministratorInvitation(request.body));
    yield put({ type: actions.REMOVE_ADMINISTRATOR_INVITATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_ADMINISTRATOR_INVITATION_FAILED, error });
  }
}

function* removeLogoRequest(request) {
  try {
    const context = yield call(() => removeLogo(request.body));
    yield put({ type: actions.REMOVE_LOGO_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_LOGO_FAILED, error });
  }
}

function* revokeSquareRequest(request) {
  try {
    const context = yield call(() => revokeSquare(request.body));
    yield put({ type: actions.REVOKE_SQUARE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REVOKE_STRIPE_FAILED, error });
  }
}

function* revokeStripeRequest(request) {
  try {
    const context = yield call(() => revokeStripe(request.body));
    yield put({ type: actions.REVOKE_STRIPE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REVOKE_STRIPE_FAILED, error });
  }
}

function* updateAdministratorRequest(request) {
  try {
    const context = yield call(() => updateAdministrator(request.body));
    yield put({ type: actions.UPDATE_ADMINISTRATOR_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_ADMINISTRATOR_FAILED, error });
  }
}

function* updateAdministratorInvitationRequest(request) {
  try {
    const context = yield call(() => updateAdministratorInvitation(request.body));
    yield put({ type: actions.UPDATE_ADMINISTRATOR_INVITATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_ADMINISTRATOR_INVITATION_FAILED, error });
  }
}

function* updateClientRequest(request) {
  try {
    const context = yield call(() => updateClient(request.body));
    yield put({ type: actions.UPDATE_CLIENT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CLIENT_FAILED, error });
  }
}

function* updateConfigurationRequest(request) {
  try {
    const context = yield call(() => updateConfiguration(request.body));
    yield put({ type: actions.UPDATE_CLIENT_OPTIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CLIENT_OPTIONS_FAILED, error });
  }
}

function* updateGatewayRequest(request) {
  try {
    const context = yield call(() => updateGateway(request.body));
    yield put({ type: actions.UPDATE_CLIENT_GATEWAY_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CLIENT_GATEWAY_FAILED, error });
  }
}

function* updateSettingsRequest(request) {
  try {
    const context = yield call(() => updateSettings(request.body));
    yield put({ type: actions.UPDATE_CLIENT_SETTINGS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CLIENT_SETTINGS_FAILED, error });
  }
}

function* updateSocialRequest(request) {
  try {
    const context = yield call(() => updateSocial(request.body));
    yield put({ type: actions.UPDATE_CLIENT_SOCIAL_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CLIENT_SOCIAL_FAILED, error });
  }
}

function* uploadLogoRequest(request) {
  try {
    const context = yield call(() => uploadLogo(request.body));
    yield put({ type: actions.UPLOAD_LOGO_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPLOAD_LOGO_FAILED, error });
  }
}

function* apiFailed(request) {
  toast.error(request.error.message);
  if (request.error.status === 403) {
    yield put({ type: userActions.CLEAR_USER });
  }
}

function* clientSaga() {
  yield takeEvery(actions.AUTHORIZE_SQUARE_REQUEST, authorizeSquareRequest);
  yield takeEvery(actions.AUTHORIZE_STRIPE_REQUEST, authorizeStripeRequest);
  yield takeEvery(actions.GET_CLIENT_REQUEST, getClientRequest);
  yield takeEvery(actions.GET_CLIENT_INVOICES_REQUEST, getClientInvoicesRequest);
  yield takeEvery(actions.GET_CLIENT_LIST_REQUEST, getClientListRequest);
  yield takeEvery(actions.GET_CLIENT_VOLUNTEER_LIST_REQUEST, getClientVolunteerListRequest);
  yield takeEvery(actions.PAY_INVOICE_REQUEST, payInvoiceRequest);
  yield takeEvery(actions.REMOVE_ADMINISTRATOR_REQUEST, removeAdministratorRequest);
  yield takeEvery(actions.REMOVE_ADMINISTRATOR_INVITATION_REQUEST, removeAdministratorInvitationRequest);
  yield takeEvery(actions.REMOVE_LOGO_REQUEST, removeLogoRequest);
  yield takeEvery(actions.REVOKE_SQUARE_REQUEST, revokeSquareRequest);
  yield takeEvery(actions.REVOKE_STRIPE_REQUEST, revokeStripeRequest);
  yield takeEvery(actions.UPDATE_ADMINISTRATOR_REQUEST, updateAdministratorRequest);
  yield takeEvery(actions.UPDATE_ADMINISTRATOR_INVITATION_REQUEST, updateAdministratorInvitationRequest);
  yield takeEvery(actions.UPDATE_CLIENT_REQUEST, updateClientRequest);
  yield takeEvery(actions.UPDATE_CLIENT_GATEWAY_REQUEST, updateGatewayRequest);
  yield takeEvery(actions.UPDATE_CLIENT_OPTIONS_REQUEST, updateConfigurationRequest);
  yield takeEvery(actions.UPDATE_CLIENT_SETTINGS_REQUEST, updateSettingsRequest);
  yield takeEvery(actions.UPDATE_CLIENT_SOCIAL_REQUEST, updateSocialRequest);
  yield takeEvery(actions.UPLOAD_LOGO_REQUEST, uploadLogoRequest);

  yield takeEvery(actions.AUTHORIZE_SQUARE_FAILED, apiFailed);
  yield takeEvery(actions.AUTHORIZE_STRIPE_FAILED, apiFailed);
  yield takeEvery(actions.GET_CLIENT_FAILED, apiFailed);
  yield takeEvery(actions.GET_CLIENT_INVOICES_FAILED, apiFailed);
  yield takeEvery(actions.GET_CLIENT_LIST_FAILED, apiFailed);
  yield takeEvery(actions.GET_CLIENT_VOLUNTEER_LIST_FAILED, apiFailed);
  yield takeEvery(actions.PAY_INVOICE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_ADMINISTRATOR_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_ADMINISTRATOR_INVITATION_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_LOGO_FAILED, apiFailed);
  yield takeEvery(actions.REVOKE_SQUARE_FAILED, apiFailed);
  yield takeEvery(actions.REVOKE_STRIPE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_ADMINISTRATOR_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_ADMINISTRATOR_INVITATION_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CLIENT_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CLIENT_GATEWAY_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CLIENT_OPTIONS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CLIENT_SETTINGS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CLIENT_SOCIAL_FAILED, apiFailed);
  yield takeEvery(actions.UPLOAD_LOGO_FAILED, apiFailed);
}

export default clientSaga;
