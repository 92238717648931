import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes, sponsorSizes } from 'src/constants';
import { updateEventSponsorTierRequest } from 'src/redux/actions/events';

const SponsorTierForm = ({ sponsorTier, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const dispatch = useDispatch();

  const handleSubmit = (evt) => {
    evt.sequence = evt.sequence || event.sponsorTiers?.length || 0;
    dispatch(updateEventSponsorTierRequest({ clientURL: client.url, eventURL: event.url, form: {...(sponsorTier || {}), ...evt} }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Sponsor Tier Name',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: sponsorTier?.name || '',
          required: true
        }]
      }, {
        elements: [{
          label: 'Sponsor Rendering Size',
          name: 'size',
          type: fieldTypes.select,
          options: sponsorSizes,
          value: sponsorTier?.size || 'small',
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="sponsor-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_EVENT_SPONSOR_TIER'}
      />
    </section>
  )
}

export default SponsorTierForm;
