export const ACTIVE_CLOSE_DIALOG = 'ACTIVE_CLOSE_DIALOG';
export const ACTIVE_CLOSE_DIALOG_SUCCESS = 'ACTIVE_CLOSE_DIALOG_SUCCESS';

export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const CLOSE_DIALOG_SUCCESS = 'CLOSE_DIALOG_SUCCESS';

export const OPEN_DIALOG = 'OPEN_DIALOG';
export const OPEN_DIALOG_SUCCESS = 'OPEN_DIALOG_SUCCESS';

export function activeCloseDialog() {
  return {
    type: ACTIVE_CLOSE_DIALOG
  }
}

export function closeDialog() {
  return {
    type: CLOSE_DIALOG
  }
}

export function openDialog (body) {
  return {
    body,
    type: OPEN_DIALOG
  }
}
