import React, { useState } from 'react';
import { TextField } from '@mui/material';

const TextareaField = ({
  element,
  error,
  handleChange,
  handleBlur,
  value
}) => {
  const [touched, setTouched] = useState(false);
  const handleTouched = () => {
    setTouched(true);
  };

  return (
    <div className={`form-field ${element.class || 'flex-1'}`}>
      <label>
        <span>
          {element.required && (<span>* </span>)}
          {element.label}
        </span>
      </label>
      <TextField
        autoComplete={element.autoComplete || 'new-password'}
        disabled={element.disabled}
        error={!!(touched && error)}
        helperText={touched && error ? error : null}
        id={element.name}
        maxRows={8}
        minRows={2}
        multiline
        name={element.name}
        onChange={(e) => {handleChange(e.target.value, element)}}
        onBlur={handleBlur}
        onFocus={handleTouched}
        required={element.required}
        type="text"
        value={value || ''}
      />
    </div>
  )
}

export default TextareaField;
