import React from 'react';
import TinyMCE from 'src/components/TinyMCE';

const HomepageColumnTextForm = ({ column, handleClose, onSubmit, row }) => {
  const handleSubmit = (event) => {
    onSubmit({ column, event, row });
    handleClose();
  }

  return (
    <TinyMCE
      handleSubmit={(event => handleSubmit(event))}
      handleCancel={handleClose}
      requestPrefix=""
      value={column?.content?.text}
    />
  )
}

export default HomepageColumnTextForm;
