export const formFieldTypes = {
  heading: 'heading',
  paragraph: 'paragraph',
  input: 'input',
  image: 'image',
  checkbox: 'checkbox',
  multiSelect: 'multiSelect',
  radio: 'radio',
  textarea: 'textarea'
}

export const entryFormInputTypes = {
  date: 'date',
  email: 'email',
  phone: 'phone',
  text: 'text'
}

export const formFieldOptions = [{
  label: 'Title',
  value: formFieldTypes.heading
}, {
  label: 'Paragraph',
  value: formFieldTypes.paragraph
}, {
  label: 'Short Answer',
  value: formFieldTypes.input
}, {
  label: 'Image',
  value: formFieldTypes.image
}, {
  label: 'Checkbox',
  value: formFieldTypes.checkbox
}, {
  label: 'Multi-Select',
  value: formFieldTypes.multiSelect
}, {
  label: 'Radio',
  value: formFieldTypes.radio
}, {
  label: 'Textarea',
  value: formFieldTypes.textarea
}];

export const entryFormInputOptions = [{
  label: 'Plain Text',
  value: entryFormInputTypes.text
}, {
  label: 'Email Address',
  value: entryFormInputTypes.email
}, {
  label: 'Phone Number',
  value: entryFormInputTypes.phone
}, {
  label: 'Date',
  value: entryFormInputTypes.date
}];
