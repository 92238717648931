import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import { logoutRequest } from 'src/redux/actions/users';
import { openModal } from 'src/redux/actions/modal';
import { Link, useNavigate } from 'react-router-dom';

const LoginStateComponent = () => {
  const user = useSelector(state => state.userStore?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = () => {
    dispatch(logoutRequest());
    handleClose();
  }

  const goToActionPanel = () => {
    dispatch(openModal({
      key: 'actionPanel'
    }));
    handleClose();
  }

  const goToAccount = () => {
    navigate('/account');
    handleClose();
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <section className="login-state">
      {
        user && <div className="login-state-user">
          <div className="login-state-user-profile" onClick={handleMenu}>
            <img className="login-state-user-profile-image" src={user.headshot} />
            <div className="login-state-user-profile-details">
              <div className="login-state-user-profile-details-name">{user?.fullName}</div>
              <div className="login-state-user-profile-details-email">{user?.email}</div>
            </div>
          </div>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={handleClose}
            sx={{ marginTop: '10px' }}
          >
            <MenuItem onClick={() => goToActionPanel()}>My Action Panel</MenuItem>
            <MenuItem onClick={() => goToAccount()}>My Account</MenuItem>
            <MenuItem onClick={() => logout()}>Logout</MenuItem>
          </Menu>
        </div>
      }
      {
        !user && <div className="login-state-signin">
          { user === false ? <Link href="/login">Sign In</Link> : <LoadingCircle /> }
        </div>
      }
    </section>
  );
}

export default LoginStateComponent;
