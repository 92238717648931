import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox } from '@mui/material';
import LoadingCircles from 'src/components/Elements/LoadingCircles';
import { createContestArchiveRequest } from 'src/redux/actions/contests';

const ScholarshipArchiveForm = ({ archive, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const isCreating = useSelector(state => state.loadingStore?.CREATE_CONTEST_ARCHIVE);
  const [applicationMap, setApplicationMap] = useState();
  const [formState, setFormState] = useState();
  const [list, setList] = useState();
  const [filesOnly, setFilesOnly] = useState();
  const dispatch = useDispatch();

  const handleBlur = () => {}

  const handleChange = (checked, entryID) => {
    setFormState({
      ...formState,
      [entryID]: checked
    });
  }

  const setItems = () => {
    const items = (contest?.archiveScholarshipList || [])?.map(scholarship => {
      const applications = scholarship?.applications?.filter(application => filesOnly ?
        (!application.disqualified && !!application.complete) : true);

      return {
        ...scholarship,
        applications
      };
    }).filter(scholarship => scholarship.applications?.length > 0);

    return items;
  }

  useEffect(() => {
    const map = new Map();
    (contest?.archiveScholarshipList || []).forEach(scholarship => {
      (scholarship.applications || []).forEach(application => {
        map.set(application.entryID, { ...application, scholarshipID: scholarship.scholarshipID });
      });
    });
    setApplicationMap(map);
  }, [contest?.archiveScholarshipList]);

  useEffect(() => {
    setList(setItems());
  }, [filesOnly])

  useEffect(() => {
    const state = {};
    if (archive?.payload) {
      (archive?.payload || []).forEach(item => state[item] = true);
    }
    setFormState(state);
  }, [archive?.payload])

  const handleSubmit = () => {
    const applications = Object.keys(formState).map(entryID => applicationMap.get(entryID));

    dispatch(createContestArchiveRequest({ clientURL: client.url, contestURL: contest.url, form: { applications, filesOnly }}));
  }

  const toggleCheckAll = (event) => {
    const state = {};
    list.forEach(scholarship => {
      (scholarship.applications || []).forEach(application => {
        state[application.entryID] = !!event.target.checked;
      });
    });
    setFormState(state);
  }

  const toggleCheckWinners = (event) => {
    const state = {};
    list.forEach(scholarship => {
      (scholarship.applications || []).forEach(application => {
        if (!!(application.awards || [])?.length) {
          state[application.entryID] = !!event.target.checked;
        }
      });
    });
    setFormState(state);
  }

  const toggleCheckFilesOnly = (event) => {
    setFilesOnly(!!event.target.checked);
  }

  return (
    <section className={`archive-form${archive?.archiveID ? ' disabled' : ''}`}>
      <div className="archive-form-options">
        <div className="archive-form-options-item">
          <label>
            <Checkbox
              disabled={archive?.archiveID}
              name="winners"
              onBlur={handleBlur}
              onChange={toggleCheckWinners}
            />Select Winners Only
          </label>
        </div>
        <div className="archive-form-options-item">
          <label>
            <Checkbox
              disabled={archive?.archiveID}
              name="all"
              onBlur={handleBlur}
              onChange={toggleCheckAll}
            />Select All Applications
          </label>
        </div>
        <div className="archive-form-options-item">
          <label>
            <Checkbox
              disabled={archive?.archiveID}
              name="filesOnly"
              onBlur={handleBlur}
              onChange={toggleCheckFilesOnly}
            />Download Files Only
          </label>
        </div>
      </div>
      {
        (contest?.archiveScholarshipList || []).map(scholarship => {
          return (
            <div
              className="archive-form-category"
              key={scholarship.scholarshipID}
            >
              <div className="archive-form-category-title">
                {scholarship.name}
              </div>
              {
                (scholarship.applications || []).map(application => {
                  return (
                    <div
                      className={`archive-form-category-entry${!application.complete ? ' incomplete' : ''}${application.disqualified ? ' disqualified' : ''}`}
                      key={application.entryID}
                      onClick={() => { !archive?.archiveID && handleChange(!formState[application?.entryID], application?.entryID) }}
                    >
                      <div className="archive-form-category-entry-number">
                        <Checkbox
                          checked={!!(formState || {})[application?.entryID]}
                          disabled={!!archive?.archiveID}
                          name={application?.entryID}
                          onBlur={handleBlur}
                          onChange={e => handleChange(e.target.checked, application?.entryID)}
                        />
                        <span>{application?.applicantName}</span>
                      </div>
                      <div className="archive-form-category-entry-users">
                        {application?.applicantEmail}
                      </div>
                      <div className="archive-form-category-entry-awards">
                        {
                          (application?.awards || [])?.map(award => 
                            <span key={award?.awardID}>{award?.name}</span>
                          ).reduce((acc, item) => acc === null ? [item] : [...acc, ', ', item], null)
                        }
                      </div>
                    </div> 
                  )
                })
              }
            </div>
          )
        })
      }
      {
        !archive?.archiveID && (
          <div className="archive-form-buttons">
            <Button
              disabled={isCreating || !Object.keys(formState || {}).some(entryID => !!formState[entryID])}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
            >
              {isCreating && (
                <LoadingCircles />
              )}
              {!isCreating && (
                <>
                  Save
                </>
              )}
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
            >Cancel</Button>
          </div>
        )
      }
    </section>
  )
}

export default ScholarshipArchiveForm;
