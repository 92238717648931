import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ResourceList from 'src/components/ResourceList';

const Home = () => {
  const clients = useSelector(state => state.clientStore?.clients);
  const user = useSelector(state => state.userStore?.user);
  const [actions, setActions] = useState([]);
  const fetching = useSelector(state => state.loadingStore.GET_CLIENT_LIST);
  const navigate = useNavigate();
  const [permittableClients, setPermittableClients] = useState(null);

  const getPermittableClients = () => {
    return user?.admin ? clients : (clients || []).filter(client => !!client.active);
  }

  useEffect(() => {
    const clients = getPermittableClients();
    setPermittableClients(clients);
    if (clients?.length === 1) {
      navigate(`/${clients[0].url}`);
    }
  }, [clients]);

  useEffect(() => {
    if (user) {
      const actions = user?.admin ? [{
        label: 'Network Admin',
        variant: 'contained',
        fn: () => { navigate('/network') }
      }] : [];
      
      setActions([...actions, {
          label: 'Edit',
          variant: 'contained',
          requiresSelection: true,
          fn: client => {
            navigate(client.url);
          }
        }
      ]);
    }
  }, [user]);

  const filters = {
    searchBy: ['name']
  };

  return (
    <section className="client-list">
      { (permittableClients || [])?.length > 1 &&
        <ResourceList
          actions={actions}
          fetching={fetching}
          filters={filters}
          list={permittableClients}
          renderItem={item => 
            <Link
              className="client-list-item"
              to={item.url}
            >
              <div
                className="client-list-name"
              >{item.name}</div>
            </Link>
          }
          renderKey="clientID"
          title="My Admin Rights"
        />
      }
      {
        (!fetching && (permittableClients !== null && !(permittableClients || [])?.length)) && 
        <section className="box sorry">
          <h1>We Are Sorry</h1>
          <p>You do not have rights to access this content.</p>
        </section>
      }
    </section>
  )
}

export default Home;
