import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResourceList from 'src/components/ResourceList';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { getEventListRequest } from 'src/redux/actions/events';
import { Link, useNavigate } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import { updateDrawerContent } from 'src/redux/actions/drawer';

const Events = () => {
  const client = useSelector(state => state.clientStore?.client);
  const events = useSelector(state => state.eventStore?.events);
  const eventsFetching = useSelector(state => state.loadingStore.GET_EVENT_LIST);
  const navigate = useNavigate();
  
  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client },
          key: 'createEvent'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: event => {
      navigate(event.url);
    }
  }, {
    label: (event) => event?.archived ? 'Restore' : 'Archive',
    requiresSelection: true,
    fn: event => {
      dispatch(
        openDialog({
          props: { client, event },
          key: 'archiveEvent'
        })
      )
    }
  }];

  const filters = {
    searchBy: ['name'],
    options: {
      active: {
        fn: item => !item.archived,
        label: 'Active'
      },
      archived: {
        fn: item => !!item.archived,
        label: 'Archived'
      }
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'events', client }));
    dispatch(updateDrawerContent({ key: 'clientEvents', options: { client } }));

    if (!eventsFetching) {
      dispatch(getEventListRequest({ clientURL: client.url }));
    }
  }, [client]);

  return (
    <ResourceList
      actions={actions}
      fetching={eventsFetching}
      filters={filters}
      list={events}
      renderHeaders={() => 
        <div className="event-list">
          <div className="event-list-name">Name</div>
          <div className="event-list-status">Status</div>
        </div>
      }
      renderItem={item => 
        <div className={`event-list${item.archived ? ' archived' : ''}`}>
          <div className="event-list-name">
            <Link to={item.url}>{item.name}</Link>
          </div>
          <div className="event-list-status">{item.archived ? 'Archived' : 'Active'}</div>
        </div>
      }
      renderKey="name"
      title="Event List"
    />
  )
}

export default Events;
